import {UserNewModalForm} from './UserNewModalForm'

const UserNewModalFormWrapper = ({
    selectedListingId,
    setSelectedListingId,
    listing,
    getListing,
    openAddModal,
    setOpenAddModal
}) => {
    return <UserNewModalForm 
    selectedListingId={selectedListingId}
    setSelectedListingId={setSelectedListingId}
    listing={listing}
    getListing={getListing}
    openAddModal={openAddModal}
    setOpenAddModal={setOpenAddModal}   
    />

}

export {UserNewModalFormWrapper}
