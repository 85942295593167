import { KTIcon} from '../../../../_metronic/helpers'
import "./style.css"
import { PatternFormat } from "react-number-format";

const BlacklistSearchComponent = ({search, setSearch, type}) => {
  return (
    <div className="card-header border-0 pt-1" style={{ minHeight:'45px' }}>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative search-container'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        {type == 1 ? <input
          type={'text'}
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14 user-search-input'
          placeholder="Search by Email"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          autocomplete="off"
          readOnly
          onFocus={(e) => {
            e.target.readOnly = false
          }}
          onTouchStart={(e) => {
            e.target.readOnly = false
          }}
        />
          :
          <PatternFormat
            format={"+1 (###) ###-####"}
            className={"form-control form-control-solid w-250px ps-14 user-search-input"}
            value={search}
            onValueChange={(values) => {
              setSearch(values.value)
            }}
            placeholder={"Search by Phone Number"}

          />
        }
      </div>
      {/* end::Search */}
    </div>
  )
}

export { BlacklistSearchComponent }
