import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import opacity from "react-element-popper/animations/opacity";
import dayjs from "dayjs";
import "./style.css";

const ReportsHeader = ({
  range,
  setRange,
  setType,
  type,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}) => {
  return (
    <div
      className="card-header border-0 pt-6 justify-content-end align-items-center"
      style={{ flexWrap: 'nowrap' }}
    >
      <>
        <div style={{ marginRight: "auto", fontSize: "18px", fontWeight: 600, marginRight:'45px' }}>
          Overview
        </div>
        <div style={{ display: 'grid', columnGap: '12px', gridTemplateColumns: 'repeat(4, 1fr)' }}>
          <div className="mb-3">
            <label className="form-label fs-6 fw-bold">Booking Type:</label>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-kt-user-table-filter="two-step"
              data-hide-search="true"
              onChange={(e) => setType(e.target.value)}
              value={type}
            >
              <option value="">Select Type</option>
              <option value="1">Per Hour</option>
              <option value="2">Per Day</option>
              <option value="3">Per Week</option>
              <option value="4">Per Month</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label fs-6 fw-bold">Range:</label>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-kt-user-table-filter="role"
              data-hide-search="true"
              onChange={(e) => setRange(e.target.value)}
              value={range}
            >
              <option value="">Select Range</option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="fw-bold fs-6 mb-2">Start Date</label>
            <DatePicker
              style={{
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "8px",
                padding: "0.775rem 1rem",
                fontSize: "1.1rem",
                fontWeight: "500",
                lineHeight: "1.5",
                backgroundColor: "#f9f9f9",
                border: "1px solid #f9f9f9",
                color: "#5e6278",
                transition: "color 0.2s ease",
                height: "auto"
              }}
              containerStyle={{
                width: "100%"
              }}
              className="rmdp-mobile custom-calendar"
              arrow={false}
              value={startDate}
              onChange={(val) => {
                setStartDate(val);
              }}
              maxDate={dayjs(endDate).toDate()}
              format="dddd MMM DD,YYYY"
              fixMainPosition={true}
              animations={[opacity()]}
              placeholder="Select Start Date"
              weekDays={["S", "M", "T", "W", "T", "F", "S"]}
              scrollSensitive={false}
              hideOnScroll={false}
            />
          </div>
          <div className="mb-3">
            <label className="fw-bold fs-6 mb-2">End Date</label>
            <DatePicker
              style={{
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "8px",
                padding: "0.775rem 1rem",
                fontSize: "1.1rem",
                fontWeight: "500",
                lineHeight: "1.5",
                backgroundColor: "#f9f9f9",
                border: "1px solid #f9f9f9",
                color: "#5e6278",
                transition: "color 0.2s ease",
                height: "auto"
              }}
              containerStyle={{
                width: "100%"
              }}
              className="rmdp-mobile custom-calendar"
              arrow={false}
              value={endDate}
              onChange={(val) => {
                setEndDate(val);
              }}
              minDate={dayjs(startDate).toDate()}
              format="dddd MMM DD,YYYY"
              fixMainPosition={true}
              animations={[opacity()]}
              placeholder="Select End Date"
              weekDays={["S", "M", "T", "W", "T", "F", "S"]}
              scrollSensitive={false}
              hideOnScroll={false}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export { ReportsHeader };
