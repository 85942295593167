import { PendingListingModalForm } from "./PendingListingModalForm";

const PendingListingModalFormWrapper = ({
  selectedListingId,
  setSelectedListingId,
  openAdditionalUserModal,
  setOpenAdditionalUserModal,
  listing,
  getListing,
  setOpenDisableModal,
  openDisableModal,
  openNoteModal,
  setOpenNoteModal,
  note,
  setNote,
  notes,
  admins,
  adminNotes,
  noteId,
  setNoteId,
  deleteAdminNote
}) => {
  const itemIdForUpdate = null;
  const isLoading = false;

  if (selectedListingId) {
    return (
      <PendingListingModalForm
        isUserLoading={isLoading}
        user={{ id: undefined }}
        setSelectedListingId={setSelectedListingId}
        selectedListingId={selectedListingId}
        openAdditionalUserModal={openAdditionalUserModal}
        setOpenAdditionalUserModal={setOpenAdditionalUserModal}
        listing={listing}
        getListing={getListing}
        openDisableModal={openDisableModal}
        setOpenDisableModal={setOpenDisableModal}
        openNoteModal={openNoteModal}
        setOpenNoteModal={setOpenNoteModal}
        note={note}
        setNote={setNote}
        notes={notes}
        admins={admins}
        adminNotes={adminNotes}
        noteId={noteId}
        setNoteId={setNoteId}
        deleteAdminNote={deleteAdminNote}
      />
    );
  }
  return null;
};

export { PendingListingModalFormWrapper };
