import { KTIcon } from "../../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";

const RolesHeader = ({
selectedRole,
}) => {

  const navigate = useNavigate();

  return (
    <div className="card-header border-0 pt-6 justify-content-end align-items-center" style={{ flexWrap: "nowrap" }}>
      <div className="card-toolbar" style={{ gap: "5px" }}>
          <>
            <button
              type="button" className="btn" style={{ backgroundColor: "#2ecc71", color: "white" }}
              onClick={() => {
                navigate(`/view-role/${selectedRole?.role_id}`)
              }}
            >
              <KTIcon iconName={'eye'} className="fs-2 text-white" />
              View Role
            </button>
          </>
      </div>
    </div>
  );
};

export { RolesHeader };
