import React, { useEffect } from "react";
import { useState } from "react";
import { KTCard } from "../../../_metronic/helpers";
import "./style.css";
import { ReportsHeader } from "./header/ReportsHeader";
import axios from "axios";
import { CChart } from "@coreui/react-chartjs";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useAuth } from "../auth";

const hours = ["12 AM", "01 AM", "02 AM", "03 AM", "04 AM", "05 AM", "06 AM", "07 AM", "08 AM", "09 AM", "10 AM", "11 AM", "12 PM", "01 PM", "02 PM", "03 PM", "04 PM", "05 PM", "06 PM", "07 PM", "08 PM", "09 PM", "10 PM", "11 PM"];

const options = {
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        color: "#4a4a4a"
      }
    }
  },
  scales: {
    x: {
      grid: {
        color: "transparent"
      },
      ticks: {
        color: "#4a4a4a"
      }
    },
    y: {
      grid: {
        color: "transparent"
      },
      min: 0,
      ticks: {
        stepSize: 1,
        color: "#4a4a4a"
      }
    }
  }
};

const Reports = () => {
  const {currentUser, haveAccess} = useAuth();
  const [password, setPassword] = useState("");
  const [isPasswordValid, setPasswordValid] = useState(true);
  const [range, setRange] = useState("month");
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    switch (range) {
      case "day":
        setStartDate(dayjs().toDate());
        setEndDate(dayjs().toDate());
        break;
      case "week":
        setStartDate(dayjs().add(-1, "week").toDate());
        setEndDate(dayjs().toDate());
        break;
      case "month":
        setStartDate(dayjs().add(-1, "month").toDate());
        setEndDate(dayjs().toDate());
        break;
      case "year":
        setStartDate(dayjs().add(-1, "year").toDate());
        setEndDate(dayjs().toDate());
        break;
      default:
        break
    }
  }, [range]);

  const [chartsData, setChartsData] = useState([]);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [reportsData, setReportsData] = useState(null);
  const [step, setStep] = useState("");
  const [labels, setLabels] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const [payoutData, setPayoutData] = useState([]);
  const [successfulPaymentsData, setSuccessfulPaymentsData] = useState([]);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [failedPaymentsData, setFailedPaymentsData] = useState([]);
  const [refundsData, setRefundsData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [subscriptionsByYear, setSubscriptionsByYear] = useState([]);

  const getDayLabels = (start, end) => {
    const dateFormat = "YYYY-MM-DD";
    const dateRange = [];

    let currentDate = dayjs(start, dateFormat);
    const endDateObj = dayjs(end, dateFormat);

    while (currentDate.isBefore(endDateObj) || currentDate.isSame(endDateObj)) {
      dateRange.push(currentDate.format(dateFormat));
      currentDate = currentDate.add(1, "day");
    }

    return dateRange;
  };

  const getWeekLabels = (start, end) => {
    const dateFormat = "YYYY-MM-DD";
    const weekFormat = "YYYY-MM-DD";
    const weekStartDates = [];

    let currentWeekStart = dayjs(start, dateFormat).startOf("week").day(1);
    const endOfWeek = dayjs(end, dateFormat).endOf("week").day(1);

    while (currentWeekStart.isBefore(endOfWeek) || currentWeekStart.isSame(endOfWeek)) {
      weekStartDates.push(currentWeekStart.format(weekFormat));
      currentWeekStart = currentWeekStart.add(7, "day");
    }

    return weekStartDates;
  };

  useEffect(() => {
    if(isPasswordValid){
    axios.get(`${process.env.REACT_APP_API_V2_URL}/reports`)
        .then(res => {
          setReportsData(res.data);
        });
      }
  },[isPasswordValid])

  useEffect(() => {
    if (startDate && endDate && isPasswordValid) {
      axios.get(`${process.env.REACT_APP_API_V2_URL}/reports/charts?start_date=${dayjs(startDate).format("YYYY-MM-DD")}&end_date=${dayjs(endDate).add(1, "day").format("YYYY-MM-DD")}&booking_type=${type}`)
        .then(res => {
          setChartsData(res.data.data);
          setStep(res.data.steps);
        });
    }

    if(isPasswordValid){
    axios.get(`${process.env.REACT_APP_API_URL}/user?page_size=1&page=1`).then(res => {
      setTotalUsers(res.data.total);
    })
  }
  }, [startDate, endDate, type, isPasswordValid]);

  useEffect(() => {
    if (startDate && endDate && isPasswordValid) {
      axios.get(`${process.env.REACT_APP_API_V2_URL}/reports/analytics?start_date=${dayjs(startDate).format("YYYY-MM-DD")}&end_date=${dayjs(endDate).add(1, "day").format("YYYY-MM-DD")}`)
        .then(res => {
          setAnalyticsData(res.data);
        });
    }
  }, [startDate, endDate, isPasswordValid]);

  useEffect(() => {
    if (step === "hour") {
      setLabels(hours);
    } else if (step === "day") {
      const result = getDayLabels(startDate, endDate);
      setLabels(result);
    } else if (step === "week") {
      const result = getWeekLabels(startDate, endDate);
      setLabels(result);
    } else {
      setLabels([]);
    }
  }, [endDate, startDate, step]);

  useEffect(() => {
    if (chartsData) {
      getData();
    }
  }, [labels, type, chartsData]);

  const getHourData = (key, chartName) => {
    let result = [];

    for (let el of labels) {
      const target = chartsData[chartName].find(record => moment.utc(record.time_stamp).format("hh A") === el);

      if (target) {
        result.push(key === "total" || key === "payout_amount" ? target[key] / 100 : target[key]);
      } else {
        result.push(0);
      }
    }

    return result;
  };

  const getDayData = (key, chartName) => {
    let result = [];
    for (let el of labels) {
      const target = chartsData[chartName].find(record => dayjs(record.time_stamp).format("YYYY-MM-DD") === el);

      if (target) {
        result.push(key === "total" || key === "payout_amount" ? target[key] / 100 : target[key]);
      } else {
        result.push(0);
      }
    }


    return result;
  };

  const getWeekData = (key, chartName) => {
    let result = [];

    for (let el of labels) {
      const target = chartsData[chartName].find(record => dayjs(record.time_stamp).format("YYYY-MM-DD") === el);

      if (target) {
        result.push(key === "total" || key === "payout_amount" ? target[key] / 100 : target[key]);
      } else {
        result.push(0);
      }
    }

    return result;
  };


  const getData = () => {
    let new_customers = [];
    let total = [];
    let payout_amount = [];
    let successful_payments = [];
    let active_subscriptions = [];
    let failed_payments = [];
    let refunds = [];

    if (step === "hour") {
      new_customers = getHourData("users", "new_customers");
      total = getHourData("total", "payout_data");
      payout_amount = getHourData("payout_amount", "payout_data");
      successful_payments = getHourData("payments", "successful_payments");
      active_subscriptions = getHourData("subscriptions", "active_subscriptions");
      failed_payments = getHourData("payments", "failed_payments");
      refunds = getHourData("refunds", "refunds");
    } else if (step === "day") {
      new_customers = getDayData("users", "new_customers");
      total = getDayData("total", "payout_data");
      payout_amount = getDayData("payout_amount", "payout_data");
      successful_payments = getDayData("payments", "successful_payments");
      active_subscriptions = getDayData("subscriptions", "active_subscriptions");
      failed_payments = getDayData("payments", "failed_payments");
      refunds = getDayData("refunds", "refunds");
    } else if (step === "week") {
      new_customers = getWeekData("users", "new_customers");
      total = getWeekData("total", "payout_data");
      payout_amount = getWeekData("payout_amount", "payout_data");
      successful_payments = getWeekData("payments", "successful_payments");
      active_subscriptions = getWeekData("subscriptions", "active_subscriptions");
      failed_payments = getWeekData("payments", "failed_payments");
      refunds = getWeekData("refunds", "refunds");
    }

    setCustomersData([
      {
        label: "New Customers",
        backgroundColor: "#ffa459",
        data: new_customers
      }
    ]);

    setPayoutData([
      {
        label: "Total",
        backgroundColor: "#ffa459",
        data: total
      },
      {
        label: "Transferred to PM",
        backgroundColor: "#96ff59",
        data: payout_amount
      },
      {
        label: "Net Volume to TPC",
        backgroundColor: "#164bff",
        data: total.map((el, index) => el - payout_amount[index])
      }
    ]);

    setSuccessfulPaymentsData([
      {
        label: "Successful Payments",
        backgroundColor: "#ffa459",
        data: successful_payments
      }
    ]);

    setSubscriptionsData([
      {
        label: "Active Subscriptions",
        backgroundColor: "#ffa459",
        data: active_subscriptions
      }
    ]);

    setFailedPaymentsData([
      {
        label: "Failed Payments",
        backgroundColor: "#ffa459",
        data: failed_payments
      }
    ]);

    setRefundsData([
      {
        label: "Refunds",
        backgroundColor: "#ffa459",
        data: refunds
      }
    ]);
  };

  function calculateTotalBookingsByYear(data, year) {
    const result = [];
      const bookings = Array.from({ length: 12 }, (_, monthIndex) => {
        const monthData = data.find(entry => entry.year === year && entry.month == (monthIndex + 1));
        return monthData ? monthData.total_bookings : 0;
      });
      result.push(...bookings);
  
    return result;
  }
  
useEffect(() => {
  if(reportsData?.subscriptions_by_month && reportsData?.subscriptions_by_month.length > 0) { 
  let years = [...new Set(reportsData?.subscriptions_by_month.map(el => el.year))]
  const totalSubscriptionsByYear = years.map(el => {
    const target =  calculateTotalBookingsByYear(reportsData?.subscriptions_by_month, el);
    return {label: el, data: target, backgroundColor: el >= new Date().getFullYear() ? "#ffa459" : "#96ff59", hidden: el >= new Date().getFullYear() ? false : true}
  })
  setSubscriptionsByYear(totalSubscriptionsByYear)
}

},[reportsData])


  // if (!isPasswordValid) {
  //   return (
  //     <div className="d-flex column-gap-3">
  //       <input
  //         type='password'
  //         className='form-control form-control-sm form-control-solid w-250px ps-6'
  //         value={password}
  //         onChange={(e) => setPassword(e.target.value)}
  //         placeholder='Enter Password'
  //         style={{ marginRight: '12px', height: '44px', backgroundColor: "#fff", border: '1px solid #ddd' }}
  //       />
  //       <button
  //         type='button'
  //         className='btn'
  //         style={{ backgroundColor: "#2ecc71", color: "white", marginRight: 'auto' }}
  //         onClick={() => {
  //           if (password === '@Tpc#2024') {
  //             setPasswordValid(true)
  //           } else {
  //             setPassword('')
  //             toast.error('Incorrect password')
  //           }
  //         }}
  //       >
  //         Submit
  //       </button>
  //     </div>
  //   )
  // }
  return (
    <KTCard>
    {(haveAccess(currentUser,  'reports_charts')) && 
     <ReportsHeader
        range={range}
        setRange={setRange}
        setType={setType}
        type={type}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
  }
      <div style={{ padding: "8px 2.25rem", display: "flex", flexWrap: "wrap",  gap: "10px" }}>
        <h4>Total Customers: <span style={{color:'rgb(255, 164, 41)', fontWeight:800}}>{totalUsers}</span></h4>
       {(haveAccess(currentUser,  'reports_v2')) &&    <>
        <h4>Total Public Spaces: <span style={{color:'rgb(255, 164, 41)', fontWeight:800}}>{reportsData?.seats?.total}</span></h4>
        <h4>Total Booked Spaces: <span style={{color:'rgb(255, 164, 41)', fontWeight:800}}>{reportsData?.seats?.booked}</span></h4>
        <h4>Currently Booked Spaces: <span style={{color:'rgb(255, 164, 41)', fontWeight:800}}>{reportsData?.seats?.booked_now}</span></h4>
        <h4>Booking Coverage: <span style={{color:'rgb(255, 164, 41)', fontWeight:800}}>{reportsData?.seats?.coverage ? `${reportsData?.seats?.coverage}%` : 0}</span></h4>
        <h4>Total Active Listing: <span style={{color:'rgb(255, 164, 41)', fontWeight:800}}>{reportsData?.listings?.find((el) => el.status == 1) ? reportsData?.listings?.find((el) => el.status == 1).count : 0}</span></h4>
        <h4>Total Pending Listing: <span style={{color:'rgb(255, 164, 41)', fontWeight:800}}>{reportsData?.listings?.find((el) => el.status == 4) ? reportsData?.listings?.find((el) => el.status == 4).count : 0}</span></h4>
      </>}
      </div>
      {(haveAccess(currentUser,  'reports_charts')) && <div style={{ padding: "8px 2.25rem", display: "flex", flexWrap: "wrap", gap: "40px 20px" }}>
        <div style={{ width: "45%", display: "flex", flexDirection: "column", rowGap: "10px" }}>
          <div>New Customers <span style={{color:'#ffa459', fontWeight:700}}>({customersData[0]?.data?.reduce((prev, curr) => prev + curr, 0)})</span></div>
          <CChart
            type="line"
            data={{
              labels,
              datasets: customersData
            }}
            options={options}
          />
        </div>
        <div style={{ width: "45%", display: "flex", flexDirection: "column", rowGap: "10px" }}>
          <div>Payout Data <span style={{color:'#ffa459', fontWeight:700}}>(Total: ${payoutData?.find((el) => el.label === "Total")?.data?.reduce((prev, curr) => prev + curr, 0)?.toFixed(2)}, Transferred: ${payoutData?.find((el) => el.label === "Transferred to PM")?.data?.reduce((prev, curr) => prev + curr, 0)?.toFixed(2)}, TPC: ${payoutData?.find((el) => el.label === "Net Volume to TPC")?.data?.reduce((prev, curr) => prev + curr, 0)?.toFixed(2)})</span></div>
          <CChart
            type="line"
            data={{
              labels,
              datasets: payoutData
            }}
            options={options}
          />
        </div>
        <div style={{ width: "45%", display: "flex", flexDirection: "column", rowGap: "10px" }}>
          <div>Successful Payments <span style={{color:'#ffa459', fontWeight:700}}>({successfulPaymentsData[0]?.data?.reduce((prev, curr) => prev + curr, 0)})</span></div>
          <CChart
            type="line"
            data={{
              labels,
              datasets: successfulPaymentsData
            }}
            options={options}
          />
        </div>
        <div style={{ width: "45%", display: "flex", flexDirection: "column", rowGap: "10px" }}>
          <div>Active Subscriptions <span style={{color:'#ffa459', fontWeight:700}}>({subscriptionsData[0]?.data?.reduce((prev, curr) => prev + curr, 0)})</span></div>
          <CChart
            type="line"
            data={{
              labels,
              datasets: subscriptionsData
            }}
            options={options}
          />
        </div>
        <div style={{ width: "45%", display: "flex", flexDirection: "column", rowGap: "10px" }}>
          <div>Failed Payments <span style={{color:'#ffa459', fontWeight:700}}>({failedPaymentsData[0]?.data?.reduce((prev, curr) => prev + curr, 0)})</span></div>
          <CChart
            type="line"
            data={{
              labels,
              datasets: failedPaymentsData
            }}
            options={options}
          />
        </div>
        <div style={{ width: "45%", display: "flex", flexDirection: "column", rowGap: "10px" }}>
          <div>Refunds <span style={{color:'#ffa459', fontWeight:700}}>({refundsData[0]?.data?.reduce((prev, curr) => prev + curr, 0)})</span></div>
          <CChart
            type="line"
            data={{
              labels,
              datasets: refundsData
            }}
            options={options}
          />
        </div>
     {(haveAccess(currentUser,  'reports_v2')) &&   <div style={{ width: "45%", display: "flex", flexDirection: "column", rowGap: "10px" }}>
          <div>Active Subscription By Month <span style={{color:'#ffa459', fontWeight:700}}>({subscriptionsByYear?.sort((a,b) => a.label - b.label)?.map((el) => `${el.label}: ${el.data.reduce((prev, curr) => prev + curr, 0)}`).join(", ")})</span> </div>
          <CChart
            type="line"
            data={{
              labels:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
              datasets: subscriptionsByYear
            }}
           options={options}
          />
        </div>
        }
      </div>
      }
      {analyticsData && (haveAccess(currentUser,  'reports_analytics')) && (
        <div className="analytics">
          <div className="d-flex flex-column">
            <div className="form-label fs-6 fw-bold">Average Spend</div>
            <div className="analytics-value">${(analyticsData.average_spend / 100).toFixed(2)}</div>
          </div>
          <div className="d-flex flex-column">
            <div className="form-label fs-6 fw-bold">Average Daily</div>
            <div className="analytics-value">${(analyticsData.average_daily / 100).toFixed(2)}</div>
          </div>
          <div className="d-flex flex-column">
            <div className="form-label fs-6 fw-bold">Average Subscription</div>
            <div className="analytics-value">${(analyticsData.average_subscription / 100).toFixed(2)}</div>
          </div>
          <div className="d-flex flex-column">
            <div className="form-label fs-6 fw-bold">New Listings</div>
            <div className="analytics-value">{analyticsData.new_listings}</div>
          </div>
        </div>
      )}
      {analyticsData && (haveAccess(currentUser,  'reports_analytics')) && (
        <div className="analytics">
          <div className="d-flex flex-column">
            <div className="form-label fs-6 fw-bold">Booking Delta</div>
            <div className="analytics-value">
              {analyticsData.booking_delta
                ? (
                  <>
                    {analyticsData.booking_delta.days > 0 && `${analyticsData.booking_delta.days} ${analyticsData.booking_delta.days === 1 ? "day " : "days "}`}
                    {analyticsData.booking_delta.hours > 0 && `${analyticsData.booking_delta.hours} ${analyticsData.booking_delta.hours === 1 ? "hour " : "hours "}`}
                    {analyticsData.booking_delta.minutes > 0 && `${analyticsData.booking_delta.minutes} ${analyticsData.booking_delta.minutes === 1 ? "minute " : "minutes "}`}
                  </>
                )
                : "-"}
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="form-label fs-6 fw-bold">Daily Booking Delta</div>
            <div className="analytics-value">
              {analyticsData.daily_booking_delta
                ? (
                  <>
                    {analyticsData.daily_booking_delta.days > 0 && `${analyticsData.daily_booking_delta.days} ${analyticsData.daily_booking_delta.days === 1 ? "day " : "days "}`}
                    {analyticsData.daily_booking_delta.hours > 0 && `${analyticsData.daily_booking_delta.hours} ${analyticsData.daily_booking_delta.hours === 1 ? "hour " : "hours "}`}
                    {analyticsData.daily_booking_delta.minutes > 0 && `${analyticsData.daily_booking_delta.minutes} ${analyticsData.daily_booking_delta.minutes === 1 ? "minute " : "minutes "}`}
                  </>
                )
                : "-"}
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="form-label fs-6 fw-bold">Monthly Booking Delta</div>
            <div className="analytics-value">
              {analyticsData.monthly_booking_delta
                ? (
                  <>
                    {analyticsData.monthly_booking_delta.days > 0 && `${analyticsData.monthly_booking_delta.days} ${analyticsData.monthly_booking_delta.days === 1 ? "day " : "days "}`}
                    {analyticsData.monthly_booking_delta.hours > 0 && `${analyticsData.monthly_booking_delta.hours} ${analyticsData.monthly_booking_delta.hours === 1 ? "hour " : "hours "}`}
                    {analyticsData.monthly_booking_delta.minutes > 0 && `${analyticsData.monthly_booking_delta.minutes} ${analyticsData.monthly_booking_delta.minutes === 1 ? "minute " : "minutes "}`}
                  </>
                )
                : "-"}
            </div>
          </div>
        </div>
      )}
      {analyticsData && (haveAccess(currentUser,  'reports_analytics')) && (
        <div className="analytics last">
          <div className="d-flex flex-column">
            <div className="form-label fs-6 fw-bold">New Listings By State</div>
            {analyticsData.listings_by_state.map(el => (
              <div
                key={el.state}
                className="analytics-value"
              >
                {el.state}: <span>{el.total}</span>
              </div>
            ))}
            {!analyticsData.listings_by_state.length && '-'}
          </div>
          <div className="d-flex flex-column">
            <div className="form-label fs-6 fw-bold">Top Customers</div>
            <div className="analytics-value">
              {analyticsData.top_customers.map(el => (
                <div
                  key={el.customer_id}
                  className="analytics-value"
                >
                  {el.customer_name}: <span>${(el.spent_amount / 100).toFixed(2)}</span>
                </div>
              ))}
            </div>
            {!analyticsData.top_customers.length && '-'}
          </div>
          <div className="d-flex flex-column">
            <div className="form-label fs-6 fw-bold">Subscription Data</div>
            <div className="analytics-value">
              MRR: <span>${(analyticsData.subscription_data.mrr / 100).toFixed(2)}</span>
            </div>
            <div className="analytics-value">
              Average Spend Per User: <span>${(analyticsData.subscription_data.average_per_user / 100).toFixed(2)}</span>
            </div>
            <div className="analytics-value">
              New Subscriptions: <span>{analyticsData.subscription_data.new_subscriptions}</span>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="form-label fs-6 fw-bold">Arrear Data</div>
            <div className="analytics-value">
              Count: <span>{analyticsData?.arrear_data?.count}</span>
            </div>
            <div className="analytics-value">
              Total Charges: <span>${(analyticsData.arrear_data?.original_amount / 100).toFixed(2)}</span>
            </div>
            <div className="analytics-value">
              Penalty: <span>${(analyticsData.arrear_data?.penalty / 100).toFixed(2)}</span>
            </div>
          </div>
        </div>
      )}
    </KTCard>
  );
};

export default Reports;
