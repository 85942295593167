import React, { useEffect } from "react";
import { useState } from "react";
import { KTCard, useDebounce } from "../../../_metronic/helpers";
import "./style.css";
import axios from "axios";
import { ListingsTable } from "./table/ListingsTable";
import { Header } from "./header/Header";
import { toast } from "react-toastify";
import { VideoTable } from "./table/VideoTable";
import { useAuth } from "../auth";

const PendingApprovals = () => {
  const { currentUser, haveAccess, pendingApprovalsCount } = useAuth();
  const [tab, setTab] = useState('listings')
  const [listings, setListings] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [videos, setVideos] = useState([]);


  useEffect(() => {
    setPage(1);
    setPageSize(10);
    setSearch('');
    setFilter('');
  }, [tab])


  const debouncedSearchTerm = useDebounce(search, 550);


  const fetchVideoApprovals = () => {
    axios.get(
      `${process.env.REACT_APP_API_URL}/listing/approvals?field_id=${filter}`
    ).then(res => {
      setVideos(res.data);
      setLoading(false);
    })
      .catch(err => {
        toast.error(err?.response?.data?.message || 'Could not fetch pending listings');
        setLoading(false);
      })
  }


  useEffect(() => {
    setLoading(true);
    if (tab === 'listings') {
      axios.get(
        `${process.env.REACT_APP_API_URL}/listing?page=${page}&page_size=${pageSize}&name=${debouncedSearchTerm}&sort=4&status=4`
      ).then(res => {
        setListings(res.data.data);
        setTotal(res.data.total);
        setLoading(false);
      })
        .catch(err => {
          toast.error(err?.response?.data?.message || 'Could not fetch pending listings');
          setLoading(false);
        })
    } else {
      fetchVideoApprovals()
    }
  }, [page, pageSize, debouncedSearchTerm, tab, filter])

  return (
    <KTCard>
      <Header search={search} setSearch={setSearch} tab={tab} setTab={setTab} filter={filter} setFilter={setFilter} count={pendingApprovalsCount} />
      {(haveAccess(currentUser,  (tab === 'listings' ? 'listing_list' : 'listing_video_approvals'))) ?
        tab === 'listings' ?
          <ListingsTable
            listings={listings}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            total={total}
            isLoading={isLoading}
          />
          :
          <VideoTable
            videos={videos}
            isLoading={isLoading}
            fetchVideoApprovals={fetchVideoApprovals}
          />
        :
        <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
          You do not have permission to view
        </div>
      }
    </KTCard>
  );
};

export default PendingApprovals;
