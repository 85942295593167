import { ListViewProvider, useListView } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { AdminsListHeader } from "./components/header/AdminsListHeader";
import { AdminsTable } from "./table/AdminsTable";
import { AdminEditModal } from "./admin-edit-modal/AdminEditModal";
import { AdminNewModal } from "./admin-new-modal/AdminNewModal";
import { KTCard } from "../../../../_metronic/helpers";

const AdminsList = () => {
  const { itemIdForUpdate, modalType } = useListView();
  return (
    <>
      <KTCard>
        <AdminsListHeader />
        <AdminsTable />
      </KTCard>

      {(itemIdForUpdate !== undefined && modalType !== "NEW") ? (
        <AdminEditModal />
      ) : (itemIdForUpdate === undefined && modalType === "NEW") && (
        <AdminNewModal />
      )}
    </>
  );
};

const AdminsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AdminsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { AdminsListWrapper };
