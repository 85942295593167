import { useEffect, useState, useRef } from "react";
import {
  KTIcon,
  isNotEmpty,
  toAbsoluteUrl,
  useDebounce
} from "../../../../_metronic/helpers";
import { getEnd } from "../../../../_metronic/helpers/utils";
import { getBrandIcon, parseAddressFromHTMLString } from "../../../../_metronic/helpers/utils";
import clsx from "clsx";
import "./style.css";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import opacity from "react-element-popper/animations/opacity";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { toast } from "react-toastify";
import dayjs from "dayjs";
// import moment from "moment";
import moment from "moment-timezone";
import { Breakdown } from "./Breakdown";
import css from "./checkout.module.scss";
import {
  Elements,
  CardElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { Clock } from "./Clock";
import { convertListingTimeToUTC, getLocalListingTime } from "../../../../_metronic/helpers/convertTime.js";
import { getListingPrices } from "../../../../_metronic/helpers/utils";
import GoogleMapReact from 'google-map-react'
import { ToastStyle } from "../../../../_metronic/helpers/utils";

const bookingTypes = [
  { text: "Per hour", value: 1 },
  { text: "Per day", value: 2 },
  { text: "Per week", value: 3 },
  { text: "Per month", value: 4 }
];

const hoursList = [
  { text: "01", value: 1 },
  { text: "02", value: 2 },
  { text: "03", value: 3 },
  { text: "04", value: 4 },
  { text: "05", value: 5 },
  { text: "06", value: 6 },
  { text: "07", value: 7 },
  { text: "08", value: 8 },
  { text: "09", value: 9 },
  { text: "10", value: 10 },
  { text: "11", value: 11 },
  { text: "12", value: 12 }
];

const minutesList = [
  { text: "00", value: 0 },
  { text: "15", value: 15 },
  { text: "30", value: 30 },
  { text: "45", value: 45 }
];

const ampmList = [
  { text: "AM", value: "AM" },
  { text: "PM", value: "PM" }
];

const UserEditModalForm = ({
  user,
  isUserLoading,
  selectedListingId,
  setSelectedListingId
}) => {
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  const isQr = selectedListingId.is_qr_only;
  const stripe = useStripe();
  const elements = useElements();
  const [searchType, setSearchType] = useState("1");
  const [search, setSearch] = useState();
  const [userForEdit, setUserForEdit] = useState();
  const [billingType, setBillingType] = useState();
  const [bookingType, setBookingType] = useState();
  const [startDate, setStartDate] = useState(null);
  const [clockIn, setClockIn] = useState("");
  const [hours, setHours] = useState("");
  const [startHourly, setStartHourly] = useState(null);
  const [startHourlyTime, setStartHourlyTime] = useState(null);
  const [days, setDays] = useState("");
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [nextDay, setNextDay] = useState(false);
  const [weeks, setWeeks] = useState("");
  const [availableSeatsOnDates, setAvailableSeatsOnDates] = useState();
  const [allVehicles, setAllVehicles] = useState([]);
  const [type, setType] = useState();
  const [name, setName] = useState("");
  const [make, setMake] = useState("");
  const [plate_number, setPlateNumber] = useState("");
  const [model, setModel] = useState("");
  const [trailer_plate, setTrailerPlate] = useState("");
  const [truck_number, setTruckNumber] = useState("");
  const [trailer_number, setTrailerNumber] = useState("");
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [isFormVisible, setFormVisible] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState({});
  const [vehicles, setVehicles] = useState([]);
  // const [addVehicle, setAddVehicle] = useState(false);
  const [vehicleType, setVehicleType] = useState(selectedListingId.prices.filter((price) => price.plan_duration !== 5).find((price) => price.vehicle_type == 3) ? 3 : selectedListingId.prices.filter((price) => price.plan_duration !== 5)[0]?.vehicle_type);
  const [vehicleTypeAllowed, setVehicleTypesAllowed] = useState(selectedListingId.prices.filter((price) => price.plan_duration !== 5).map((price) => price.vehicle_type));
  const [allowedVehicles, setAllowedVehicles] = useState([]);
  const [availableVehicles, setAvailableVehicles] = useState([]);
  const [isMapLoaded, setIsMapLoaded] = useState(false)


  useEffect(() => {
    if (isQr && selectedListingId && selectedListingId.prices) {
      const qrPrices = selectedListingId.prices.sort((a, b) => a.plan_duration - b.plan_duration).filter(el => el.price > 0 && el.plan_duration !== 5)
      const defaultType = qrPrices[0].plan_duration
      setBookingType(defaultType)
      if (defaultType === 1) {
        setStartDate(dayjs())
        setStartHourly(dayjs())
        setStartHourlyTime('12:00 AM')
      }
    }
  }, [isQr, selectedListingId]);

  const reset = () => {
    setSearch("");
    setUserForEdit();
    setBillingType();
    if (!isQr) {
      setBookingType();
      setStartDate();
      setStartHourly();
      setStartHourlyTime();
    }
    setHours();
    setDays();
    setStartDay();
    setEndDay();
    setWeeks();
    setClockIn();
    setAvailableSeatsOnDates();
    setType();
    setName();
    setMake();
    setPlateNumber();
    setModel();
    setTrailerPlate();
    setAllVehicles();
    setVehicles([]);
    setCards([]);
    setSelectedCard();
    setFormVisible(false);
    setCardHolderName();
    setAddress();
    setZipCode();
    setCity();
    setState();
    setPromoCode("");
    setDiscount({});
  };

  // useEffect(() => {
  //   reset();
  // }, []);

  useEffect(() => {
    if (cards && cards.length === 1) {
      setSelectedCard(cards[0].id);
    }
  }, [cards]);

  const getCards = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/${id}/cards`)
      .then((res) => {
        setCards(res.data);
      });
  };


  const getUser = () => {
    axios
      .get(
        searchType == 1
          ? `${process.env.REACT_APP_API_URL
          }/user/email?email=${encodeURIComponent(search?.trim())}`
          : `${process.env.REACT_APP_API_URL
          }/user?page_size=1&public_id=${search?.trim()}`
      )
      .then((res) => {
        if (res.status === 200) {
          const responseUser = searchType == 1 ? res.data : res.data.users;
          if (searchType == 1) {
            if (responseUser.id === selectedListingId.owner_id) {
              toast("Can't book user own listing", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
              });
              setSearch("");
              setUserForEdit();
            } else {
              setUserForEdit(responseUser);
              setSearch(responseUser.first_name + " " + responseUser.last_name);
              // if (selectedListingId?.vehicle_type_allowed === 1) {
              //   setAllVehicles(responseUser?.vehicles.filter((vehicle) => vehicle.vehicle_type == 1));
              // } else if (selectedListingId?.vehicle_type_allowed === 2) {
              //   setAllVehicles(responseUser?.vehicles.filter((vehicle) => vehicle.vehicle_type == 2));
              // } else {
              setAllVehicles(vehicleTypeAllowed.includes(0) ? responseUser?.vehicles : responseUser?.vehicles.filter((vehicle) => vehicleTypeAllowed.includes(vehicle.vehicle_type)));
              // }
              getCards(responseUser.uid);
            }
          } else {
            if (responseUser && responseUser.length)
              if (responseUser[0].id === selectedListingId.owner_id) {
                toast("Can't book user own listing", {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark"
                });
                setSearch("");
                setUserForEdit();
              } else {
                setUserForEdit(responseUser[0]);
                setSearch(
                  responseUser[0].first_name + " " + responseUser[0].last_name
                );
                getCards(responseUser[0].uid);
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/vehicle?customer_id=${responseUser[0].id}`
                  )
                  .then((res) => {
                    // if (selectedListingId?.vehicle_type_allowed === 1) {
                    //   setAllVehicles(res.data.filter((vehicle) => vehicle.vehicle_type == 1));
                    // } else if (selectedListingId?.vehicle_type_allowed === 2) {
                    //   setAllVehicles(res.data.filter((vehicle) => vehicle.vehicle_type == 2));
                    // } else {
                    setAllVehicles(vehicleTypeAllowed.includes(0) ? res?.data : res.data.filter((vehicle) => vehicleTypeAllowed.includes(vehicle.vehicle_type)));
                    // }
                  });
              }
          }
        } else {
          setSearch("");
        }
      })
      .catch((err) => {
        setSearch("");
        toast(searchType == 1 ? "Email Not Found/Incorrect Email" : "Member Number Not Found", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      });
  };

  // const debouncedSearchTerm = useDebounce(search, 250);
  // // Effect for API call
  // useEffect(() => {
  //   if (debouncedSearchTerm !== undefined && search !== undefined) {
  //     getUser();
  //   }
  // }, [debouncedSearchTerm]);

  const cancel = (withRefresh) => {
    setSelectedListingId();
    reset();
  };

  const duration = (duration) => {
    switch (duration) {
      case 1:
        return "hour";
      case 2:
        return "day";
        break;
      case 3:
        return "week";
        break;
      case 4:
        return "month";
        break;
    }
  };

  const disabledVehicle = () => {
    if (!type) return true;
    if (type) {
      if (+type === 1) {
        if (name && make && truck_number) {
          return false;
        }
      }
      if (+type === 2) {
        if (model && trailer_number) {
          return false;
        }
      }
      if (+type === 3) {
        if (name && make && truck_number && model && trailer_number) {
          return false;
        }
      }
    }
    return true;
  };


  useEffect(() => {
    if (bookingType) {
      let allowed = [...new Set(selectedListingId?.prices
        .filter(el => el.plan_duration === +bookingType)
        .map(el => el.vehicle_type)
      )]
      if (allowed.length === 1 && allowed[0] === 0) {
        allowed = [1, 2, 3]
      }
      setAllowedVehicles(allowed)
      if (allVehicles.length) {
        setAvailableVehicles(allVehicles.filter(el => allowed.includes(el.vehicle_type)))
      }
    }
  }, [bookingType, allVehicles])

  const addVehicle = () => {
    if (vehicles.length < availableVehicles.length) {
      if (availableVehicles[0].id) {
        setVehicles((prev) => [
          ...prev,
          availableVehicles.find((el) => vehicles.indexOf(el.id) === -1).id
        ]);
      } else {
        setVehicles((prev) => [
          ...prev,
          availableVehicles.find((el) => vehicles.indexOf(el.vehicle_id) === -1).vehicle_id
        ]);
      }
    }
  };

  const handleSelectVehicle = (e, i) => {
    if (e.target.value) {
      let updated = [...vehicles];
      updated[i] = +e.target.value;
      setVehicles(updated);
    }
  };

  useEffect(() => {
    if (bookingType) {
      if (
        (bookingType == 1 && hours && startHourlyTime) ||
        (bookingType == 2 && startDay && endDay && clockIn) ||
        ((bookingType == 3 || bookingType == 4) && startDate && clockIn)
      ) {
        let start, end, duration;
        if (bookingType == 1) {
          start = startHourly.format("YYYY-MM-DD");
          duration = hours;
        } else if (bookingType == 2) {
          start = startDay.format("YYYY-MM-DD");
          end = endDay.format("YYYY-MM-DD");
          duration = dayjs(end).diff(start, "days");
          setDays(duration);
        } else {
          start = startDate.format("YYYY-MM-DD");
          duration = 1;
        }
        const dateString = bookingType == 1
          ? moment(`${start} ${startHourlyTime}`).format("YYYY-MM-DD HH:mm")
          : moment(`${start} ${clockIn}`).format("YYYY-MM-DD HH:mm");
        const sourceMoment = moment.tz(dateString, selectedListingId.timezone_id);
        const utcMoment = sourceMoment.utc();
        const utcTimeString = utcMoment.format("YYYY-MM-DD HH:mm");
        const utcStart = utcTimeString.split(" ")[0];
        const utcClockIn = utcTimeString.split(" ")[1];

        axios
          .get(
            `${process.env.REACT_APP_API_URL}/listing/public/${selectedListingId.listing_id
            }/availability?start_date=${utcStart}&clock_in=${utcClockIn}&duration=${duration}&booking_type=${+bookingType}${+bookingType === 1 ? "&is_advance=true" : ""}`
          )
          .then((res) => {
            if (res.data) {
              setAvailableSeatsOnDates(res.data.available_seats);
            }
          })
          .catch((err) => {
            if (err.response.data.message.includes("No seats available")) {
              setAvailableSeatsOnDates(0);
            }
            // toast("Cannot get availability, Try Again", {
            //   position: "top-right",
            //   autoClose: 2500,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "dark",
            // });
          });
      }
    }
  }, [bookingType, clockIn, startDate, hours, startDay, endDay, startHourlyTime]);


const getQuote = (quoteId, user, listing) => {
  axios
    .get(`${process.env.REACT_APP_API_V3_URL}/quotes?quote_id=${quoteId}`)
    .then((res) => {
      if(res.data && res.data.booking_id){
        toast(
          `Booking Created for ${user.first_name} ${user.last_name} on the ${listing.title} Listing `,
          ToastStyle
        );
      }else{
        toast(`Booking Cannot be created, Please Try Again `, ToastStyle);
      }
    })
    .catch((err) => {
      toast(`Booking Cannot be created, Please Try Again `, ToastStyle);
      setLoading(false);
    });
}


  const createBooking = () => {
    setLoading(true);
    const end = getEnd(
      bookingType,
      bookingType == 1 ? startDate : startDate.format(),
      hours,
      days
    ).full;

    let date, utcDate;
    if (bookingType == 1) {
      date = dayjs(startDate).format("YYYY-MM-DD");
      // utcDate = convertListingTimeToUTC(date + ' ' + startHourlyTime, selectedListingId?.time_offset)
      utcDate = moment.tz(date + " " + startHourlyTime, selectedListingId?.timezone_id).utc().format("YYYY-MM-DD HH:mm:00");
    } else {
      date = startDate.format("YYYY-MM-DD");
      // utcDate = convertListingTimeToUTC(date + ' ' + clockIn, selectedListingId?.time_offset)
      utcDate = moment.tz(date + " " + clockIn, selectedListingId?.timezone_id).utc().format("YYYY-MM-DD HH:mm:00");
    }

    if (bookingType !== 4) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/booking/checkout/preview?dr=false`, {
          payment_mode: 1,
          customer_id: userForEdit.id,
          listing_id: selectedListingId.listing_id,
          booking_type: bookingType,
          vehicles: [...vehicles],
          start_date: utcDate,
          clock_in: bookingType == 1 ? startHourlyTime : clockIn,
          duration: bookingType == 1 ? +hours : bookingType == 2 ? +days : weeks,
          ...(selectedCard && { card_id: selectedCard }),
          promo_code: promoCode ? promoCode : null
        })
        .then((res) => {
          setTimeout(() => {
            getQuote(res?.data?.quote_id, userForEdit, selectedListingId)
            cancel();
            setLoading(false);
          },4000)
        })
        .catch((err) => {
          if (err && err.response && err.response?.data && err.response?.data?.message) {
            toast(err.response.data.message, ToastStyle);
          } else {
            toast(`Booking Cannot be created, Please Try Again `, ToastStyle);
          }
          setLoading(false);
        });
    } else if (bookingType == 4) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/booking/checkout/preview?dr=false`, {
          payment_mode: 1,
          customer_id: userForEdit.id,
          listing_id: selectedListingId.listing_id,
          booking_type: bookingType,
          vehicles: [...vehicles],
          start_date: utcDate,
          clock_in: clockIn,
          duration: 1,
          ...(!userForEdit?.is_b2b && { card_id: selectedCard }),
          promo_code: promoCode ? promoCode : null
        })
        .then((res) => {
          getQuote(res?.data?.quote_id, userForEdit, selectedListingId)
          cancel();
          setLoading(false);
        })
        .catch((err) => {
          if (err && err.response && err.response?.data && err.response?.data?.message) {
            toast(err.response.data.message, ToastStyle);
          } else {
            toast(`Booking Cannot be created, Please Try Again `, ToastStyle);
          }
          setLoading(false);
        });
    }

    // localStorage.setItem(
    //     'checkoutData',
    //     JSON.stringify({
    //         listing_id: selectedListingId.listing_id,
    //         vehicles: [...vehicles],
    //         booking_type: bookingType,
    //         end_date: dayjs(end).format('YYYY-MM-DD'),
    //         start_date: bookingType == 1 ? dayjs(startDate).format('YYYY-MM-DD') : startDate.format('YYYY-MM-DD'),
    //         clock_in: bookingType == 1 ? startHourlyTime : clockIn,
    //         duration: bookingType == 1 ? +hours : +days,
    //     })
    // )
  };

  const [isManualFormVisible, setManualFormVisible] = useState(false);

  const handleAddVehicle = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/vehicle`, {
        vehicle_type: type,
        ...(name && { name }),
        ...(make && { make }),
        ...(model && { model }),
        ...(plate_number && { plate_number }),
        ...(trailer_plate && { trailer_plate }),
        ...(truck_number && { truck_number }),
        ...(trailer_number && { trailer_number }),
        customer_id: userForEdit.id,
      }
      )
      .then((res) => {
        toast("Vehicle Added Successfully", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });

        setVehicles((prev) => [...prev, res.data.vehicle_id]);
        setAllVehicles((prev) => [
          ...prev,
          {
            id: res.data.vehicle_id,
            vehicle_type: type,
            ...(name && { name }),
            ...(make && { make }),
            ...(model && { model }),
            ...(plate_number && { plate_number }),
            ...(trailer_plate && { trailer_plate }),
            ...(truck_number && { truck_number }),
            ...(trailer_number && { trailer_number })
          }
        ]);

        setManualFormVisible(false);
        setType("");
        setName("");
        setMake("");
        setPlateNumber("");
        setModel("");
        setTrailerPlate("");


      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast(err.response.data.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
        } else {
          toast("Technical Error, Please Try Again", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
        }
      });
  };

  const isToday = (someDate) => {
    // const today = new Date();
    // const today = dayjs(getLocalListingTime(selectedListingId?.time_offset)).toDate()
    const today = moment(moment().tz(selectedListingId?.timezone_id).format("DD/MM/YYYY HH:mm"), "DD/MM/YYYY HH:mm").toDate();
    return (
      new Date(someDate).getDate() === today.getDate() &&
      new Date(someDate).getMonth() === today.getMonth() &&
      new Date(someDate).getFullYear() === today.getFullYear()
    );
  };

  const [currentTime, setCurrentTime] = useState("");
  const [today, setToday] = useState(false);

  useEffect(() => {
    if (startDate && selectedListingId?.timezone_id) {
      // const time = dayjs().format("HH:mm");
      // const time = dayjs(getLocalListingTime(selectedListingId?.time_offset)).format('HH:mm')
      const time = moment().tz(selectedListingId?.timezone_id).format("HH:mm");
      const isSelectedToday = isToday(
        bookingType === 1 ? startDate : startDate.format()
      );

      setClockIn("");
      setCurrentTime(time);
      setToday(isSelectedToday);
    }
  }, [startDate, selectedListingId]);

  const [timepickerOpen, setTimepickerOpen] = useState(false);
  const [hrHour, setHrHour] = useState(12);
  const [hrMin, setHrMin] = useState(0);
  const [hrAP, setHrAP] = useState("AM");
  const [hrTime, setHrTime] = useState("12:00 AM");

  const [disableHr, setDisableHr] = useState(0);
  const [disableAmPm, setDisableAmPm] = useState(false);

  useEffect(() => {
    if (today) {
      // const [hr, amPm] = dayjs().format("h A").split(" ");
      // const [hr, amPm] = dayjs(getLocalListingTime(selectedListingId?.time_offset)).format('h A').split(' ')
      const [hr, amPm] = moment().tz(selectedListingId?.timezone_id).format("h A").split(" ");
      if (hr === "11" && amPm === "AM") {
        setDisableAmPm(true);
        setDisableHr(0);
        setHrHour(12);
        setHrAP("PM");
      } else if (hr === "12") {
        setDisableAmPm(amPm === "PM");
        setDisableHr(1);
        setHrHour(1);
        setHrAP(amPm);
      } else {
        setDisableAmPm(amPm === "PM");
        setDisableHr(+hr + 1);
        setHrHour(+hr + 1);
        setHrAP(amPm);
      }

      setHrMin(0);
      setHrTime(
        `${hoursList.find((el) => el.value === (+hr + 1 < 12 ? +hr + 1 : 1)).text
        }:00 ${amPm}`
      );

      setStartHourlyTime(
        moment(
          `${hoursList.find((el) => el.value === (+hr + 1 < 12 ? +hr + 1 : 1))
            .text
          }:00 ${amPm}`,
          "hh:mm A"
        ).format("HH:mm:00")
      );

      if (hr === '11' && isQr) {
        if (amPm === 'PM') {
          setHrTime('00:00 AM')
          setStartHourlyTime('00:00:00')
          setNextDay(true)
        }
        if (amPm === 'AM') {
          setStartHourlyTime('12:00:00')
        }
      } else {
        setNextDay(false)
      }
    } else {
      setDisableHr(0);
      setDisableAmPm(false);
    }
  }, [today]);

  useEffect(() => {
    if (
      !disableAmPm &&
      (hrHour < disableHr || (hrHour === 12 && disableHr === 1))
    ) {
      setHrHour(disableHr);
    }
  }, [hrAP]);

  // const blankImg = toAbsoluteUrl("/media/svg/avatars/blank.svg");
  // const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

  const handleSave = () => {
    const card = elements.getElement(CardElement);


    stripe.createToken(card, {
      name,
      address_line1: address,
      address_city: city,
      address_state: state,
      address_zip: zipCode,
      address_country: "US"
    }).then((res) => {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/user/${userForEdit.uid}/card`,
          {
            token: res.token.id
            // billing: {
            //   address: {
            //     city: city,
            //     state: state,
            //     line1: address,
            //     postal_code: zipCode,
            //   },
            // },
            // name: name,
            //   billing: {
            //     name,
            //     line1: address,
            //     city,
            //     state,
            //     postal_code: zipCode,
            // },
          }
        )
        .then(() => {
          setFormVisible(false);
          toast(
            `Card Have Been Added to ${userForEdit.first_name} ${userForEdit.last_name} Account `,
            {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark"
            }
          );
          getCards(userForEdit.uid);
        })
        .catch(() => {
          toast("Card Cannot be Added, Please Try Again", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
        });
    })
      .catch((err) => {
        console.log(err);
      });
  };


  const [plan, setPlan] = useState([]);

  useEffect(() => {
    if (selectedListingId && selectedListingId.listing_id) {
      axios.get(`${process.env.REACT_APP_API_USER_URL}/user/listing/public/${selectedListingId.listing_id}/seats`).then(res => {
        setPlan(res.data);
      });
    }
  }, [selectedListingId]);

  const destroyLabels = () => {
    const days = document.querySelectorAll(".rmdp-day-picker .rmdp-day");
    for (let el of days) {
      el.classList.remove("full");

      const label = document.querySelector(".seats-available");
      if (label) {
        label.remove();
      }
    }
  };

  const fillCalendarWithSeats = (e) => {
    const days = document.querySelectorAll(".rmdp-day-picker .rmdp-day");

    const month = e ? e.format("MM") : dayjs().format("MM");

    // const disabled = bookingType === 3 ? plan.unavailableDates.week : bookingType === 4 ? plan.unavailableDates.month : plan.unavailableDates.day;

    for (let el of days) {
      const day = el.textContent;
      if (!day) continue;
      const seats = document.createElement("div");
      seats.classList.add("seats-available");
      if (selectedListingId?.is_zero || plan.reserved.find(date => date.slice(5, 7) === month && +date.slice(-2) === +day)) {
        if (!el.classList.contains("rmdp-disabled")) {
          seats.textContent = "0";
          seats.classList.add("full");
          // el.classList.add('full')
        }
      } else {
        const item = Object.entries(plan.seats).find(([date]) => date.slice(5, 7) === month && +date.slice(-2) === +day);

        if (!item) continue;

        seats.textContent = item[1];
      }

      // if (disabled.find(date => date.slice(5, 7) === month && +date.slice(-2) === +day)) {
      //     el.classList.add('full')
      // }
      el.appendChild(seats);
    }
  };

  const getDailyMaxDate = () => {
    const fullyBooked = Object.entries(plan.bookings).filter(pair => pair[1] >= selectedListingId.seats);

    if (!!fullyBooked.length && fullyBooked.find(el => el[1] >= selectedListingId.seats)) {
      return dayjs(fullyBooked.find(el => el[1] >= selectedListingId.seats)[1]).toDate();
    } else {
      return moment(moment().tz(selectedListingId?.timezone_id).format("DD/MM/YYYY HH:mm"), "DD/MM/YYYY HH:mm").add(2, "month").toDate();
    }

    //   const exceptional = plan.unavailableDates.day.find(el => dayjs(el).isAfter(dayjs(startDay.format('YYYY-MM-DD')).format('YYYY-MM-DD')))
    //   if (plan.unavailableDates.day.length && exceptional) {
    //       return dayjs(exceptional).toDate()
    //   } else {
    //       return moment(moment().tz(selectedListingId?.timezone_id).format('DD/MM/YYYY HH:mm'), 'DD/MM/YYYY HH:mm').add(2, 'month').toDate()
    //   }
  };

  const autoCompleteRef = useRef(null);
  const inputRef = useRef(null);

  const options = {
    componentRestrictions: { country: "us" },
    requestedLanguage: "en",
    fields: ["adr_address", "address_components", "formatted_address", "geometry", "icon", "name"],
    types: ["address"]
  };
  let autocompleteService;
  const handleApiLoaded = async (map, maps) => {
    const { Autocomplete, AutocompleteService } = await maps.importLibrary('places')
    autoCompleteRef.current = new Autocomplete(
      inputRef.current,
      options
    )
    autocompleteService = new AutocompleteService()

    autoCompleteRef.current.addListener('place_changed', async function () {
      const place = await autoCompleteRef.current.getPlace()
      const p_state = place.address_components.find(el => el.types.indexOf('administrative_area_level_1') > -1)
      const p_city = parseAddressFromHTMLString(place.adr_address).city
      const p_zip = place.address_components.find(el => el.types.indexOf('postal_code') > -1)
      const p_address = place.formatted_address

      setAddress(p_address)
      setCity(p_city ? p_city : '')
      setState(p_state ? p_state.long_name : '')
      setZipCode(p_zip ? p_zip.long_name : '')
    })

    setIsMapLoaded(true)
  }

  const applyPromo = (type) => {
    axios.post(`${process.env.REACT_APP_API_URL}/booking/promo/preview`, {
      listing_id: selectedListingId.listing_id,
      vehicles: vehicles,
      booking_type: type,
      duration: type == 1 ? hours : type == 2 ? days : type == 3 ? weeks : 1,
      promo_code: promoCode,
      user_id: userForEdit.id
    }).then(res => {
      toast("Promo Code Applied Succesfully", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      });
      setDiscount(res.data);
    }).catch(err => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      } else {
        toast("Technical Error, Please Try Again", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      }
    });
  };

  const getHourlyEnd = () => {
    if (isQr) {
      return moment(moment().tz(selectedListingId?.timezone_id).format('DD/MM/YYYY HH:mm'), 'DD/MM/YYYY HH:mm').add(hours, 'hours').format('DD MMM, hh:mm A')
    } else {
      const start = startHourly.format('YYYY-MM-DD')
      const dateString = moment(`${start} ${startHourlyTime}`).format('YYYY-MM-DD HH:mm')
      return moment(dateString).add(hours, 'hours').format('DD MMM, hh:mm A')
    }
  }


  return (
    <>
      {/* <form id='kt_modal_add_user_form' className='form' onSubmit={() => {}} noValidate> */}
      {/* begin::Modal header */}
      <div id="kt_modal_add_user_header">
        <h2 className="fw-bolder" style={{ color: "#4a4a4a" }}>
          Book {selectedListingId.title}
        </h2>
        {
          (((vehicleTypeAllowed.includes(2) && !vehicleTypeAllowed.includes(1)) || (vehicleTypeAllowed.includes(1) && !vehicleTypeAllowed.includes(2))) && !vehicleTypeAllowed.includes(3)) &&
          <h5 style={{ color: "#ed2626", marginBottom: "1.5rem" }}>
            Only {(vehicleTypeAllowed.includes(2) && !vehicleTypeAllowed.includes(1)) ? 'Trailers' : 'Trucks'} can be parked at this spot
          </h5>
        }
        <div className="row">
          <div className="col-12">
            <h5
              style={{ color: +selectedListingId?.available_seats <= 0 || +selectedListingId?.is_zero ? "#ed2626" : "#2ecc71" }}>
              {+selectedListingId?.available_seats <= 0 || +selectedListingId?.is_zero ? 0 : selectedListingId?.available_seats} Spaces
              Available for Today
            </h5>
          </div>
          {selectedListingId?.timezone_id && (
            <Clock timezone={selectedListingId?.timezone_id} />
          )}
          <div className="gap-5px">
            <h3 style={{ fontSize: "16px", color: '#ffa459' }}>Prices {vehicleTypeAllowed.includes(0) ? `all vehicle types` : `${vehicleType == 1 ? 'Truck' : vehicleType == 2 ? 'Trailer' : 'Truck + Trailer'}`}:</h3>
            {!vehicleTypeAllowed.includes(0) && <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-kt-user-table-filter="two-step"
              data-hide-search="true"
              value={vehicleType}
              onChange={(e) => setVehicleType(e.target.value)}
            // disabled={selectedListingId?.vehicle_type_allowed && selectedListingId.vehicle_type_allowed !== 0}
            >
              <option value='1' disabled={(!vehicleTypeAllowed.includes(1))}>Truck</option>
              <option value='2' disabled={(!vehicleTypeAllowed.includes(2))}>Trailer</option>
              <option value='3' disabled={(!vehicleTypeAllowed.includes(3))}>Truck + Trailer</option>
            </select>
            }
          </div>
          <div className="col-12 mt-5">
            <div
              className="d-flex wrap"
              style={{ gap: "20px" }}
            >
              {getListingPrices(selectedListingId, selectedListingId.prices.find((price) => price.vehicle_type > 0)?.vehicle_type ? vehicleType : 0)
                .sort((a, b) => a.plan_duration - b.plan_duration)
                .map((price) =>
                  price.price > 0 && price.plan_duration !== 5 && (
                    <h5 style={{ fontSize: "12px" }}>
                      <span className="price-mobile">
                        ${(price.price / 100).toFixed(2)}
                      </span>{" "}
                      <br />
                      per {duration(price.plan_duration)}
                    </h5>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className="separator separator-dashed my-5"></div>
      {/* {selectedListingId.available_seats <= 0 && (
        <div className="d-flex text-center w-100 align-content-center py-5">
          <h4 className="text-danger">
            Space Not Available. Please Try Some Other Time{" "}
          </h4>
        </div>
      )} */}
      <div className="kt_body">
        <div className="input-search mb-5" style={{ position: "relative" }}>
          <label className="form-label fs-6 fw-bold">
            Select Search User By:
          </label>
          <select
            className="form-select form-select-solid fw-bolder"
            data-kt-select2="true"
            data-placeholder="Select option"
            data-allow-clear="true"
            data-kt-user-table-filter="two-step"
            data-hide-search="true"
            value={searchType}
            onChange={(e) => {
              setSearchType(e.target.value);
              reset();
            }}
            disabled={selectedListingId.seats <= 0}
          >
            <option value="1">Search By Email</option>
            <option value="2">Search By Member Number</option>
          </select>
        </div>
        <div className="input-search mb-5" style={{ position: "relative" }}>
          <label className="form-label fs-6 fw-bold">
            {searchType == 1 ? "Enter Email" : "Enter Member Number"}
          </label>
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-100 "
            placeholder={
              searchType == 1 ? "Enter Email" : "Enter Member Number"
            }
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                getUser();
              }
            }}
            disabled={selectedListingId.seats <= 0}
          />
          <span
            className="search-icon"
            style={{
              position: "absolute",
              bottom: "11px",
              right: "10px",
              cursor: "pointer"
            }}
            onClick={() => getUser()}
          >
            <i className="fas fa-search" style={{ color: "#ffa459" }}></i>
          </span>
        </div>

        {userForEdit && (
          <>
            <div className="mb-5">
              <label className="form-label fs-6 fw-bold">Booking Type</label>
              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                onChange={(e) => {
                  setBookingType(+e.target.value);
                  setVehicles([]);
                  setPromoCode("");
                  setDiscount({});
                  setWeeks();
                }}
                value={bookingType}
              >
                <option value="" disabled selected>
                  Select Billing Type
                </option>
                {selectedListingId?.prices
                  .filter((price, index, self) =>
                    index === self.findIndex((p) => p.plan_duration === price.plan_duration)
                  ) // Filter to keep only unique plan_duration values
                  .sort((a, b) => a.plan_duration - b.plan_duration)
                  .map((price) => {
                    return (
                      price.price > 0 &&
                      price.plan_duration !== 5 && (
                        <option key={price.plan_duration} value={price.plan_duration}>
                          {duration(price.plan_duration) === "hour"
                            ? "Per Hour"
                            : duration(price.plan_duration) === "day"
                              ? "Per Day"
                              : duration(price.plan_duration) === "week"
                                ? "Per Week"
                                : duration(price.plan_duration) === "month" && "Per Month"}
                        </option>
                      )
                    );
                  })}
                {/* <option value='hour'>Per Hour</option>
            <option value='day'>Per Day</option>
            <option value='day'>Per Week</option>
            <option value='month'>Per Month</option>        */}
              </select>
            </div>

            {bookingType && (
              <>
                {bookingType == 1 && (
                  <>
                    {!isQr && (
                      <div className="fv-row mb-7">
                        <label className="fw-bold fs-6 mb-2">Select Date</label>
                        <DatePicker
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            borderRadius: "8px",
                            padding: "0.775rem 1rem",
                            fontSize: "1.1rem",
                            fontWeight: "500",
                            lineHeight: "1.5",
                            backgroundColor: "#f9f9f9",
                            border: "1px solid #f9f9f9",
                            color: "#5e6278",
                            transition: "color 0.2s ease",
                            height: "auto"
                          }}
                          containerStyle={{
                            width: "100%"
                          }}
                          className="rmdp-mobile custom-calendar"
                          arrow={false}
                          value={startHourly}
                          onChange={(val) => {
                            setStartHourly(val);
                            setStartDate(val);
                          }}
                          format="dddd MMM DD,YYYY"
                          fixMainPosition={true}
                          // minDate={
                          //   new Date().getHours() === 23
                          //     ? dayjs().add(1, "day").toDate()
                          //     : new Date()
                          // }
                          // minDate={new Date(getLocalListingTime(selectedListingId?.time_offset)).getHours() === 23 ? dayjs(getLocalListingTime(selectedListingId?.time_offset)).add(1, 'day').toDate() : new Date(getLocalListingTime(selectedListingId?.time_offset))}
                          // minDate={moment().tz(selectedListingId?.timezone_id).toDate().getHours() === 23 ? moment().tz(selectedListingId?.timezone_id).add(1, 'day').toDate() : moment().tz(selectedListingId?.timezone_id).toDate()}
                          minDate={moment(moment().tz(selectedListingId?.timezone_id).format("DD/MM/YYYY HH:mm"), "DD/MM/YYYY HH:mm").toDate().getHours === 23 ? moment(moment().tz(selectedListingId?.timezone_id).format("DD/MM/YYYY HH:mm"), "DD/MM/YYYY HH:mm").add(1, "day").toDate() : moment(moment().tz(selectedListingId?.timezone_id).format("DD/MM/YYYY HH:mm"), "DD/MM/YYYY HH:mm").toDate()}
                          maxDate={moment(moment().tz(selectedListingId?.timezone_id).format("DD/MM/YYYY HH:mm"), "DD/MM/YYYY HH:mm").add(2, "month").toDate()}
                          animations={[opacity()]}
                          placeholder="Select Date"
                          weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                          scrollSensitive={false}
                          hideOnScroll={false}
                          onMonthChange={e => {
                            destroyLabels();
                            setTimeout(() => fillCalendarWithSeats(e), 10);
                          }}
                          onOpen={() => {
                            destroyLabels();
                            setTimeout(fillCalendarWithSeats, 10);
                          }}
                        />
                      </div>
                    )}
                    {(startHourly && !isQr) && (
                      <>
                        <div className="fv-row mb-7">
                          <label className="fw-bold fs-6 mb-2">Select Time</label>

                          <input
                            className={clsx(
                              "form-control form-control-solid mb-3 mb-lg-0"
                            )}
                            type="text"
                            readOnly
                            value={hrTime}
                            onClick={() => setTimepickerOpen(true)}
                          />
                        </div>
                        {timepickerOpen && (
                          <div
                            className="rmdp-calendar-container-mobile"
                            style={{
                              position: "fixed",
                              left: 0,
                              top: 0,
                              zIndex: 100
                            }}
                          >
                            <div
                              className={`rmdp-wrapper rmdp-shadow rmdp-mobile custom-calendar rmdp-mobile timepickerContainer`}
                            >
                              <div className="timepickerSelects">
                                <div className="timer-section">
                                  <button
                                    onClick={() => {
                                      setHrHour((prev) => {
                                        const index = hoursList.findIndex(
                                          (el) => el.value === prev
                                        );
                                        if (index + 1 === hoursList.length) {
                                          return hoursList[0].value;
                                        } else {
                                          return hoursList[index + 1].value;
                                        }
                                      });
                                    }}
                                    disabled={
                                      disableHr &&
                                      hrHour === 11 &&
                                      !(!disableAmPm && hrAP === "PM")
                                    }
                                  >
                                    +
                                  </button>
                                  <div>
                                    {
                                      hoursList.find((el) => el.value === hrHour)
                                        .text
                                    }
                                  </div>
                                  <button
                                    onClick={() => {
                                      setHrHour((prev) => {
                                        const index = hoursList.findIndex(
                                          (el) => el.value === prev
                                        );
                                        if (index === 0) {
                                          return hoursList.at(-1).value;
                                        } else {
                                          return hoursList[index - 1].value;
                                        }
                                      });
                                    }}
                                    disabled={
                                      hrHour === disableHr &&
                                      !(!disableAmPm && hrAP === "PM")
                                    }
                                  >
                                    -
                                  </button>
                                </div>
                                <div className="timer-section">
                                  <button
                                    onClick={() => {
                                      setHrMin((prev) => {
                                        const index = minutesList.findIndex(
                                          (el) => el.value === prev
                                        );
                                        if (index + 1 === minutesList.length) {
                                          return minutesList[0].value;
                                        } else {
                                          return minutesList[index + 1].value;
                                        }
                                      });
                                    }}
                                  >
                                    +
                                  </button>
                                  <div>
                                    {
                                      minutesList.find((el) => el.value === hrMin)
                                        .text
                                    }
                                  </div>
                                  <button
                                    onClick={() => {
                                      setHrMin((prev) => {
                                        const index = minutesList.findIndex(
                                          (el) => el.value === prev
                                        );
                                        if (index === 0) {
                                          return minutesList.at(-1).value;
                                        } else {
                                          return minutesList[index - 1].value;
                                        }
                                      });
                                    }}
                                  >
                                    -
                                  </button>
                                </div>
                                <div className="timer-section">
                                  <button
                                    onClick={() => {
                                      setHrAP((prev) =>
                                        prev === "AM" ? "PM" : "AM"
                                      );
                                    }}
                                    disabled={disableAmPm}
                                  >
                                    +
                                  </button>
                                  <div>
                                    {ampmList.find((el) => el.value === hrAP).text}
                                  </div>
                                  <button
                                    onClick={() => {
                                      setHrAP((prev) =>
                                        prev === "AM" ? "PM" : "AM"
                                      );
                                    }}
                                    disabled={disableAmPm}
                                  >
                                    -
                                  </button>
                                </div>
                              </div>
                              <div className="timepickerBtns">
                                <button
                                  onClick={() => {
                                    setTimepickerOpen(false);
                                  }}
                                >
                                  CANCEL
                                </button>
                                <button
                                  onClick={() => {
                                    const formattedTime = `${hoursList.find((el) => el.value === hrHour)
                                      .text
                                      }:${minutesList.find((el) => el.value === hrMin)
                                        .text
                                      } ${ampmList.find((el) => el.value === hrAP).text
                                      }`;
                                    setHrTime(formattedTime);
                                    setStartHourlyTime(
                                      moment(formattedTime, "hh:mm A").format(
                                        "HH:mm:00"
                                      )
                                    );
                                    setTimepickerOpen(false);
                                  }}
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {(startDate && startHourlyTime) && (
                      <div className="fv-row mb-7">
                        <label className="fw-bold fs-6 mb-2">Number Of Hours</label>
                        <input
                          placeholder="Number of hours"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0"
                          )}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="duration"
                          value={hours}
                          min={1}
                          onChange={(e) => {
                            const re = /^[0-9]*$/;
                            if (!re.test(e.target.value)) {
                              setHours("");
                            } else {
                              if (+e.target.value >= +1 || e.target.value === "") {
                                setHours(e.target.value);
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                    {hours && (
                      <div className='mb-6' style={{ marginTop: '-14px' }}><span style={{ fontWeight: '600' }}>End Date & Time:</span> {getHourlyEnd()}</div>
                    )}
                  </>
                )}
                {bookingType == 2 && (
                  <>
                    <div className="fv-row mb-7">
                      <label className="fw-bold fs-6 mb-2">Start Date</label>
                      <DatePicker
                        style={{
                          width: "100%",
                          boxSizing: "border-box",
                          borderRadius: "8px",
                          padding: "0.775rem 1rem",
                          fontSize: "1.1rem",
                          fontWeight: "500",
                          lineHeight: "1.5",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #f9f9f9",
                          color: "#5e6278",
                          transition: "color 0.2s ease",
                          height: "auto"
                        }}
                        containerStyle={{
                          width: "100%"
                        }}
                        className="rmdp-mobile custom-calendar"
                        arrow={false}
                        value={startDay}
                        onChange={(val) => {
                          setEndDay(null);
                          setStartDay(val);
                          setStartDate(val);
                        }}
                        format="dddd MMM DD,YYYY"
                        fixMainPosition={true}
                        // minDate={new Date()}
                        // minDate={new Date(getLocalListingTime(selectedListingId?.time_offset))}
                        minDate={new Date(moment(moment().tz(selectedListingId?.timezone_id).format("DD/MM/YYYY HH:mm"), "DD/MM/YYYY HH:mm").toDate())}
                        // minDate={new Date(moment().tz(selectedListingId?.timezone_id).toDate())}
                        // maxDate={
                        //   endDay &&
                        //   dayjs(endDay.format("YYYY-MM-DD"))
                        //     .subtract(1, "day")
                        //     .toDate()
                        // }
                        maxDate={moment(moment().tz(selectedListingId?.timezone_id).format("DD/MM/YYYY HH:mm"), "DD/MM/YYYY HH:mm").add(2, "month").toDate()}
                        animations={[opacity()]}
                        placeholder="Select Start Date"
                        weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                        scrollSensitive={false}
                        hideOnScroll={false}
                        onMonthChange={e => {
                          destroyLabels();
                          setTimeout(() => fillCalendarWithSeats(e), 10);
                        }}
                        onOpen={() => {
                          destroyLabels();
                          setTimeout(fillCalendarWithSeats, 10);
                        }}
                      />
                    </div>

                    {startDay && (
                      <>
                        <div className="fv-row mb-7">
                          <label className="fw-bold fs-6 mb-2">End Date</label>
                          <DatePicker
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              padding: "0.775rem 1rem",
                              fontSize: "1.1rem",
                              fontWeight: "500",
                              lineHeight: "1.5",
                              backgroundColor: "#f9f9f9",
                              border: "1px solid #f9f9f9",
                              color: "#5e6278",
                              transition: "color 0.2s ease",
                              height: "auto"
                            }}
                            containerStyle={{
                              width: "100%"
                            }}
                            className="rmdp-mobile custom-calendar"
                            arrow={false}
                            value={endDay}
                            onChange={setEndDay}
                            format="dddd MMM DD,YYYY"
                            fixMainPosition={true}
                            minDate={dayjs(startDay.format("YYYY-MM-DD"))
                              .add(1, "day")
                              .toDate()}
                            maxDate={getDailyMaxDate()}
                            animations={[opacity()]}
                            placeholder="Select End Date"
                            weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                            scrollSensitive={false}
                            hideOnScroll={false}
                            mapDays={({ date }) => {
                              let color;
                              if (
                                date.format("YYYY-MM-DD") ===
                                startDay.format("YYYY-MM-DD")
                              )
                                color = "red";
                              if (color)
                                return {
                                  className: "highlight highlight-" + color
                                };
                            }}
                            onMonthChange={e => {
                              destroyLabels();
                              setTimeout(() => fillCalendarWithSeats(e), 10);
                            }}
                            onOpen={() => {
                              destroyLabels();
                              setTimeout(fillCalendarWithSeats, 10);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                {(bookingType == 3 || bookingType == 4) && (
                  <>
                    <div className="fv-row mb-7">
                      <label className="fw-bold fs-6 mb-2">Start Date</label>
                      <DatePicker
                        style={{
                          width: "100%",
                          boxSizing: "border-box",
                          borderRadius: "8px",
                          padding: "0.775rem 1rem",
                          fontSize: "1.1rem",
                          fontWeight: "500",
                          lineHeight: "1.5",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #f9f9f9",
                          color: "#5e6278",
                          transition: "color 0.2s ease",
                          height: "auto"
                        }}
                        containerStyle={{
                          width: "100%"
                        }}
                        className="rmdp-mobile custom-calendar"
                        arrow={false}
                        value={startDate}
                        onChange={setStartDate}
                        format="dddd MMM DD,YYYY"
                        fixMainPosition={true}
                        // minDate={new Date()}
                        minDate={new Date(moment(moment().tz(selectedListingId?.timezone_id).format("DD/MM/YYYY HH:mm"), "DD/MM/YYYY HH:mm").toDate())}
                        maxDate={moment(moment().tz(selectedListingId?.timezone_id).format("DD/MM/YYYY HH:mm"), "DD/MM/YYYY HH:mm").add(2, "month").toDate()}
                        animations={[opacity()]}
                        placeholder="Select Start Date"
                        weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                        scrollSensitive={false}
                        hideOnScroll={false}
                        onMonthChange={(e) => {
                          destroyLabels();
                          setTimeout(() => fillCalendarWithSeats(e), 10);
                        }}
                        onOpen={() => {
                          destroyLabels();
                          setTimeout(fillCalendarWithSeats, 10);
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {(bookingType == 3 && !!startDate) && <div className="mb-5">
              <label className="form-label fs-6 fw-bold">No. of Weeks</label>
              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                onChange={(e) => {
                  setWeeks(e.target.value);
                }}
                value={weeks}
              >
                <option value="" disabled selected>
                  Select Number of Weeks
                </option>
                <option value="1">
                  1
                </option>
                <option value="2">
                  2
                </option>
                <option value="3">
                  3
                </option>
              </select>
            </div>
            }
            {((bookingType == 1 && !!hours) ||
              (bookingType == 2 && startDay && endDay) ||
              (bookingType == 4 && !!startDate)
              || (bookingType == 3 && !!startDate && !!weeks)
            ) && (
                <>
                  {bookingType !== 1 && (
                    <div className="fv-row mb-7">
                      <label className="fw-bold fs-6 mb-2">
                        Clock In (location time zone)
                      </label>
                      <select
                        className="form-select form-select-solid fw-bolder"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        data-kt-user-table-filter="two-step"
                        data-hide-search="true"
                        value={clockIn}
                        onChange={(e) => setClockIn(e.target.value)}
                      >
                        <option value="">Select clock in (location time zone)</option>
                        {allHours.map((el) => (
                          <option
                            value={el.value}
                            key={el.value}
                            disabled={
                              today &&
                              el.value.split(":")[0] <= currentTime.split(":")[0]
                            }
                          >
                            {el.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {(bookingType == 1 || (bookingType !== 1 && clockIn)) && (
                    <div className="fv-row mb-7 row g-5">
                      <label className="fw-bold fs-6 mb-2 col-12">Booking for</label>

                      {vehicles.map((el, i) => (
                        <div key={i} className="selected col-12">
                          <select
                            className="form-select form-select-solid fw-bolder"
                            data-kt-select2="true"
                            data-placeholder="Select option"
                            data-allow-clear="true"
                            data-kt-user-table-filter="two-step"
                            data-hide-search="true"
                            onChange={(e) => handleSelectVehicle(e, i)}
                            value={el}
                          >
                            {availableVehicles.map((v) => {
                              if (allVehicles[0].id) {
                                return (
                                  <option
                                    value={v.id}
                                    key={v.id}
                                    disabled={vehicles.find((el) => +el === +v.id)}
                                  >
                                    {v.vehicle_type === 1 ? `${v?.truck_number} (Truck)` : v?.vehicle_type === 2 ? `${v?.trailer_number} (Trailer)` : `${v?.truck_number}/${v?.trailer_number} (Truck/Trailer)`}
                                  </option>
                                );
                              } else {
                                return (
                                  <option
                                    value={v.vehicle_id}
                                    key={v.vehicle_id}
                                    disabled={vehicles.find((el) => +el === +v.vehicle_id)}
                                  >
                                    {v.vehicle_type === 1 ? v?.truck_number : v?.vehicle_type === 2 ? v?.trailer_number : `${v?.truck_number}/${v?.trailer_number}`}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <button
                            className="remove"
                            onClick={() =>
                              setVehicles((prev) =>
                                prev.filter((elem) => elem !== el)
                              )
                            }
                          >
                            <KTIcon iconName="cross" />
                          </button>
                        </div>
                      ))}

                      {vehicles.length < availableVehicles.length && (
                        <button
                          // className={`button buttonFont buttonText buttonBorders buttonColors ${css.saved}`}
                          className="btn col-12"
                          style={{ backgroundColor: "#ffa459", color: "#fff" }}
                          onClick={addVehicle}
                        >
                          Select a saved vehicle
                        </button>
                      )}
                      {isManualFormVisible && (
                        <div className="manualForm">
                          <button
                            className="remove"
                            onClick={() => {
                              setManualFormVisible(false);
                              setName();
                              setType();
                              setMake();
                              setModel();
                              setPlateNumber();
                              setTrailerPlate();
                              setTruckNumber();
                              setTrailerNumber();
                            }}
                          >
                            <KTIcon iconName="cross" />
                          </button>
                          <div
                            className="d-flex flex-column scroll-y me-n7 pe-7"
                            id="kt_modal_add_user_scroll"
                            data-kt-scroll="true"
                            data-kt-scroll-activate="{default: false, lg: true}"
                            data-kt-scroll-max-height="auto"
                            data-kt-scroll-dependencies="#kt_modal_add_user_header"
                            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                            data-kt-scroll-offset="300px"
                          >
                            <select
                              className="form-select form-select-solid fw-bolder"
                              data-kt-select2="true"
                              data-placeholder="Select option"
                              data-allow-clear="true"
                              data-kt-user-table-filter="two-step"
                              data-hide-search="true"
                              value={type}
                              onChange={(e) => setType(Number(e.target.value))}
                            >
                              <option value="">Select Vehicle Type</option>
                              {(allowedVehicles.includes(1)) && <option value="1">Truck</option>}
                              {(allowedVehicles.includes(2)) && <option value="2">Trailer</option>}
                              {(allowedVehicles.includes(3)) && <option value="3">Truck + Trailer</option>}
                            </select>

                            {type && (
                              <div className="mt-7">
                                {type !== 2 && (
                                  <>
                                    <div className="fv-row mb-7">
                                      <label className="required fw-bold fs-6 mb-2">
                                        Company Name
                                      </label>
                                      <input
                                        placeholder="Company Name"
                                        type="text"
                                        name="companyname"
                                        className={clsx(
                                          "form-control form-control-solid mb-3 mb-lg-0"
                                        )}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="fv-row mb-7">
                                      <label className="required fw-bold fs-6 mb-2">
                                        Truck #
                                      </label>
                                      <input
                                        placeholder="Truck #"
                                        type="text"
                                        name="truckNumber"
                                        className={clsx(
                                          "form-control form-control-solid mb-3 mb-lg-0"
                                        )}
                                        value={truck_number}
                                        onChange={(e) => setTruckNumber(e.target.value)}
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="fv-row mb-7">
                                      <label className="required fw-bold fs-6 mb-2">
                                        Truck Make
                                      </label>
                                      <input
                                        placeholder="Truck Make"
                                        type="text"
                                        name="make"
                                        className={clsx(
                                          "form-control form-control-solid mb-3 mb-lg-0"
                                        )}
                                        value={make}
                                        onChange={(e) => setMake(e.target.value)}
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="fv-row mb-7">
                                      <label className="fw-bold fs-6 mb-2">
                                        Truck Plate # (optional)
                                      </label>
                                      <input
                                        placeholder="Truck Plate #"
                                        type="text"
                                        name="plate"
                                        className={clsx(
                                          "form-control form-control-solid mb-3 mb-lg-0"
                                        )}
                                        value={plate_number}
                                        onChange={(e) =>
                                          setPlateNumber(e.target.value)
                                        }
                                        autoComplete="off"
                                      />
                                    </div>
                                  </>
                                )}
                                {type !== 1 && (
                                  <>
                                    <div className="fv-row mb-7">
                                      <label className="required fw-bold fs-6 mb-2">
                                        Trailer Type
                                      </label>
                                      <input
                                        placeholder="Trailer Type"
                                        type="text"
                                        name="model"
                                        className={clsx(
                                          "form-control form-control-solid mb-3 mb-lg-0"
                                        )}
                                        value={model}
                                        onChange={(e) => setModel(e.target.value)}
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="fv-row mb-7">
                                      <label className="required fw-bold fs-6 mb-2">
                                        Trailer #
                                      </label>
                                      <input
                                        placeholder="Trailer #"
                                        type="text"
                                        name="trailer_number"
                                        className={clsx(
                                          "form-control form-control-solid mb-3 mb-lg-0"
                                        )}
                                        value={trailer_number}
                                        onChange={(e) =>
                                          setTrailerNumber(e.target.value)
                                        }
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="fv-row mb-7">
                                      <label className="fw-bold fs-6 mb-2">
                                        Trailer Plate # (optional)
                                      </label>
                                      <input
                                        placeholder="Trailer Plate #"
                                        type="text"
                                        name="trailer_plate"
                                        className={clsx(
                                          "form-control form-control-solid mb-3 mb-lg-0"
                                        )}
                                        value={trailer_plate}
                                        onChange={(e) =>
                                          setTrailerPlate(e.target.value)
                                        }
                                        autoComplete="off"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                          <button
                            type="submit"
                            className="btn mt-4 w-100"
                            data-kt-users-modal-action="submit"
                            style={{ background: "#ffa459", color: "#fff" }}
                            disabled={disabledVehicle()}
                            onClick={handleAddVehicle}
                          >
                            <span className="indicator-label">Add Vehicle</span>
                          </button>
                        </div>
                      )}
                      {!isManualFormVisible && (
                        <button
                          onClick={() => setManualFormVisible(true)}
                          style={{ backgroundColor: "#ffa459", color: "#fff" }}
                          className="btn col-12"
                        >
                          Add a vehicle manually
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}
            {(bookingType == 1 || clockIn) &&
              !!vehicles.filter((el) => !!el).length && (
                <>
                  <div className="mt-7">
                    <div className="title">Booking Breakdown</div>
                    <div className="availabilityNote">
                      Updated availability shown below for your chosen date range
                    </div>
                    <div
                      className={`availability ${availableSeatsOnDates <= 0 && "text-danger"
                        }`}
                    >
                      {availableSeatsOnDates <= 0
                        ? "Space Not Available for your date range"
                        : `${availableSeatsOnDates} seats left for your date range`}
                    </div>
                    {availableSeatsOnDates > 0 && (
                      <div className="note">
                        Please make sure you filled out the information fully and
                        accurately to avoid getting fined, booted or towed!
                      </div>
                    )}
                    {availableSeatsOnDates > 0 && (
                      <Breakdown
                        type={bookingType}
                        hours={hours}
                        days={days}
                        start={startDate}
                        price={
                          selectedListingId?.prices
                        }
                        // vehiclesAmount={vehicles.filter((el) => !!el).length}
                        vehiclesAmount={1}
                        weeks={weeks}
                        discount={discount}
                        promoCode={promoCode}
                        vehicles={vehicles}
                        allVehicles={availableVehicles}
                        listing={selectedListingId}
                      />
                    )}
                  </div>
                  {availableSeatsOnDates > 0 && (
                    <div className="chargeNote">User won't be charged yet</div>
                  )}
                  {bookingType == 4 &&
                    availableSeatsOnDates > 0 && (
                      <div className="chargeNote">
                        Please note that monthly charges are recurring. User will need to email{" "}
                        <a href="mailto:info@truckparkingclub.com">
                          info@truckparkingclub.com
                        </a>{" "}
                        to cancel.
                      </div>
                    )}

                  <div className="fv-row mt-7">
                    <label className="fw-bold fs-6 mb-2">
                      Promo Code (Optional)
                    </label>
                    {discount && discount.discounted_percent &&
                      <div className="form-check form-check-custom form-check-solid" style={{
                        background: "#ffa459",
                        padding: "5px",
                        display: "flex",
                        justifyContent: "flex-end",
                        borderRadius: "15px",
                        minWidth: "max-content",
                        maxWidth: "max-content"
                      }}>
                        <label
                          className="form-check-label"
                          for="flexCheckChecked"
                          style={{ fontWeight: "700", color: "#fff", fontSize: "14px" }}
                        >
                          {promoCode}
                        </label>
                        <button
                          className="listing-remove"
                          onClick={() => {
                            setDiscount({});
                            setPromoCode("");
                          }}
                        >
                          <KTIcon iconName="cross" />
                        </button>
                      </div>
                    }
                    <div style={{ position: "relative" }}>
                      {(discount && !discount.discounted_percent) && (
                        <input
                          placeholder="Enter Promo Code"
                          type="text"
                          name="code"
                          className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                          value={promoCode}
                          onChange={(e) => {
                            setPromoCode(e.target.value);
                          }}
                          autoComplete="off"
                          disabled={discount && discount.discounted_percent}
                        />
                      )}
                      {discount && !discount.discounted_percent && <div
                        className="apply cursor-pointer"
                        onClick={() => {
                          applyPromo(bookingType);
                        }}
                      >
                        Apply Promo Code
                      </div>
                      }
                    </div>
                  </div>

                </>
              )}

            {(bookingType == 1 || clockIn) &&
              !!vehicles.filter((el) => !!el).length &&
              availableSeatsOnDates > 0 && (
                <>
                  <label
                    className="fw-bold fs-1 mb-2 w-100 mt-7 mb-4"
                    style={{ textAlign: "center", color: "#b2b2b2" }}
                  >
                    Pay With
                  </label>
                  <div className={css.list}>
                    {!!cards.length &&
                      cards.map((el) => (
                        <div
                          key={el.id}
                          className={`${css.cardItem} ${selectedCard === el.id ? css.active : ""
                            }`}
                          onClick={() => {
                            if (selectedCard === el.id) {
                              setSelectedCard("");
                            } else {
                              setSelectedCard(el.id);
                            }
                          }}
                        >
                          <div className={css.icon}>
                            <img src={getBrandIcon(el.brand)} alt="" />
                          </div>
                          <div
                            className={css.number}
                          >{`•••• •••• •••• ${el.last4}`}</div>
                          <div>
                            {el.exp_month}/{el.exp_year.toString().slice(-2)}
                          </div>
                        </div>
                      ))}

                    <button
                      className={`btn`}
                      style={{
                        backgroundColor: "#ffa459",
                        color: "#fff",
                        marginBottom: "16px"
                      }}
                      onClick={() => setFormVisible((prev) => !prev)}
                    >
                      {isFormVisible ? "Hide form" : "Add a new card"}
                    </button>
                    {isFormVisible && (
                      <div>
                        <div style={{ height: '0' }}>
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: GOOGLE_MAPS_API_KEY,
                              language: 'en',
                              region: 'US'
                            }}
                            defaultCenter={{ lat: 38.6311966, lng: -98.1633745 }}
                            defaultZoom={4}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                          />
                        </div>
                        <div>
                          <label className="required fw-bold fs-6 mb-4">
                            Payment Card Details
                          </label>
                          <CardElement
                            // className={css.card}
                            className={clsx(
                              "form-control form-control-solid mb-3 mb-lg-0"
                            )}
                            options={{
                              hidePostalCode: true,
                              style: {
                                base: {
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  fontWeight: "500",
                                  color: "#4a4a4a",
                                  "::placeholder": { color: "#B2B2B2" }
                                }
                              }
                            }}
                          />
                        </div>
                        <div className={css.billing}>
                          <label
                            className="fw-bold fs-2"
                            style={{ color: "#b2b2b2" }}
                          >
                            Billing Details
                          </label>
                          <div className={css.inputs}>
                            <div className="fv-row mb-7">
                              <label className="required fw-bold fs-6 mb-2">
                                Card Holder's Name
                              </label>
                              <input
                                placeholder="Card Holder's Name"
                                type="text"
                                name="card_holder_name"
                                className={clsx(
                                  "form-control form-control-solid mb-3 mb-lg-0"
                                )}
                                value={cardHolderName}
                                onChange={(e) => setCardHolderName(e.target.value)}
                                autoComplete="off"
                              />
                            </div>

                            <div className="fv-row mb-7">
                              <label className="required fw-bold fs-6 mb-2">
                                Address
                              </label>
                              <input
                                placeholder="123 Example Street"
                                type="text"
                                name="address"
                                className={clsx(
                                  "form-control form-control-solid mb-3 mb-lg-0"
                                )}
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                autoComplete="off"
                                ref={inputRef}
                                disabled={!isMapLoaded}
                              />
                            </div>

                            <div className="fv-row mb-7">
                              <label className="required fw-bold fs-6 mb-2">
                                Postal Code
                              </label>
                              <input
                                placeholder="Postal Code"
                                type="text"
                                name="postal_code"
                                className={clsx(
                                  "form-control form-control-solid mb-3 mb-lg-0"
                                )}
                                value={zipCode}
                                onChange={(e) => setZipCode(e.target.value)}
                                autoComplete="off"
                              />
                            </div>

                            <div className="fv-row mb-7">
                              <label className="required fw-bold fs-6 mb-2">
                                City
                              </label>
                              <input
                                placeholder="City"
                                type="text"
                                name="city"
                                className={clsx(
                                  "form-control form-control-solid mb-3 mb-lg-0"
                                )}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                autoComplete="off"
                              />
                            </div>

                            <div className="fv-row mb-7">
                              <label className="required fw-bold fs-6 mb-2">
                                State
                              </label>
                              <input
                                placeholder="State"
                                type="text"
                                name="state"
                                className={clsx(
                                  "form-control form-control-solid mb-3 mb-lg-0"
                                )}
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <button
                            className="btn mt-0"
                            style={{ backgroundColor: "#2ecc71", color: "#fff" }}
                            onClick={handleSave}
                            disabled={
                              !cardHolderName ||
                              !address ||
                              !zipCode ||
                              !city ||
                              !state
                            }
                          >
                            Save Payment Card
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
          </>
        )}
      </div>
      <div className="text-center pt-15">
        <button
          type="reset"
          onClick={() => cancel()}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        >
          Discard
        </button>

        <button
          type="submit"
          className="btn"
          style={{ backgroundColor: "#ffa459", color: "#fff" }}
          data-kt-users-modal-action="submit"
          onClick={createBooking}
          disabled={
            !bookingType ||
            !userForEdit?.is_b2b && !selectedCard ||
            !userForEdit ||
            !vehicles.length ||
            loading
          }
        >
          <span className="indicator-label">Book</span>
        </button>
      </div>
      {/* end::Actions */}
      {/* </form> */}
    </>
  );
};

export { UserEditModalForm };

export const allHours = [
  { text: "00:00 AM", value: "00:00:00" },
  { text: "01:00 AM", value: "01:00:00" },
  { text: "02:00 AM", value: "02:00:00" },
  { text: "03:00 AM", value: "03:00:00" },
  { text: "04:00 AM", value: "04:00:00" },
  { text: "05:00 AM", value: "05:00:00" },
  { text: "06:00 AM", value: "06:00:00" },
  { text: "07:00 AM", value: "07:00:00" },
  { text: "08:00 AM", value: "08:00:00" },
  { text: "09:00 AM", value: "09:00:00" },
  { text: "10:00 AM", value: "10:00:00" },
  { text: "11:00 AM", value: "11:00:00" },
  { text: "12:00 PM", value: "12:00:00" },
  { text: "01:00 PM", value: "13:00:00" },
  { text: "02:00 PM", value: "14:00:00" },
  { text: "03:00 PM", value: "15:00:00" },
  { text: "04:00 PM", value: "16:00:00" },
  { text: "05:00 PM", value: "17:00:00" },
  { text: "06:00 PM", value: "18:00:00" },
  { text: "07:00 PM", value: "19:00:00" },
  { text: "08:00 PM", value: "20:00:00" },
  { text: "09:00 PM", value: "21:00:00" },
  { text: "10:00 PM", value: "22:00:00" },
  { text: "11:00 PM", value: "23:00:00" }
];
