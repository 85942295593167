import { ListingModalForm } from "./ListingModalForm"

const ListingModalFormWrapper = ({
  selectedPromo,
  setSelectedPromo,
  setOpenListingModal
}) => {
  const itemIdForUpdate = null;
  const isLoading = false;

  if (selectedPromo) {
    return (
      <ListingModalForm
        selectedPromo={selectedPromo}
        setSelectedPromo={setSelectedPromo}
        setOpenListingModal={setOpenListingModal}
      />
    );
  }
  return null;
};

export { ListingModalFormWrapper };
