import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";
import { useAuth } from "../../auth";

const UserEditModalForm = ({ user, setShowEditModal, getUser }) => {
  const {currentUser, haveAccess} = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [description, setDescription] = useState("");
  const [company, setCompany] = useState("");
  const [isAch, setAch] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [address, setAddress] = useState("");
  const [notificationUsers, setNotificationUsers] = useState([]);
  const [notificationEmail, setNotificationEmail] = useState("");
  const [notificationPhone, setNotificationPhone] = useState("");
  const [isFormVisible, setFormVisible] = useState(false);

  function formatPhone(phone) {
    phone = phone?.replace(/[^\d]/g, "");

    if (phone?.length === 11) {
      return phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
    } else if (phone?.length === 10) {
      return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
    }

    return null;
  }

  const cancel = () => {
    setShowEditModal(false);
  };

  const getNotificationUsers = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/user/notification?user_id=${user?.id}`)
      .then(res => {
        setNotificationUsers(res.data);
      })
  }

  useEffect(() => {
    setAvatar(`${process.env.REACT_APP_IMAGE_URL}200:200:${user?.avatar?.slice(1)}`);
    setFirstName(user?.first_name);
    setLastName(user?.last_name);
    setEmail(user?.email);
    setPhone(user?.phone);
    setBillingPhone(user?.billing_phone ? user?.billing_phone : "");
    setDescription(user?.description);
    setCompany(user?.company);
    setAch(user?.is_ach);

    getNotificationUsers()
  }, []);

  const editUser = () => {
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("display_name", firstName + " " + lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("billing_phone", billingPhone ? billingPhone : "");
    formData.append("description", description ? description : "");
    formData.append("company", company ? company : "");
    formData.append("mailing_address", address ? address : "");
    formData.append("is_ach", isAch);

    axios.put(`${process.env.REACT_APP_API_URL}/user/${user?.uid}`, formData)
      .then((res) => {
        toast.success("User Profile have been updated");
        getUser();
        cancel();
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  const saveNotificationUser = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/user/notification`, {
      ...(notificationEmail && { email: notificationEmail }),
      ...(notificationPhone && { phone: notificationPhone }),
      user_id: user?.id
    })
      .then(() => {
        toast.success("Additional user successfully added");
        getNotificationUsers();
        setNotificationPhone("");
        setNotificationEmail("");
        setFormVisible(false);
      })
      .catch(() => {
        toast.error("Technical Error, Please Try Again");
      })
  }

  const deleteNotificationUser = (id) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/user/notification/${id}`)
      .then(() => {
        toast.success("Additional user successfully deleted");
        getNotificationUsers();
      })
      .catch(() => {
        toast.error("Error while deleting additional user");
      })
  }

  const changeAvatar = (e) => {
    const avatar = e.target.files[0];
    const formData = new FormData();
    formData.append("avatar", avatar);
    axios.put(`${process.env.REACT_APP_API_URL}/user/${user?.uid}`, formData)
      .then((res) => {
        toast.success("Profile Picture Updated Successfully");
        setAvatar(URL.createObjectURL(avatar));
        getUser();
      })
      .catch((err) => {
        setAvatar(URL.createObjectURL(avatar));
        getUser();
      });
  };

  const blankImg = toAbsoluteUrl("/media/logos/blank.png");

  return (
    <>
      <div
        className="d-flex flex-column scroll-y me-n7 pe-7"
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        <div className="fv-row mb-7 d-flex flex-row">
          <div>
            <label className="d-block fw-bold fs-6 mb-5">Avatar</label>
            <div
              className="image-input "
              data-kt-image-input="true"
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {user?.avatar ? (
                <div
                  className="image-input-wrapper w-125px h-125px"
                  style={{
                    backgroundImage: `url('${avatar}')`,
                    boxShadow: "none",
                  }}
                />
              ) : (
                <div
                  className="image-input-wrapper w-125px h-125px"
                  style={{ backgroundImage: `url('${blankImg}')` }}
                />
              )}

              <label
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                title="Change avatar"
              >
                <i className="bi bi-pencil-fill fs-7" />

                <input
                  type="file"
                  name="avatar"
                  accept="image/jpg, image/jpeg, image/png, image/gif"
                  onChange={changeAvatar}
                />
                <input type="hidden" name="avatar_remove" />
              </label>

            </div>
          </div>
        </div>
        <div className="fv-row mb-7">
          <label className="required fw-bold fs-6 mb-2">First Name</label>
          <input
            placeholder="First name"
            type="text"
            name="name"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
           
          />
        </div>

        <div className="fv-row mb-7">
          <label className="required fw-bold fs-6 mb-2">Last Name</label>
          <input
            placeholder="Last name"
            type="text"
            name="name"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        <div className="fv-row mb-7">
          <label className="required fw-bold fs-6 mb-2">Email</label>
          <input
            placeholder="Email"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            type="email"
            name="email"
            autoComplete="off"
            disabled={+currentUser.role == 2}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => {
              if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)){
                setEmail(email)
              }else{
                setEmail(user.email)
                toast("Please Enter Valid Email", {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }
            }}
          />
        </div>

        <div className="fv-row mb-7">
          <label className="fw-bold fs-6 mb-2">Phone Number</label>

          <PatternFormat
            format={"+1 (###) ###-####"}
            className={"form-control form-control-solid mb-3 mb-lg-0"}
            value={phone}
            onValueChange={(values) => setPhone(values.value)}
            placeholder={"Phone Number"}
          />
        </div>
        <div className="fv-row mb-7">
          <label className="fw-bold fs-6 mb-2">Billing Phone Number</label>
          <PatternFormat
            format={"+1 (###) ###-####"}
            className={"form-control form-control-solid mb-3 mb-lg-0"}
            value={billingPhone}
            onValueChange={(values) => setBillingPhone(values.value)}
            placeholder={"Billing Phone Number"}
          />
        </div>
        <div className="fv-row mb-7">
          <label className="fw-bold fs-6 mb-2">Mailing Address</label>

          <input
            placeholder="Mailing Address"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            type="textarea"
            name="Mailing Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="fv-row mb-7">
          <label className="fw-bold fs-6 mb-2">Profile Bio</label>

          <input
            placeholder="Bio"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            type="textarea"
            name="bio"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="fv-row mb-7">
          <label className="fw-bold fs-6 mb-2">Company Name</label>

          <input
            placeholder="Company Name"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            type="text"
            name="company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </div>

        <div className="input-search mb-5 form-check form-check-custom form-check-solid">
          <input
            className="form-check-input"
            type="checkbox"
            id='ach'
            checked={isAch}
            onChange={(e) => setAch(prev => !prev)}
          />
          <label
            className="form-check-label"
            htmlFor="ach"
            style={{ fontWeight: "700", color: "#3f4254" }}
          >
            Allow ACH
          </label>
        </div>

     {(haveAccess(currentUser,  'user_notifiers_list')) && 
      <div className='mt-4'>
          <h3
            style={{
              color: "#b2b2b2",
              fontSize: "16px",
              width: "70%",
              marginBottom: '14px'
            }}
          >
            Additional User For Notification Sharing
          </h3>
          {!!notificationUsers.length ? (
            <div className="row gx-2 gy-4 my-4">
              {notificationUsers.map(user => (
                <div
                  key={user.id}
                  className="col-12 col-md-6"
                  style={{ padding: '0 8px' }}
                >
                  <div
                    className="card card-custom g-10"
                    style={{
                      border: "1px solid #b2b2b2",
                      padding: "0 10px",
                      background: "rgba(244,244,244,0.4)",
                      borderRadius: "10px"
                    }}
                  >
                  {(haveAccess(currentUser,  'user_notifiers_delete')) && <span
                      style={{
                        position: "absolute",
                        background: "#ed2626",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        right: "-10px",
                        top: "-10px",
                        cursor: "pointer"
                      }}
                      onClick={() => deleteNotificationUser(user.id)}
                    >
                      <i
                        className="fa fa-times"
                        style={{ color: "#fff" }}
                      />
                    </span>
                    }
                    <div
                      className="row pt-4"
                      style={{ height: "auto" }}
                    >
                      <div className="fv-row mb-2">
                        <h5 className="fw-bold fs-6 mb-2">Email</h5>
                        <h3 style={{ color: "#ffa459" }}>
                          {user.email ? user.email : "-"}
                        </h3>
                      </div>
                      <div className="fv-row mb-2">
                        <h5 className="fw-bold fs-6 mb-2">
                          Phone Number
                        </h5>
                        <h3 style={{ color: "#ffa459" }}>
                          {user?.phone ? formatPhone(user.phone) : "-"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='mb-7'>No Additional User Added Yet</div>
          )}
       {(haveAccess(currentUser,  'user_notifiers_create')) &&
          <>
          {isFormVisible ? (
            <div>
              <div className="fv-row mb-3">
                <label className="fw-bold fs-6 mb-2">Email</label>
                <input
                  placeholder="Enter Email"
                  className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                  type="text"
                  name="additional-email"
                  value={notificationEmail}
                  onChange={(e) => setNotificationEmail(e.target.value)}
                />
              </div>
              <div className="fv-row mb-3">
                <label className="fw-bold fs-6 mb-2">Phone Number</label>
                <PatternFormat
                  format={"+1 (###) ###-####"}
                  className={"form-control form-control-solid mb-3 mb-lg-0"}
                  value={notificationPhone}
                  onValueChange={(values) => setNotificationPhone(values.value)}
                  placeholder={"Enter Phone Number"}
                  autoComplete="off"
                />
              </div>
              <div className='mt-4'>
                <button
                  className="btn w-30"
                  style={{
                    background: "rgb(46, 204, 113)",
                    color: "#fff",
                    height: "auto",
                    marginRight: "12px"
                  }}
                  onClick={saveNotificationUser}
                  disabled={!notificationEmail && !notificationPhone}
                >
                  Save
                </button>
                <button
                  className="btn w-30"
                  style={{
                    background: "#f4f4f4",
                    color: "#7e8299",
                    height: "auto",
                  }}
                  onClick={() => setFormVisible(false)}
                >
                  Discard
                </button>
              </div>
            </div>
          ) : (
            <button
              onClick={() => setFormVisible(true)}
              className="btn w-30"
              style={{
                background: "#4a4a4a",
                color: "#fff",
                height: "auto",
              }}
            >
              Add Notification User
            </button>
          )}
          </>
          }
        </div>
        }
      </div>

      <div className="text-center pt-15">
        <button
          type="reset"
          onClick={() => cancel()}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        >
          Discard
        </button>

        <button
          type="submit"
          className="btn"
          data-kt-users-modal-action="submit"
          style={{ background: "#ffa459", color: "#fff" }}
          disabled={!firstName || !lastName || !email}
          onClick={editUser}
        >
          <span className="indicator-label">Submit</span>
        </button>
      </div>
    </>
  );
};

export { UserEditModalForm };
