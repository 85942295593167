import {AddNewPromoModalForm} from './AddNewPromoModalForm'

const AddNewPromoModalFormWrapper = ({
    setOpenPromoModal,
    fetchPromoCode,
    selectedPromo,
    setSelectedPromo,
    tab
}) => {
    return <AddNewPromoModalForm
    setOpenPromoModal={setOpenPromoModal}
    fetchPromoCode={fetchPromoCode}
    selectedPromo={selectedPromo}
    setSelectedPromo={setSelectedPromo}
    tab={tab}
    />

}

export {AddNewPromoModalFormWrapper}
