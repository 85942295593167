import { useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";

const DiscountModalForm = ({ bookingId, setBookingToDiscount, setOpenDiscountModal, fetchBooking }) => {
  const [discount, setDiscount] = useState('')

  const cancel = () => {
    setBookingToDiscount(null)
    setOpenDiscountModal(false)
  };

  const applyPromo = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/booking/${bookingId}/discount`, {
      discounted_amount: discount * 100
    })
      .then(() => {
        toast.success('Discount applied to the next cycle')
        setBookingToDiscount(null)
        setOpenDiscountModal(false)
        fetchBooking(bookingId)
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  };

  return (
    <>
      {/* begin::Scroll */}
      <div>
        <div className="fv-row mb-7">
          <label className="required fw-bold fs-6 mb-2">Discount (Fixed Amount)</label>
          <input
            placeholder="Enter Amount"
            type="text"
            name="promo"
            className={clsx(
              "form-control form-control-solid mb-3 mb-lg-0"
            )}
            value={discount}
            onChange={(e) => {
              const re = /^[0-9]*$/
              if (!re.test(e.target.value)) {
                return
              } else {
                setDiscount(e.target.value);
              }
            }}
          />
        </div>
      </div>
      {/* end::Scroll */}

      {/* begin::Actions */}
      <div className="text-center pt-15">
        <button
          type="reset"
          onClick={() => cancel()}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        >
          Discard
        </button>
        <button
          type="submit"
          className="btn"
          data-kt-users-modal-action="submit"
          style={{ background: "#ffa459", color: "#fff" }}
          disabled={!discount}
          onClick={applyPromo}
        >
          <span className="indicator-label">Apply</span>
        </button>
      </div>
      {/* end::Actions */}
    </>
  );
};

export { DiscountModalForm };
