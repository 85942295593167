import { KTIcon } from "../../../../_metronic/helpers";

const BlacklistHeader = ({ setOpenModal, type, setType, setSearch, currentUser, haveAccess }) => {
  return (
    <div className="card-header border-0 pt-6 justify-content-between align-items-center">
        <div className="d-flex align-items-center rounded justify-content-start" style={{ background: '#f9f9f9', borderRadius: '10px' }}>
        <span style={{ padding: '15px', fontWeight: '700', background: type == 1 ? '#a5f0c2' : '', borderRadius: '10px', cursor: 'pointer' }}
          onClick={() => {
            setType('1')
            setSearch('')
            }}>Emails</span>
        <span style={{ padding: '15px', fontWeight: '700', background: type == 2 ? '#a5f0c2' : '', borderRadius: '10px', cursor: 'pointer' }}
          onClick={() => {
            setType('2')
            setSearch('')
            }}>Phones</span>
      </div>
    {(haveAccess(currentUser, 'blacklist_create')) &&
    <div className="d-flex">
    <div className="card-toolbar" style={{ gap: "5px", margin: '0 12px 0 0' }}>
       <button
          type="button"
          className="btn"
          style={{ backgroundColor: "#2ecc71", color: "white" }}
          onClick={() => {
            setType('2')
            setOpenModal(true);
          }}
        >
          <KTIcon iconName="plus" className="fs-2 text-white" />
          Add Blacklist Phone
        </button>
      </div>
      <div className="card-toolbar" style={{ gap: "5px" }}>
        <button
          type="button"
          className="btn"
          style={{ backgroundColor: "#2ecc71", color: "white" }}
          onClick={() => {
            setType('1')
            setOpenModal(true);
          }}
        >
          <KTIcon iconName="plus" className="fs-2 text-white" />
          Add Blacklist Email
        </button>
      </div>
      </div>
      }
    </div>
  );
};

export { BlacklistHeader };
