import {useState,useEffect, useRef} from 'react'
import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import { UsersListFilterSearch } from './UsersListFilterSearch'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import { useAuth } from '../../../../../auth'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const UsersListHeader = () => {
  const navigate = useNavigate()
  const notificationRef = useRef();
  const {selected, setItemIdForUpdate, setIsModalType} = useListView()
  const {currentUser, haveAccess} = useAuth()
  const [openNotification, setOpenNotification] = useState(false)
  const [users, setUsers] = useState([])



  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
  
    useEffect(() => {
      document.addEventListener('click', handleClick);
  
      return () => {
        document.removeEventListener('click', handleClick);
      };
    });
  };
  
    
    useOutsideClick(notificationRef, () => {
      if (openNotification) setOpenNotification(false);
    });
  

const getLatestUsers = () => {
  axios.get(`${process.env.REACT_APP_API_URL}/user/latest?page=1&page_size=7&sort=2`).then((res) => {
      setUsers(res.data.users);
  })
}

useEffect(() => {
  getLatestUsers();
},[])



  return (
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar' style={{gap:'5px'}}>
             <div
              style={{
                position: 'relative',
                cursor: 'pointer',
                width:'max-content',
                height: '43px',
                marginRight: '15px',
                border: 'solid 1px rgba(255, 255, 255, 0.08)',
                borderRadius: '5px',
                background: '#f9f9f9',
                display: 'flex',
                alignItems: 'center',
                padding:'0 15px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
              }}
              className='fw-bolder'          
              ref={notificationRef}    
              onClick={() => {
                setOpenNotification(!openNotification)
              }}       
            >
                <span style={{ overflow: 'auto', width:'100%', color:'#5e6278' }}
                >
                <KTIcon iconName="notification-on" className="fs-2 text-danger" />
                </span>
              {openNotification  && (
                <div
                  style={{
                    position: 'absolute',
                    width: '300px',
                    minHeight: 'max-content',
                    overflowY: 'auto',
                    maxHeight: '300px',
                    left: '0%',
                    top: '115%',
                    borderRadius: '5px',
                    border: 'solid 1px rgba(255, 255, 255, 0.08)',
                    background: '#f9f9f9',
                    zIndex: 99,
                    minWidth: '10rem',
                    fontSize: '1.1rem',
                    color: '#000',
                    textAlign: 'left',
                    padding: '10px 15px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  {users && users.length > 0 ? users.map((item, index) => (
                    <div
                      style={{
                        display: 'flex',
                        gap: '15px',
                        marginLeft: 0,
                        alignItems: 'center',
                        marginBottom: '4px',
                        color: '#5e6278',
                        fontSize:'16px',
                        marginBottom:'10px'                      
                      }}
                      onClick={() => {
                        navigate(`/users/${item.uid}`)
                      }}
                      key={index}
                    >
                    <span style={{cursor:'pointer'}}> {item.first_name + " " + item.last_name}</span>
                    </div>
                    
                  ))
                :
                <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  marginLeft: 0,
                  alignItems: 'center',
                  marginBottom: '4px',
                  color: '#ed2626',
                  fontSize:'16px',
                }}
              >
              <span>No New User Added</span>
              </div>
                }
                </div>
              )}
            </div>
      <UsersListFilterSearch />
   {(haveAccess(currentUser, 'user_create')) && <button type='button' className='btn'  style={{ backgroundColor: "#2ecc71", color: "white" }}
      onClick={() => {
        setIsModalType("NEW")
        setItemIdForUpdate()
      }}
      >
        <KTIcon iconName='plus' className='fs-2 text-white' />
        Add New User
      </button>
}
        {/* begin::Group actions */}
        {/* {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />} */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
