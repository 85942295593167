import { useMemo } from "react";
import { useTable } from "react-table";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";
import { useQueryResponseData, useQueryResponseLoading, useQueryResponse } from "../core/QueryResponseProvider";
import { usersColumns } from "./columns/_columns";
import { AdminsListLoading } from "../components/loading/AdminsListLoading";
import { KTCardBody } from "../../../../../_metronic/helpers";
import { AdminsListPagination } from "../components/pagination/AdminsListPagination";

const AdminsTable = () => {
  const { response } = useQueryResponse();
  const users = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => users, [users]);
  const columns = useMemo(() => usersColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data
  });

  return (
    <KTCardBody className="py-4">
      <h6
        style={{
          color: "#7e8299",
          textAlign: "right"
        }}
      >
        Total Admin Users: {response?.data?.total || 0}
      </h6>
      <div className="table-responsive">
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead>
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              {headers.map((column) => {
              return <CustomHeaderColumn key={column.id} column={column} />
            })}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0 && (
              rows.map((row, i) => {
                prepareRow(row);

                return (
                  <CustomRow row={row} key={`row-${i}-${row.id}`} />
                )
              })
            )}
          </tbody>
        </table>
        {rows && !rows.length > 0 && 
        <div className='d-flex text-center text-gray-600 fw-bolder fs-6 w-100 align-content-center justify-content-center mt-10'>
         No matching records found
        </div>
        }
      </div>

      <AdminsListPagination />
      {isLoading && (
        <AdminsListLoading />
      )}
    </KTCardBody>
  );
};

export { AdminsTable };
