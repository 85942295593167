import { ListingEditModalForm } from "./ListingEditModalForm";

const ListingEditModalFormWrapper = ({
  selectedListingId,
  setSelectedListingId,
  openEditListing,
  setOpenEditListing,
  listing,
  getListing,
}) => {
  const itemIdForUpdate = null;
  const isLoading = false;

  if (selectedListingId) {
    return (
      <ListingEditModalForm
        isUserLoading={isLoading}
        user={{ id: undefined }}
        setSelectedListingId={setSelectedListingId}
        selectedListingId={selectedListingId}
        openEditListing={openEditListing}
        setOpenEditListing={setOpenEditListing}
        listing={listing}
        getListing={getListing}
      />
    );
  }
  return null;
};

export { ListingEditModalFormWrapper };
