import { useEffect, useState } from 'react'
import { VehicleSwapModalHeader } from './VehicleSwapModalHeader'
import { VehicleSwapModalFormWrapper } from './vehicleSwapModalFormWrapper'
import axios from "axios";
import { toast } from "react-toastify";
import { VehicleModal } from "../../UserProfile/vehicleModal/VehicleModal";

const VehicleSwapModal = ({ vehicles, selectedVehicle, setSelectedVehicle, fetchBooking, selectedBookingId, fetchBookingId, bookingDetails, customerDetails, getUserDetails }) => {
  const [selectSwapVehicle, setSelectSwapVehicle] = useState(null);
  const [editVehicle, setEditVehicle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  // const onSwapVehicle = () => {
  //   setIsLoading(true);
  //   axios.delete(`${process.env.REACT_APP_API_V2_URL}/booking/${selectedBookingId}/vehicle?vehicle_id=${selectedVehicle.vehicle_id}`).then((res) => {
  //     onAddVehicle(selectSwapVehicle.id)
  //   }).catch((err) => {
  //     toast.error(err.response.data.message);
  //     setIsLoading(false);
  //   }) 
  // }


  const onSwapVehicle = () => {
    axios.put(`${process.env.REACT_APP_API_V2_URL}/booking/${selectedBookingId}/vehicle`, {
      vehicle_id: selectSwapVehicle.id,
      removed_vehicle_id:selectedVehicle.vehicle_id
    }).then((res) => {
      toast.success("Vehicle Changed Successfully");
      fetchBookingId(selectedBookingId);
      setSelectedVehicle(null);
      setIsLoading(false);
    }).catch((err) => {
      toast.error(err.response.data.message);
    })
  };



  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className={`modal-dialog modal-dialog-centered mw-650px`}>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <VehicleSwapModalHeader vehicles={vehicles} selectedVehicle={selectedVehicle} setSelectedVehicle={setSelectedVehicle} selectedBookingId={selectedBookingId} setEditVehicle={setEditVehicle} setShowModal={setShowModal} />
            {/* begin::Modal body */}
            <div className={`modal-body scroll-y`}>
              <VehicleSwapModalFormWrapper vehicles={vehicles} selectedVehicle={selectedVehicle} setSelectedVehicle={setSelectedVehicle} fetchBooking={fetchBooking} selectedBookingId={selectedBookingId} fetchBookingId={fetchBookingId} bookingDetails={bookingDetails} setSelectSwapVehicle={setSelectSwapVehicle} selectSwapVehicle={selectSwapVehicle} />
            </div>
            {/* end::Modal body */}
            <div className="text-center py-2 mx-5 mx-xl-5">
              <button
                type="reset"
                disabled={isLoading}
                onClick={() => {
                  setSelectedVehicle(null)
                }}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
              >
                Cancel
              </button>
              <button className='btn me-3' style={{ background: "#2ec771", color: "#fff" }}
              disabled={isLoading}
              onClick={() => setShowModal(true)}
              >Add New Vehicle</button>
              <button
                type="submit"
                className="btn"
                data-kt-users-modal-action="submit"
                style={{ background: "#ffa459", color: "#fff" }}
                disabled={!selectSwapVehicle || isLoading}
                onClick={onSwapVehicle}
              >
                <span className="indicator-label">Change Vehicle</span>
              </button>
            </div>
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
      {showModal && <VehicleModal setShowEditModal={setShowModal} getUser={getUserDetails} customerDetails={customerDetails} setVehicle={setEditVehicle} user={editVehicle} hide={true} />}
    </>
  )
}

export { VehicleSwapModal }
