import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNotEmpty, toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { User } from "../core/_models";
import clsx from "clsx";
import { useListView } from "../core/ListViewProvider";
import { UsersListLoading } from "../components/loading/UsersListLoading";
import { createUser, updateUser } from "../core/_requests";
import { useQueryResponse } from "../core/QueryResponseProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
  phone: Yup.number().min(10, "Minimum 10 digits").max(10, "Maximum 10 digits"),
});

const UserEditModalForm = ({ user, isUserLoading }) => {
  const navigate = useNavigate()
  const { setItemIdForUpdate, modalType, setIsModalType, itemIdForUpdate } =
    useListView();
  const { refetch } = useQueryResponse();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] =  useState({
    bookings:[],
    listings:[],
  })

  const [userForEdit] = useState({
    ...user,
    avatar: user.avatar,
    role: user.role,
    position: user.position,
    name: user.displayName || user?.first_name + " " + user?.last_name,
    email: user.email,
    phone: user.phone,
    two_steps: user.disabled,
    bio: user.description,
    company: user.company,
  });

  const cancel = (withRefresh) => {
      refetch();
    setItemIdForUpdate(undefined);
    setIsModalType("");
  };

  const blankImg = toAbsoluteUrl("/media/logos/blank.png");
  // const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (isNotEmpty(values.uid)) {
          await updateUser({
            id: "j",
          });
        } else {
          await createUser(values);
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
        cancel(true);
      }
    },
  });

  const changePassword = () => {
    if (password !== confirmPassword) {
      toast("Password & Confirm Password Doesn't Match, Enter Again", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/user/${itemIdForUpdate}/password`,
          {
            password: password,
          }
        )
        .then((res) => {
          toast("Password is changed", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          cancel()
        })
        .catch((err) => {
          if(err && err.response && err.response?.data && err.response?.data?.message){
            toast(err.response.data.message, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }else{
          toast("Technical Error, Please Try Again", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        });
    }
  };


  const deleteUser = (userId) => {
     axios.delete(`${process.env.REACT_APP_API_URL}/user/${userId}`).then(() => {
      toast("User have been deleted", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
          });
        cancel()
    }).catch((err) => {
      if(err && err.response && err.response?.data && err.response?.data?.message){
        toast(err.response.data.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setErrorMessage({
          bookings:err.response.data.bookings,
          listings:err.response.data.spots_owned,
        })
      }else{
      toast("Technical Error, Please Try Again", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
          });
        }
    })
  }
  

  return (
    <>
      {modalType === "EDIT" ? (
        <form
          id="kt_modal_add_user_form"
          className="form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className="d-flex flex-column scroll-y me-n7 pe-7"
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            {/* begin::Input group */}
            <div className="fv-row mb-7 d-flex flex-row">
              {/* begin::Label */}
              <div>
                <label className="d-block fw-bold fs-6 mb-5">Avatar</label>
                {/* end::Label */}

                {/* begin::Image input */}
                <div
                  className="image-input"
                  data-kt-image-input="true"
                  style={{ backgroundImage: `url('${blankImg}')` }}
                >
                  {/* begin::Preview existing avatar */}
                  {user?.avatar ? (
                    <div
                      className="image-input-wrapper w-125px h-125px"
                      style={{
                        backgroundImage: `url('${
                          process.env.REACT_APP_IMAGE_URL + '300:200:' + user.avatar.slice(1)
                        }')`,
                        boxShadow: "none",
                      }}
                    ></div>
                  ) : (
                    <div
                      className="image-input-wrapper w-125px h-125px"
                      style={{ backgroundImage: `url('${blankImg}')` }}
                    ></div>
                  )}
                  {/* end::Preview existing avatar */}

                  {/* begin::Label */}
                  <label
                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="change"
                    data-bs-toggle="tooltip"
                    title="Change avatar"
                  >
                    <i className="bi bi-pencil-fill fs-7"></i>

                    <input
                      type="file"
                      name="avatar"
                      accept=".png, .jpg, .jpeg"
                    />
                    <input type="hidden" name="avatar_remove" />
                  </label>
                  {/* end::Label */}

                  {/* begin::Cancel */}
                  {/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
                  {/* end::Cancel */}

                  {/* begin::Remove */}
                  {/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='remove'
              data-bs-toggle='tooltip'
              title='Remove avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
                  {/* end::Remove */}
                </div>
              </div>

              {/* end::Image input */}

              {/* begin::Hint */}
              {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
              {/* end::Hint */}
            </div>
            {/* end::Input group */}

            {/* begin::Input group */}
            <div className="fv-row mb-7">
              {/* begin::Label */}
              <label className="required fw-bold fs-6 mb-2">Full Name</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder="Full name"
                {...formik.getFieldProps("name")}
                type="text"
                name="name"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  { "is-invalid": formik.touched.name && formik.errors.name },
                  {
                    "is-valid": formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete="off"
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.name}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input group */}

            {/* begin::Input group */}
            <div className="fv-row mb-7">
              {/* begin::Label */}
              <label className="required fw-bold fs-6 mb-2">Email</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder="Email"
                {...formik.getFieldProps("email")}
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  { "is-invalid": formik.touched.email && formik.errors.email },
                  {
                    "is-valid": formik.touched.email && !formik.errors.email,
                  }
                )}
                type="email"
                name="email"
                autoComplete="off"
                disabled={true}
              />
              {/* end::Input */}
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              )}
            </div>
            {/* end::Input group */}

            {/* begin::Input group */}

            <div className="fv-row mb-7">
              {/* begin::Label */}
              <label className="fw-bold fs-6 mb-2">Phone Number</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder="Phone Number"
                {...formik.getFieldProps("phone")}
                className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                type="number"
                onWheel={(e) => e.target.blur()}
                name="phone"
                maxLength={10}
              />
              {/* end::Input */}
            </div>

            <div className="fv-row mb-7">
              {/* begin::Label */}
              <label className="fw-bold fs-6 mb-2">Profile Bio</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder="Bio"
                {...formik.getFieldProps("bio")}
                className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                type="textarea"
                name="bio"
              />
              {/* end::Input */}
            </div>

            <div className="fv-row mb-7">
              {/* begin::Label */}
              <label className="fw-bold fs-6 mb-2">Company Name</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder="Company Name"
                {...formik.getFieldProps("company")}
                className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                type="text"
                name="company"
              />
              {/* end::Input */}
            </div>

            <div
              className="
                form-check form-check-custom form-check-solid form-check-success form-switch 
           
                  "
            >
              <label
                className="form-check-label text-gray-700 fw-bold "
                htmlFor="kt_builder_sidebar_minimize_desktop_enabled"
                data-bs-toggle="tooltip"
                data-kt-initialized="1"
                style={{ marginRight: "10px" }}
              >
                Status
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="kt_builder_sidebar_minimize_desktop_enabled"
                {...formik.getFieldProps("two_steps")}
                checked={!user.two_steps ? true : false}
              />
            </div>

            {/* end::Input group */}
          </div>
          {/* end::Scroll */}

          {/* begin::Actions */}
          <div className="text-center pt-15">
            <button
              type="reset"
              onClick={() => cancel()}
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              disabled={formik.isSubmitting || isUserLoading}
            >
              Discard
            </button>

            <button
              type="submit"
              className="btn"
              data-kt-users-modal-action="submit"
              style={{ background: "#ffa459", color: "#fff" }}
              disabled={
                isUserLoading ||
                formik.isSubmitting ||
                !formik.isValid ||
                !formik.touched
              }
            >
              <span className="indicator-label">Submit</span>
              {(formik.isSubmitting || isUserLoading) && (
                <span className="indicator-progress">
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </form>
      ) : modalType === 'CHANGE_PASSWORD' ? (
        <>
          <div className="input-search mb-5">
            <label className="form-label fs-6 fw-bold">New Password</label>
            <input
              type="password"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autocomplete="off"
              readOnly
              onFocus={(e) => {
                e.target.readOnly = false
            }}
            onTouchStart={(e) => {
                e.target.readOnly = false
            }}
            />
          </div>
          <div className="input-search mb-5">
            <label className="form-label fs-6 fw-bold">Confirm Password</label>
            <input
              type="password"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autocomplete="off"
              readOnly
              onFocus={(e) => {
                e.target.readOnly = false
            }}
            onTouchStart={(e) => {
                e.target.readOnly = false
            }}
            />
          </div>
          <div className="text-center pt-5">
            <button
              type="reset"
              onClick={() => cancel()}
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              // disabled={formik.isSubmitting || isUserLoading}
            >
              Discard
            </button>

            <button
              type="submit"
              className="btn btn-primary"
              style={{ background: "#ffa459", color: "#fff" }}
              data-kt-users-modal-action="submit"
              disabled={password && confirmPassword ? false : true}
              onClick={changePassword}
            >
              <span className="indicator-label">Change</span>
            </button>
          </div>
        </>
      )
    :
    (
      <>
          <h4>
            Are you sure you want to{" "}
            <span style={{ color: "#ed2626", fontWeight: 700 }}>
              Delete
            </span>{" "}
            this user?
          </h4>
        {(errorMessage?.listings?.length > 0  || errorMessage?.bookings?.length > 0) && <h3 className="fw-bold text-danger my-5" style={{textAlign:'center'}}>Oops! this user can't be deleted</h3>}
        <div style={{minHeight:'auto', maxHeight:'250px', overflowY:'auto'}}>
          {errorMessage?.listings && errorMessage?.listings?.length > 0 && (
            <div className="mb-7">
              <h6 className="fw-bold">Delete listings first in order to delete the user account</h6>
              {errorMessage?.listings?.map((listing) => (
                <h4 key={listing.listing_id} onClick={() => navigate(`/listings/${listing.listing_id}`)} style={{color:'#ffa459', cursor: 'pointer'}}>
                  *{listing.listing_name}
                  <br />
                </h4>
              ))}
              </div>
          )}
            {errorMessage.bookings && errorMessage.bookings.length > 0 && (
            <div className="mb-7">
              <h6 className="fw-bold">User have bookings</h6>
              {errorMessage.bookings.map((booking) => (
                <h4 key={booking.id}>
                  *Reservation Code: <span style={{color:'#2ecc71'}}>{booking.reservation_code}</span>
                  <br />
                </h4>
              ))}
              </div>
          )}
          </div>
        <div className="text-center pt-5">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            // disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            style={{ background: "#ffa459", color: "#fff" }}
            data-kt-users-modal-action="submit"
            onClick={() => deleteUser(user.uid)}
          >
            <span className="indicator-label">Delete</span>
          </button>
        </div>
      </>
    )
    }
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  );
};

export { UserEditModalForm };
