import {MessageModalForm} from './MessageModalForm'

const MessageModalFormWrapper = ({
    setOpenMessageModal,
    type,
    setTrigger
}) => {
    return <MessageModalForm
        setOpenMessageModal={setOpenMessageModal}
        type={type}
        setTrigger={setTrigger}
    />

}

export {MessageModalFormWrapper}
