import {useState} from "react";
import axios from "axios";
import { toast } from "react-toastify";


const AdvocateModalForm = ({
  user,
  getUser,
  setOpenAdvocateModal
}) => {
  const [percent, setPercent] = useState();


  const onChangePercent = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}/user/${user.uid}/fees?advocate_fees=${percent}`).then(() => {
      toast.success("Marketting Comission Fees have been updated");
      getUser();
      setOpenAdvocateModal(false)
    }).catch((err) => {
      if(err && err?.response?.data && err?.response?.data?.message){
        toast.error(err.response.data.message);
      }else{
        toast.error('Something went wrong');
      }
    })
  }

  return (
    <>
      <div className="kt_body">
          <>
            <div className="input-search mb-5">
              <label className="form-label fs-6 fw-bold">Percentage</label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder="Enter Commission Percentage"
                value={percent}
                min={1}
                onChange={(e) => setPercent(e.target.value.replace(/[^0-9]/g, ""))}
              />
            </div>
          </>  
      </div>

      <div className="text-center pt-5">
        <button
          type="reset"
          onClick={() => setOpenAdvocateModal(false)}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        >
          Close
        </button>
          <button
            type="submit"
            className="btn"
            style={{ backgroundColor: "#ffa459", color: "#fff" }}
            data-kt-users-modal-action="submit"
            disabled={!percent}
            onClick={onChangePercent}
          >
            <span className="indicator-label">Change Marketing Commission</span>
          </button>
      </div>
    </>
  );
};

export { AdvocateModalForm };
