import { useEffect } from "react";
import { MenuComponent } from "../../../../../../_metronic/assets/ts/components";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useAuth } from "../../../../auth";

const AdminActionsCell = ({ id }) => {
  const {currentUser, haveAccess} = useAuth();
  const { setItemIdForUpdate, setIsModalType } = useListView();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openEditModal = () => {
    setIsModalType("EDIT");
    setItemIdForUpdate(id);
  };

  const changeStatus = () => {
    setIsModalType("CHANGE_STATUS");
    setItemIdForUpdate(id);
  };

  const changeRole = () => {
    setIsModalType("CHANGE_ROLE");
    setItemIdForUpdate(id);
  };

  const onDelete = () => {
    setIsModalType("DELETE");
    setItemIdForUpdate(id);
  };

  const addAccess = () => {
    setIsModalType("ADD_ACCESS");
    setItemIdForUpdate(id);
  };

  const viewListing = () => {
    setIsModalType("VIEW_LISTINGS");
    setItemIdForUpdate(id);
  };


  return (
    <>
      <a
        href="#"
        className="btn btn-light btn-active-light-primary btn-sm"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        <KTIcon iconName="pencil" className="fs-5 m-0" />
      </a>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4"
        data-kt-menu="true"
      >
      {(haveAccess(currentUser, 'admin_edit')) &&  <div className="menu-item px-3">
          <a className="menu-link px-3" onClick={openEditModal}>
            Edit Profile
          </a>
        </div>
        }
        {((haveAccess(currentUser, 'admin_revoke_access') && id?.access_level === 3) && id?.access?.length > 0) && <div className="menu-item px-3">
          <a className="menu-link px-3" onClick={viewListing}>
            Show Listings
          </a>
        </div>
        }
        {(haveAccess(currentUser, 'admin_edit_access')  && id?.access_level === 3) && <div className="menu-item px-3">
          <a className="menu-link px-3" onClick={addAccess}>
            Add Access
          </a>
        </div>
        }
    {(haveAccess(currentUser, 'admin_permission_edit')) &&  <div className="menu-item px-3">
          <a className="menu-link px-3" onClick={changeRole}>
            Change Role
          </a>
        </div>
        }
     {(haveAccess(currentUser, 'admin_permission_edit')) &&     <div className="menu-item px-3">
          <a className="menu-link px-3" onClick={changeStatus}>
            Change Status
          </a>
        </div>
        }
         {(haveAccess(currentUser, 'admin_delete')) && <div className="menu-item px-3">
          <a
            className="menu-link px-3"
            data-kt-users-table-filter="delete_row"
            onClick={onDelete}
          >
            Delete User
          </a>
        </div>
        }
      </div>
    </>
  );
};

export { AdminActionsCell };
