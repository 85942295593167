import { AdminEditModalForm } from "./AdminEditModalForm";

const AdminEditModalFormWrapper = ({ isLoading, error, user }) => {
  if (!isLoading && !error && user) {
    return (
      <AdminEditModalForm
        user={user}
      />
    )
  }

  return null;
};

export { AdminEditModalFormWrapper };
