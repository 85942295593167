import React from "react";
import { useState, useEffect } from "react";
import { KTCard, KTCardBody, KTIcon, useDebounce } from "../../../_metronic/helpers";
import axios from "axios";
import "./style.css";
import { BlacklistHeader } from "./header/BlacklistHeader";
import { toast } from "react-toastify";
import { AddToBlacklistModal } from "./AddToBlacklistModal/AddToBlacklistModal";
import { useAuth } from "../auth";
import { BlacklistSearchComponent } from "./header/BlacklistSearchComponent";

const Index = () => {
  const {currentUser, haveAccess} = useAuth();
  const [type, setType] = useState('1');
  const [search, setSearch] = useState('');
  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState(false);


  const debouncedSearchTerm = useDebounce(search, 600)

  const fetchBlacklist = () => {
    axios.get(`${process.env.REACT_APP_API_V2_URL}/blacklist?type=${type}&search=${encodeURIComponent(debouncedSearchTerm?.trim())}`)
      .then((res) => {
          setList(res.data);
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  useEffect(() => {
    (haveAccess(currentUser, 'blacklist_list')) &&
    fetchBlacklist();
  }, [type, debouncedSearchTerm]);

  const deleteBlacklist = (id) => {
    axios.delete(`${process.env.REACT_APP_API_V2_URL}/blacklist/${id}`)
      .then((res) => {
        toast.success(`${type === '1' ? 'Email' : 'Phone'} have been removed from the blacklist`);
        fetchBlacklist();
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  return (
    <KTCard>
      <BlacklistHeader
        setOpenModal={setOpenModal}
        type={type}
        setType={setType}
        setSearch={setSearch}
        currentUser={currentUser}
        haveAccess={haveAccess}
      />
      <BlacklistSearchComponent
      type={type}
      search={search}
      setSearch={setSearch}
      />
    {(haveAccess(currentUser, 'blacklist_list')) ?
     <KTCardBody>
        <div className="row g-4">
          {(list && !!list.length) ? (
            <div className="mb-10 mt-5 rest-stop-container">
              {list.map((item, index) => (
                <div
                  className="form-check form-check-custom form-check-solid"
                  key={index}
                  style={{
                    background: "#f9f9f9",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "5px"
                  }}
                >
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                    style={{ fontWeight: "700", color: "#3f4254", fontSize: "16px" }}
                  >
                    {type === '1' ? item.email : item.phone}
                  </label>
                {(haveAccess(currentUser, 'blacklist_remove')) &&  <button
                    className="listing-remove"
                    onClick={() => deleteBlacklist(item.id)}
                  >
                    <KTIcon iconName="trash" />
                  </button>
                  }
                </div>
              ))}
            </div>
          ) : (
            <div className="d-flex text-center w-100 align-content-center justify-content-center">
              No {type === '1' ? 'Email' : 'Phone'} Have been Blacklisted
            </div>
          )}
        </div>
      </KTCardBody>
      :
      <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{height:'70vh', alignItems:'center', fontSize:'42px', color:'#ffa049', background:'rgba(0,0,0,0.1)'}}>
      You do not have permission to view
     </div>
        }
      {openModal && (
        <AddToBlacklistModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          fetchBlacklist={fetchBlacklist}
          type={type}
        />
      )}
    </KTCard>
  );
};

export default Index;
