// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import "./style.css"
// import moment from 'moment'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name'  />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index] } />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Email'  />,
    accessor: 'email',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Phone Number'   />,
    accessor: 'phone',
    id:'phone'
  },
  
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Member Number' />
    ),
    id: 'status',
    accessor: 'public_id',
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Joined date'/>
    ),
    accessor: 'createdAt',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="" className='text-end' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].uid} isDeleted={props.data[props.row.index]?.is_deleted} />,
  },
]

export {usersColumns}
