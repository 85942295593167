import "react-multi-date-picker/styles/layouts/mobile.css";
import { convertUTCTimeToListingTime } from "../../../../../_metronic/helpers/convertTime";

const UsagesModalForm = ({
  usages,
  onClose
}) => {
  return (
    <>
      <form className="form" id="kt_modal_add_user_form" autoComplete="off">
        <div
          className="d-flex flex-column scroll-y me-n7"
        >
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', fontSize: '14px', fontWeight: 500, marginBottom: '12px' }}>
            <div>Reservation Number</div>
            <div>Listing Name</div>
            <div>Customer Name</div>
            <div>Used Date/Time</div>
          </div>
          {usages.map(el => (
            <div key={el?.booking_id} style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)'}}>
              <div>{el?.reservation_code}</div>
              <div>{el?.listing_name}</div>
              <div>{el?.customer_name}</div>
              <div>{convertUTCTimeToListingTime(el.created_at, el.timezone_id,0,"YYYY-MM-DD hh:mm A")}</div>
            </div>
          ))}
        </div>
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={onClose}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Close
          </button>
        </div>
      </form>
    </>
  );
};

export { UsagesModalForm };
