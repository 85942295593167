import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'
import {toast} from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}`
// const GET_USERS_URL = `${API_URL}/users/query`
// const GET_USERS_URL= 'https://apiv1.truckparkingclub.com/admin/v1/user'
const getUsers = (query: string, page: number): Promise<UsersQueryResponse> => {
  return axios
    .get(`${USER_URL}/review?page=${page}&${query}`)
    .then(d => d)
}

const getUserById = (id: ID) => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response) => response.data)
    .then((response) => response)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user:{id:string}) => {
  return axios
    .put(`${USER_URL}/${user.id}`, user)
    .then((response) => response.data)
    .then((response) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {
    toast("User have been deleted", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
        });
  }).catch((err) => {
    if(err && err.response && err.response?.data && err.response?.data?.message){
      toast(err.response.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }else{
    toast("Technical Error, Please Try Again", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
        });
      }
  })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser}
