import {useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import axios from 'axios'


const AppRoutes = () => {
  const {currentUser, setCurrentUser, saveAuth, auth} = useAuth()


  const getUserData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth?get_permissions=true`)
      .then((res) => {
        setCurrentUser(res.data);
        saveAuth({
          ...auth,
          permissions: res.data.permissions,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if(auth && auth?.token)
    getUserData();
  }, []);


  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/listings'/>} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
