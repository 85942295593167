import { useEffect } from "react";
import { AdminEditModalHeader } from "./AdminEditModalHeader";
import { AdminEditModalFormWrapper } from "./AdminEditModalFormWrapper";
import { useListView } from "../core/ListViewProvider";

const AdminEditModal = () => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const { itemIdForUpdate, modalType } = useListView();

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className={`modal-dialog modal-dialog-centered ${modalType === "EDIT" ? "mw-650px" : ""}`}>
          <div className="modal-content">
            <AdminEditModalHeader user={itemIdForUpdate} />
            <div className={`modal-body scroll-y mx-5 ${modalType === "EDIT" ? " mx-xl-15 my-7" : "mx-xl-1 my-1"} `}>
              <AdminEditModalFormWrapper user={itemIdForUpdate} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

export { AdminEditModal };
