import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNotEmpty, toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { User } from "../core/_models";
import clsx from "clsx";
import { useListView } from "../core/ListViewProvider";
import { UsersListLoading } from "../components/loading/UsersListLoading";
import { createUser, updateUser } from "../core/_requests";
import { useQueryResponse } from "../core/QueryResponseProvider";
import axios from "axios";
import { toast } from "react-toastify";
import {
  useDebounce,
  KTIcon
} from "../../../../../../_metronic/helpers";
import "../user-new-modal/style.css"
import { useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

const UserEditModalForm = ({ user, isUserLoading }) => {
  const navigate = useNavigate()
  const { setItemIdForUpdate, modalType, setIsModalType, itemIdForUpdate } =
    useListView();
  const { refetch } = useQueryResponse();
  const [rating, setRating] = useState(0)
  const [review, setReview] = useState("")

  useEffect(() => {
    if (user) {
      setRating(user?.ratings)
      setReview(user?.comment)
    }
  }, [user])



  const cancel = (withRefresh) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
    setIsModalType("");
    setRating(0)
    setReview("")
  };


  const onEdit = () => {
      axios.put(`${process.env.REACT_APP_API_URL}/review/${user.id}`, {
        ratings: rating,
        comment: review
      }).then((res) => {
        toast("Review have been updated", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        cancel(true)
      }).catch((err) => {
        if(err && err.response && err.response?.data && err.response?.data?.message){
          toast(err.response.data.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          })
        }else{
        toast("Technical Error, Please Try Again", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
      })
    }
      })
  }

  const Delete = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/review/${user.id}`).then((res) => {
      toast("Review have been deleted", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      cancel(true)
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      } else {
        toast("Technical Error, Please Try Again", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
        })
      }
    })
  }

  return (
    <>
      {modalType === "EDIT" ? (
        <>
          <div
            className="d-flex flex-column scroll-y me-n7 pe-7"
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Rating</label>
             {rating && <ReactStars
                size={25}
                value={rating}
                onChange={(e) => {
                  setRating(e)
                }}
                activeColor="#ffa459"
              />
              }
            </div>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Review</label>
              <textarea
                  className="form-control form-control-solid w-100 mt-4"
                  rows="3"
                  placeholder="Enter review..."
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
            </div>
            {/* end::Input group */}
            {/* end::Input group */}
          </div>
          {/* end::Scroll */}
          {/* begin::Actions */}
          <div className="text-center pt-15">
            <button
              type="reset"
              onClick={() => cancel()}
              className="btn btn-light mx-3"
              data-kt-users-modal-action="cancel"
            >
              Discard
            </button>

            <button
              type="submit"
              className="btn"
              data-kt-users-modal-action="submit"
              style={{ background: "#ffa459", color: "#fff" }}
              disabled={!rating || !review}
              onClick={onEdit}
            >
              <span className="indicator-label">Edit Changes</span>
            </button>
          </div>
        </>
      )
        : modalType === "DELETE" ? (
          <>
            <div>
              <h4>
                Are you sure you want to{" "}
                <span style={{ color: "#ed2626", fontWeight: 700 }}>
                  Delete
                </span>{" "}
                this Review for Booking ID #{user?.booking_id}?
              </h4>
              <div className="text-center pt-5">
                <button
                  type="reset"
                  onClick={() => cancel()}
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn"
                  style={{ backgroundColor: "#ed2626", color: "#fff" }}
                  data-kt-users-modal-action="submit"
                  onClick={() => {
                    Delete()
                  }}
                >
                  <span className="indicator-label">Delete Review</span>
                </button>
              </div>
            </div>
          </>
        )
          : null
      }
    </>
  );
};

export { UserEditModalForm };
