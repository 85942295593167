import { KTIcon } from "../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";
import { PatternFormat } from "react-number-format";

const UserBanHeader = ({ currentUser, haveAccess, searchType, setSearchType, searchValue, setSearchValue }) => {
  const navigate = useNavigate();

  return (
    <div className="card-header border-0 pt-6 justify-content-between align-items-center" style={{ flexWrap: "nowrap" }}>
      <div className='d-flex column-gap-3'>
        {/* <select
          className="form-select form-select-solid fw-bolder w-200px"
          value={sort}
          onChange={(e) => setSort(e.target.value)}
        >
          <option value="1">
            Latest First
          </option>
          <option value="2" >
            Oldest First
          </option>
        </select> */}
        <select
          className="form-select form-select-solid fw-bolder w-200px"
          value={searchType}
          onChange={(e) => {
            setSearchType(e.target.value)
            setSearchValue('')
          }}
        >
          <option value="">
            Select Search For
          </option>
          <option value="email" >
            Email
          </option>
          <option value="phone_number">
            Phone Number
          </option>
        </select>
        {searchType && (
          <div className="d-flex align-items-center position-relative">
            <KTIcon
              iconName="magnifier"
              className="fs-1 position-absolute ms-6"
            />
            {searchType === 'email' ?
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-220px ps-14"
                placeholder={`Search By ${searchType.split('_').join(' ')}`}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              :
              <PatternFormat
                format={"+1 (###) ###-####"}
                className={"form-control form-control-solid w-250px ps-14 user-search-input"}
                value={searchValue}
                onValueChange={(values) => {
                  setSearchValue(values.value)
                }}
                placeholder={"Search by Phone Number"}

              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export { UserBanHeader };
