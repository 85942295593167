import { useEffect, useState } from "react";
import axios from "axios";
import { KTIcon } from "../../../../../../../_metronic/helpers";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const UsersListFilterSearch = ({ setFilter }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const resetData = () => {
    setEmail("");
  };

const findByEmail = () => {
  axios.get(`${process.env.REACT_APP_API_URL}/user/email?email=${encodeURIComponent(email?.trim())}`).then((res) => {
 if(res.status === 200){
  navigate(`/users/${res.data?.uid}`);
  setEmail("")
 }else{
  toast("Matching Not Found", {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
  setEmail('')
 }

  }).catch((err) => {
    if(err && err.response && err.response?.data && err.response?.data?.message){
      toast(err.response.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }else{
    toast("Technical Error, Please try again", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  })
}


  return (
    <>
      {/* begin::Filter Button */}
      <button
        type="button"
        className="btn"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        style={{ backgroundColor: "#ffa459", color: "white" }}
      >
        <KTIcon iconName="magnifier" className="fs-2 text-white" />
        Find By Email
      </button>
      <div
        className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
        data-kt-menu="true"
      >
        <div className="separator border-gray-200"></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className="px-7 py-5" data-kt-user-table-filter="form">
          <div className="mb-5">
            <label className="form-label fs-6 fw-bold">Enter Email</label>
            <input
              placeholder="Search by email"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={resetData}
              className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
            >
              Reset
            </button>
            <button
              type="button"
              disabled={!email}
              onClick={findByEmail}
              className="btn fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="filter"
              style={{ backgroundColor: "#ffa459", color: "#fff" }}
            >
              Apply
            </button>
          </div>
        </div>
        {/* end::Input group */}

        {/* begin::Actions */}

        {/* end::Actions */}
      </div>
      {/* end::Content */}

      {/* end::SubMenu */}
    </>
  );
};

export { UsersListFilterSearch };
