import {useEffect, useState } from "react";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import { UserBanTable } from "./table/UserBanTable";
import { UserBanHeader } from "./header/UserBanHeader";
import { useAuth } from "../auth";


const Index = () => {
    const {currentUser, haveAccess} = useAuth();
  const [bannedUser, setBannedUser] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState('email');
  const [searchValue, setSearchValue] = useState('');



  const fetchBannedUsers = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_V2_URL}/user/ban?page_size=50&${searchType === 'email' ? 'email' : 'phone'}=${searchValue}`);
      setBannedUser(data);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Could not fetch users');
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    fetchBannedUsers()
  }, [searchValue])


  return (
    <KTCard>
      <UserBanHeader currentUser={currentUser} haveAccess={haveAccess} searchType={searchType} setSearchType={setSearchType} searchValue={searchValue} setSearchValue={setSearchValue} />
      {(haveAccess(currentUser,  'user_ban')) ? <UserBanTable
        bannedUser={bannedUser}
        isLoading={isLoading}
      />
      :
      <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{height:'70vh', alignItems:'center', fontSize:'42px', color:'#ffa049', background:'rgba(0,0,0,0.1)'}}>
      You do not have permission to view
     </div>
     }
    </KTCard>
  );
};

export default Index;
