import {useState} from 'react'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

export function PasswordReset() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [password, setPassword] = useState('')
  const token = searchParams.get('token');

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_API_URL}/auth/password-reset`, { password, token })
      .then(res => {
        setPassword('')
        toast.success('New password was set')
        setTimeout(() => {
          navigate('/auth')
        }, 5000)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || 'Could not set the new password')
      })
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Password Reset</h1>

        <div className='text-gray-500 fw-semibold fs-6'>
          Enter your new password.
        </div>
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
        <input
          value={password}
          onChange={e => setPassword(e.target.value)}
          type='password'
          placeholder='Enter password'
          autoComplete='off'
          className='form-control bg-transparent'
        />
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={!password}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
    </form>
  )
}
