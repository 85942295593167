import { useEffect, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";

const MessageModalForm = ({
  setOpenMessageModal,
  type,
  setTrigger
}) => {
  const [message, setMessage] = useState("");
  const [sendTo, setSendTo] = useState(0);

  const cancel = () => {
    setMessage("");
    setOpenMessageModal(false);
  };

  useEffect(() => {
    setMessage('');
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    if (type === 'email') {
      axios.post(`${process.env.REACT_APP_API_URL}/bulk-email`, {
        email_content: message,
        send_to: +sendTo
      })
        .then(res => {
          toast.success(res.data.text);
          setMessage('');
          setOpenMessageModal(false);
          setTrigger(prev => !prev)
        })
        .catch(() => {
          toast.error('Could not send email');
        })
    }

    if (type === 'SMS') {
      axios.post(`${process.env.REACT_APP_API_URL}/bulk-sms`, {
        sms_content: message,
        send_to: +sendTo
      })
        .then(res => {
          toast.success(res.data.text);
          setMessage('');
          setOpenMessageModal(false);
          setTrigger(prev => !prev)
        })
        .catch(() => {
          toast.error('Could not send SMS');
        })
    }
  }

  return (
    <>
      <form className="form" id="kt_modal_add_user_form" autoComplete="off" onSubmit={sendMessage} action="">
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="fv-row mb-6">
            <label className="required fw-bold fs-6 mb-2">Send to</label>
            <select
              className='form-select form-select-sm form-select-solid'
              data-control='select2'
              data-placeholder='Latest'
              data-hide-search='true'
              value={sendTo}
              onChange={(e) => setSendTo(e.target.value)}
              style={{ fontSize: '14.3px' }}
            >
              <option value='0'>All</option>
              <option value='1'>Trucker Members</option>
              <option value='2'>Property Members</option>
            </select>
          </div>
          <div className="fv-row">
            <label className="required fw-bold fs-6 mb-2">Message</label>
            <textarea
              placeholder="Message"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn"
            data-kt-users-modal-action="submit"
            style={{ background: "#ffa459", color: "#fff" }}
            disabled={!message}
          >
            <span className="indicator-label">Submit</span>
          </button>
        </div>
      </form>
    </>
  );
};

export { MessageModalForm };
