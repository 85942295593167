import { useEffect, useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";

const UsersListFilter = ({ setFilter, filter }) => {
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");

  const resetData = () => {
    setStatus("");
    setType("");
    setFilter({
      status: "",
      type: ""
    });
  };

  const filterData = () => {
    setFilter({
      status: status,
      type: type
    });
  };


  useEffect(() => {
    setStatus(filter.status);
    setType(filter.type);
  }, [filter]);

  return (
    <>
      <button
        type="button"
        className="btn me-3"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        style={{ backgroundColor: "#ffa459", color: "white" }}
      >
        <KTIcon iconName="filter" className="fs-2 text-white" iconType={(filter.status || filter.type) ? 'solid' : 'duotone'} />
        Filter
      </button>

      <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Filter</div>
        </div>

        <div className="separator border-gray-200" />

        <div className="px-7 py-5" data-kt-user-table-filter="form">
          <div className="mb-5">
            <label className="form-label fs-6 fw-bold">Booking Status:</label>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-kt-user-table-filter="role"
              data-hide-search="true"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value="">Select Status</option>
              <option value="pending">Pending</option>
              <option value="ongoing">Active</option>
              <option value="active_not_renewed">Active & Not Renewed</option>
              <option value="refunded">Refunded</option>
              <option value="upcoming">Upcoming</option>
              <option value="completed">Completed</option>
              <option value="canceled">Canceled</option>
              <option value="due">Past Due</option>
              <option value="failed">Payment Failed</option>
              <option value="suspended">Suspended</option>
            </select>
          </div>

          <div className="mb-5">
            <label className="form-label fs-6 fw-bold">Billing Type:</label>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-kt-user-table-filter="two-step"
              data-hide-search="true"
              onChange={(e) => setType(e.target.value)}
              value={type}
            >
              <option value="">Select Type</option>
              <option value="1">Per Hour</option>
              <option value="2">Per Day</option>
              <option value="3">Per Week</option>
              <option value="4">Per Month</option>
            </select>
          </div>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={resetData}
              className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
            >
              Reset
            </button>
            <button
              type="button"
              onClick={filterData}
              className="btn fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="filter"
              style={{ backgroundColor: "#ffa459", color: "#fff" }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { UsersListFilter };
