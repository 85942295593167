import { UserEditModalForm } from "./UserEditModalForm"

const UserEditModalFormWrapper = ({
  setOpenPromoCycleModal,
  setPromoCycleId,
  promoCycleId,
  getBookings
}) => {
  const itemIdForUpdate = null;
  const isLoading = false;

  if (promoCycleId) {
    return (
      <UserEditModalForm
        setOpenPromoCycleModal={setOpenPromoCycleModal}
        setPromoCycleId={setPromoCycleId}
        promoCycleId={promoCycleId}
        itemIdForUpdate={itemIdForUpdate}
        isLoading={isLoading}
        getBookings={getBookings}
      />
    );
  }
  return null;
};

export { UserEditModalFormWrapper };
