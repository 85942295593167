import { useEffect, useState } from "react";
import axios from "axios";
import { pointsStatus } from "../../../../_metronic/helpers/utils";
import moment from "moment";
import { PointsTable } from "./table/PointsTable";

const PointsModalForm = ({
user,
setOpenPointsModal
}) => {
  
const [pointsHistory, setPointsHistory] = useState([]);

const pointsHistoryList = () => {
  axios.get(`${process.env.REACT_APP_API_URL}/user/${user.uid}/wallet/history`).then((res) => {
    let data = res.data.sort((a, b) => moment(b.created_at).utc() - moment(a.created_at).utc())
    .filter(el => el.type === 'DR' ? el.balance_consumed > 0 : el)
  setPointsHistory(data);
  });
}


useEffect(() => {
  pointsHistoryList()
},[])


  return (
    <>
        <div className="kt_body">
          <div style={{maxHeight:'300px', overflowY:'auto', minHeight:'auto'}}>
          <PointsTable points={pointsHistory} />
          </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                type="button"
                className="btn mt-4 w-100 btn-primary"
                onClick={() => setOpenPointsModal(false)}
                style={{
                  color: "#fff",
                  height: "46px",
                  fontWeight: "700",
                }}
              >
               Go Back
              </button>
            </div>
        </div>
    </>
  );
};

export { PointsModalForm };
