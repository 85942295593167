import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const ApiModalForm = ({
  setOpenAPIModal,
  getUser,
  user
}) => {
  const [limit, setLimit] = useState();

  const cancel = () => {
    setLimit();
    setOpenAPIModal(false);
  };

  useEffect(() => {
    setLimit();
  }, []);

  const enableAPI = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}/user/${user.uid}/enable_apis?usage_limit=${limit}`)
      .then(() => {
        toast.success("API access have been enabled for this user successfully");
        getUser();
        cancel();
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      }); 
  }

  return (
    <>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="fv-row">
          <label className="form-label fs-6 fw-bold">Usage Limit</label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder="Enter Usage Limit"
                value={limit}
                min={1}
                onChange={(e) => setLimit(e.target.value.replace(/[^0-9]/g, ""))}
              />
          </div>
        </div>
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn"
            data-kt-users-modal-action="submit"
            style={{ background: "#ffa459", color: "#fff" }}
            disabled={!limit}
            onClick={enableAPI}
          >
            <span className="indicator-label">Enable API Access</span>
          </button>
        </div>
    </>
  );
};

export { ApiModalForm };
