import { useEffect, useState } from 'react'
import css from './clock.module.scss'
import { getLocalListingTime, MIN_MILLISECONDS } from '../../../../../_metronic/helpers/convertTime'
import moment from 'moment-timezone'

export const Clock = ({ timezone }) => {
    const [dateState, setDateState] = useState(moment().tz(timezone).format('DD MMM, hh:mm A'))

    useEffect(() => {
        const interval = () => {
            setDateState(moment().tz(timezone).format('DD MMM, hh:mm A'))
        }

        setInterval(interval, MIN_MILLISECONDS)

        return clearInterval(interval)
    }, [])


    return (
        <div className={css.wrapper}>
            <div>Time at the spot right now:</div>
            &nbsp;
            <div className={css.time}>
                {dateState}
                {/* {new Date(dateState).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                })}
                {', '}
                {new Date(dateState).toLocaleString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                })} */}
            </div>
        </div>
    )
}
