import { KTIcon } from "../../../../../_metronic/helpers";
import { useListView } from "../core/ListViewProvider";

const AdminEditModalHeader = ({ user }) => {
  const { setItemIdForUpdate, modalType, setIsModalType } = useListView();

  return (
    <div className="modal-header">
      <h2 className="fw-bolder">
        {modalType === "EDIT"
          ? `Edit Profile of ${user?.displayName || user?.first_name + " " + user?.last_name}`
          : modalType === "CHANGE_ROLE"
            ? `Change Role of ${user?.displayName || user?.first_name + " " + user?.last_name}`
            : modalType === "CHANGE_STATUS"
              ? `Change Status of ${user?.displayName || user?.first_name + " " + user?.last_name}`
              : modalType === "ADD_ACCESS"
                ? `Add Access to ${user?.displayName || user?.first_name + " " + user?.last_name}`
                : modalType === "DELETE"
                  ? `Delete ${user?.displayName || user?.first_name + " " + user?.last_name} User`
                  : modalType === "VIEW_LISTINGS"
                    ? `View Listings for ${user?.displayName || user?.first_name + " " + user?.last_name}`
                    : ""
        }
      </h2>
      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => {
          setItemIdForUpdate(undefined);
          setIsModalType("");
        }}
        style={{ cursor: "pointer" }}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </div>
    </div>
  );
};

export { AdminEditModalHeader };
