import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void,
  bookingPage: number,
  setBookingPage: Dispatch<SetStateAction<number>>,
  listingPage: number,
  setListingPage: Dispatch<SetStateAction<number>>
  userPage: number,
  setUserPage: Dispatch<SetStateAction<number>>,
  adminPage: number,
  setAdminPage: Dispatch<SetStateAction<number>>
  reviewPage: number,
  setReviewPage: Dispatch<SetStateAction<number>>
  haveAccess: (user:any, permission_tag:string) => void,
  pointsEnabled: boolean,
  pendingApprovalsCount: number,
  setPendingApprovalsCount: Dispatch<SetStateAction<number>>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  setBookingPage: () => {},
  bookingPage: 1,
  listingPage: 1,
  setListingPage: () => {},
  userPage: 1,
  setUserPage: () => {},
  adminPage: 1,
  setAdminPage: () => {},
  reviewPage: 1,
  setReviewPage: () => {},
  haveAccess: () => {},
  pointsEnabled: false,
  pendingApprovalsCount: 0,
  setPendingApprovalsCount: () => {}
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [bookingPage, setBookingPage] = useState<number>(1)
  const [listingPage, setListingPage] = useState<number>(1)
  const [userPage, setUserPage] = useState<number>(1)
  const [adminPage, setAdminPage] = useState<number>(1)
  const [reviewPage, setReviewPage] = useState<number>(1)
  const [pointsEnabled, setPointsEnabled] = useState<boolean>(false)
  const [pendingApprovalsCount, setPendingApprovalsCount] = useState<number>(0)
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth?.token) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  const haveAccess = (user: any, permission_tag:string) => {
    return (user?.access_level == 1 ||  user?.permissions?.some((permission: any) => permission.permission_tag === permission_tag));
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout, bookingPage, setBookingPage, listingPage, setListingPage, userPage, setUserPage, adminPage, setAdminPage, reviewPage, setReviewPage, haveAccess, pointsEnabled, pendingApprovalsCount, setPendingApprovalsCount}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken()
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.token) {
      setCurrentUser(auth)
      setShowSplashScreen(false)
      // requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
