import { KTIcon } from "../../../../_metronic/helpers";

const RefundsHeader = ({ searchType, setSearchType, searchValue, setSearchValue }) => {
  return (
    <div className="card-header border-0 pt-6 justify-content-between align-items-center" style={{ flexWrap: "nowrap" }}>
      <div className='d-flex column-gap-3'>
        <select
          className="form-select form-select-solid fw-bolder w-200px"
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
        >
          <option value="">
            Select Search For
          </option>
          <option value="customer_name" >
            Customer Name
          </option>
          <option value="owner_name">
            Owner Name
          </option>
          <option value="listing_name">
            Listing Name
          </option>
          <option value="created_by">
            Created By
          </option>
          <option value="reservation_code">
            Reservation Number
          </option>
          <option value="approved_by">
            Approved By
          </option>
          <option value="status">
            Status
          </option>
          <option value="is_refund">
            Is Refund
          </option>
          <option value="reversal_from_parking">
            Is Reversal
          </option>
        </select>
        {(searchType && searchType !== "is_refund" && searchType !== "reversal_from_parking" && searchType !== "status") && (
          <div className="d-flex align-items-center position-relative">
            <KTIcon
              iconName="magnifier"
              className="fs-1 position-absolute ms-6"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-220px ps-14"
              placeholder={`Search By ${searchType.split('_').join(' ')}`}
              style={{ textTransform: 'capitalize' }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        )}
        {(searchType && (searchType === "status" || searchType === "is_refund" || searchType === "reversal_from_parking")) && (
          <>
            {searchType !== "status" && (
              <select
                className="form-select form-select-solid fw-bolder w-200px"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              >
                <option value="">
                  Select Value
                </option>
                <option value="true">
                  Yes
                </option>
                <option value="false">
                  No
                </option>
              </select>
            )}
            {searchType === "status" && (
              <select
                className="form-select form-select-solid fw-bolder w-200px"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              >
                <option value="">
                  Select Status
                </option>
                <option value="1">
                  Pending
                </option>
                <option value="2">
                  Approved
                </option>
                <option value="3">
                  Declined
                </option>
              </select>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { RefundsHeader };
