import { CommissionModalForm } from "./CommissionModalForm";

const CommissionModalFormWrapper = ({
  selectedListingId,
  setSelectedListingId,
  openCommissionModal,
  setOpenCommissionModal,
  listing,
  getListing,
}) => {
  const itemIdForUpdate = null;
  const isLoading = false;

  if (selectedListingId) {
    return (
      <CommissionModalForm
        isUserLoading={isLoading}
        user={{ id: undefined }}
        setSelectedListingId={setSelectedListingId}
        selectedListingId={selectedListingId}
        openCommissionModal={openCommissionModal}
        setOpenCommissionModal={setOpenCommissionModal}
        listing={listing}
        getListing={getListing}
      />
    );
  }
  return null;
};

export { CommissionModalFormWrapper };
