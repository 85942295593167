import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { KTIcon } from '../../../../_metronic/helpers'
import { listingFieldStatus } from '../../../../_metronic/helpers/utils'


const ListingChangesFilter = ({filter, setFilter}) => {
  const [change, setChange] = useState('')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    if(filter) {
      setChange(filter)
    }
  }, [filter])

  const resetData = () => {
    setChange('')
    setFilter('')
  }

  const filterData = () => {
    setFilter(change)
  }

 
  return (
    <>
      <button
        type='button'
        className='btn me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{ backgroundColor: '#ffa459', color: 'white' }}
      >
        <KTIcon iconName='filter' className='fs-2 text-white' iconType={filter ? 'solid' : 'duotone'}/>
        Filter
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter</div>
        </div>
        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5' data-kt-user-table-filter='form'>

          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Filter by Change:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select Status'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setChange(e.target.value)}
              value={change}
            >
              <option value="" selected >Select Change Status</option>
              {
                listingFieldStatus?.map((item, index) => (
                  <option key={index} value={item.value}>{item.label}</option>
                ))
              }
            </select>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              onClick={() => resetData()}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
            >
              Reset
            </button>
            <button
              disabled={!change}
              type='button'
              onClick={filterData}
              className='btn fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
              style={{ backgroundColor: '#ffa459', color: '#fff' }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export { ListingChangesFilter }
