import { PromoModalForm } from "./PromoModalForm"

const PromoModalFormWrapper = ({
  selectedListingId,
  setSelectedListingId,
  openPromoModal,
  setOpenPromoModal,
  listing,
  getListing,
}) => {
  const itemIdForUpdate = null;
  const isLoading = false;

  if (selectedListingId) {
    return (
      <PromoModalForm
        isUserLoading={isLoading}
        user={{ id: undefined }}
        setSelectedListingId={setSelectedListingId}
        selectedListingId={selectedListingId}
        listing={listing}
        getListing={getListing}
        openPromoModal={openPromoModal}
        setOpenPromoModal={setOpenPromoModal}
      />
    );
  }
  return null;
};

export { PromoModalFormWrapper };
