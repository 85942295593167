import { KTIcon } from "../../../../_metronic/helpers";
import { useState } from "react";
import { toast } from "react-toastify";

const MessagesHeader = ({
  setOpenMessageModal,
  setType,
  tab,
  setTab,
  isPasswordValid,
  setPasswordValid,
  currentUser,
  haveAccess
}) => {

  const [password, setPassword] = useState('')

  return (
    <div className="card-header border-0 pt-6 justify-space-between align-items-center">
      {!isPasswordValid && (
        <>
          <input
            type='password'
            className='form-control form-control-sm form-control-solid w-250px ps-6'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Enter Password'
            style={{ marginRight: '12px', height: '44px' }}
          />
          <button
            type='button'
            className='btn'
            style={{ backgroundColor: "#2ecc71", color: "white", marginRight: 'auto' }}
            onClick={() => {
              if (password === '@Tpc#2024') {
                setPasswordValid(true)
              } else {
                setPassword('')
                toast.error('Incorrect password')
              }
            }}
          >
            Submit
          </button>
        </>
      )}
      {isPasswordValid && (
        <>
          <div style={{ display: 'flex', background: '#f9f9f9', borderRadius: '10px' }}>
            <span style={{ padding: '15px', fontWeight: '600', background: tab === 'email' ? '#a5f0c2' : '', borderRadius: '10px', cursor: 'pointer' }}
              onClick={() => setTab('email')}>EMAIL</span>
            <span style={{ padding: '15px', fontWeight: '600', background: tab === 'sms' ? '#a5f0c2' : '', borderRadius: '10px', cursor: 'pointer' }}
              onClick={() => setTab('sms')}>SMS</span>
          </div>
          <div className="card-toolbar" style={{ gap: "5px", marginRight: "12px" }}>
           {(haveAccess(currentUser,  'bulk_email_create')) && <button
              type="button" className="btn" style={{ backgroundColor: "#2ecc71", color: "white" }}
              onClick={() => {
                setOpenMessageModal(true);
                setType("email");
              }}
            >
              <KTIcon iconName="plus" className="fs-2 text-white" />
              Create Email
            </button>
            }
        {(haveAccess(currentUser,  'bulk_sms_create')) && <button
              type="button" className="btn" style={{ backgroundColor: "#2ecc71", color: "white" }}
              onClick={() => {
                setOpenMessageModal(true);
                setType("SMS");
              }}
            >
              <KTIcon iconName="plus" className="fs-2 text-white" />
              Create SMS
            </button>
        }
          </div>
        </>
      )}
    </div>
  );
};

export { MessagesHeader };
