import { KTIcon } from "../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";

const RolesHeader = ({currentUser, haveAccess}) => {
  const navigate = useNavigate();

  return (
    <div className="card-header border-0 pt-6 justify-content-end align-items-center" style={{ flexWrap: "nowrap" }}>
      {/* <div className='d-flex column-gap-3'>
        <select
          className="form-select form-select-solid fw-bolder w-200px"
          value={sort}
          onChange={(e) => setSort(e.target.value)}
        >
          <option value="1">
            Latest First
          </option>
          <option value="2" >
            Oldest First
          </option>
        </select>
        <select
          className="form-select form-select-solid fw-bolder w-200px"
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
        >
          <option value="">
            Select Search For
          </option>
          <option value="customer_name" >
            Customer Name
          </option>
          <option value="owner_name">
            Owner Name
          </option>
          <option value="listing_name">
            Listing Name
          </option>
          <option value="created_by">
            Created By
          </option>
          <option value="reservation_code">
            Reservation Number
          </option>
        </select>
        {searchType && (
          <div className="d-flex align-items-center position-relative">
            <KTIcon
              iconName="magnifier"
              className="fs-1 position-absolute ms-6"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-220px ps-14"
              placeholder={`Search By ${searchType.split('_').join(' ')}`}
              style={{ textTransform: 'capitalize' }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        )}
      </div> */}
      <div className="card-toolbar" style={{ gap: "5px" }}>
      {(haveAccess(currentUser,  'admin_roles_create')) &&  <button
          type="button" className="btn" style={{ backgroundColor: "#2ecc71", color: "white" }}
          onClick={() => {
            navigate("/create-role")
          }}
        >
          <KTIcon iconName="plus" className="fs-2 text-white" />
          Create New Role
        </button>
        }
      </div>
    </div>
  );
};

export { RolesHeader };
