import {DiscountModalForm} from './DiscountModalForm'

const DiscountModalFormWrapper = ({ bookingId, setBookingToDiscount, setOpenDiscountModal, fetchBooking }) => {
    return (
        <DiscountModalForm
            bookingId={bookingId}
            setBookingToDiscount={setBookingToDiscount}
            setOpenDiscountModal={setOpenDiscountModal}
            fetchBooking={fetchBooking}
        />
    )
}

export {DiscountModalFormWrapper}
