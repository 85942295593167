import { UsagesModalForm } from "./UsagesModalForm";

const UsagesModalFormWrapper = ({
  usages,
  onClose
}) => {
  return (
    <UsagesModalForm
      usages={usages}
      onClose={onClose}
    />
  );
};

export { UsagesModalFormWrapper };
