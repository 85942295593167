import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { listingLogs } from "../../../../../_metronic/helpers/utils";
import moment from "moment";

const UserEditModalForm = ({
  selectedListingId,
  setSelectedListingId,
  listing,
  getListing,
  openTimelineModal,
  setOpenTimelineModal,
}) => {
  const navigate = useNavigate();
  const [admins, setAdmins] = useState([]);
  const [timeline, setTimeline] = useState([...listing.timeline.sort((a, b) => moment(b.createdAt).utc() - moment(a.createdAt).utc())]);

 
  const cancel = () => {
    setSelectedListingId();
    setOpenTimelineModal(false);
  };


const adminList = () => {
  axios.get(`${process.env.REACT_APP_API_URL}/admin/public`).then((res) => {
  setAdmins(res.data);
  });
}


useEffect(() => {
  adminList()
},[])


  return (
    <>
        <div className="kt_body">
          <div style={{maxHeight:'300px', overflowY:'auto', minHeight:'auto'}}>
          {
            listing.timeline && listing.timeline.length > 0 ? 
            <>
            {timeline.map((item, index) => {
            return <div
            key={index}
            style={{display:'flex', gap:'5px', background:'#f9f9f9', marginBottom:'10px', padding:'10px', borderRadius:'10px', flexDirection:'column'}}>
             <div style={{display:'flex', justifyContent:'space-between'}}>
               <h4 style={{marginBottom:'2px'}}> {listingLogs(item.action_id)}</h4>
               <h6>{moment(item.createdAt).utc().format('YYYY-MM-DD hh:mm A')}</h6>
              </div>
              <h6 style={{marginBottom:'2px'}}>Action Done By: {item.action_by_role == 2 ? `${listing.owner_first_name} ${listing.owner_last_name} (Owner)` : item.action_by_role == 3 ? `${admins?.find((_i) => _i.id == item.action_by)?.name || ""} (Admin)` : 'Auto'}</h6>
            {(item.action_id !== 10 && item.action_id !== 16 && item.action_id !== 17) && item.notes &&  <h6>{item.notes}</h6>}
              </div>
            })
            }
            </>
            :
            <h4>No Timeline yet for this listing</h4>
          }
          </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                type="button"
                className="btn mt-4 w-100 btn-primary"
                onClick={() => cancel()}
                style={{
                  color: "#fff",
                  height: "46px",
                  fontWeight: "700",
                }}
              >
               Go Back
              </button>
            </div>
        </div>
    </>
  );
};

export { UserEditModalForm };
