import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {UserEditModalHeader} from './UserEditModalHeader'
import {UserEditModalFormWrapper} from './UserEditModalFormWrapper'
import {useListView} from '../core/ListViewProvider';
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {getUserById} from '../core/_requests'

const UserEditModal = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const {itemIdForUpdate, setItemIdForUpdate, modalType} = useListView()


  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className={`modal-dialog modal-dialog-centered ${modalType === 'EDIT' ? 'mw-600px' : 'mw-600px'}`}>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <UserEditModalHeader user={itemIdForUpdate}  />
            {/* begin::Modal body */}
            <div className={`modal-body scroll-y mx-5 ${modalType === 'EDIT' ? ' mx-xl-15 my-7' : 'mx-xl-1 my-1' } `}>
              <UserEditModalFormWrapper  user={itemIdForUpdate} />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {UserEditModal}
