import {KTIcon} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'

const UserEditModalHeader = ({user}) => {
  const {setItemIdForUpdate, modalType, setIsModalType} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{modalType === 'EDIT' ? `Edit Profile of ${user?.displayName || user?.first_name + " " + user?.last_name}` : modalType === 'DELETE' ? `Delete Profile of ${user?.first_name ? user?.first_name : ""} ${user?.last_name ? user?.last_name : ""}` : `Change Password of ${user?.first_name ? user?.first_name : ""} ${user?.last_name ? user?.last_name : ""}`}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() =>  {
          setItemIdForUpdate(undefined)
          setIsModalType("")
        }}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {UserEditModalHeader}
