import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";
import moment from "moment";
import GoogleMapReact from 'google-map-react'
import ReactQuill from 'react-quill';
import { hours } from "../../Bookings/editBookingModal/BookingEditModalForm";
import {parseAddressFromHTMLString} from "../../../../_metronic/helpers/utils";

const AddListingModalForm = ({ user, getUser, setShowListingModal }) => {
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [phone, setPhone] = useState();
  const [billingPhone, setBillingPhone] = useState();
  const [note, setNote] = useState();
  const [signs, setSigns] = useState();
  const [trailersAllowed, setTrailersAllowed] = useState(false);
  const [trucksAllowed, setTrucksAllowed] = useState(false);
  const [optionalTerms, setOptionalTerms] = useState(false);
  const [tnc, setTnc] = useState('');
  const [isQr, setIsQr] = useState(false);
  const [textCode, setTextCode] = useState('');
  const [apt, setApt] = useState();
  const [amenities, setAmenities] = useState([]);
  const [address, setAddress] = useState();
  const [seats, setSeats] = useState();
  const [enabledBookings, setEnabledBookings] = useState([1, 2, 3, 4]);
  const [truckHourly, setTruckHourly] = useState('')
  const [trailerHourly, setTrailerHourly] = useState('')
  const [truckTrailerHourly, setTruckTrailerHourly] = useState('')
  const [truckDaily, setTruckDaily] = useState('');
  const [trailerDaily, setTrailerDaily] = useState('');
  const [truckTrailerDaily, setTruckTrailerDaily] = useState('');
  const [truckWeekly, setTruckWeekly] = useState('');
  const [trailerWeekly, setTrailerWeekly] = useState('');
  const [truckTrailerWeekly, setTruckTrailerWeekly] = useState('');
  const [truckMonthly, setTruckMonthly] = useState('');
  const [trailerMonthly, setTrailerMonthly] = useState('');
  const [truckTrailerMonthly, setTruckTrailerMonthly] = useState('');
  const [separatePrices, setSeparatePrices] = useState(false);
  const [hourly, setHourly] = useState("");
  const [daily, setDaily] = useState("");
  const [weekly, setWeekly] = useState("");
  const [monthly, setMonthly] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [details, setDetails] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [photos, setPhotos] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState();
  const [showModal, setShowModal] = useState({
    status: false,
    date: "",
  });
  const [loading, setLoading] = useState(false);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [video, setVideo] = useState('');
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const [vehicleTypeActive, setVehicleTypeActive] = useState('truck')

  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY


  const EditorModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'link'],
    ],
  };

  const cancel = () => {
    setShowListingModal(false);
  };

  const autoCompleteRef = useRef(null)
  const inputRef = useRef(null)
  const options = {
    componentRestrictions: { country: 'us' },
    requestedLanguage: 'en',
    fields: ['adr_address', 'address_components', 'formatted_address', 'geometry', 'icon', 'name'],
    types: ['address']
  }

  let autocompleteService


  useEffect(() => {
    getAmenities()
    // if (window.google) {
    //   autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    //     inputRef.current,
    //     options
    //   );
    //   autoCompleteRef.current.addListener("place_changed", async function () {
    //     const place = await autoCompleteRef.current.getPlace();
    //     if (!place?.address_components) {
    //       setAddress(place?.name);
    //     } else {
    //       const p_street = place?.address_components?.find(
    //         (el) => el.types.indexOf("route") > -1
    //       );
    //       const p_number = place?.address_components?.find(
    //         (el) => el.types.indexOf("street_number") > -1
    //       );
    //       const p_state = place?.address_components?.find(
    //         (el) => el.types.indexOf("administrative_area_level_1") > -1
    //       );
    //       const p_city = place?.address_components?.find(
    //         (el) => el.types.indexOf("locality") > -1
    //       );
    //       const p_zip = place?.address_components?.find(
    //         (el) => el.types.indexOf("postal_code") > -1
    //       );
    //       const p_address = place?.formatted_address;

    //       setAddress(p_address);
    //       setCity(p_city ? p_city.long_name : "");
    //       setState(p_state ? p_state.long_name : "");
    //       setZip(p_zip ? p_zip.long_name : "");
    //       setDetails(
    //         `${p_street ? p_street.long_name : ""} ${
    //           p_number ? p_number.long_name : ""
    //         }`
    //       );
    //       setLat(place.geometry.location.lat());
    //       setLng(place.geometry.location.lng());
    //     }
    //   });
    // }
  }, []);

  const getAmenities = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/listing/amenities`).then((res) => {
      setAmenitiesList(res.data);
    }).catch((err) => {
      toast("Error Fetching Amenities", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }


  const handleApiLoaded = async (map, maps) => {
    const { Autocomplete, AutocompleteService } = await maps.importLibrary('places')
    autoCompleteRef.current = new Autocomplete(
      inputRef.current,
      options
    )
    autocompleteService = new AutocompleteService()
    // inputRef.current.addEventListener('blur', () => {
    //   autoCompleteRef.current.getPlace()
    //   if (!autoCompleteRef.current.getPlace()?.address_components) {
    //     setAddress('')
    //   }
    // })

    autoCompleteRef.current.addListener('place_changed', async function () {
      const place = await autoCompleteRef.current.getPlace()

      const p_street = parseAddressFromHTMLString(place.adr_address).street
      const p_number = place.address_components.find(el => el.types.indexOf('street_number') > -1)
      const p_state = place.address_components.find(el => el.types.indexOf('administrative_area_level_1') > -1)
      const p_city = parseAddressFromHTMLString(place.adr_address).city
      const p_zip = place.address_components.find(el => el.types.indexOf('postal_code') > -1)
      const p_address = place.formatted_address

      setAddress(p_address)
      setCity(p_city ? p_city : '')
      setState(p_state ? p_state.long_name : '')
      setZip(p_zip ? p_zip?.long_name : '')
      setDetails(p_street ? p_street : '')
      setStreetNumber(p_number ? p_number.long_name : '')
      setLat(place.geometry.location.lat()?.toFixed(5))
      setLng(place.geometry.location.lng()?.toFixed(5))
    })

    setIsMapLoaded(true)
  }

  // const amenitiesList = {
  //   Pavement: [9, 12],
  //   Lights: [2, 13],
  //   "Fenced / Gated": [3, 14],
  //   "Security Guards": [11, 15],
  //   Cameras: [4, 16],
  //   "24/7 access": [5, 17],
  //   "Food / Food Trucks": [10, 18],
  //   "Restrooms / Porta Potty": [6, 19],
  //   "Repair Shop": [7, 20],
  //   "Truck wash": [8, 21],
  //   "Free showers": [0,22],
  //   "Paid showers":[0,23],
  //   "Oversize":[0,24],
  //   "Hazmat":[0,25],
  //   "Personal car allowed":[0,26]
  // };

  const onPriceChange = (e, fn) => {
    const newValue = e.target.value
    const regex = /^[1-9]\d*(\.)?(\d{1,2})?$/
    const isValidPrice = regex.test(newValue)

    if (isValidPrice || e.target.value === '') {
      fn(e.target.value)
    }


  };

  const onBookingsChange = (e) => {
    const checked = e.target.checked;
    const value = +e.target.value;

    if (checked) {
      setEnabledBookings((prev) => [...prev, value]);
    } else {
      setEnabledBookings((prev) => prev.filter((el) => el !== value));
    }
  };

  const isNextDisabled = () => {
    let isEmpty = false;

    if (!separatePrices) {
      if (
        (enabledBookings.indexOf(1) > -1 && !hourly) ||
        (enabledBookings.indexOf(2) > -1 && !daily) ||
        (enabledBookings.indexOf(3) > -1 && !weekly) ||
        (enabledBookings.indexOf(4) > -1 && !monthly)
      ) {
        isEmpty = true;
      }
    } else {
      if (
        (enabledBookings.indexOf(1) > -1 && !truckHourly && trucksAllowed) ||
        (enabledBookings.indexOf(2) > -1 && !truckDaily && trucksAllowed) ||
        (enabledBookings.indexOf(3) > -1 && !truckWeekly && trucksAllowed) ||
        (enabledBookings.indexOf(4) > -1 && !truckMonthly && trucksAllowed)
      ) {
        isEmpty = true;
      } else if (
        (enabledBookings.indexOf(1) > -1 && !trailerHourly && trailersAllowed) ||
        (enabledBookings.indexOf(2) > -1 && !trailerDaily && trailersAllowed) ||
        (enabledBookings.indexOf(3) > -1 && !trailerWeekly && trailersAllowed) ||
        (enabledBookings.indexOf(4) > -1 && !trailerMonthly && trailersAllowed)
      ) {
        isEmpty = true;
      } else if (
        (enabledBookings.indexOf(1) > -1 && (!trailerHourly || !truckHourly || !truckTrailerHourly) && (!trucksAllowed && !trailersAllowed)) ||
        (enabledBookings.indexOf(2) > -1 && (!trailerDaily || !truckDaily || !truckTrailerDaily) && (!trucksAllowed && !trailersAllowed)) ||
        (enabledBookings.indexOf(3) > -1 && (!trailerWeekly || !truckWeekly || !truckTrailerWeekly) && (!trucksAllowed && !trailersAllowed)) ||
        (enabledBookings.indexOf(4) > -1 && (!trailerMonthly || !truckMonthly || !truckTrailerMonthly) && (!trucksAllowed && !trailersAllowed))
      ) {
        isEmpty = true;
      }
    }

    return !enabledBookings.length || isEmpty;
  };

  const Save = () => {
    let prices = [];

    const addPrice = (type, value, vehicleType) => {
      prices.push({ type, price: value, vehicle_type: vehicleType });
    };

    if (enabledBookings.indexOf(1) > -1) {
      if (!separatePrices) {
        addPrice("hour", (+hourly * 100)?.toFixed(0), 0);
        addPrice("hour", 0, 1);
        addPrice("hour", 0, 2);
        addPrice("hour", 0, 3);
      } else {
        if (trucksAllowed && !trailersAllowed) {
          addPrice("hour", 0, 0);
          addPrice("hour", (+truckHourly * 100)?.toFixed(0), 1);
          addPrice("hour", 0, 2);
          addPrice("hour", 0, 3);
        } else if (trailersAllowed && !trucksAllowed) {
          addPrice("hour", 0, 0);
          addPrice("hour", 0, 1);
          addPrice("hour", (+trailerHourly * 100)?.toFixed(0), 2);
          addPrice("hour", 0, 3);
        } else {
          addPrice("hour", 0, 0);
          addPrice("hour", (+truckHourly * 100)?.toFixed(0), 1);
          addPrice("hour", (+trailerHourly * 100)?.toFixed(0), 2);
          addPrice("hour", (+truckTrailerHourly * 100)?.toFixed(0), 3);
        }
      }
    } else {
      addPrice("hour", 0, 0);
      addPrice("hour", 0, 1);
      addPrice("hour", 0, 2);
      addPrice("hour", 0, 3);
    }

    if (enabledBookings.indexOf(2) > -1) {
      if (!separatePrices) {
        addPrice("day", (+daily * 100)?.toFixed(0), 0);
        addPrice("day", 0, 1);
        addPrice("day", 0, 2);
        addPrice("day", 0, 3);
      } else {
        if (trucksAllowed && !trailersAllowed) {
          addPrice("day", 0, 0);
          addPrice("day", (+truckDaily * 100)?.toFixed(0), 1);
          addPrice("day", 0, 2);
          addPrice("day", 0, 3);
        } else if (trailersAllowed && !trucksAllowed) {
          addPrice("day", 0, 0);
          addPrice("day", 0, 1);
          addPrice("day", (+trailerDaily * 100)?.toFixed(0), 2);
          addPrice("day", 0, 3);
        } else {
          addPrice("day", 0, 0);
          addPrice("day", (+truckDaily * 100)?.toFixed(0), 1);
          addPrice("day", (+trailerDaily * 100)?.toFixed(0), 2);
          addPrice("day", (+truckTrailerDaily * 100)?.toFixed(0), 3);
        }
      }
    } else {
      addPrice("day", 0, 0);
      addPrice("day", 0, 1);
      addPrice("day", 0, 2);
      addPrice("day", 0, 3);
    }

    if (enabledBookings.indexOf(3) > -1) {
      if (!separatePrices) {
        addPrice("week", (+weekly * 100)?.toFixed(0), 0);
        addPrice("week", 0, 1);
        addPrice("week", 0, 2);
        addPrice("week", 0, 3);
      } else {
        if (trucksAllowed && !trailersAllowed) {
          addPrice("week", 0, 0);
          addPrice("week", (+truckWeekly * 100)?.toFixed(0), 1);
          addPrice("week", 0, 2);
          addPrice("week", 0, 3);
        } else if (trailersAllowed && !trucksAllowed) {
          addPrice("week", 0, 0);
          addPrice("week", 0, 1);
          addPrice("week", (+trailerWeekly * 100)?.toFixed(0), 2);
          addPrice("week", 0, 3);
        } else {
          addPrice("week", 0, 0);
          addPrice("week", (+truckWeekly * 100)?.toFixed(0), 1);
          addPrice("week", (+trailerWeekly * 100)?.toFixed(0), 2);
          addPrice("week", (+truckTrailerWeekly * 100)?.toFixed(0), 3);
        }
      }
    } else {
      addPrice("week", 0, 0);
      addPrice("week", 0, 1);
      addPrice("week", 0, 2);
      addPrice("week", 0, 3);
    }

    if (enabledBookings.indexOf(4) > -1) {
      if (!separatePrices) {
        addPrice("month", (+monthly * 100)?.toFixed(0), 0);
        addPrice("month", 0, 1);
        addPrice("month", 0, 2);
        addPrice("month", 0, 3);
      } else {
        if (trucksAllowed && !trailersAllowed) {
          addPrice("month", 0, 0);
          addPrice("month", (+truckMonthly * 100)?.toFixed(0), 1);
          addPrice("month", 0, 2);
          addPrice("month", 0, 3);
        } else if (trailersAllowed && !trucksAllowed) {
          addPrice("month", 0, 0);
          addPrice("month", 0, 1);
          addPrice("month", (+trailerMonthly * 100)?.toFixed(0), 2);
          addPrice("month", 0, 3);
        } else {
          addPrice("month", 0, 0);
          addPrice("month", (+truckMonthly * 100)?.toFixed(0), 1);
          addPrice("month", (+trailerMonthly * 100)?.toFixed(0), 2);
          addPrice("month", (+truckTrailerMonthly * 100)?.toFixed(0), 3);
        }
      }
    } else {
      addPrice("month", 0, 0);
      addPrice("month", 0, 1);
      addPrice("month", 0, 2);
      addPrice("month", 0, 3);
    }

    setLoading(true);


    let terms = tnc
    if (optionalTerms && tnc) {
      terms = terms.substring(3, terms.length - 4);
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/listing`, {
        customer_id: user.id,
        title: name,
        description: description,
        phone: phone,
        notes: note,
        signs: signs,
        // trailers_only: trailersAllowed
        vehicle_type_allowed: trucksAllowed ? 1 : trailersAllowed ? 2 : 0
      })
      .then((res) => {
        if (res.data.listing_id) {
          axios
            .put(
              `${process.env.REACT_APP_API_URL}/listing/${res.data.listing_id}`,
              {
                billing_phone: billingPhone,
                apt: apt,
                seats: seats,
                amenities: amenities,
                lat: lat,
                lng: lng,
                state: state,
                city: city,
                zip_code: zip ? zip : -1,
                address: address,
                details: details,
                full_address: address,
                video,
                tnc: optionalTerms ? terms : null,
                is_qr_only: isQr,
                ...(isQr && { textcode: textCode })
              }
            )
            .then((res) => {
              toast("Listing Added Successfully", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              getUser();
              setShowListingModal(false);
              setLoading(false)
            })
            .catch((err) => {
              toast("Error Adding", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              setLoading(false)
            });
          axios
            .put(
              `${process.env.REACT_APP_API_URL}/listing/${res.data.listing_id}/price`,
              {
                prices: prices,
              }
            )
            .then((res) => {
              getUser();
            })
            .catch((err) => {
              toast("Error Adding Prices", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            });
          const formData = new FormData();

          if (photos.length) {
            for (let el of photos) {
              formData.append("images", el, el.name);
            }
          }

          axios
            .put(
              `${process.env.REACT_APP_API_URL}/listing/${res.data.listing_id}`,
              formData
            )
            .then((res) => {
              getUser();
            })
            .catch((err) => { });
        }
      })
      .catch((err) => {
        toast("Error Adding Listing", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };

  const DeleteImage = (id) => {
    setPhotos(photos.filter((item) => item.name !== id));
  };

  const fileSelectHandler = (e) => {
    if (photos.length < 8 && e?.target?.files?.length < 8) {
      setPhotos([...photos, ...e.target.files]);
    } else {
      toast("Can't Add More Images max 8 is allowed", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };


  const handleSetSeats = () => {
    if (selectedSeats) {
      setCalendarData([
        ...calendarData,
        {
          title: `${selectedSeats}`,
          start: showModal.date,
          backgroundColor: '#e6e6e6',
          borderColor: 'transparent',
          textColor: '#000',
          display: 'background',
        },
      ])
      setShowModal({
        status: false,
        date: ''
      })
      setSelectedSeats()
    }
  }



  return (
    <>
      <div className="kt_body">
        <>
          <div style={{ height: '0' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: GOOGLE_MAPS_API_KEY,
                language: 'en',
                region: 'US'
              }}
              defaultCenter={{ lat: 38.6311966, lng: -98.1633745 }}
              defaultZoom={4}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            />
          </div>
          <div className="input-search mb-5">
            <label className="required form-label fs-6 fw-bold">Name of Space</label>
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="Space Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input-search mb-5">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label className="form-label fs-6 fw-bold">
                Description of Space
              </label>
              <div>{description?.replace(/<[^>]+>/g, '')?.length || 0}/500</div>
            </div>
            {/* <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="Space Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            /> */}
            <ReactQuill
              theme="snow"
              className="editor"
              value={description}
              onChange={setDescription}
              modules={EditorModules}
            />
          </div>

          <div className="input-search mb-5 form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              id='qr'
              checked={isQr}
              onChange={(e) => setIsQr(prev => !prev)}
            />
            <label
              className="form-check-label"
              htmlFor="qr"
              style={{ fontWeight: "700", color: "#3f4254" }}
            >
              QR and Text2Pay Only
            </label>
          </div>
          {isQr && (
            <div className="input-search mb-5">
              <label className="form-label fs-6 fw-bold">Text2Pay Code</label>
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder="Enter Text2Pay Code"
                value={textCode}
                onChange={(e) => setTextCode(e.target.value)}
              />
            </div>
          )}

          <div className="input-search mb-5">
            <label className="form-label fs-6 fw-bold">
              Contact Phone Number
            </label>
            {/* <input
                type="number"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value.replace(/^\d{10}/g, ''))}
              /> */}
            <PatternFormat
              format={"+1 (###) ###-####"}
              className={"form-control form-control-solid mb-3 mb-lg-0"}
              value={phone}
              onValueChange={(values) => setPhone(values.value)}
              placeholder={"Phone Number"}
            />
          </div>
          <div className="input-search mb-5">
            <label className="form-label fs-6 fw-bold">
              Billing Phone Number
            </label>
            <PatternFormat
              format={"+1 (###) ###-####"}
              className={"form-control form-control-solid mb-3 mb-lg-0"}
              value={billingPhone}
              onValueChange={(values) => setBillingPhone(values.value)}
              placeholder={"Billing Phone Number"}

            />
          </div>
          <div className="input-search mb-5">
            <label className="form-label fs-6 fw-bold">
              Important information sent to the trucker after paying for a space
            </label>
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="Important Notes"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
          <div className="input-search mb-5">
            <label className="form-label fs-6 fw-bold">No of Signs</label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="No. Of Signs"
              value={signs}
              onChange={(e) => setSigns(e.target.value.replace(/[^0-9.]/g, ""))}
            />
          </div>
          {/* vehicle type allowed hidden */}
          {/* <div className="input-search mb-5 form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              value="2"
              id="trucksAllowed"
              checked={trucksAllowed}
              onChange={(e) => {
                if (e.target.checked) {
                  setTrucksAllowed(true)
                  setSeparatePrices(true)
                  setVehicleTypeActive('truck')
                  if (trailersAllowed) {
                    setTrailersAllowed(false)
                  }
                } else {
                  setTrucksAllowed(false)
                }
              }}
            />
            <label
              className="form-check-label"
              htmlFor="trucksAllowed"
              style={{ fontWeight: "700", color: "#3f4254" }}
            >
              Only Trucks Allowed
            </label>
          </div>
          <div className="input-search mb-5 form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              value="2"
              id="trailersAllowed"
              checked={trailersAllowed}
              onChange={(e) => {
                if (e.target.checked) {
                  setTrailersAllowed(true)
                  setSeparatePrices(true)
                  setVehicleTypeActive('trailer')
                  if (trucksAllowed) {
                    setTrucksAllowed(false)
                  }
                } else {
                  setTrailersAllowed(false)
                }
              }}
            />
            <label
              className="form-check-label"
              for="trailersAllowed"
              style={{ fontWeight: "700", color: "#3f4254" }}
            >
              Only Trailers Allowed
            </label>
          </div> */}
          <div className="input-search mb-5">
            <label className="form-label fs-6 fw-bold">Amenities</label>
            <div
              className="mb-10 mt-5"
              style={{
                display: "grid",
                gap: "25px",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              {
                amenitiesList.map((el, index) => (
                  <div className="form-check form-check-custom form-check-solid" key={index}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={el.id}
                      id="flexCheckChecked"
                      checked={amenities.indexOf(el.id) > -1}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        const value = e.target.value.split(",");
                        if (checked) {
                          setAmenities((prev) => [...prev, +e.target.value]);
                        } else {
                          setAmenities((prev) =>
                            prev.filter(
                              (el) => el !== +e.target.value
                            )
                          );
                        }
                      }}
                    />
                    <label
                      className="form-check-label"
                      // for="flexCheckChecked"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      {el.title}
                    </label>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="input-search mb-5">
            <label className="required form-label fs-6 fw-bold">Address</label>
            <input
              type="search"
              className="form-control form-control-solid w-100"
              placeholder="123 Example Street"
              value={address}
              disabled={!isMapLoaded}
              ref={inputRef}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          {/* <div className="input-search mb-5">
              <label className="form-label fs-6 fw-bold">
                Apt, suite, building #
              </label>
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder="A 42"
                value={apt}
                onChange={(e) => setApt(e.target.value)}
              />
            </div> */}<div className="input-search mb-5">
            <div className="d-flex justify-content-between">
              <label className="required form-label fs-6 fw-bold">Pricing</label>
              <div className="form-check form-check-custom form-check-solid mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="separatePrices"
                  checked={separatePrices}
                  disabled={trucksAllowed || trailersAllowed}
                  onChange={() => setSeparatePrices(!separatePrices)}
                />
                <label
                  className="form-check-label"
                  for="separatePrices"
                  style={{ fontWeight: "700", color: "#3f4254" }}
                >
                 Enable Separate Prices
                </label>
              </div>
            </div>
            {separatePrices && <h6 className="my-2" style={{color:'#ed2626'}}>Note: add prices {trucksAllowed ? 'trucks only' : trailersAllowed ? 'trailers only' : 'all types of vehicles which have booking type enabled'}</h6> }
            {separatePrices ?
              <>
                <div className="mt-2 d-flex">
                  <button className={`px-10 btn bg-light border border-light rounded-0 ${vehicleTypeActive === 'truck' && 'vehicle-active rounded'}`}
                    // disabled={trailersAllowed}
                    onClick={() => {
                      setVehicleTypeActive('truck')
                    }}
                  >Truck</button>
                  <button className={`px-10 btn bg-light border border-light rounded-0  ${vehicleTypeActive === 'trailer' && 'vehicle-active rounded'}`}
                  // disabled={trucksAllowed}
                    onClick={() => {
                      setVehicleTypeActive('trailer')
                    }}
                  >Trailer</button>
                  <button className={`px-10 btn bg-light border border-light rounded-0  ${vehicleTypeActive === 'trucktrailer' && 'vehicle-active rounded'}`}
                  // disabled={trucksAllowed || trailersAllowed}
                    onClick={() => {
                      setVehicleTypeActive('trucktrailer')
                    }}
                  >Truck + Trailer</button>
                </div>
                <div
                  className="mb-10 mt-5"
                  style={{
                    display: "grid",
                    gap: "25px",
                    gridTemplateColumns: "1fr",
                  }}
                >
                  <div>
                    <div className="form-check form-check-custom form-check-solid mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="1"
                        id="hourly"
                        checked={enabledBookings.indexOf(1) > -1}
                        onChange={onBookingsChange}
                      />
                      <label
                        className="form-check-label"
                        for="hourly"
                        style={{ fontWeight: "700", color: "#3f4254" }}
                      >
                        Enable hourly bookings
                      </label>
                    </div>
                    {enabledBookings.indexOf(1) > -1 && (
                      <>
                        <span style={{ fontWeight: "600" }}>{vehicleTypeActive === 'trucktrailer' ? 'Truck + Trailer' : vehicleTypeActive === 'truck' ? 'Truck' : 'Trailer'} Price per hour</span>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          data-kt-user-table-filter="search"
                          className="form-control form-control-solid w-100 mt-2 "
                          placeholder="Price Per Hour"
                          value={vehicleTypeActive === 'truck' ? truckHourly : vehicleTypeActive === 'trailer' ? trailerHourly : truckTrailerHourly}
                          onChange={(e) => {
                            vehicleTypeActive === 'truck' ? onPriceChange(e, setTruckHourly) : vehicleTypeActive === 'trailer' ? onPriceChange(e, setTrailerHourly) : onPriceChange(e, setTruckTrailerHourly)
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    <div className="form-check form-check-custom form-check-solid mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="2"
                        id="daily"
                        checked={enabledBookings.indexOf(2) > -1}
                        onChange={onBookingsChange}
                      />
                      <label
                        className="form-check-label"
                        for="daily"
                        style={{ fontWeight: "700", color: "#3f4254" }}
                      >
                        Enable daily bookings
                      </label>
                    </div>
                    {enabledBookings.indexOf(2) > -1 && (
                      <>
                        <span style={{ fontWeight: "600" }}>{vehicleTypeActive === 'trucktrailer' ? 'Truck + Trailer' : vehicleTypeActive === 'truck' ? 'Truck' : 'Trailer'} Price per day</span>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          data-kt-user-table-filter="search"
                          className="form-control form-control-solid w-100 mt-2 "
                          placeholder="Price Per Day"
                          value={vehicleTypeActive === 'truck' ? truckDaily : vehicleTypeActive === 'trailer' ? trailerDaily : truckTrailerDaily}
                          onChange={(e) => {
                            vehicleTypeActive === 'truck' ? onPriceChange(e, setTruckDaily) : vehicleTypeActive === 'trailer' ? onPriceChange(e, setTrailerDaily) : onPriceChange(e, setTruckTrailerDaily)
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    <div className="form-check form-check-custom form-check-solid mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="3"
                        id="weekly"
                        checked={enabledBookings.indexOf(3) > -1}
                        onChange={onBookingsChange}
                      />
                      <label
                        className="form-check-label"
                        for="weekly"
                        style={{ fontWeight: "700", color: "#3f4254" }}
                      >
                        Enable weekly bookings
                      </label>
                    </div>
                    {enabledBookings.indexOf(3) > -1 && (
                      <>
                        <span style={{ fontWeight: "600" }}>{vehicleTypeActive === 'trucktrailer' ? 'Truck + Trailer' : vehicleTypeActive === 'truck' ? 'Truck' : 'Trailer'} Price per week</span>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          data-kt-user-table-filter="search"
                          className="form-control form-control-solid w-100 mt-2 "
                          placeholder="Price Per Week"
                          value={vehicleTypeActive === 'truck' ? truckWeekly : vehicleTypeActive === 'trailer' ? trailerWeekly : truckTrailerWeekly}
                          onChange={(e) => {
                            vehicleTypeActive === 'truck' ? onPriceChange(e, setTruckWeekly) : vehicleTypeActive === 'trailer' ? onPriceChange(e, setTrailerWeekly) : onPriceChange(e, setTruckTrailerWeekly)
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    <div className="form-check form-check-custom form-check-solid mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="4"
                        id="monthly"
                        checked={enabledBookings.indexOf(4) > -1}
                        onChange={onBookingsChange}
                      />
                      <label
                        className="form-check-label"
                        for="monthly"
                        style={{ fontWeight: "700", color: "#3f4254" }}
                      >
                        Enable monthly bookings
                      </label>
                    </div>
                    {enabledBookings.indexOf(4) > -1 && (
                      <>
                        <span style={{ fontWeight: "600" }}>
                        {vehicleTypeActive === 'trucktrailer' ? 'Truck + Trailer' : vehicleTypeActive === 'truck' ? 'Truck' : 'Trailer'} Price per month (30 days)
                        </span>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          data-kt-user-table-filter="search"
                          className="form-control form-control-solid w-100 mt-2 "
                          placeholder="Price Per Month"
                          value={vehicleTypeActive === 'truck' ? truckMonthly : vehicleTypeActive === 'trailer' ? trailerMonthly : truckTrailerMonthly}
                          onChange={(e) => {
                            vehicleTypeActive === 'truck' ? onPriceChange(e, setTruckMonthly) : vehicleTypeActive === 'trailer' ? onPriceChange(e, setTrailerMonthly) : onPriceChange(e, setTruckTrailerMonthly)
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
              :
              <div
                className="mb-10 mt-5"
                style={{
                  display: "grid",
                  gap: "25px",
                  gridTemplateColumns: "1fr",
                }}
              >
                <div>
                  <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      id="hourly"
                      checked={enabledBookings.indexOf(1) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="hourly"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable hourly bookings
                    </label>
                  </div>
                  {enabledBookings.indexOf(1) > -1 && (
                    <>
                      <span style={{ fontWeight: "600" }}>Price per hour</span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Hour"
                        value={hourly}
                        onChange={(e) => onPriceChange(e, setHourly)}
                      />
                    </>
                  )}
                </div>
                <div>
                  <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="2"
                      id="daily"
                      checked={enabledBookings.indexOf(2) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="daily"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable daily bookings
                    </label>
                  </div>
                  {enabledBookings.indexOf(2) > -1 && (
                    <>
                      <span style={{ fontWeight: "600" }}>Price per day</span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Day"
                        value={daily}
                        onChange={(e) => onPriceChange(e, setDaily)}
                      />
                    </>
                  )}
                </div>
                <div>
                  <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="3"
                      id="weekly"
                      checked={enabledBookings.indexOf(3) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="weekly"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable weekly bookings
                    </label>
                  </div>
                  {enabledBookings.indexOf(3) > -1 && (
                    <>
                      <span style={{ fontWeight: "600" }}>Price per week</span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Week"
                        value={weekly}
                        onChange={(e) => onPriceChange(e, setWeekly)}
                      />
                    </>
                  )}
                </div>
                <div>
                  <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="4"
                      id="monthly"
                      checked={enabledBookings.indexOf(4) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="monthly"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable monthly bookings
                    </label>
                  </div>
                  {enabledBookings.indexOf(4) > -1 && (
                    <>
                      <span style={{ fontWeight: "600" }}>
                        Price per month (30 days)
                      </span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Month"
                        value={monthly}
                        onChange={(e) => onPriceChange(e, setMonthly)}
                      />
                    </>
                  )}
                </div>
              </div>
            }
          </div>
          {/* <Calendar
            setCalendarData={setCalendarData}
            calendarData={calendarData}
            setShowModal={setShowModal}
            spaceAvailable={seats}
          /> */}
          <div className="input-search mb-5">
            <label className="required form-label fs-6 fw-bold">Spaces Available</label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="Spaces Available"
              value={seats}
              onChange={(e) => setSeats(e.target.value.replace(/[^0-9]/g, ""))}
              min={1}
            />
          </div>
          <div className="input-search mb-5">
            <label className="form-label fs-6 fw-bold">
              Youtube Video
            </label>
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100"
              placeholder="Enter Youtube Video Link"
              value={video}
              onChange={(e) => setVideo(e.target.value)}
            />
          </div>
          <div className="input-search mb-5 form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              id='optionalTerms'
              checked={optionalTerms}
              onChange={() => setOptionalTerms(prev => !prev)}
            />
            <label
              className="form-check-label"
              htmlFor="optionalTerms"
              style={{ fontWeight: "700", color: "#3f4254" }}
            >
              Optional Terms and Conditions
            </label>
          </div>
          {optionalTerms && (
            <div>
              <ReactQuill
                theme="snow"
                value={tnc}
                onChange={setTnc}
                modules={EditorModules}
              />
            </div>
          )}

          <div className="input-search mb-5">
            <label className="form-label fs-1 fw-bold">Photos</label>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
              {photos &&
                photos.map((el, i) => (
                  <div
                    key={i}
                    style={{
                      position: "relative",
                      width: "150px",
                      height: "150px",
                      outline: i === 0 && "4px solid #ffa459",
                    }}
                  >
                    <button
                      style={{
                        position: "absolute",
                        top: i === 0 ? "-8px" : "-4px",
                        right: i === 0 ? "-8px" : "-4px",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        background: "#ffa459",
                        color: "#fff",
                        zIndex: 9,
                        border: "none",
                      }}
                      onClick={() => DeleteImage(el.name)}
                    >
                      <i className="fas fa-times" style={{ color: "#fff" }}></i>
                    </button>
                    {i === 0 && (
                      <span
                        style={{
                          position: "absolute",
                          left: "2px",
                          top: "1px",
                          color: "#ffa459",
                          zIndex: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#ffa459"
                          height="1.2em"
                          width="1.5em"
                          viewBox="0 0 576 512"
                        >
                          <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                        </svg>{" "}
                        <span
                          style={{
                            color: "#fff",
                            marginLeft: "5px",
                            fontWeight: "700",
                          }}
                        >
                          Profile Picture
                        </span>
                      </span>
                    )}
                    <img
                      src={URL.createObjectURL(el)}
                      style={{
                        width: "100%",
                        height: "100%",
                        filter: "opacity(0.8)",
                      }}
                      alt=""
                    />
                  </div>
                ))}
            </div>
            <label
              style={{
                width: "95%",
                height: "240px",
                border: "2px dashed #4a4a4a",
                borderRadius: "2px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                rowGap: "6px",
                margin: "16px 0 8px",
                cursor: "pointer",
              }}
            >
              <div>+ Choose an image...</div>
              <span>.JPG, .GIF or .PNG. Max 20 MB</span>
              <input
                type="file"
                multiple
                onChange={fileSelectHandler}
                style={{ display: "none" }}
                accept="image/jpg, image/jpeg, image/png, image/gif"
              />
            </label>
            Tip: Choose the top 2-3 photos (max 8) of your space, we recommend
            aerials and a photo of the entrance
          </div>
        </>
      </div>

      <div className="text-center pt-5">
        <button
          type="reset"
          onClick={() => cancel()}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        // disabled={formik.isSubmitting || isUserLoading}
        >
          Discard
        </button>
        <button
          type="submit"
          className="btn"
          style={{ backgroundColor: "#ffa459", color: "#fff" }}
          data-kt-users-modal-action="submit"
          disabled={
            !name ||
            isNextDisabled() ||
            !seats ||
            !address || 
            !city || 
            !state ||
            !lat ||
            !lng 
            || loading
          }
          onClick={Save}
        >
          <span className="indicator-label">Add Listing</span>
        </button>
      </div>
      {/* end::Actions */}
      {/* </form> */}
      {showModal.status && (
        <div className="modal_wrapper">
          <div className="modal_container">
            <div className="input-search mb-5">
              <label className="form-label fs-6 fw-bold">
                Enter Seats Available for
                {moment(showModal.date).format("dddd DD MMM, YYYY")}
              </label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder="1"
                value={selectedSeats}
                onChange={(e) => {
                  if (seats && +e.target.value <= +seats) {
                    setSelectedSeats(e.target.value);
                  } else {
                    setSelectedSeats(e.target.value)
                  }
                }}
                min={1}
              />
            </div>

            <div className="modal_btn_container">
              <button
                className="btn"
                onClick={() =>
                  setShowModal({
                    status: false,
                    date: "",
                  })
                }
                style={{ background: "#fff", color: "#ffa459" }}
              >
                Cancel
              </button>
              <button className="btn" onClick={handleSetSeats} disabled={!selectedSeats} style={{ background: "#ffa459", color: "#fff" }}>
                Set
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { AddListingModalForm };
