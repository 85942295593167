import {useEffect} from 'react'
import {VehicleEditModalHeader} from './VehicleEditModalHeader'
import {VehicleEditModalFormWrapper} from './vehicleEditModalFormWrapper'


const VehicleEditModal = ({vehicles, setSelectedBookingId, setVehicles, fetchBooking, selectedBookingId, fetchBookingId, bookingDetails}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])



  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className={`modal-dialog modal-dialog-centered mw-750px`}>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <VehicleEditModalHeader vehicles={vehicles} setSelectedBookingId={setSelectedBookingId} setVehicles={setVehicles} selectedBookingId={selectedBookingId} bookingDetails={bookingDetails}/>
            {/* begin::Modal body */}
            <div className={`modal-body scroll-y mx-5  mx-xl-5`}>
              <VehicleEditModalFormWrapper  vehicles={vehicles} setSelectedBookingId={setSelectedBookingId} setVehicles={setVehicles} fetchBooking={fetchBooking} selectedBookingId={selectedBookingId} fetchBookingId={fetchBookingId} bookingDetails={bookingDetails} />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
      
    </>
  )
}

export {VehicleEditModal}
