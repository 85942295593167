import {RefundsModalForm} from './RefundsModalForm'

const RefundsModalFormWrapper = ({
    setOpenRefundsModal
}) => {
    return <RefundsModalForm
      setOpenRefundsModal={setOpenRefundsModal}
    />

}

export {RefundsModalFormWrapper}
