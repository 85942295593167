import moment from 'moment-timezone'

export const MIN_MILLISECONDS = 60000

export const getLocalListingTime = (offset, date) => {
    const d = date ? date : new Date()
    const utc = d.getTime() + (d.getTimezoneOffset() * MIN_MILLISECONDS)
    const listingTime = new Date(utc + (MIN_MILLISECONDS * -offset))
    return listingTime.getTime()
}


export const convertListingTimeToUTC = (timestamp, offset) => {
    try {
        let adjustedTimestamp = moment(new Date(timestamp))
        if (offset > 0) adjustedTimestamp = moment(new Date(timestamp)).add(offset, 'minutes')
        if (offset < 0) adjustedTimestamp = moment(new Date(timestamp)).subtract(offset, 'minutes')

        return adjustedTimestamp.format('YYYY-MM-DD HH:mm:00+00')
    } catch (err) {
        return null
    }
}

export const convertUTCTimeToListingTime = (timestamp, timezone, addMinute, format) => {
    try {
        // let adjustedTimestamp = moment(new Date(timestamp)).add(addMinute ? 1 : 0, 'minute').utcOffset(-offset)
        let adjustedTimestamp = moment.tz(timestamp, timezone).add(addMinute ? 1 : 0, 'minute')
        if (format) return adjustedTimestamp.format(format)

        return adjustedTimestamp.format('MMMM DD, YYYY dddd, hh:mm A')
    } catch (err) {
        return null
    }
}

export const offsetUtc = (date) => {
    if(typeof date === 'undefined') {
        date = new Date()
    }
    const offsetUtcDate = new Date()
    offsetUtcDate.setUTCFullYear(date.getFullYear())
    offsetUtcDate.setUTCMonth(date.getMonth())
    offsetUtcDate.setUTCDate(date.getDate())
    offsetUtcDate.setUTCHours(date.getHours())
    offsetUtcDate.setUTCMinutes(date.getMinutes())
    offsetUtcDate.setUTCSeconds(date.getSeconds())
    offsetUtcDate.setUTCMilliseconds(date.getMilliseconds())
    return offsetUtcDate
}

export const isoDateToLocalDate = (isoDateAsLocalDate) => {
    const isoDate = new Date(isoDateAsLocalDate)
    const isoDateString = isoDate.toISOString()
    return isoDateStringToLocalDate(isoDateString)
}

export const isoDateStringToLocalDate = (isoDateTimeString) => {
    const isoDateString = isoDateTimeString.split('T')[0]
    const isoDateComponents = isoDateString.split('-')
    const dayOrdinal = isoDateComponents[2]
    const monthOrdinal = isoDateComponents[1]
    const yearOrdinal = isoDateComponents[0]
    return getLocalDate(yearOrdinal, monthOrdinal, dayOrdinal)
}

export const getLocalDate = (year, month, day) => {
    const monthJsIndex = (+month) -1
    const localDate = new Date('2000-01-01')
    localDate.setHours(0, 0, 0, 0)
    localDate.setDate(day)
    localDate.setMonth(monthJsIndex)
    localDate.setFullYear(year)
    return localDate
}
