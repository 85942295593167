
import {VehicleModalForm} from './VehicleModalForm'

const VehicleModalFormWrapper = ({user, setShowEditModal, getUser, setVehicle, customerDetails, hide, confirmationModal, setConfirmationModal}) => {

    return <VehicleModalForm user={user} setShowEditModal={setShowEditModal} getUser={getUser} setVehicle={setVehicle} customerDetails={customerDetails} hide={hide} confirmationModal={confirmationModal} setConfirmationModal={setConfirmationModal}/>


}

export {VehicleModalFormWrapper}
