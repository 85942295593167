import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { KTIcon } from "../../../../../_metronic/helpers";
import { ToastStyle } from "../../../../../_metronic/helpers/utils";

const PendingListingModalForm = ({
  selectedListingId,
  setSelectedListingId,
  openAdditionalUserModal,
  setOpenAdditionalUserModal,
  listing,
  getListing,
  openDisableModal,
  setOpenDisableModal,
  openNoteModal,
  setOpenNoteModal,
  notes,
  admins,
  adminNotes,
  note,
  setNote,
  noteId,
  setNoteId,
  deleteAdminNote
}) => {
  const [type, setType] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  // const [admins, setAdmins] = useState([]);
  // const [notes, setNotes] = useState([]);

  const cancel = () => {
    setSelectedListingId();
    setOpenAdditionalUserModal(false);
    setOpenDisableModal(false);
    setOpenNoteModal(false);
    setType("");
    setNote("");
  };

  // const adminList = () => {
  //   axios.get(`${process.env.REACT_APP_API_URL}/admin/public`).then((res) => {
  //     setAdmins(res.data);
  //   });
  // }

  // const adminNotes = () => {
  //   axios.get(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/notes`).then((res) => {
  //     setNotes(res.data.reverse());
  //   })
  // }

  const PendingApproval = (type) => {
    if (type) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/listing/${selectedListingId}?is_approved=true`
        )
        .then((res) => {
          toast("Approval Accepted", ToastStyle);
          if (window.gtag) {
            window.gtag('event', 'listing_approved', {
              listing_id: selectedListingId,
              listing_name: listing.title,
              owner_name: `${listing?.owner_first_name} ${listing?.owner_last_name[0]}`
            })
          }
          getListing();
          setOpenAdditionalUserModal(false);
        })
        .catch((err) => {
          toast("Error Pending Approval", ToastStyle);
        });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/listing/${selectedListingId}?is_approved=false&note=${note}`
        )
        .then((res) => {
          toast("Approval Rejected", ToastStyle);
          getListing();
          setOpenAdditionalUserModal(false);
        })
        .catch((err) => {
          toast("Error Pending Approval", ToastStyle);
        });
    }
  };

  const ChangeListingStatus = (type) => {
    if (type === "disable") {
      axios.patch(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/status?is_disabled=true&note=${note}`).then((res) => {
        toast("Listing is Disabled", ToastStyle);
        getListing();
        setOpenDisableModal(false);
      }).catch((err) => {
        toast("Error Disabling Listing", ToastStyle);
      });
    } else if (type === "enable") {
      axios.patch(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/status?is_disabled=false`).then((res) => {
        toast("Listing is Enabled", ToastStyle);
        getListing();
        setOpenDisableModal(false);
      }).catch((err) => {
        toast("Error Enabling Listing", ToastStyle);
      });
    }
  };

  const AddAdminNote = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/note`, { note })
      .then((res) => {
        toast("Admin Note Added", ToastStyle);
        setNote("");
        getListing();
        adminNotes();
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast(err.response.data.message, ToastStyle);
        } else {
          toast("Error Adding Admin Note", ToastStyle);
        }
      });
  };

  const EditAdminNote = () => {
    axios.put(`${process.env.REACT_APP_API_URL}/listing/note/${noteId?.id}`, { note }).then((res) => {
      toast("Note Updated", ToastStyle);
      getListing();
      adminNotes();
      setNoteId(null);
      setNote("");
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, ToastStyle);
      } else {
        toast("Error Updating Admin Note", ToastStyle);
      }
    });
  }

  return (
    <>
      {openDisableModal ? (
        <div className="kt_body">
          {listing.status === 3 || listing.status === 2 ? (
            <div>
              <h4>
                Are you sure you want to{" "}
                <span style={{ color: "#2ecc71", fontWeight: 700 }}>
                  ENABLE
                </span>{" "}
                this listing
              </h4>
              <div className="text-center pt-5">
                <button
                  type="reset"
                  onClick={() => cancel()}
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn"
                  style={{ backgroundColor: "#2ecc71", color: "#fff" }}
                  data-kt-users-modal-action="submit"
                  onClick={() => {
                    ChangeListingStatus("enable");
                  }}
                >
                  <span className="indicator-label">ENABLE LISTING</span>
                </button>
              </div>
            </div>
          ) : (
            <div>
              {!showConfirmation ? (
                <h4>
                  Are you sure you want to{" "}
                  <span style={{ color: "#ed2626", fontWeight: 700 }}>
                    DISABLE
                  </span>{" "}
                  this listing?
                </h4>
              ) : (
                <h4>
                  Are you sure?
                </h4>
              )}
              {!showConfirmation && (
                <>
                  <h6 style={{ color: "#ed2626" }}>Note: You are required to leave a note in order to disable a
                    listing</h6>
                  <textarea
                    className="form-control form-control-solid w-100 mt-4"
                    placeholder="Note..."
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </>
              )}
              <div className="text-center pt-5">
                <button
                  type="reset"
                  onClick={() => cancel()}
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn"
                  style={{ backgroundColor: "#ed2626", color: "#fff" }}
                  data-kt-users-modal-action="submit"
                  onClick={() => {
                    if (showConfirmation) {
                      ChangeListingStatus("disable");
                    } else {
                      setShowConfirmation(true);
                    }
                  }}
                  disabled={!note}
                >
                  <span className="indicator-label">DISABLE LISTING</span>
                </button>
              </div>
            </div>
          )}
        </div>
      ) : openNoteModal ? (
        <div className="kt_body">
          {!noteId && <div style={{ maxHeight: '240px', overflowY: 'auto', minHeight: 'auto' }}>
            {
              notes && notes.length > 0 ?
                <>
                  {notes.map((item, index) => {
                    return <div
                      key={index}
                      style={{ display: 'flex', gap: '5px', background: '#f9f9f9', marginBottom: '10px', padding: '10px', borderRadius: '10px', flexDirection: 'column' }}>
                      <div className="d-flex justify-content-between">
                        <label htmlFor="notes">Notes:</label>
                        <button
                          className="listing-remove"
                          onClick={() => {
                            setNoteId(item);
                            setNote(item.notes);
                          }}
                        >
                          <KTIcon iconName="pencil" />
                        </button>
                      </div>
                      <h6 style={{ marginBottom: '2px', textAlign: 'justify' }}> {item.notes}</h6>
                      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                        <h6 style={{ marginBottom: '2px', fontWeight: 400 }}>Action Done By: <span style={{ color: '#ffa459', fontWeight: 700 }}>{item.action_by_role == 2 ? `${listing.owner_first_name} ${listing.owner_last_name} (Owner)` : item.action_by_role == 3 ? `${admins?.find((_i) => _i.id == item.action_by)?.name || ""} (Admin)` : '-'}</span></h6>
                        <h6>{moment(item.createdAt).utc().format('YYYY-MM-DD')}</h6>
                      </div>
                    </div>
                  })
                  }
                </>
                :
                <h4>No notes to display</h4>
            }
          </div>
          }
          <textarea
            className="form-control form-control-solid w-100 mt-4"
            style={{ height: '100px' }}
            placeholder="Add Note..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <div className="text-center pt-5">
          {noteId && <button
              type="reset"
              onClick={() => deleteAdminNote()}
              className="btn btn-danger me-3"
              data-kt-users-modal-action="cancel"
            >
              Delete Note
            </button>
            }
            <button
              type="reset"
              onClick={() => {
                if (noteId) {
                  setNoteId(null);
                  setNote('');
                } else {
                  cancel();
                }
              }}
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn"
              style={{ backgroundColor: "#2ecc71", color: "#fff" }}
              data-kt-users-modal-action="submit"
              onClick={() => {
                if (noteId) {
                  EditAdminNote();
                } else {
                  AddAdminNote();
                }
              }}
              disabled={!note}
            >
              <span className="indicator-label">{noteId ? 'Edit Note' : 'Add Note'}</span>
            </button>
          </div>
        </div>
      ) : (
        <div className="kt_body">
          {!type ? (
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                type="button"
                className="btn mt-4 w-100"
                onClick={() => setType("reject")}
                style={{
                  background: "#ed2626",
                  color: "#fff",
                  height: "46px",
                  fontWeight: "700"
                }}
              >
                Reject
              </button>
              <button
                type="button"
                className="btn mt-4 w-100"
                onClick={() => setType("accept")}
                style={{
                  background: "#2ecc71",
                  color: "#fff",
                  height: "46px",
                  fontWeight: "700"
                }}
              >
                Accept
              </button>
            </div>
          ) : (
            <>
              {type === "accept" ? (
                <div>
                  <h4>
                    Are you sure you want to{" "}
                    <span style={{ color: "#2ecc71", fontWeight: 700 }}>
                      APPROVE
                    </span>{" "}
                    this listing
                  </h4>
                  <div className="text-center pt-5">
                    <button
                      type="reset"
                      onClick={() => cancel()}
                      className="btn btn-light me-3"
                      data-kt-users-modal-action="cancel"
                    >
                      Cancel
                    </button>
                    <button
                      type="reset"
                      onClick={() => setType("")}
                      className="btn btn-dark me-3"
                      data-kt-users-modal-action="cancel"
                    >
                      Go Back
                    </button>
                    <button
                      type="submit"
                      className="btn"
                      id="approve"
                      style={{ backgroundColor: "#2ecc71", color: "#fff" }}
                      data-kt-users-modal-action="submit"
                      onClick={() => {
                        PendingApproval(true);
                      }}
                    >
                      <span className="indicator-label">APPROVE LISTING</span>
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <h4>
                    Are you sure you want to{" "}
                    <span style={{ color: "#ed2626", fontWeight: 700 }}>
                      REJECT
                    </span>{" "}
                    this listing{" "}
                  </h4>
                  <textarea
                    className="form-control form-control-solid w-100 mt-4"
                    placeholder="Note..."
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                  <div className="text-center pt-5">
                    <button
                      type="reset"
                      onClick={() => cancel()}
                      className="btn btn-light me-3"
                      data-kt-users-modal-action="cancel"
                    >
                      Cancel
                    </button>
                    <button
                      type="reset"
                      onClick={() => setType("")}
                      className="btn btn-dark me-3"
                      data-kt-users-modal-action="cancel"
                    >
                      Go Back
                    </button>
                    <button
                      type="submit"
                      className="btn"
                      style={{ backgroundColor: "#ed2626", color: "#fff" }}
                      data-kt-users-modal-action="submit"
                      disabled={!note}
                      onClick={() => {
                        PendingApproval(false);
                      }}
                    >
                      <span className="indicator-label">REJECT LISTING</span>
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export { PendingListingModalForm };
