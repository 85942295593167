/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import {Link, useNavigate} from 'react-router-dom'


const UserInfoCell = ({user, id}) => {
const navigate = useNavigate()


const goTo = () => {
  id === "from" && user?.review_type == 1 ? navigate(`/users/${user?.owner_uid}`) : id === 'from' && user?.review_type == 2 ? navigate(`/users/${user?.customer_uid}`) : id === 'for' && user?.review_type == 1 ? navigate(`/users/${user?.customer_uid}`) : id === 'for' && user?.review_type == 2 && navigate(`/listings/${user?.listing_id}`)
}

return (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      <span className='text-gray-800 mb-1 pointer' onClick={goTo}>
        {id === "from" && user?.review_type == 1 ? user?.owner_name : id === 'from' && user?.review_type == 2 ? user?.customer_name : id === 'for' && user?.review_type == 1 ? user?.customer_name : id === 'for' && user?.review_type == 2 && user?.listing_name}
      </span>
      {/* <span>{user.email}</span> */}
    </div>
  </div>
)
}
export {UserInfoCell}
