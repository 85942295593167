import { KTIcon } from "../../../../../_metronic/helpers";

const PendingListingModalHeader = ({
  setSelectedListingId,
  openAdditionalUserModal,
  setOpenAdditionalUserModal,
  listing,
  openDisableModal,
  setOpenDisableModal,
  openNoteModal,
  setOpenNoteModal,
  noteId,
  setNoteId,
  deleteAdminNote
}) => {
  return (
    <div className="modal-header">
      {/* begin::Modal title */}
      {noteId && openNoteModal && <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() =>  {
         deleteAdminNote()
        }}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='trash' className='fs-1' />
      </div>
    }
      <h2 className="fw-bolder">{`${openDisableModal ? `Change Listing Status For ${listing.title}` : openNoteModal && !noteId ? `Add Note For ${listing.title}`  : openNoteModal && noteId ? `Edit Note For ${listing.title}` : `Approval For ${listing.title}` }` }</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => {
          if(noteId){
            setNoteId(null)
          }else{
            setSelectedListingId()
            setOpenAdditionalUserModal(false)
            setOpenDisableModal(false)
            setOpenNoteModal(false)
            setNoteId(null)
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </div>
      {/* end::Close */}
    </div>
  );
};

export { PendingListingModalHeader };
