import { PointsModalForm } from "./PointsModalForm"

const PointsModalFormWrapper = ({
  user,
  setOpenPointsModal
}) => {

  if (user) {
    return (
      <PointsModalForm
        user={user}
        setOpenPointsModal={setOpenPointsModal}
      />
    );
  }
  return null;
};

export { PointsModalFormWrapper };
