import { KTIcon } from "../../../../_metronic/helpers";


const DiscountModalHeader = ({ setBookingToDiscount, setOpenDiscountModal }) => {
  return (
    <div className="modal-header">
      <h2 className="fw-bolder">Apply discount to the next cycle</h2>
      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => {
          setBookingToDiscount(null);
          setOpenDiscountModal(false)
        }}
        style={{ cursor: "pointer" }}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </div>

    </div>
  );
};

export { DiscountModalHeader };
