import React, { useEffect } from "react";
import { useState } from "react";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import "./style.css";
import { MessagesHeader } from "./header/MessagesHeader";
import { MessageModal } from "./messageModal/MessageModal";
import axios from "axios";
import { MessagesTable } from "./table/MessagesTable";
import { toast } from "react-toastify";
import { useAuth } from "../auth";

const Index = () => {
  const { currentUser, haveAccess } = useAuth();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [tab, setTab] = useState('email');
  const [type, setType] = useState('');
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [messages, setMessages] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);


useEffect(() => {
  setPage(1)
  setPageSize(10)
},[tab])


  useEffect(() => {
    if(isPasswordValid && (haveAccess(currentUser,  (tab === 'email' ? 'bulk_msg_list' : 'bulk_sms_list')))){
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/bulk-${tab}?page=${page}&page_size=${pageSize}`)
      .then(res => {
        setMessages(res.data.data);
        setTotal(res.data.total) ;
        setLoading(false);
      })
      .catch(err => {
        toast.error(err?.response?.data?.message || 'Could not fetch messages');
        setLoading(false);
      })
    }
  }, [trigger, isPasswordValid, page, pageSize, tab])

  return (
    <KTCard>
      <MessagesHeader
        openMessageModal={openMessageModal}
        setOpenMessageModal={setOpenMessageModal}
        setType={setType}
        tab={tab}
        setTab={setTab}
        isPasswordValid={isPasswordValid}
        setPasswordValid={setPasswordValid}
        currentUser={currentUser}
        haveAccess={haveAccess}
      />
      {isPasswordValid && (
        <MessagesTable
          messages={messages}
          total={total}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentUser={currentUser}
          tab={tab}
          haveAccess={haveAccess}
        />
      )}

      {openMessageModal && (
        <MessageModal
          setOpenMessageModal={setOpenMessageModal}
          type={type}
          setTrigger={setTrigger}
        />
      )}
    </KTCard>
  );
};

export default Index;
