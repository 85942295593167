/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import {Link} from 'react-router-dom'

type Props = {
  user: User
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3' style={{width:'50px', height:'50px'}}>
     {!user?.is_deleted ? 
     <Link to={`${user.uid}`}>
        {user.avatar && user.avatar !== null ? (
          <div className='w-100 h-100'>
            <img src={process.env.REACT_APP_IMAGE_URL + '200:200:' + user.user_image.slice(1)} alt={user.name} className='w-100 h-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-primary`,
              `text-primary`
            )}       
          >
            {user?.first_name?.charAt(0)}
            {user?.last_name?.charAt(0)}
          </div>
        )}
      </Link>
      : 
      user.avatar && user.avatar !== null ? (
        <div className='w-100 h-100'>
          <img src={process.env.REACT_APP_IMAGE_URL + '200:200:' + user.user_image.slice(1)} alt={user.name} className='w-100 h-100' />
        </div>
      ) : (
        <div
          className={clsx(
            'symbol-label fs-3',
            `bg-light-primary`,
            `text-primary`
          )}       
        >
          {user?.first_name?.charAt(0)}
          {user?.last_name?.charAt(0)}
        </div>
      )
    }
    </div>
    <div className='d-flex flex-column mx-2'>
      {!user?.is_deleted ?
      <Link to={`${user.uid}`} className='text-gray-800 mb-1'>
        {user?.first_name} {user?.last_name}
      </Link>
      : 
      <div className='text-gray-800 mb-1'>
        {user?.first_name} {user?.last_name}
      </div>
      }
    </div>
  </div>
)

export {UserInfoCell}
