import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import dayjs from "dayjs";
import { Pagination } from "../../../../components/Pagination";
import { Link } from "react-router-dom";

const headers = [
  { title: 'Listing Name', className: 'w-200' },
  { title: 'Owner Name', className: 'w-200' },
  { title: 'Seats', className: 'w-200' },
  { title: 'Signs', className: 'w-200' },
  { title: 'Identifier', className: 'w-200' },
  { title: 'Created At', className: 'w-200' },
]

export const ListingsTable = ({
  listings,
  page,
  setPage,
  pageSize,
  setPageSize,
  total,
  isLoading
}) => {
  return (
    <KTCardBody className='py-4'>
      <h6 style={{ color: '#7e8299', textAlign: 'right' }}>Total: {total}</h6>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map(column => (
                <th
                  key={column.title}
                  className={column.className}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {listings.length > 0 && (
              listings.map((row, i) => (
                <tr key={row.id}>
                  <td className='w-200' style={{ width: '300px' }}><Link to={`/listings/${row.listing_id}`} style={{color:'inherit'}}>{row.title}</Link></td>
                  <td className='w-200'><Link to={`/users/${row.owner_uid}`} style={{color:'inherit'}}>
                  {row.owner_first_name + ' ' + row.owner_last_name}
                  </Link></td>
                  <td className='w-200'>{row.seats || '-'}</td>
                  <td className='w-200'>{row.signs || '-'}</td>
                  <td className='w-200'> {`${Math.min(...Object?.values(row?.platform_fees))}-${row.listing_id}`}</td>
                  <td className='w-200'>{dayjs(row.createdAt).format('MMMM DD YYYY, dddd')}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {listings && !listings.length > 0 && 
        <div className='d-flex text-center text-gray-600 fw-bolder fs-6 w-100 align-content-center justify-content-center mt-10'>
         No matching records found
        </div>
        }
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
      />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};
