import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const StripeConfirmModalForm = ({
  selectedListingId,
  setSelectedListingId,
  listing,
  getListing,
  setOpenStripeConfirmModal
}) => {
  const navigate = useNavigate();

  const cancel = () => {
    setSelectedListingId();
    setOpenStripeConfirmModal(false);
  };

  const enableStripeConnect = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/enable-stripe`).then((res) => {
      toast.success("Stripe Connect Enabled");
      getListing();
      setOpenStripeConfirmModal(false);
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Error Enabling Stripe Connect");
      }
    });
  };

  return (
    <>
      <div className="kt_body">
          <h4>
            Are you sure you want to{" "}
            <span style={{ color: "#2ecc71", fontWeight: 700 }}>
              Enable Stripe Connect
            </span>{" "} for
            this listing?
          </h4>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            type="button"
            className="btn mt-4 w-100 btn-light"
            onClick={() => cancel()}
            style={{
              color: "#000",
              height: "46px",
              fontWeight: "700"
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn mt-4 w-100"
            onClick={() => {
              enableStripeConnect();
            }}
            style={{
              background: "#2ecc71",
              color: "#fff",
              height: "46px",
              fontWeight: "700"
            }}
          >
            Enable
          </button>
        </div>
      </div>
    </>
  );
};

export { StripeConfirmModalForm };
