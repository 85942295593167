import { AdminInfoCell } from "./AdminInfoCell";
import { AdminTwoStepsCell } from "./AdminTwoStepsCell";
import { AdminActionsCell } from "./AdminActionsCell";
import { AdminCustomHeader } from "./AdminCustomHeader";
import "./style.css";

const usersColumns = [
  {
    Header: (props) => <AdminCustomHeader tableProps={props} title="Name" className="w-240" />,
    id: "name",
    Cell: ({ ...props }) => <AdminInfoCell user={props.data[props.row.index]} />
  },
  {
    Header: (props) => <AdminCustomHeader tableProps={props} title="Email" className="w-170" />,
    accessor: "email"
  },
  {
    Header: (props) => <AdminCustomHeader tableProps={props} title="Phone" className="w-170" />,
    accessor: "phone_number",
    id: "phone_number"
  },
  {
    Header: (props) => <AdminCustomHeader tableProps={props} title="Role Name" className="w-170" />,
    accessor: "role_name",
    id: "role_name"
  },
  {
    Header: (props) => (
      <AdminCustomHeader tableProps={props} title="View Finance" className="w-135" />
    ),
    id: "finance",
    Cell: ({ ...props }) => <AdminTwoStepsCell viewFinance={true} finance={props.data[props.row.index].can_view_finance} />
  },
  {
    Header: (props) => (
      <AdminCustomHeader tableProps={props} title="Restricted"  className="w-135"/>
    ),
    id: "restricted",
    Cell: ({ ...props }) => <AdminTwoStepsCell restrictedStatus={true} restricted={props.data[props.row.index].access_level} />
  },
  {
    Header: (props) => (
      <AdminCustomHeader tableProps={props} title="Status" />
    ),
    id: "status",
    Cell: ({ ...props }) => <AdminTwoStepsCell status={true} two_steps={props.data[props.row.index].is_disabled} />
  },
  {
    Header: (props) => (
      <AdminCustomHeader tableProps={props} title="" className="text-end" />
    ),
    id: "actions",
    Cell: ({ ...props }) => <AdminActionsCell id={props.data[props.row.index]} />
  }
];

export { usersColumns };
