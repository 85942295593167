import { useEffect } from "react";
import { AddListingModalHeader } from "./AddListingModalHeader";
import { AddListingModalFormWrapper } from "./AddListingModalFormWrapper";

const AddListingModal = ({
 getUser,
 user,
 setShowListingModal
}) => {
  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <AddListingModalHeader
              getUser={getUser}
              user={user}
              setShowListingModal={setShowListingModal}
            />
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-5 my-2 p-4">
              <AddListingModalFormWrapper
                getUser={getUser}
                user={user}
                setShowListingModal={setShowListingModal}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};

export { AddListingModal };
