import { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import Refunds from "../modules/Refunds";
import Reports from "../modules/Reports";
import  Messages from "../modules/Messages";
import  AccountPage from "../modules/accounts/AccountPage";
import  UsersPage from "../modules/apps/user-management/UsersPage";
import  SingleUser from "../modules/UserProfile";
import  Listing from "../modules/Listings";
import  ListingChanges from "../modules/ListingsChanges";
import  PendingApprovals from "../modules/PendingApprovals";
import  Arrearages from "../modules/Arrearages";
import  SingleListing from "../modules/Listings/SingleListing";
import  Settings from "../modules/Settings";
import  Bookings from "../modules/Bookings";
import  Admins from "../modules/Admins";
import  BlackList from "../modules/Blacklist";
import  PromoCode from "../modules/PromoCode";
import  Reviews from "../modules/Reviews/Review/UsersPage";
import  Roles from "../modules/Roles";
import  AddRole from "../modules/Roles/AddRole";
import  EditRole from "../modules/Roles/EditRole";
import  ViewRole from "../modules/Roles/ViewRole";
import  UserBan from "../modules/userBan";
import Organization from "../modules/Organizations";
import { useAuth } from "../modules/auth";


const PrivateRoutes = () => {
  const { currentUser, haveAccess } = useAuth()
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/listings" />} />
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="users"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
         <Route
          path="organizations"
          element={
            <SuspensedView>
              <Organization />
            </SuspensedView>
          }
        />
        <Route
          path="admins"
          element={
            <SuspensedView>
              <Admins />
            </SuspensedView>
          }
        />
        <Route
          path="blacklist"
          element={
            <SuspensedView>
              <BlackList />
            </SuspensedView>
          }
        />
        <Route
          path="promocodes"
          element={
            <SuspensedView>
              <PromoCode />
            </SuspensedView>
          }
        />
        <Route
          path="reviews"
          element={
            <SuspensedView>
              <Reviews />
            </SuspensedView>
          }
        />
        {(haveAccess(currentUser, 'bulk')) && <Route
          path="messages"
          element={
            <SuspensedView>
              <Messages />
            </SuspensedView>
          }
        />
        }
        <Route
          path="users/:id"
          element={
            <SuspensedView>
              <SingleUser />
            </SuspensedView>
          }
        />
        <Route
          path="listings"
          element={
            <SuspensedView>
              <Listing />
            </SuspensedView>
          }
        />
        <Route
          path="listings-changes"
          element={
            <SuspensedView>
              <ListingChanges />
            </SuspensedView>
          }
        />
        <Route
          path="listings-pending-approvals"
          element={
            <SuspensedView>
              <PendingApprovals />
            </SuspensedView>
          }
        />
        <Route
          path="arrearages"
          element={
            <SuspensedView>
              <Arrearages />
            </SuspensedView>
          }
        />
        <Route
          path="reports"
          element={
            <SuspensedView>
              <Reports />
            </SuspensedView>
          }
        />
        <Route
          path="refunds"
          element={
            <SuspensedView>
              <Refunds />
            </SuspensedView>
          }
        />
        <Route
          path="listings/:id"
          element={
            <SuspensedView>
              <SingleListing />
            </SuspensedView>
          }
        />
        <Route
          path="bookings/*"
          element={
            <SuspensedView>
              <Bookings />
            </SuspensedView>
          }
        />
        <Route
          path="account/*"
          element={
            <SuspensedView>
              <Settings />
            </SuspensedView>
          }
        />
        <Route
          path="roles/*"
          element={
            <SuspensedView>
              <Roles />
            </SuspensedView>
          }
        />
        <Route
          path="create-role/*"
          element={
            <SuspensedView>
              <AddRole />
            </SuspensedView>
          }
        />
        <Route
          path="edit-role/*"
          element={
            <SuspensedView>
              <EditRole />
            </SuspensedView>
          }
        />
        <Route
          path="view-role/*"
          element={
            <SuspensedView>
              <ViewRole />
            </SuspensedView>
          }
        />
        <Route
          path="user-ban/*"
          element={
            <SuspensedView>
              <UserBan />
            </SuspensedView>
          }
        />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  });

  return (
    <Suspense
      fallback={<TopBarProgress />}
    >
      {children}
    </Suspense>
  )
};

export { PrivateRoutes };
