import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import clsx from "clsx";
import { toast } from "react-toastify";

const PromoCycleModalForm = ({
  setOpenPromoCycleModal,
  setPromoCycleId,
  promoCycleId,
  getBookings,
  itemIdForUpdate,
  isLoading
}) => {

  const [promos, setPromos] = useState([])
  const [pageSize, setPageSize] = useState(6);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);

  const cancel = () => {
    setOpenPromoCycleModal(false);
    setPromoCycleId(null);
  };


  const updatePage = (type) => {
    if (type === "dec" && page > 1 && page !== 1) {
      setPage(page - 1);
    } else if (type === "inc" && page >= 1) {
      if (page !== Math.ceil(total / pageSize)) {
        setPage(page + 1);
      }
    }else{
      setPage(type);
    }
  };



  const getPromoCycle = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/promo/cycles?cycle_id=${id}&page_size=${pageSize}&page=${page}`)
      .then(res => {
        setPromos(res.data.promo_codes);
        setTotal(res.data.total);
      })
      .catch(err => {
        toast.error(err?.response?.data?.message || "Error");
      });
  }

  useEffect(() => {
    getPromoCycle(promoCycleId)
  }, [page, pageSize]);



  return (
    <>
      <div className="kt_body">
        <div style={{ maxHeight: '600px', overflowY: 'auto', minHeight: 'auto', display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
          {
            promos && promos.length > 0 ?
              <>
                {promos?.map((item, index) => {
                  return <div
                    className="form-check form-check-custom form-check-solid"
                    key={index}
                    style={{
                      background: item.is_active ? "#e3feee9e" : "#f9f9f9",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      borderRadius: "10px",
                      flexWrap: "wrap"
                    }}
                  >
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Code</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>{item.code}</h5>
                    </div>
                    <div className="fv-row w-50" style={{ textAlign: "right" }}>
                      <label className="fw-bold fs-6 mb-2">Start Date</label>
                      <h5 style={{
                        color: "#ffa459",
                        fontWeight: 700
                      }}>{moment(item.start_date).utc().format("YYYY-MM-DD")}</h5>
                    </div>
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Total Usage</label>
                      <div
                        style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                        <h5 style={{ color: "#ffa459", fontWeight: 700, margin: "0" }}>{item.total_usage || "0"}</h5>
                        {!!item.total_usage && (
                          <button
                            style={{
                              backgroundColor: "rgb(255, 164, 89)",
                              border: "none",
                              color: "white",
                              borderRadius: "2px",
                              padding: "4px"
                            }}
                            onClick={() => getBookings(item.code, item.total_usage || 1)}
                          >
                            Show
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="fv-row w-50" style={{ textAlign: "right" }}>
                      <label className="fw-bold fs-6 mb-2">Expiration Date</label>
                      <h5 style={{
                        color: "#ffa459",
                        fontWeight: 700
                      }}>{moment(item.expiry_date).utc().format("YYYY-MM-DD")}</h5>
                    </div>
                  </div>
                })
                }
              </>
              :
              <h4>No Promo Cycles Available</h4>
          }
        </div>

        <div className="row mt-10">
          <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select Status"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                onChange={(e) => setPageSize(e.target.value)}
                value={pageSize}
              >
                <option value="6">6</option>
                <option value="12">12</option>
                <option value="18">18</option>
                <option value="24">24</option>
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-lg-end">
            <div id="kt_table_users_paginate">
              {total > 0 && <ul className="pagination">
                {page > 1 && (
                  <li className={clsx("page-item", "previous")}>
                    <button
                      className={clsx("page-link", "next", "page-text")}
                      onClick={() => updatePage("dec")}
                      style={{ cursor: "pointer" }}
                      disabled={page === 1}
                    >
                      Previous Page
                    </button>
                  </li>
                )}
                <div style={{ display: "flex", gap: "5px", fontWeight: 700, fontSize: "14px" }}>
                  <span style={{ color: "#ffa459" }}>{page}</span>/ {Math.ceil(total / pageSize)}
                </div>
                <li className={clsx("page-item", "previous", page === Math.ceil(total / pageSize) && "disabled")}>
                  <button
                    className={clsx("page-link")}
                    onClick={() => updatePage("inc")}
                    style={{
                      cursor: page !== Math.ceil(total / pageSize) ? "pointer" : "default",
                      background: page !== Math.ceil(total / pageSize) ? "transparent" : "#e6e6e6",
                      color: page !== Math.ceil(total / pageSize) ? "#5e6278" : "#b2b2b2",
                      marginLeft: page === Math.ceil(total / pageSize) && "10px"
                    }}
                  >
                    Next Page
                  </button>
                </li>
                {Math.ceil(total / pageSize) > 5 && <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: '10px' }}>
                  <h6 style={{ marginBottom: '0' }}>Go To Page: </h6>
                  <select
                    style={{ width: "max-content" }}
                    className="form-select form-select-solid fw-bolder"
                    data-kt-select2="true"
                    data-placeholder="Select Status"
                    data-allow-clear="true"
                    data-kt-user-table-filter="two-step"
                    data-hide-search="true"
                    onChange={(e) => updatePage(+e.target.value)}
                    value={page}
                  >
                    {
                      [...Array(Math.ceil(total / pageSize))].map((_, i) => {
                        return (
                          <option value={i + 1}>{i + 1}</option>
                        )
                      }
                      )}
                  </select>
                </div>
                }
              </ul>
              }
            </div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            type="button"
            className="btn mt-4 w-100 btn-primary"
            onClick={() => cancel()}
            style={{
              color: "#fff",
              height: "46px",
              fontWeight: "700",
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export { PromoCycleModalForm };
