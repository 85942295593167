/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  TablesWidget12,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  StatisticsWidget5,
  ChartsWidget3,
  ChartsWidget1,
} from '../../../_metronic/partials/widgets'
import axios from 'axios'
import { useEffect, useState } from 'react'
import moment from 'moment'

const DashboardPage = () => {
  const [metric, setMetric] = useState()
  const [bookings, setBookings] = useState([])
  const getMetrics = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/metrics`)
      .then((res) => {
       setMetric(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getMetrics()
    fetchListing();
  },[])



  const checkEdit = (d, t, e, o) => {
    const date = moment(`${d} ${t}`, "DD-MM-YYYY HH:mm:ss").format();
    const diff = moment(date).diff(moment().tz(o).format());
    if (diff < 0 && moment(e).tz(o).diff(moment().tz(o).format()) > 0) {
      return "progress";
    } else if (diff > 0) {
      return "upcoming";
    } else if (diff < 0 && moment(e).tz(o).diff(moment().tz(o).format()) < 0) {
      return "completed";
    }
  };



  const fetchListing = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/booking`
      )
      .then((res) => {
        setBookings(res.data?.bookings.map((booking) => {
          return {
            ...booking,
            type: checkEdit(
              moment(booking.start_date).tz(booking.timezone_id).format("DD-MM-YYYY"),
              booking.clock_in,
              booking.end_date,
              booking?.timezone_id,
            ),
          }
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  };




  
  return (<>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='chart-simple'
            color='white'
            iconColor='primary'
            title={metric?.bookings}
            description='Total Bookings'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='cheque'
            color='dark'
            iconColor='white'
            title={((metric?.users_with_bookings / metric?.bookings) * 100).toFixed(2) + '%'}
            titleColor='white'
            description='Retention Rate'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='briefcase'
            color='success'
            iconColor='white'
            title={metric?.listings}
            titleColor='white'
            description='Total Listings'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='chart-pie-simple'
            color='info'
            iconColor='white'
            title={metric?.users}
            titleColor='white'
            description='Total Users'
            descriptionColor='white'
          />
        </div>
      </div>
    {/* <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Active Projects'
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
        <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        />
      
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
        <ListsWidget26 className='h-lg-50' />
      </div>
      <div className='col-xxl-6'>
        <EngageWidget10 className='h-md-100' />
      </div>
    </div> */}


<div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <ChartsWidget3 className='card-xl-stretch mb-xl-8' metric={metric} />
        </div>
        <div className='col-xl-6'>
          <ChartsWidget1 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
      </div>

    {/* begin::Row */}
    <div className='row gx-5 gx-xl-10'>
      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px"></app-new-charts-widget8> */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />

      </div>
    </div> */}
    {/* end::Row */}

    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        {/* <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        /> */}
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={metric?.most_booked} />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget12 className='card-xxl-stretch mb-5 mb-xxl-8' data={bookings && bookings.length ? bookings?.filter((booking) => booking.type === "upcoming").slice(0, 5).sort((a, b) => moment(a.start_date).diff(b.start_date)) : []} />
      </div>
    </div>
  </>
)
}

const DashboardWrapper  = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
