import "./style.css";
import axios from "axios";
import { toast } from "react-toastify";

const DeleteOrgModalForm = ({
  idToDelete,
  setIdToDelete,
  setOpenDeleteModal,
  fetchOrganizations,
}) => {

  const handleDeleteOrg = () => {
    axios.delete(`${process.env.REACT_APP_API_V3_URL}/org/${idToDelete}`)
      .then((res) => {
        toast.success("Organization have been deleted");
        fetchOrganizations();
        setOpenDeleteModal(false);
        setIdToDelete(null);
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  }


  return (
    <>
    <div>
      <h4>
        Are you sure you want to{" "}
        <span style={{ color: "#ed2626", fontWeight: 700 }}>
          Delete
        </span>{" "}
        this Organization?
      </h4>
      <div className="text-center pt-5">
        <button
          type="reset"
          onClick={() => {
            setOpenDeleteModal(false);
            setIdToDelete(null);
          }}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn"
          style={{ backgroundColor: "#ed2626", color: "#fff" }}
          data-kt-users-modal-action="submit"
          onClick={handleDeleteOrg}
        >
          <span className="indicator-label">Delete Organization</span>
        </button>
      </div>
    </div>
  </>
  );
};

export { DeleteOrgModalForm };
