import { Pagination } from "../../../../components/Pagination";
import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import dayjs from "dayjs";

const headers = [
  { title: 'Admin Id', className: 'w-200'},
  { title: 'Admin Name', className: 'w-200'},
  { title: 'Date', className: 'w-200'},
  { title: 'Message', className: 'w-300'},
  // { title: 'Type', className: 'w-200'}
]

export const MessagesTable = ({ messages, total, isLoading, page, setPage, pageSize, setPageSize, currentUser, tab, haveAccess }) => {
  return (
    (haveAccess(currentUser,  (tab === 'email' ? 'bulk_msg_list' : 'bulk_sms_list'))) ?
   <KTCardBody className='py-4'>
      <h6 style={{color:'#7e8299', textAlign:'right'}}>Total {tab === 'email' ? 'Emails' : 'SMS'}: {total} </h6>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map(column => (
              <th
                key={column.title}
                className={column.className}
              >
                {column.title}
              </th>
            ))}
          </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
          {messages.length > 0 ? (
            messages.map((row, i) => (
              <tr key={row.id}>
                <td className='w-200'>{row.admin_id}</td>
                <td className='w-200'>{row.admin_name}</td>
                <td className='w-200'>{dayjs(row.date).format('YYYY-MM-DD')}</td>
                <td className='w-300'>{row.content}</td>
                {/* <td className='w-200'>{row.type === 'EMAIL' ? 'Email' : 'SMS'}</td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  No matching records found
                </div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
      />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
    :
    <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{height:'70vh', alignItems:'center', fontSize:'42px', color:'#ffa049', background:'rgba(0,0,0,0.1)'}}>
    You do not have permission to view
   </div>

  )
};
