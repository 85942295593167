// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {User} from '../../core/_models'
import moment from 'moment'
import { useAuth } from '../../../../../auth'


function formatPhone(phone) {
  //normalize string and remove all unnecessary characters
  phone = phone?.replace(/[^\d]/g, "");

  //check if number length equals to 10
  if (phone?.length == 11) {
    //reformat and return phone number
    return phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
  }
  else if (phone?.length == 10){
    return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
  }

  return null;
}

const CustomRow = ({row}) => {
  const {currentUser} = useAuth()
 return <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}      
        >
          {cell.column.id === 'phone' ? formatPhone(cell.value) : cell.column.id === 'createdAt' ? moment(cell.value).utc().format('YYYY-MM-DD') : cell.column.id === 'actions' && (currentUser.access_level == 3) ? null :  cell.render('Cell')}
        </td>
      )
    })}
  </tr>
}

export {CustomRow}
