import {UserEditModalForm} from './UserEditModalForm'


const UserEditModalFormWrapper = ({selectedListingId, setSelectedListingId}) => {
  const itemIdForUpdate = null;
   const isLoading = false;
  
  if (selectedListingId) {
    return <UserEditModalForm isUserLoading={isLoading} user={{id: undefined}} setSelectedListingId={setSelectedListingId} selectedListingId={selectedListingId} />
  }
  return null
}

export {UserEditModalFormWrapper}
