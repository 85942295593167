import { useEffect } from "react";
import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import { MenuComponent } from "../../../../_metronic/assets/ts/components";
import { KTIcon } from "../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";

const headers = [
  { title: 'Role Name', width: '170px' },
  { title: 'Role ID', width: '150px' },
  { title: 'View Finance', width: '150px' },
  { title: 'Permissions', width: '270px' },
  { title: 'Action', width: '150px', className: 'text-end' },
]

export const RolesTable = ({ roles, isLoading, haveAccess, currentUser }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    MenuComponent.reinitialization();
  }, [roles]);

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map(column => (
                <th
                  key={column.title}
                  className={column.className}
                  style={{ minWidth: column.width }}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {roles && roles.length > 0 && (
              roles.map(row => (
                <tr key={row.role_id}>
                  <td style={{ minWidth: headers[1].width }}>{row.role_name}</td>
                  <td style={{ minWidth: headers[0].width }}>{row.role_id}</td>
                  <td style={{ minWidth: headers[2].width }}>
                    {row.can_view_finance ? 'Yes' : 'No'}
                  </td>
                  <td style={{ minWidth: headers[3].width, display:'flex', flexWrap:'wrap', gap:'5px' }}>
                    {row.permissions?.slice(0,10).map((permission) => <span className="badge badge-light-primary">{permission}</span>)} {row.permissions?.length > 10 && <span className="badge badge-light-primary">More</span>}
                  </td>
                  <td style={{ minWidth: headers[4].width }} className="text-end">
                    <>
                      <span
                        className="btn btn-light btn-active-light-primary btn-sm"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <KTIcon iconName={(haveAccess(currentUser,  'admin_roles_create') || haveAccess(currentUser,  'admin_role_edit')) ? 'pencil' : 'lock-2'}  className="fs-5 m-0" />
                      </span>
                  {(haveAccess(currentUser, "admin_roles_create") || haveAccess(currentUser, "admin_roles_edit")) &&
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        <div className="menu-item px-3">
                          <a className="menu-link px-3" onClick={() => {
                            navigate(`/view-role/${row.role_id}`)
                          }}>
                            View Role
                          </a>
                        </div>
                        <div className="menu-item px-3">
                          <a className="menu-link px-3" onClick={() => {
                            navigate(`/edit-role/${row.role_id}`)
                          }}>
                            Edit Role
                          </a>
                        </div>
                      </div>
                    }
                    </>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {roles && !roles.length > 0 && 
        <div className='d-flex text-center text-gray-600 fw-bolder fs-6 w-100 align-content-center justify-content-center mt-10'>
         No matching records found
        </div>
        }
      </div>
      {/* <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
      /> */}
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};
