import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { UsersListWrapper } from './users-list/UsersList'
import { useAuth } from '../../auth'

const usersBreadcrumbs = [
  {
    title: 'User Management',
    path: '/apps/user-management/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersPage = () => {
  const { currentUser, haveAccess } = useAuth()
  return (
    // <Routes>
    //   <Route element={<Outlet />}>
    //     <Route
    //       path='users'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={usersBreadcrumbs}>Users list</PageTitle>
    //           <UsersListWrapper />
    //         </>
    //       }
    //     />
    //   </Route>
    //   <Route index element={<Navigate to='/apps/user-management/users' />} />
    // </Routes>
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Users list</PageTitle>
      {(haveAccess(currentUser, 'user_list')) ? <UsersListWrapper />
        :
        <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
          You do not have permission to view
        </div>
      }
    </>
  )
}

export default UsersPage
