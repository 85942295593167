import { useEffect, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";

const RefundsModalForm = ({
  setOpenRefundsModal
}) => {
  const [bookingId, setBookingId] = useState('');
  const [reason, setReason] = useState('');
  const [isRefund, setRefund] = useState(true);
  const [refundAmount, setRefundAmount] = useState('');
  const [isReversal, setReversal] = useState(false);
  const [notes, setNotes] = useState('');

  const [listingsVisible, setListingsVisible] = useState(false)
  const [listings, setListings] = useState([])
  const [selectedListing, setSelectedListing] = useState('')

  const cancel = () => {
    setBookingId('');
    setReason('');
    setRefund('');
    setReversal('');
    setNotes('');
    setRefundAmount('');
    setOpenRefundsModal(false);
  };

  useEffect(() => {
    setBookingId('');
    setReason('');
    setRefund('');
    setReversal('');
    setNotes('');
    setRefundAmount('');
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_API_V2_URL}/booking/cancellation?refund_amount=${refundAmount}&is_refund=${isRefund}&booking_id=${bookingId}&reason=${reason}&reversal_from_parking=${isReversal}&notes=${notes}`, {

    })
      .then(() => {
        toast.success('Cancellation request successfully created');
        cancel();
        setOpenRefundsModal(false);
      })
      .catch(err => {
        console.log(err);
        toast.error('Could not create cancellation request');
      })
  }

  return (
    <>
      <form className="form" id="kt_modal_add_user_form" autoComplete="off" onSubmit={handleSubmit} action="">
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="fv-row mb-5" style={{ position: 'relative' }}>
            <label className="required fw-bold fs-6 mb-2">Booking ID</label>
            <input
              placeholder="Enter Booking ID"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
              name="bookingId"
              value={bookingId}
              onChange={(e) => setBookingId(e.target.value)}
            />
            {(!!listings.length && listingsVisible) && (
              <div style={{
                position: 'absolute',
                backgroundColor: '#f9f9f9',
                width: '100%',
                padding: '0.775rem 1rem',
                zIndex: 2,
                borderRadius: '0.475rem',
                marginTop: '4px',
                border: '1px solid rgb(206, 206, 206)'
              }}>
                {listings.map(el => (
                  <div
                    key={el}
                    onClick={() => {
                      setSelectedListing(el);
                    }}
                    style={{
                      fontSize: '1.1rem',
                      fontWeight: 500,
                      color: '#5e6278',
                      cursor: 'pointer',
                      padding: '3px 0'
                    }}
                  >
                    {el}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="fv-row mb-5">
            <label className="required fw-bold fs-6 mb-2">Reason</label>
            <input
              placeholder="Enter Reason"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
              name="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <div className="fv-row mb-5">
            <input
              className='form-check-input'
              type='checkbox'
              data-kt-check={isRefund}
              data-kt-check-target='#kt_table_users .form-check-input'
              checked={isRefund}
              onChange={(e) => setRefund(e.target.checked)}
              style={{ marginRight: '12px' }}
            />
            <label className="fw-bold fs-6 mb-2">Cancel with Refund?</label>
          </div>
          <div className="fv-row mb-5">
            <label className="required fw-bold fs-6 mb-2">Refund Amount</label>
            <input
              placeholder="Enter Refund Amount"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
              name="date"
              value={refundAmount}
              onChange={(e) => setRefundAmount(e.target.value)}
            />
          </div>
          <div className="fv-row mb-5">
            <input
              className='form-check-input'
              type='checkbox'
              data-kt-check={isReversal}
              data-kt-check-target='#kt_table_users .form-check-input'
              checked={isReversal}
              onChange={(e) => setReversal(e.target.checked)}
              style={{ marginRight: '12px' }}
            />
            <label className="fw-bold fs-6 mb-2">Reverse Charge PM?</label>
          </div>
          <div className="fv-row mb-5">
            <label className="required fw-bold fs-6 mb-2">Notes</label>
            <input
              placeholder="Enter Notes"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
              name="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
        </div>
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn"
            data-kt-users-modal-action="submit"
            style={{ background: "#ffa459", color: "#fff" }}
            // disabled={!propertyName}
          >
            <span className="indicator-label">Submit</span>
          </button>
        </div>
      </form>
    </>
  );
};

export { RefundsModalForm };
