import {UserEditModalForm} from './UserEditModalForm'

const UserEditModalFormWrapper = ({user, setShowEditModal, getUser}) => {
  if ( user) {
    return <UserEditModalForm  user={user} setShowEditModal={setShowEditModal} getUser={getUser} />
  }

  return null
}

export {UserEditModalFormWrapper}
