import { useEffect, useState } from "react";
import clsx from "clsx";
import { useListView } from "../core/ListViewProvider";
import { useQueryResponse } from "../core/QueryResponseProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { useDebounce, KTIcon } from "../../../../../_metronic/helpers";
import "../admin-new-modal/style.css";
import { useNavigate } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import { useAuth } from "../../../auth";

const AdminEditModalForm = ({ user }) => {
  const {currentUser, haveAccess} = useAuth();
  const navigate = useNavigate();
  const { setItemIdForUpdate, modalType, setIsModalType, itemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState();
  const [roles, setRoles] = useState([]);
  const [roleSearch, setRoleSearch] = useState("");
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState({
    name: "",
    id: ""
  });
  const [listings, setListings] = useState([]);
  const [access, setAccess] = useState([]);

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
      setPhone(user.phone_number);
      setRole(user?.role_id);
      setStatus(user.is_disabled);
      setAccess(user.access);
    }
  }, [user]);

  const cancel = (withRefresh) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
    setIsModalType("");
    setListings([]);
    setSearch({
      name: "",
      id: ""
    });
    setRoles([]);
    setRoleSearch("");
    setRole();
    setAccess([]);
  };


  const fetchRoles = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_V2_URL}/roles`);
      setRoles(data);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Could not fetch roles');
    }
  }


  useEffect(() => {
    fetchRoles()
  }, [])


  const fetchListing = () => {
    debouncedSearchTerm &&
    axios.get(`${process.env.REACT_APP_API_URL}/listing?page=1&page_size=10&name=${debouncedSearchTerm}`)
      .then((res) => {
        setListings(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const debouncedSearchTerm = useDebounce(search.name, 250);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      fetchListing();
    }
  }, [debouncedSearchTerm]);

  const onEdit = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}/admin/${itemIdForUpdate.id}`, {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phone,
      role
    })
      .then((res) => {
        toast.success("User has been updated");
        cancel(true);
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  const addAccess = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/admin/${user.id}/access`, {
      listing_id: search.id
    })
      .then(() => {
        toast.success("Access has been added");
        cancel(true);
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  const changeRole = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}/admin/${itemIdForUpdate.id}/role`, { role_id: role })
      .then(() => {
        toast.success("Role has been updated");
        cancel(true);
      })
      .catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  const changeStatus = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}/admin/${itemIdForUpdate.id}/status`, {
      is_disabled: !status
    })
      .then(() => {
        toast.success("Status has been updated");
        cancel(true);
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  const deleteAdmin = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/admin/${itemIdForUpdate.id}`)
      .then(() => {
        toast.success("Admin User have been deleted");
        cancel(true);
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  const revokeAccess = (id, name) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/admin/${user.id}/access/${id}`)
      .then(() => {
        refetch();
        toast.success(`${name} has been revoked`);
        setAccess((prev) => prev.filter((item) => item.access_id !== id));
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  return (
    <>
      {modalType === "EDIT" && (
        <>
          <div
            className="d-flex flex-column scroll-y me-n7 pe-7"
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">First Name</label>
              <input
                placeholder="First name"
                type="text"
                name="name"
                className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Last Name</label>
              <input
                placeholder="Last name"
                type="text"
                name="name"
                className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Email</label>
              <input
                placeholder="Email"
                className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                type="email"
                name="email"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => {
                  if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                    setEmail(email);
                  } else {
                    setEmail("");
                    toast.error("Please Enter Valid Email");
                  }
                }}
              />
            </div>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Phone</label>
              <PatternFormat
                format={"+1 (###) ###-####"}
                className={"form-control form-control-solid mb-3 mb-lg-0"}
                value={phone}
                onValueChange={(values) => setPhone(values.value)}
                placeholder={"Phone Number"}
              />
            </div>
          </div>
          <div className="text-center pt-15">
            <button
              type="reset"
              onClick={cancel}
              className="btn btn-light mx-3"
              data-kt-users-modal-action="cancel"
            >
              Discard
            </button>
            <button
              type="submit"
              className="btn"
              data-kt-users-modal-action="submit"
              style={{ background: "#ffa459", color: "#fff" }}
              disabled={!firstName || !lastName || !email}
              onClick={onEdit}
            >
              <span className="indicator-label">Submit</span>
            </button>
          </div>
        </>
      )}

      {modalType === "CHANGE_ROLE" && (
        <>    
          {role && (
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Selected Role</label>
                <div
                  className="selected col-12"
                  style={{ position: "relative", marginBottom: "5px" }}
                >
                  <input
                    placeholder="Role"
                    type="text"
                    name="name"
                    className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                    disabled
                    value={roles.find((r) => r.role_id === role)?.role_name}
                    autoComplete="off"
                  />
                  <button
                    className="remove"
                    style={{ right: "10px", top: "12px" }}
                    onClick={() =>
                      setRole()
                    }
                  >
                    <KTIcon iconName="cross" />
                  </button>
                </div>
            </div>
          )}
          {!role && (
            <div className="manualForm fv-row mb-7">
              <label className="required form-label fw-bolder text-dark fs-6 mb-0">
                Select Role
              </label>
              <span style={{ color: role?.role_id ? "#2ecc71" : "#ed2626" }}>
                {role?.role_id ? "Role selected" : "Role Not selected"}
              </span>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control form-control-solid w-100 "
                  placeholder="Search Role Name"
                  value={roleSearch}
                  onChange={(e) => setRoleSearch(e.target.value)}
                />
               {roleSearch && <button
                  className="remove"
                  style={{ right: "10px", top: "12px" }}
                  onClick={() => {
                    setRoleSearch('');
                  }}
                >
                  <KTIcon iconName="cross" />
                </button>
                }
              </div>
              {(roles && roles.length > 0 && !role?.role_id) && (
                <div
                  className="form-control form-control-solid w-100"
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    minHeight: "auto"
                  }}
                >
                  {roles?.filter((item) => item.role_name.replace(/\s/g, '').toLowerCase().includes(roleSearch.replace(/\s/g, '').toLowerCase())).map((item, index) => (
                    <div
                      key={index}
                      style={{ padding: "10px 5px", cursor: "pointer" }}
                      onClick={() => {
                        setRoleSearch('');
                        setRole(item?.role_id);
                      }}
                    >
                      {item.role_name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="text-center pt-5">
            <button
              type="reset"
              onClick={cancel}
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
            >
              Discard
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              style={{ background: "#ffa459", color: "#fff" }}
              data-kt-users-modal-action="submit"
              disabled={!role}
              onClick={changeRole}
            >
              <span className="indicator-label">Change</span>
            </button>
          </div>
        </>
      )}

      {modalType === "CHANGE_STATUS" && (
        <>
          <div>
            <h4>
              Are you sure you want to{" "}
              <span style={{ color: status ? "#2ecc71" : "#ed2626", fontWeight: 700 }}>
                {status ? "Enable" : "Disable"}
              </span>{" "}
              this Admin User?
            </h4>
            <div className="text-center pt-5">
              <button
                type="reset"
                onClick={cancel}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn"
                style={{ backgroundColor: status ? "#2ecc71" : "#ed2626", color: "#fff" }}
                data-kt-users-modal-action="submit"
                onClick={() => {
                  changeStatus();
                }}
              >
                <span className="indicator-label">{status ? "Enable" : "Disable"} Admin User</span>
              </button>
            </div>
          </div>
        </>
      )}

      {modalType === "DELETE" && (
        <>
          <div>
            <h4>
              Are you sure you want to{" "}
              <span style={{ color: "#ed2626", fontWeight: 700 }}>
                Delete
              </span>{" "}
              this Admin User?
            </h4>
            <div className="text-center pt-5">
              <button
                type="reset"
                onClick={cancel}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn"
                style={{ backgroundColor: "#ed2626", color: "#fff" }}
                data-kt-users-modal-action="submit"
                onClick={deleteAdmin}
              >
                <span className="indicator-label">Delete Admin User</span>
              </button>
            </div>
          </div>
        </>
      )}

      {modalType === "ADD_ACCESS" && (
        <>
          <div className="fv-row mb-7">
            <label className="required form-label fw-bolder text-dark fs-6 mb-0">
              Select Listing
            </label>
            <span style={{ color: search.id ? "#2ecc71" : "#ed2626" }}>
              {search.id ? "Listing selected" : "Listing Not selected"}
            </span>
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="Enter Listing Name"
              value={search.name}
              onChange={(e) => setSearch({
                id: "",
                name: e.target.value
              })}
            />
            {(listings && listings.length > 0 && !search.id) && (
              <div
                className="form-control form-control-solid w-100"
                style={{ maxHeight: "150px", overflowY: "auto", minHeight: "auto" }}
              >
                {listings.map((item, index) => (
                  <div
                    key={index}
                    style={{ padding: "10px 5px", cursor: "pointer" }}
                    onClick={() => {
                      setSearch({
                        id: item.listing_id,
                        name: item.title
                      });
                    }}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            )}
            <div className="text-center pt-5">
              <button
                type="reset"
                onClick={cancel}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn"
                style={{ backgroundColor: "#ffa459", color: "#fff" }}
                data-kt-users-modal-action="submit"
                onClick={addAccess}
                disabled={!search.id}
              >
                <span className="indicator-label">Add Access</span>
              </button>
            </div>
          </div>
        </>
      )}

      {modalType === "VIEW_LISTINGS" && (
        <>
          {(access?.length > 0) ? (
            <div className="fv-row mb-7">
              {access?.map((el, i) => (
                <div
                  key={i}
                  className="selected col-12"
                  style={{ position: "relative", marginBottom: "5px" }}
                >
                  <div
                    className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/listings/${el.listing_id}`)}
                  >
                    {el.listing_name}
                  </div>
                  {(haveAccess(currentUser, 'admin_revoke_access')) &&    <button
                    className="remove"
                    style={{ right: "10px", top: "12px" }}
                    onClick={() => revokeAccess(el.access_id, el.listing_name)}
                  >
                    <KTIcon iconName="cross" />
                  </button>
                  }
                </div>
              ))}
            </div>
          ) : (
            <div className="fv-row mb-7">
              <label className="required form-label fw-bolder text-dark fs-6 mb-0">
                No Listings Are Assigned
              </label>
            </div>
          )}
        </>
      )}
    </>
  );
};

export { AdminEditModalForm };
