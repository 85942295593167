import clsx from 'clsx'

const AdminCustomHeader = ({className, title, tableProps}) => {
    return (
        <th
          {...tableProps.column.getHeaderProps()}
          className={clsx(
            className,
            title === 'Name' && 'p-60'
          )}
          style={{paddingLeft: title === 'Name'? '60px' : ''}}
          // onClick={sortColumn}
        >
          {title}
        </th>
      )
    }

export { AdminCustomHeader };
