import clsx from "clsx";

const AdminInfoCell = ({ user }) => (
  <div className="d-flex align-items-center">
    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3" style={{ width: "50px", height: "50px" }}>
      {user.avatar ? (
        <div className="w-100 h-100">
          <img
            src={process.env.REACT_APP_IMAGE_URL + "300:200:" + user.user_image.slice(1)}
            alt={user.name}
            className="w-100 h-100" />
        </div>
      ) : (
        <div
          className={clsx(
            "symbol-label fs-3",
            `bg-light-primary`,
            `text-primary`
          )}
        >
          {user?.first_name?.slice(0, 1) + user?.last_name?.slice(0, 1)}
        </div>
      )}
    </div>
    <div className="d-flex flex-column mx-2">
      <span className="text-gray-800 mb-1">
        {user?.first_name} {user?.last_name}
      </span>
    </div>
  </div>
);

export { AdminInfoCell };
