/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useQueryResponseLoading, useQueryResponsePagination, useQueryResponse } from '../../core/QueryResponseProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../../../auth'
const mappedLabel = (label) => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const UsersListPagination = () => {
  const { reviewPage, setReviewPage } = useAuth()
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const { updateState, state } = useQueryRequest()
  const { refetch, response } = useQueryResponse()
  const [page, setPage] = useState(pagination.page)
  const [pageSize, setPageSize] = useState(pagination.page_size)
  const [total, setTotal] = useState(1)

  const updatePage = (type) => {
    if (type === 'dec' && reviewPage > 1 && reviewPage !== 1) {
      setPage(reviewPage - 1)
      updateState({ page: reviewPage - 1, page_size: pageSize })
      setReviewPage(reviewPage - 1)
      setTimeout(() => {
        refetch()
      }, 100)
    } else if (type === 'inc' && reviewPage >= 1) {
      if (reviewPage !== Math.ceil(total / pageSize)) {
        setPage(reviewPage + 1)
        updateState({ page: reviewPage + 1, page_size: pageSize })
        setReviewPage(reviewPage + 1)
        setTimeout(() => {
          refetch()
        }, 100)
      }
    }else{
      setPage(type)
      updateState({ page: type, page_size: pageSize })
      setReviewPage(type)
      setTimeout(() => {
        refetch()
      }, 100)
    }
    // if (!page || isLoading || pagination.page === page) {
    //   return
    // }

  }

  useEffect(() => {
    if (pageSize !== 10) {
      setPage(1)
      setReviewPage(1)
    }
  }, [pageSize])





  useEffect(() => {
    // if (pagination.page) {
    //   setPage(pagination.page)
    // }
    // if(pagination.page_size) {
    //   setPageSize(pagination.page_size)
    // }
    if (response?.data?.total) {
      setTotal(response?.data?.total)
    }
  }, [response])



  return (
    <div className='row'>
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
        <div style={{ display: "flex", gap: "10px", alignItems: 'center' }}>
          <select
            className='form-select form-select-solid fw-bolder'
            data-kt-select2='true'
            data-placeholder='Select Status'
            data-allow-clear='true'
            data-kt-user-table-filter='two-step'
            data-hide-search='true'
            onChange={(e) => {
              setPageSize(e.target.value)
              updateState({ page: 1, page_size: e.target.value })
            }}
            value={pageSize}
          >
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='40'>40</option>
            <option value='50'>50</option>
          </select>
        </div>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {reviewPage > 1 && <li

              className={clsx('page-item',
                'previous'

              )}
            >
              <a
                className={clsx('page-link', 'next', 'page-text')}
                onClick={() => updatePage('dec')}
                style={{ cursor: 'pointer' }}
                disabled={reviewPage === 1}
              >
                Previous
              </a>
            </li>

            }

            <div style={{ display: 'flex', gap: '5px', fontWeight: 700, fontSize: '14px' }}>
              <span style={{ color: '#ffa459' }}>{reviewPage}</span>/ {Math.ceil(total / pageSize)}
            </div>
            <li
              className={clsx('page-item',
                'previous',
                reviewPage === Math.ceil(total / pageSize) && 'disabled'
              )}
            >
              <a
                className={clsx('page-link')}
                onClick={() => updatePage('inc')}
                style={{ cursor: reviewPage !== Math.ceil(total / pageSize) ? "pointer" : "default", background: reviewPage !== Math.ceil(total / pageSize) ? "transparent" : "#e6e6e6", color: reviewPage !== Math.ceil(total / pageSize) ? '#5e6278' : "#b2b2b2", marginLeft: reviewPage === Math.ceil(total / pageSize) && '10px' }}
              >
                Next
              </a>
            </li>
            {Math.ceil(total / pageSize) > 5 && <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: '10px' }}>
              <h6 style={{ marginBottom: '0' }}>Go To Page: </h6>
              <select
                style={{ width: "max-content" }}
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select Status"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                onChange={(e) => updatePage(+e.target.value)}
                value={reviewPage}
              >
                {
                  [...Array(Math.ceil(total / pageSize))].map((_, i) => {
                    return (
                      <option value={i + 1}>{i + 1}</option>
                    )
                  }
                  )}
              </select>
            </div>
            }

            {/* {pagination.links
              ?.map((link) => {
                return {...link, label: mappedLabel(link.label)}
              })
              .map((link) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: pagination.page === link.page,
                    disabled: isLoading,
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                    })}
                    onClick={() => updatePage(link.page)}
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))} */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export { UsersListPagination }
