/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { initialQueryState, KTIcon, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import "./style.css"
import { PatternFormat } from "react-number-format";
import { useAuth } from '../../../../../auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { generateURL } from '../../../../../../../_metronic/helpers/utils';

const UsersListSearchComponent = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {setUserPage, userPage} = useAuth()
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState(undefined)
  const [searchBy, setSearchBy] = useState('1')
  const searchParamType = searchParams.get('search_type')
  const searchParamSearch = searchParams.get('search')
  const [lastLocation, setLastLocation] = useState(null);


useEffect(() => {
  if(searchParamType || searchParamSearch){
    setSearchBy(searchParamType ? searchParamType : '1')
    setSearchTerm(searchParamSearch ? searchParamSearch : '')
  }else{
    setSearchBy('1')
    setSearchTerm('')
  }
}, [searchParams])


  const debouncedSearchTerm = useDebounce(searchTerm, 600)

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined && searchBy) {
        const newUrl = generateURL('/users', {
          search_type: searchBy,
          search: debouncedSearchTerm
        })
        if (lastLocation && lastLocation !== newUrl) {
          navigate(newUrl)
        }
        setLastLocation(newUrl)
        if (searchBy == 1) {
          updateState({ name: debouncedSearchTerm?.trim(), page:initialQueryState.page, page_size:initialQueryState.page_size, phone:'', public_id:'', plate_number:'', truck_number:'', trailer_number:'', company_name:''})
        } else if (searchBy == 2) {
          updateState({ phone: debouncedSearchTerm?.trim(), page:initialQueryState.page, page_size:initialQueryState.page_size, name:'', public_id:'', plate_number:'', truck_number:'', trailer_number:'', company_name:''})
        }
        else if (searchBy == 3) {
          updateState({ public_id: debouncedSearchTerm?.trim(), page:initialQueryState.page, page_size:initialQueryState.page_size, phone:'', name:'', plate_number:'', truck_number:'', trailer_number:'', company_name:''})
        }
        else if (searchBy == 4) {
          updateState({ plate_number: debouncedSearchTerm?.trim(), page:initialQueryState.page, page_size:initialQueryState.page_size, phone:'', public_id:'', name:'', truck_number:'', trailer_number:'', company_name:''})
        }else if (searchBy == 5) {
          updateState({ truck_number: debouncedSearchTerm?.trim(), page:initialQueryState.page, page_size:initialQueryState.page_size, phone:'', public_id:'', plate_number:'', name:'', trailer_number:'', company_name:''})
        }else if (searchBy == 6) {
          updateState({ trailer_number: debouncedSearchTerm?.trim(), page:initialQueryState.page, page_size:initialQueryState.page_size, phone:'', public_id:'', plate_number:'', truck_number:'', name:'', company_name:''})
        }
        else if (searchBy == 7) {
          updateState({ company_name: debouncedSearchTerm?.trim(), page:initialQueryState.page, page_size:initialQueryState.page_size, phone:'', public_id:'', plate_number:'', truck_number:'', trailer_number:'', name:''})
        }

      }
    },
    [debouncedSearchTerm]
  )


  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='mx-5 dropdown-search'>
        <select
          className="form-select form-select-solid fw-bolder"
          data-kt-select2="true"
          data-placeholder="Select option"
          data-allow-clear="true"
          data-kt-user-table-filter="two-step"
          data-hide-search="true"
          value={searchBy}
          onChange={(e) => {
            setSearchTerm('')
            setSearchBy(e.target.value)
          }}
          autoComplete='off'
        >
          <option value="1" selected>
            Name
          </option>
          <option value="2">
            Phone Number
          </option>
          <option value="3">
            Member Number
          </option>
          <option value="4">
            Plate Number
          </option>
          <option value="5">
            Truck #
          </option>
          <option value="6">
            Trailer #
          </option>
          <option value="7">
            Company Name
          </option>
        </select>
      </div>

      {searchBy && <div className='d-flex align-items-center position-relative my-1 search-container'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        {searchBy !== "2" ? <input
          type={'text'}
          data-kt-user-table-filter='text'
          className='form-control form-control-solid w-250px ps-14 user-search-input'
          placeholder={`Search by ${searchBy == 1 ? 'User Name' : searchBy == 3 ? 'Member Number' : searchBy == 4 ? 'Plate Number' : searchBy == 5 ? 'Truck #' : searchBy == 6 ? 'Trailer #' : 'Company Name'}`}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            if(userPage != 1){
              setUserPage(1)
            }
          }}
          autocomplete="off"
          readOnly
          onFocus={(e) => {
            e.target.readOnly = false
          }}
          onTouchStart={(e) => {
            e.target.readOnly = false
          }}
        />
          :
          <PatternFormat
            format={"+1 (###) ###-####"}
            className={"form-control form-control-solid w-250px ps-14 user-search-input"}
            value={searchTerm}
            onValueChange={(values) => {
              setSearchTerm(values.value)
              if(userPage != 1){
                setUserPage(1)
              }
            }}
            placeholder={"Search by Phone Number"}

          />
        }
      </div>
      }
      {/* end::Search */}
    </div>
  )
}

export { UsersListSearchComponent }
