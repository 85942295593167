import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { KTCard } from "../../../_metronic/helpers";
import "./style.css";
import axios from "axios";
import { ArrearsTable } from "./table/ArrearsTable";
import { toast } from "react-toastify";
import { ArrearagesHeader } from "./header/ArrearagesHeader";
import { ArrearsModal } from "./arrearsModal/ArrearsModal";
import { useAuth } from "../auth";
import moment from "moment";
import * as XLSX from "xlsx";

const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  }
}

const Index = () => {
  const { currentUser, haveAccess } = useAuth();
  const [arrears, setArrears] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isOpenArrearsModal, setOpenArrearsModal] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchType, setSearchType] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [sort, setSort] = useState('1');

  useEffect(() => {
    setSearchValue('')
  }, [searchType])

  const fetchArrearages = async (searchTypeVal, inputVal, sortVal, pageVal, pageSizeVal) => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/arrearages?page=${pageVal}&page_size=${pageSizeVal}&sort=${sortVal}&${searchTypeVal}=${inputVal}`);
      setArrears(data.data);
      setTotal(data.total);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Could not fetch arrearages');
    } finally {
      setLoading(false)
    }
  }

  const fetchAllArrearages = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/arrearages?page=${page}&page_size=${pageSize}&sort=${sort}`);
      return data.data.map((item) => {
        const originalAmount = item.original_amount ? Number((item.original_amount / 100).toFixed(2)) : 0;
        const penalty = item.penalty ? Number((item.penalty / 100).toFixed(2)) : 0;
        const arrearagesTotal = Number(originalAmount + penalty);
        const tpcShare = Number(arrearagesTotal - (item.owner_share ? Number((item.owner_share / 100).toFixed(2)) : 0));
  
        return {
          createdAt: moment(item.createdAt).format('MMM DD, YYYY hh:mm A'),
          reservation_code: item.reservation_code,
          listing_name: item.listing_name,
          owner_name: item.owner_name,
          customer_name: item.customer_name,
          original_amount: originalAmount,
          penalty: penalty,
          arrearages_total: arrearagesTotal,
          owner_share: item.owner_share ? Number((item.owner_share / 100).toFixed(2)) : 0,
          tpc_share: tpcShare,
          created_by: item.created_by_name,
          processed_by: item.processed_by_name,
          status: item.status === 1 ? 'Paid' : item.status === 2 ? 'Failed' : item.status === 3 ? 'Declined' : 'Pending',
          notes: item.notes,
          charge_id: item.charge_id,
        };
      });  
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Could not fetch arrearages');
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = useCallback(
    debounce((searchTypeVal, searchValue, sort, page, pageSize) => {
      fetchArrearages(searchTypeVal, searchValue, sort, page, pageSize);
    }, 500), []);

  useEffect(() => {
    handleSearch(searchType, searchValue, sort, page, pageSize)
  }, [page, pageSize, sort, searchValue, trigger])

  const handleExport = async () => {
    const allArrears = await fetchAllArrearages() || [];

    if (allArrears?.length === 0) {
      toast.error("No data to export");
      return
    }

    const customHeaders = {
      createdAt: "Date",
      reservation_code: "Reservation Number",
      listing_name: "Listing Name",
      owner_name: "Owner Name",
      customer_name: "Customer Name",
      original_amount: "Arrearage",
      penalty: "Penalty",
      arrearages_total: "Arrearages Total",
      owner_share: "Owner Share",
      tpc_share: "TPC Share",
      status: "Status",
      created_by: "Created By",
      processed_by: "Processed By",
      notes: "Dates Covered/Notes",
      charge_id: "Charge ID",
    };

    const customColumnWidths = {
      createdAt: 25,
      reservation_code: 20,
      listing_name: 35,
      owner_name: 20,
      customer_name: 20,
      original_amount: 15,
      penalty: 15,
      arrearages_total: 15,
      owner_share: 15,
      tpc_share: 15,
      status: 15,
      created_by: 20,
      processed_by: 20,
      notes: 30,
      charge_id: 35,
    };

    const formattedData = allArrears?.map((row) => {
      const newRow = {};
      Object.keys(customHeaders).forEach((key) => {
        if (key in row) {   
            newRow[key] = row[key];
        }
      });
      return newRow;
    });

    const ws = XLSX.utils.json_to_sheet(formattedData, {
      header: Object.keys(customHeaders),
    });

    ws["!cols"] = Object.keys(customHeaders).map((key) => ({
      width: customColumnWidths[key] || 15
    }));

    Object.keys(customHeaders).forEach((key, index) => {
      const headerCell = ws[XLSX.utils.encode_cell({ r: 0, c: index })];
      if (headerCell) {
        headerCell.v = customHeaders[key];
      }
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Arrearages.xlsx");
  };


  return (
    (haveAccess(currentUser, 'arrear_list')) ?
      <KTCard>
        <ArrearagesHeader
          setOpenArrearsModal={setOpenArrearsModal}
          sort={sort}
          setSort={setSort}
          searchType={searchType}
          setSearchType={setSearchType}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          currentUser={currentUser}
          haveAccess={haveAccess}
          handleExport={handleExport}
        />
        <ArrearsTable
          arrears={arrears}
          total={total}
          isLoading={isLoading}
          setLoading={setLoading}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setTrigger={setTrigger}
        />
        {isOpenArrearsModal && (
          <ArrearsModal
            setOpenArrearsModal={setOpenArrearsModal}
            setTrigger={setTrigger}
          />
        )}
      </KTCard>
      :
      <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
        You do not have permission to view
      </div>
  );
};

export default Index;
