import clsx from "clsx";
import {
  useQueryResponsePagination,
  useQueryResponse
} from "../../core/QueryResponseProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../auth";

const AdminsListPagination = () => {
  const { adminPage, setAdminPage } = useAuth();
  const pagination = useQueryResponsePagination();
  const { updateState } = useQueryRequest();
  const { refetch, response } = useQueryResponse();
  const [page, setPage] = useState(pagination.page);
  const [pageSize, setPageSize] = useState(pagination.page_size);
  const [total, setTotal] = useState(1);

  const updatePage = (type) => {
    if (type === "dec" && adminPage > 1 && adminPage !== 1) {
      setPage(adminPage - 1);
      updateState({ page: adminPage - 1, page_size: pageSize });
      setAdminPage(adminPage - 1);

      setTimeout(() => {
        refetch();
      }, 100);
    } else if (type === "inc" && adminPage >= 1) {
      if (adminPage !== Math.ceil(total / pageSize)) {
        setPage(adminPage + 1);
        updateState({ page: adminPage + 1, page_size: pageSize });
        setAdminPage(adminPage + 1);

        setTimeout(() => {
          refetch();
        }, 100);
      }
    }else{
      setPage(type);
      updateState({ page: type, page_size: pageSize });
      setAdminPage(type);
      setTimeout(() => {
        refetch();
      }, 100);
    }
  };

  useEffect(() => {
    if (pageSize !== 10) {
      setPage(1);
      setAdminPage(1);
    }
  }, [pageSize]);

  useEffect(() => {
    if (response?.data?.total) {
      setTotal(response?.data?.total);
    }
  }, [response]);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <select
            className="form-select form-select-solid fw-bolder"
            data-kt-select2="true"
            data-placeholder="Select Status"
            data-allow-clear="true"
            data-kt-user-table-filter="two-step"
            data-hide-search="true"
            onChange={(e) => {
              setPageSize(e.target.value);
              updateState({ page: 1, page_size: e.target.value });
            }}
            value={pageSize}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            {adminPage > 1 && (
              <li className={clsx("page-item", "previous")}>
                <button
                  className={clsx("page-link", "next", "page-text")}
                  onClick={() => updatePage("dec")}
                  style={{ cursor: "pointer" }}
                  disabled={adminPage === 1}
                >
                  Previous
                </button>
              </li>
            )}

            <div style={{ display: "flex", gap: "5px", fontWeight: 700, fontSize: "14px" }}>
              <span style={{ color: "#ffa459" }}>{adminPage}</span>/ {Math.ceil(total / pageSize)}
            </div>
            <li
              className={clsx("page-item",
                "previous",
                adminPage === Math.ceil(total / pageSize) && "disabled"
              )}
            >
              <button
                className={clsx("page-link")}
                onClick={() => updatePage("inc")}
                style={{
                  cursor: adminPage !== Math.ceil(total / pageSize) ? "pointer" : "default",
                  background: adminPage !== Math.ceil(total / pageSize) ? "transparent" : "#e6e6e6",
                  color: adminPage !== Math.ceil(total / pageSize) ? "#5e6278" : "#b2b2b2",
                  marginLeft: adminPage === Math.ceil(total / pageSize) && "10px"
                }}
              >
                Next
              </button>
            </li>
            {Math.ceil(total / pageSize) > 5 && <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: '10px' }}>
              <h6 style={{ marginBottom: '0' }}>Go To Page: </h6>
              <select
                style={{ width: "max-content" }}
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select Status"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                onChange={(e) => updatePage(+e.target.value)}
                value={adminPage}
              >
                {
                  [...Array(Math.ceil(total / pageSize))].map((_, i) => {
                    return (
                      <option value={i + 1}>{i + 1}</option>
                    )
                  }
                  )}
              </select>
            </div>
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export { AdminsListPagination };
