import { useEffect } from "react";
import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import { MenuComponent } from "../../../../_metronic/assets/ts/components";
import { KTIcon } from "../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const headers = [
  { title: 'Date', width: '170px' },
  { title: 'Email', width: '170px' },
  { title: 'Phone', width: '150px' },
  { title: 'Reason', width: '220px' },
]

export const UserBanTable = ({ bannedUser , isLoading, page, pageSize }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    MenuComponent.reinitialization();
  }, [bannedUser]);


  function formatPhone(phone) {
    //normalize string and remove all unnecessary characters
    phone = phone?.replace(/[^\d]/g, "");
  
    //check if number length equals to 10
    if (phone?.length == 11) {
      //reformat and return phone number
      return phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
    }
    else if (phone?.length == 10){
      return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
    }
  
    return null;
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map(column => (
                <th
                  key={column.title}
                  className={column.className}
                  style={{ minWidth: column.width }}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {bannedUser && bannedUser.length > 0 && (
              bannedUser.map(row => (
                <tr key={row.id}>
                  <td style={{ minWidth: headers[1].width }}>{moment(row.createdAt).utc().format('YYYY-MM-DD')}</td>
                  <td style={{ minWidth: headers[0].width }}>{row.email}</td>
                  <td style={{ minWidth: headers[2].width }}>
                    {row.phone ? formatPhone(row.phone) : '-'}
                  </td>
                  <td style={{ minWidth: headers[3].width, display:'flex', flexWrap:'wrap', gap:'5px' }}>
                    {row.reason}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {bannedUser && !bannedUser.length > 0 && 
        <div className='d-flex text-center text-gray-600 fw-bolder fs-6 w-100 align-content-center justify-content-center mt-10'>
         No matching records found
        </div>
        }
      </div>
      {/* <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
      /> */}
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};
