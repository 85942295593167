// import GoogleMap from 'google-maps-react-markers'
import GoogleMapReact from 'google-map-react'
import { useRef, useState } from 'react'
import mapOptions from './map-options.json'
import { Marker } from './marker'
import css from './map.module.scss'
// import { createSlug } from '../../assets/helpers/createSlug.js'
import { useNavigate } from 'react-router-dom'
// import { formatPriceNoDecimal } from '../../helpers/index.js'

const VITE_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

export const Map = ({
    defaultCenter = { lat: 38.6311966, lng: -98.1633745 },
    defaultZoom = 4,
}) => {
    const navigate = useNavigate()

    const mapRef = useRef(null)

    const onGoogleApiLoaded = (map, maps) => {
        mapRef.current = map
    }

    return (
        <div className={css.mapContainer}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: VITE_GOOGLE_MAPS_API_KEY,
                    language: 'en',
                    region: 'US'
                }}
                defaultCenter={defaultCenter}
                defaultZoom={defaultZoom}
                center={defaultCenter}
                yesIWantToUseGoogleMapApiInternals
                // onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
                options={mapOptions}
            >
                <Marker
                    lat={defaultCenter.lat}
                    lng={defaultCenter.lng}
                />
            </GoogleMapReact>
        </div>
    )
}
