import { useState, useEffect, useRef } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interationPlugin from '@fullcalendar/interaction'
import moment from 'moment'
import './style.css'
import axios from 'axios'
import { toast } from "react-toastify";
import { convertUTCTimeToListingTime } from '../../../_metronic/helpers/convertTime'
const Calendar = ({ calendarData, spaceAvailable, setCalendarData, setShowModal,listingId,
    setCurrentMonth,
    isLoading,
    setSeats,
    disabledAllSeats,
    setDisabledAllSeats,
    tempSeats,
    onDisableAllSeats,
    haveAccess,
    restrictedHours=[],
    listing
}) => {
    const calendarRef = useRef()
    const [closedDays, setClosedDays] = useState([])
    const [specialClosedDays, setSpecialClosedDays] = useState([])

    useEffect(() => {
        setCalendarData(
            calendarData.map((e) => {
                if (e.booked) {
                    return {
                        ...e,
                        backgroundColor: (closedDays.includes(+convertUTCTimeToListingTime(e.start, listing.timezone_id, 0, 'd')) || specialClosedDays.includes(moment(e.start).format('YYYY-MM-DD'))) ? '#ed2626' : '#bef5d4',
                        display: 'background',
                    }
                } else {
                    return {
                        ...e,
                        backgroundColor:'#e6e6e6',
                        display: 'background',
                    }
                }
            })
        )
    }, [closedDays])

useEffect(() => {
    if(restrictedHours && restrictedHours.length > 0){
        const isClosed = restrictedHours.filter((e) => e.is_closed)
        const getClosedDays = isClosed.filter((e) => e.day_of_week !== null).map((e) => e.day_of_week);
        const getSpecialClosedDays = isClosed.filter((e) => e.day_of_year).map((e) => e.day_of_year);
        setClosedDays(getClosedDays)
        setSpecialClosedDays(getSpecialClosedDays)

    }   
},[restrictedHours, isLoading])


const getDayCellContentTitleClass = (e, closedDays, calendarData) => {
    const isFuture = e.isFuture;
    const isPast =  e.isPast
    const isToday = e.isToday
    const isClosedDay = closedDays.includes(new Date(e.date).getDay());
    const isSpecialCloseDay = specialClosedDays.includes(moment(e.date).format('YYYY-MM-DD'));
    const hasEvent = calendarData.find(el => el.start === moment(e.date).format('YYYY-MM-DD'));

    return [
        (isFuture || isToday) && (isClosedDay || isSpecialCloseDay) && 'text-white',
        hasEvent &&  isPast && (isClosedDay || isSpecialCloseDay) && 'text-white'
    ].filter(Boolean).join(' ');
};



    return <div style={{ width: '100%', margin: '0 auto 20px auto', position:'relative' }}>
    {isLoading && <div className='watermark'><h1>Loading..</h1></div>}
        <FullCalendar
            allDayText="All Day"
            ref={calendarRef}
            contentHeight={'700px'}
            dayMaxEventRows={true}
            aspectRatio={'auto'}
            showNonCurrentDates={false}
            // allDay={false}
            selectable={true}
            dayHeaderFormat={{
                weekday: 'short',
            }}
            fixedWeekCount={false}
            customButtons={{
                Prev: {
                    icon: 'chevron-left',
                    click: () => {
                        const calendarAPI = calendarRef?.current?.getApi()
                        calendarAPI?.prev()
                        setCurrentMonth(moment(calendarRef.current?.calendar?.currentData.currentDate).utc().format('YYYY-MM-DD'))
                    }
                },
                Next: {
                    icon: 'chevron-right',
                    click: () => {
                        const calendarAPI = calendarRef?.current?.getApi()
                        calendarAPI?.next()
                        setCurrentMonth(moment(calendarRef.current?.calendar?.currentData.currentDate).utc().format('YYYY-MM-DD'))
                    }
                }
            }}
            plugins={[interationPlugin, dayGridPlugin]}
            initialView="dayGridMonth"
            events={{
                events: calendarData,
                borderColor: 'transparent',
            }}
            dayCellClassNames={(e) => {
                if (e.isPast) {
                    return 'fc-day-disabled'
                }
                if((e.isFuture || e.isToday) && (closedDays.includes(new Date(e.date).getDay()) || specialClosedDays.includes(moment(e.date).format('YYYY-MM-DD')))) {
                    return 'closed-day'
                }
            }}
            dayCellContent={(e) => {
                return (
                    <div className="day-cell-content">
                        <div
                            className={`day-cell-content-date ${getDayCellContentTitleClass(e, closedDays, calendarData)}`}
                            style={{ color: e.isToday ? '#ed2626' : 'inherit' }}
                        >
                            {e.dayNumberText}
                        </div>
                        <div className={`day-cell-content-title ${getDayCellContentTitleClass(e, closedDays, calendarData)}`}>
                        { calendarData.find(
                                (el) => el.start === moment(e.date).format('YYYY-MM-DD')
                            )
                                ? calendarData.find(
                                    (el) =>
                                        el.start === moment(e.date).format('YYYY-MM-DD')
                                ).title + ' Seats'
                                : spaceAvailable 
                                    ? spaceAvailable + ' Seats'
                                    : null}
                        </div>
                       { ((e.isFuture || e.isToday) && (closedDays.includes(new Date(e.date).getDay()) || specialClosedDays.includes(moment(e.date).format('YYYY-MM-DD')))) &&
                        <div className={`day-cell-content-title ${getDayCellContentTitleClass(e, closedDays, calendarData)}`}
                        style={{top:'50%', left:'50%', transform: 'translate(-50%, -50%)', cursor:'default'}}
                        >
                        Closed
                        </div>
                        }
                    </div>
                )
            }}
            selectAllow={(e) => {
                return moment().diff(e.start, 'days') <= 0
            }}
            select={(selectionInfo) => {
            //    if(!specialClosedDays.includes(selectionInfo.startStr) && 
            //    !closedDays.includes(new Date(selectionInfo.startStr).getDay())) {
                if (calendarData.find((e) => e.start === selectionInfo.startStr) && calendarData.find((e) => e.start === selectionInfo.startStr).booked === false) {
                    axios.delete(`${process.env.REACT_APP_API_URL}/listing/${listingId}/seats?date=${selectionInfo.startStr}`).then(() => {
                        setCalendarData(
                            calendarData.filter(
                                (e) => e.start !== selectionInfo.startStr
                            )
                        )
                        toast("Exceptional Seats removed successfully", {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                          });
                    })
                } else {
                    // const seats = prompt(
                    //   `Enter Seats Available for ${moment(selectionInfo.startStr).format('dddd DD MMMM, YYYY')}`
                    // );
                    setShowModal({
                        status: true,
                        date: selectionInfo.startStr
                    })
                }
            // }
            }}
            selectLongPressDelay={0}
            headerToolbar={{
                start: 'today,Prev,Next,title', // will normally be on the left. if RTL, will be on the right
                center: '',
                end: '', // will normally be on the right. if RTL, will be on the left
            }}
            themeSystem="standard"
        />
        <div className="calendar-legend">
            <div className="calendar-legend-item">
                <div className='status-box available'></div>
                <h6>Available</h6>
            </div>
            <div className="calendar-legend-item">
                <div className='status-box closed'></div>
                <h6>Closed</h6>
            </div>
            <div className="calendar-legend-item">
                <div className='status-box'></div>
                <h6>Not Available</h6>
            </div>
            <div className="calendar-legend-item">
                <div className='status-box booked'></div>
                <h6>Booked</h6>
            </div>
             {haveAccess && <div className="form-check form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="2"
                      id="flexCheckChecked"
                      checked={disabledAllSeats}
                      onChange={(e) => {
                        if(e.target.checked) {
                            setDisabledAllSeats(true)
                            // setSeats('0')
                            onDisableAllSeats(true)
                        } else {
                            setDisabledAllSeats(false)
                            setSeats(tempSeats)
                            onDisableAllSeats(false)
                        }
                    }}
                    />
                    <label
                      className="form-check-label"
                      for="flexCheckChecked"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Disable All Seats
                    </label>
                  </div>
            }
        </div>
    </div>
}

export default Calendar
