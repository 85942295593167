import { useEffect } from "react";
import { PointsModalHeader } from "./PointsModalHeader";
import { PointsModalFormWrapper } from "./PointsModalFormWrapper";

const PointsModal = ({
  user,
  setOpenPointsModal,
}) => {
  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-800px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <PointsModalHeader
              user={user}
              setOpenPointsModal={setOpenPointsModal}
            />
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-5 my-2 p-4">
              <PointsModalFormWrapper
                user={user}
                setOpenPointsModal={setOpenPointsModal}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};

export { PointsModal };
