import { useEffect } from "react";
import { UserEditModalHeader } from "./UserEditModalHeader";
import { UserEditModalFormWrapper } from "./UserEditModalFormWrapper";

const UserEditModal = ({
  setOpenPromoCycleModal,
  promoCycleId,
  setPromoCycleId,
  getBookings
}) => {
  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-850px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <UserEditModalHeader
              setOpenPromoCycleModal={setOpenPromoCycleModal}
              setPromoCycleId={setPromoCycleId}
            />
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-5 my-2 p-4">
              <UserEditModalFormWrapper
                promoCycleId={promoCycleId}
                setPromoCycleId={setPromoCycleId}
                setOpenPromoCycleModal={setOpenPromoCycleModal}
                getBookings={getBookings}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};

export { UserEditModal };
