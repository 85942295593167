import {ExtendModalForm} from './ExtendModalForm'

const ExtendModalFormWrapper = ({setSelectedBookingId, fetchBooking, selectedBookingId, bookingDetails, setOpenExtendModal}) => {

    return <ExtendModalForm  setSelectedBookingId={setSelectedBookingId} fetchBooking={fetchBooking} selectedBookingId={selectedBookingId} bookingDetails={bookingDetails} setOpenExtendModal={setOpenExtendModal} />


}

export {ExtendModalFormWrapper}
