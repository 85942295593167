import {ArrearsModalForm} from './ArrearsModalForm'

const ArrearsModalFormWrapper = ({
    setOpenArrearsModal,
    setTrigger
}) => {
    return <ArrearsModalForm
        setOpenArrearsModal={setOpenArrearsModal}
        setTrigger={setTrigger}
    />

}

export {ArrearsModalFormWrapper}
