import { useEffect, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useListView } from "../core/ListViewProvider";
import { useQueryResponse } from "../core/QueryResponseProvider";
import {
  useDebounce,
  KTIcon
} from "../../../../../_metronic/helpers";
import "./style.css";
import { PatternFormat } from "react-number-format";

const AdminNewModalForm = () => {
  const { refetch } = useQueryResponse();
  const { setItemIdForUpdate, setIsModalType } = useListView();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [roleSearch, setRoleSearch] = useState("");
  const [search, setSearch] = useState('');
  const [listings, setListings] = useState([]);
  const [password, setPassword] = useState("");
  const [isManualFormVisible, setManualFormVisible] = useState(false);
  const [addListings, setAddListings] = useState("");
  const [roles, setRoles] = useState([]);
  const [isRestricted, setIsRestricted] = useState(false);

  const cancel = () => {
    setItemIdForUpdate(undefined);
    setIsModalType("");
  };

  useEffect(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setRole();
    setPassword("");
    setSearch('');
    setRoleSearch('')
    setListings([]);
    setAddListings("");

  }, []);

  const fetchListing = () => {
    debouncedSearchTerm &&
    axios.get(`${process.env.REACT_APP_API_URL}/listing?page=1&page_size=10&name=${debouncedSearchTerm}`)
      .then((res) => {
        setListings(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const debouncedSearchTerm = useDebounce(search, 250);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      fetchListing();
    }
  }, [debouncedSearchTerm]);


  const fetchRoles = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_V2_URL}/roles`);
      setRoles(data);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Could not fetch roles');
    }
  }


  useEffect(() => {
    fetchRoles()
  }, [])




  const createAdmin = (e) => {
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_API_URL}/admin`, {
      email,
      phone_number: phone,
      password,
      first_name: firstName,
      last_name: lastName,
      role_id: role.role_id,
      access_level: isRestricted ? 3 : 2
      // listing_ids: addListings && addListings?.length > 0 ? addListings?.map((item) => item.id) : []
    })
      .then((res) => {
        toast.success("New Admin User have been added");
        if(isRestricted && addListings?.id && res?.data) {
          axios.post(`${process.env.REACT_APP_API_URL}/admin/${res?.data?.id}/access`, {
            listing_id: addListings?.id
          }).catch((err) => {
            if (err && err.response && err.response?.data && err.response?.data?.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error("Technical Error, Please Try Again");
            }
          });
        }
        cancel();
        refetch();
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      })
  };

  return (
    <>
      <form className="form" id="kt_modal_add_user_form">
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">First Name</label>
            <input
              placeholder="First name"
              type="text"
              name="name"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">Last Name</label>
            <input
              placeholder="Last name"
              type="text"
              name="name"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="fv-row mb-7">
            <div className="input-search form-check form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                id='qr'
                checked={isRestricted}
                onChange={(e) => {
                  setIsRestricted(prev => !prev)
                  setAddListings("")
                  setSearch('')
                }}
              />
              <label
                className="form-check-label"
                htmlFor="qr"
                style={{ fontWeight: "700", color: "#3f4254" }}
              >
                Restricted Access
              </label>
            </div>
          </div>
          {/* end::Input group */}
          {(isRestricted && addListings) && (
            <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">Selected Listings</label>
              {addListings && (
                <div
                  className="selected col-12"
                  style={{ position: "relative", marginBottom: "5px" }}
                >
                  <input
                    placeholder="Last name"
                    type="text"
                    name="name"
                    className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                    disabled
                    value={addListings.name}
                    autoComplete="off"
                  />
                  <button
                    className="remove"
                    style={{ right: "10px", top: "12px" }}
                    onClick={() =>
                      setAddListings("")
                    }
                  >
                    <KTIcon iconName="cross" />
                  </button>
                </div>
              )}
            </div>
          )}
          {(!addListings && isRestricted) && (
            <div className="manualForm fv-row mb-7">
              <label className="required form-label fw-bolder text-dark fs-6 mb-0">
                Select Listing
              </label>
              <span style={{ color: search.id ? "#2ecc71" : "#ed2626" }}>
                {addListings?.id ? "Listing selected" : "Listing Not selected"}
              </span>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control form-control-solid w-100 "
                  placeholder="Enter Listing Name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
               {search && <button
                  className="remove"
                  style={{ right: "10px", top: "12px" }}
                  onClick={() => {
                    setManualFormVisible(false);
                    setSearch("");
                    setListings([]);
                  }}
                >
                  <KTIcon iconName="cross" />
                </button>
                }
              </div>
              {(listings && listings.length > 0 && !search.id) && (
                <div
                  className="form-control form-control-solid w-100"
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    minHeight: "auto"
                  }}
                >
                  {listings.map((item, index) => (
                    <div
                      key={index}
                      style={{ padding: "10px 5px", cursor: "pointer" }}
                      onClick={() => {
                        setSearch("");
                        setAddListings({ id: item.listing_id, name: item.title });
                        setManualFormVisible(false);
                        setListings([]);
                      }}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {/* {(!isManualFormVisible && isRestricted) && (
            <button
              onClick={() => setManualFormVisible(true)}
              style={{ backgroundColor: "#ffa459", color: "#fff" }}
              className="btn col-12 mb-5"
            >
              Assign Listing
            </button>
          )} */}
          {role && (
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Selected Role</label>
              <div
                className="selected col-12"
                style={{ position: "relative", marginBottom: "5px" }}
              >
                <input
                  placeholder="Last name"
                  type="text"
                  name="name"
                  className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                  disabled
                  value={role.role_name}
                  autoComplete="off"
                />
                <button
                  className="remove"
                  style={{ right: "10px", top: "12px" }}
                  onClick={() =>
                    setRole()
                  }
                >
                  <KTIcon iconName="cross" />
                </button>
              </div>
            </div>
          )}
          {!role && (
            <div className="manualForm fv-row mb-7">
              <label className="required form-label fw-bolder text-dark fs-6 mb-0">
                Select Role
              </label>
              <span style={{ color: role?.role_id ? "#2ecc71" : "#ed2626" }}>
                {role?.role_id ? "Role selected" : "Role Not selected"}
              </span>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control form-control-solid w-100 "
                  placeholder="Search Role Name"
                  value={roleSearch}
                  onChange={(e) => setRoleSearch(e.target.value)}
                />
                {roleSearch && <button
                  className="remove"
                  style={{ right: "10px", top: "12px" }}
                  onClick={() => {
                    setRoleSearch('');
                  }}
                >
                  <KTIcon iconName="cross" />
                </button>
                }
              </div>
              {(roles && roles.length > 0 && !role?.role_id) && (
                <div
                  className="form-control form-control-solid w-100"
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    minHeight: "auto"
                  }}
                >
                  {roles?.filter((item) => item.role_name.replace(/\s/g, '').toLowerCase().includes(roleSearch?.replace(/\s/g, '').toLowerCase())).map((item, index) => (
                    <div
                      key={index}
                      style={{ padding: "10px 5px", cursor: "pointer" }}
                      onClick={() => {
                        setRoleSearch('');
                        setRole(item);
                      }}
                    >
                      {item.role_name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">Email</label>
            <input
              placeholder="Email"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
              type="email"
              name="email"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => {
                if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                  setEmail(email);
                } else {
                  setEmail("");
                  toast("Please Enter Valid Email", {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                  });
                }
              }}
            />
          </div>
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">Phone</label>
            <PatternFormat
              format={"+1 (###) ###-####"}
              className={"form-control form-control-solid mb-3 mb-lg-0"}
              value={phone}
              onValueChange={(values) => setPhone(values.value)}
              placeholder={"Phone Number"}
            />
          </div>
          <div className="fv-row mb-7">
            <label className="required form-label fw-bolder text-dark fs-6 mb-0">Password</label>
            <input
              type="password"
              placeholder="Password"
              autoComplete="new-password"
              aria-autocomplete="none"
              className={"form-control form-control-solid mb-3 mb-lg-0"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={cancel}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Discard
          </button>
          <button
            type="submit"
            className="btn"
            data-kt-users-modal-action="submit"
            style={{ background: "#ffa459", color: "#fff" }}
            disabled={!firstName || !lastName || !email || !phone || !password || !role || (isRestricted && !addListings.id)}
            onClick={createAdmin}
          >
            <span className="indicator-label">Submit</span>
          </button>
        </div>
      </form>
    </>
  );
};

export { AdminNewModalForm };
