import {useEffect} from 'react'
import {DiscountModalHeader} from './DiscountModalHeader'
import {DiscountModalFormWrapper} from './DiscountModalFormWrapper'


const DiscountModal = ({bookingId, setBookingToDiscount, setOpenDiscountModal, fetchBooking}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className={`modal-dialog modal-dialog-centered mw-650px`}>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <DiscountModalHeader
              setBookingToDiscount={setBookingToDiscount}
              setOpenDiscountModal={setOpenDiscountModal}
            />
            {/*/!* begin::Modal body *!/*/}
            <div className={`modal-body scroll-y mx-5  mx-xl-15 my-7`}>
              <DiscountModalFormWrapper
                bookingId={bookingId}
                setBookingToDiscount={setBookingToDiscount}
                setOpenDiscountModal={setOpenDiscountModal}
                fetchBooking={fetchBooking}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {DiscountModal}
