import {useEffect, useState } from "react";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import "./style.css";
import axios from "axios";
import { toast } from "react-toastify";
import { RolesTable } from "./table/RolesTable";
import { RolesHeader } from "./header/RolesHeader";
import { useAuth } from "../auth";


const Index = () => {
    const {currentUser, haveAccess} = useAuth();
  const [roles, setRoles] = useState([]);
  const [Permission, setPermission] = useState([]);
  const [isLoading, setLoading] = useState(false);


  const fetchRoles = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_V2_URL}/roles`);
      setRoles(data);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Could not fetch roles');
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    fetchRoles()
  }, [])

  const permissions = [
    // {
    //   name: 'Admin',
    //   id: 1,
    //   parentId: null,
    //   label: true
    // },
    // {
    //   name: 'Listing',
    //   parentId: 1,
    //   id: 2,
    //   label: false
    // },
    // {
    //   name: 'Add',
    //   parentId: 2,
    //   id: 3,
    //   label: false
    // },
    // {
    //   name: 'Users',
    //   parentId: 1,
    //   id: 6,
    //   label: false
    // },
    // {
    //   name: 'Roles',
    //   id: 4,
    //   parentId: null,
    //   label: true
    // },
    // {
    //   name: 'Edit',
    //   parentId: 4,
    //   id: 5,
    //   label: false
    // },
    {
      "id": 1,
      "permission_name": "Admin",
      "parent": null,
      "is_label": true
  },
  {
      "id": 2,
      "permission_name": "List",
      "parent": 1,
      "is_label": false
  },
  {
      "id": 3,
      "permission_name": "Create",
      "parent": 1,
      "is_label": false
  },
  {
      "id": 4,
      "permission_name": "Edit",
      "parent": 1,
      "is_label": false
  },
  {
      "id": 5,
      "permission_name": "Delete",
      "parent": 1,
      "is_label": false
  },
  {
      "id": 6,
      "permission_name": "Edit permissions",
      "parent": 1,
      "is_label": false
  },
  {
      "id": 7,
      "permission_name": "Edit access",
      "parent": 1,
      "is_label": false
  },
  {
      "id": 8,
      "permission_name": "Revoke access",
      "parent": 1,
      "is_label": false
  },
  {
      "id": 9,
      "permission_name": "Roles",
      "parent": 1,
      "is_label": true
  },
  {
      "id": 10,
      "permission_name": "List",
      "parent": 9,
      "is_label": false
  },
  {
      "id": 11,
      "permission_name": "Create",
      "parent": 9,
      "is_label": false
  },
  {
      "id": 12,
      "permission_name": "Edit",
      "parent": 9,
      "is_label": false
  },
  {
      "id": 13,
      "permission_name": "Notification admins",
      "parent": 1,
      "is_label": true
  },
  {
      "id": 14,
      "permission_name": "List",
      "parent": 13,
      "is_label": false
  },
  {
      "id": 15,
      "permission_name": "Create",
      "parent": 13,
      "is_label": false
  },
  {
      "id": 16,
      "permission_name": "Edit",
      "parent": 13,
      "is_label": false
  },
  {
      "id": 17,
      "permission_name": "Delete",
      "parent": 13,
      "is_label": false
  },
  {
      "id": 18,
      "permission_name": "User",
      "parent": null,
      "is_label": true
  },
  {
      "id": 19,
      "permission_name": "List",
      "parent": 18,
      "is_label": false
  },
  {
      "id": 20,
      "permission_name": "Details",
      "parent": 18,
      "is_label": false
  },
  {
      "id": 21,
      "permission_name": "Create",
      "parent": 18,
      "is_label": false
  },
  {
      "id": 22,
      "permission_name": "Edit",
      "parent": 18,
      "is_label": false
  },
  {
      "id": 23,
      "permission_name": "Password reset",
      "parent": 18,
      "is_label": false
  },
  {
      "id": 24,
      "permission_name": "Delete",
      "parent": 18,
      "is_label": false
  },
  {
      "id": 25,
      "permission_name": "Edit",
      "parent": 18,
      "is_label": false
  },
  {
      "id": 26,
      "permission_name": "Logout",
      "parent": 18,
      "is_label": false
  },
  {
      "id": 27,
      "permission_name": "Send email",
      "parent": 18,
      "is_label": false
  },
  {
      "id": 28,
      "permission_name": "Send sms",
      "parent": 18,
      "is_label": false
  },
  {
      "id": 29,
      "permission_name": "Login as user",
      "parent": 18,
      "is_label": false
  },
  {
      "id": 30,
      "permission_name": "Cards",
      "parent": 18,
      "is_label": true
  },
  {
      "id": 31,
      "permission_name": "List",
      "parent": 30,
      "is_label": false
  },
  {
      "id": 32,
      "permission_name": "Add",
      "parent": 30,
      "is_label": false
  },
  {
      "id": 33,
      "permission_name": "Notification users",
      "parent": 18,
      "is_label": true
  },
  {
      "id": 34,
      "permission_name": "List",
      "parent": 33,
      "is_label": false
  },
  {
      "id": 35,
      "permission_name": "Create",
      "parent": 33,
      "is_label": false
  },
  {
      "id": 36,
      "permission_name": "Delete",
      "parent": 33,
      "is_label": false
  },
  {
      "id": 37,
      "permission_name": "Vehicles",
      "parent": 18,
      "is_label": true
  },
  {
      "id": 38,
      "permission_name": "List",
      "parent": 37,
      "is_label": false
  },
  {
      "id": 39,
      "permission_name": "Add",
      "parent": 37,
      "is_label": false
  },
  {
      "id": 40,
      "permission_name": "Add",
      "parent": 37,
      "is_label": false
  },
  {
      "id": 41,
      "permission_name": "Edit",
      "parent": 37,
      "is_label": false
  },
  {
      "id": 42,
      "permission_name": "Delete",
      "parent": 37,
      "is_label": false
  },
  {
      "id": 43,
      "permission_name": "Listings",
      "parent": null,
      "is_label": true
  },
  {
      "id": 44,
      "permission_name": "List",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 45,
      "permission_name": "Details",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 46,
      "permission_name": "Create",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 47,
      "permission_name": "Edit",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 48,
      "permission_name": "Change status",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 49,
      "permission_name": "Change fees",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 50,
      "permission_name": "Delete",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 51,
      "permission_name": "Add note",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 52,
      "permission_name": "List notes",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 53,
      "permission_name": "Upsert price",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 54,
      "permission_name": "Respond to Pending requests",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 55,
      "permission_name": "Pending videos",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 56,
      "permission_name": "Respond to Pending videos",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 57,
      "permission_name": "Create",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 58,
      "permission_name": "Tracker logs",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 59,
      "permission_name": "Remove image",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 60,
      "permission_name": "Remove timing",
      "parent": 43,
      "is_label": false
  },
  {
      "id": 61,
      "permission_name": "Notification user",
      "parent": 43,
      "is_label": true
  },
  {
      "id": 62,
      "permission_name": "Create",
      "parent": 61,
      "is_label": false
  },
  {
      "id": 63,
      "permission_name": "Edit",
      "parent": 61,
      "is_label": false
  },
  {
      "id": 64,
      "permission_name": "Delete",
      "parent": 61,
      "is_label": false
  },
  {
      "id": 65,
      "permission_name": "Seats Exception",
      "parent": 43,
      "is_label": true
  },
  {
      "id": 66,
      "permission_name": "List",
      "parent": 65,
      "is_label": false
  },
  {
      "id": 67,
      "permission_name": "Edit",
      "parent": 65,
      "is_label": false
  },
  {
      "id": 68,
      "permission_name": "Delete",
      "parent": 65,
      "is_label": false
  },
  {
      "id": 69,
      "permission_name": "Amenities",
      "parent": 43,
      "is_label": true
  },
  {
      "id": 70,
      "permission_name": "List",
      "parent": 69,
      "is_label": false
  },
  {
      "id": 71,
      "permission_name": "Create",
      "parent": 69,
      "is_label": false
  },
  {
      "id": 72,
      "permission_name": "Edit",
      "parent": 69,
      "is_label": false
  },
  {
      "id": 73,
      "permission_name": "Sort",
      "parent": 69,
      "is_label": false
  },
  {
      "id": 74,
      "permission_name": "Bookings",
      "parent": null,
      "is_label": true
  },
  {
      "id": 75,
      "permission_name": "List",
      "parent": 74,
      "is_label": false
  },
  {
      "id": 76,
      "permission_name": "Details",
      "parent": 74,
      "is_label": false
  },
  {
      "id": 77,
      "permission_name": "Create",
      "parent": 74,
      "is_label": false
  },
  {
      "id": 78,
      "permission_name": "Edit",
      "parent": 74,
      "is_label": false
  },
  {
      "id": 79,
      "permission_name": "Extend",
      "parent": 74,
      "is_label": false
  },
  {
      "id": 80,
      "permission_name": "Cancel",
      "parent": 74,
      "is_label": true
  },
  {
      "id": 81,
      "permission_name": "List",
      "parent": 80,
      "is_label": false
  },
  {
      "id": 82,
      "permission_name": "Create",
      "parent": 80,
      "is_label": false
  },
  {
      "id": 83,
      "permission_name": "Edit",
      "parent": 80,
      "is_label": false
  },
  {
      "id": 84,
      "permission_name": "Decline",
      "parent": 80,
      "is_label": false
  },
  {
      "id": 85,
      "permission_name": "Approve",
      "parent": 80,
      "is_label": false
  },
  {
      "id": 86,
      "permission_name": "Invoice",
      "parent": 74,
      "is_label": false
  },
  {
      "id": 87,
      "permission_name": "Discount preview",
      "parent": 74,
      "is_label": false
  },
  {
      "id": 88,
      "permission_name": "Add Discount to next cycle",
      "parent": 74,
      "is_label": false
  },
  {
      "id": 89,
      "permission_name": "Promo",
      "parent": null,
      "is_label": true
  },
  {
      "id": 90,
      "permission_name": "List",
      "parent": 89,
      "is_label": false
  },
  {
      "id": 91,
      "permission_name": "List cycles",
      "parent": 89,
      "is_label": false
  },
  {
      "id": 92,
      "permission_name": "Create",
      "parent": 89,
      "is_label": false
  },
  {
      "id": 93,
      "permission_name": "Create FFA",
      "parent": 89,
      "is_label": false
  },
  {
      "id": 94,
      "permission_name": "Edit",
      "parent": 89,
      "is_label": false
  },
  {
      "id": 95,
      "permission_name": "Arrears",
      "parent": null,
      "is_label": true
  },
  {
      "id": 96,
      "permission_name": "List",
      "parent": 95,
      "is_label": false
  },
  {
      "id": 97,
      "permission_name": "Create",
      "parent": 95,
      "is_label": false
  },
  {
      "id": 98,
      "permission_name": "Reviews",
      "parent": null,
      "is_label": true
  },
  {
      "id": 99,
      "permission_name": "List",
      "parent": 98,
      "is_label": false
  },
  {
      "id": 100,
      "permission_name": "Edit",
      "parent": 98,
      "is_label": false
  },
  {
      "id": 101,
      "permission_name": "Delete",
      "parent": 98,
      "is_label": false
  },
  {
      "id": 102,
      "permission_name": "Reports",
      "parent": null,
      "is_label": true
  },
  {
      "id": 103,
      "permission_name": "reports v2",
      "parent": 102,
      "is_label": false
  },
  {
      "id": 104,
      "permission_name": "Metrics",
      "parent": 102,
      "is_label": false
  },
  {
      "id": 105,
      "permission_name": "Charts",
      "parent": 102,
      "is_label": false
  },
  {
      "id": 106,
      "permission_name": "Analytics",
      "parent": 102,
      "is_label": false
  },
  {
      "id": 107,
      "permission_name": "Blacklist",
      "parent": null,
      "is_label": true
  },
  {
      "id": 108,
      "permission_name": "List",
      "parent": 107,
      "is_label": false
  },
  {
      "id": 109,
      "permission_name": "Create",
      "parent": 107,
      "is_label": false
  },
  {
      "id": 110,
      "permission_name": "Remove",
      "parent": 107,
      "is_label": false
  },
  {
      "id": 111,
      "permission_name": "Bulk",
      "parent": null,
      "is_label": true
  },
  {
      "id": 112,
      "permission_name": "Email",
      "parent": 111,
      "is_label": true
  },
  {
      "id": 113,
      "permission_name": "List",
      "parent": 112,
      "is_label": false
  },
  {
      "id": 114,
      "permission_name": "Create",
      "parent": 112,
      "is_label": false
  },
  {
      "id": 115,
      "permission_name": "SMS",
      "parent": 111,
      "is_label": true
  },
  {
      "id": 116,
      "permission_name": "List",
      "parent": 115,
      "is_label": false
  },
  {
      "id": 117,
      "permission_name": "Create",
      "parent": 115,
      "is_label": false
  }
  ]


// Splitting array based on label: true and label: false
const labeledTrue = permissions.filter(permission => permission.label === true);
const labeledFalse = permissions.filter(permission => permission.label === false);

// Function to get children of a permission
// function getChildren(permission, permissionsArray) {
//   return permissionsArray.filter(child => child.parentId === permission.id);
// }

// // Getting children for each labeledTrue permission
// const labeledTrueWithChildren = labeledTrue.map(permission => ({
//   ...permission,
//   children: getChildren(permission, permissions)
// }));

// // Getting children for each labeledFalse permission
// const labeledFalseWithChildren = labeledFalse.map(permission => ({
//   ...permission,
//   children: getChildren(permission, permissions)
// }));

// console.log('Permissions with label true and their children:', labeledTrueWithChildren);
// console.log('Permissions with label false and their children:', labeledFalseWithChildren);

  function NestedChecklist({ permissions, parentId = null }) {
    const filteredPermissions = permissions.filter(permission => permission.parent === parentId);
    return (
      <ul>
        {filteredPermissions.map(permission => (
          <div>
            <div className="input-search mb-5 form-check form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                id={permission.id}
                checked={Permission?.find((p) => p == permission.id)}
              onChange={(e) => setPermission(e.target.checked ? [...Permission, permission.id] : Permission.filter((p) => p !== permission.id))}
              />
              <label
                className="form-check-label"
                htmlFor={permission.id}
                style={{ fontWeight: "700", color: "#3f4254" }}
              >
                {permission.permission_name}
              </label>
            </div>
            <NestedChecklist permissions={permissions} parentId={permission.id} />
          </div>
        ))}
      </ul>
      // <ul>
      //   {filteredPermissions.map(permission => (
      //     <li key={permission.id} style={{ paddingLeft: parentId ? '16px' : '0' }}>
      //       <input type="checkbox" id={permission.id} />
      //       <label htmlFor={permission.id}>{permission.name}</label>
      //       <NestedChecklist permissions={permissions} parentId={permission.id} />
      //     </li>
      //   ))}
      // </ul>
    );
  }



  return (
    <KTCard>
      <RolesHeader currentUser={currentUser} haveAccess={haveAccess} />
      {(haveAccess(currentUser,  'admin_roles_list')) ? <RolesTable
        roles={roles}
        isLoading={isLoading}
        haveAccess={haveAccess}
        currentUser={currentUser}
      />
      :
      <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{height:'70vh', alignItems:'center', fontSize:'42px', color:'#ffa049', background:'rgba(0,0,0,0.1)'}}>
      You do not have permission to view
     </div>
     }
    </KTCard>
  );
};

export default Index;
