import { useEffect, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const MessageModalForm = ({
  setShowMessageModal,
  messageType,
  getUser,
  user
}) => {
  const { id } = useParams();
  const [message, setMessage] = useState("");

  const cancel = () => {
    setMessage("");
    setShowMessageModal(false);
  };

  useEffect(() => {
    setMessage('');
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    if (messageType === 'email') {
      axios.post(`${process.env.REACT_APP_API_URL}/user/${id}/email`, { message })
        .then(() => {
          toast.success('Email sent');
          setMessage('');
          setShowMessageModal(false);
        })
        .catch(() => {
          toast.error('Could not send email');
        })
    }

    if (messageType === 'SMS') {
      axios.post(`${process.env.REACT_APP_API_URL}/user/${id}/sms`, { message })
        .then(() => {
          toast.success('SMS sent');
          setMessage('');
          setShowMessageModal(false);
        })
        .catch(() => {
          toast.error('Could not send SMS');
        })
    }

    if(messageType === 'ban') {
      axios.post(`${process.env.REACT_APP_API_V2_URL}/user/ban`, { 
        user_id: user?.id,
        reason: message
       })
      .then(() => {
        toast.success('User banned');
        setMessage('');
        setShowMessageModal(false);
        getUser()
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
    }
  }

  return (
    <>
      <form className="form" id="kt_modal_add_user_form" autoComplete="off" onSubmit={sendMessage} action="">
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="fv-row">
            <label className="required fw-bold fs-6 mb-2">{messageType === 'ban' ? 'Reason' : 'Message'}</label>
            <textarea
              placeholder="Message"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn"
            data-kt-users-modal-action="submit"
            style={{ background: "#ffa459", color: "#fff" }}
            disabled={!message}
          >
            <span className="indicator-label">Submit</span>
          </button>
        </div>
      </form>
    </>
  );
};

export { MessageModalForm };
