import { AdvocateModalForm } from "./advocateModalForm";

const AdvocateModalFormWrapper = ({
  user,
  getUser,
  setOpenAdvocateModal
}) => {

  if (user?.id) {
    return (
      <AdvocateModalForm
        getUser={getUser}
        user={user}
        setOpenAdvocateModal={setOpenAdvocateModal}
      />
    );
  }
  return null;
};

export { AdvocateModalFormWrapper };
