import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../auth";

const percentageType = {
  1: "Hourly",
  2: "Daily",
  3: "Weekly",
  4: "Monthly",
}


const CommissionModalForm = ({
  selectedListingId,
  setSelectedListingId,
  setOpenCommissionModal,
  listing,
  getListing,
}) => {
  const { currentUser } = useAuth();
  const [fees, setFees] = useState({
    1: null,
    2: null,
    3: null,
    4: null
  });

  const cancel = () => {
    setSelectedListingId();
    setOpenCommissionModal(false);
  };

  useEffect(() => {
    if (selectedListingId && listing?.platform_fees) {
      setFees(listing.platform_fees);
    }
  }, [selectedListingId]);

  const onChangePercent = () => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/fees`, {
        fees:{
          1: +fees[1],
          2: +fees[2],
          3: +fees[3],
          4: +fees[4]
        }
      })
      .then((res) => {
        toast.success("Commission updated successfully");
        setSelectedListingId();
        setOpenCommissionModal(false);
        getListing();
      })
      .catch((err) => {
        toast.error("Error Updating");
      });
  };

  const isDisabled = (fees) => {
    return Object.values(fees).some(value => value === null || value === "");
  } 


  return (
    <>
      <div className="kt_body">
        {
        Object.keys(fees) && Object.keys(fees).length > 0 &&
        Object.keys(fees).map((key) => (
          <div className="input-search mb-5" key={key}>
          <label className="form-label fs-6 fw-bold">Percentage for {percentageType[key]}</label>
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-100 "
            placeholder="Enter Commission Percentage"
            value={fees[key]}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, "");
              if(value > 100){
                toast.error("Percentage cannot be greater than 100");
              }else{
              setFees({ ...fees, [key]: +value });
              }
            }}
          />
        </div>
        ))
        }
      </div>
      <div className="text-center pt-5">
        <button
          type="reset"
          onClick={cancel}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        >
          {currentUser?.access_level !== 3 ? "Discard" : "Close"}
        </button>
        <button
            type="submit"
            className="btn"
            style={{ backgroundColor: "#ffa459", color: "#fff" }}
            data-kt-users-modal-action="submit"
            disabled={isDisabled(fees)}
            onClick={onChangePercent}
          >
            <span className="indicator-label">Change Rate</span>
        </button>
      </div>
    </>
  );
};

export { CommissionModalForm };
