import { AdminsListWrapper } from "./admins-list/AdminsList";
import { useAuth } from "../auth";

const Admins = () => {
  const { currentUser, haveAccess } = useAuth();

  return (
    <>
      {(haveAccess(currentUser, 'admin_list')) ? (
        <AdminsListWrapper />
      ) : (
        <div
          className="d-flex text-center w-100 align-content-center justify-content-center"
          style={{
            height: "70vh",
            alignItems: "center",
            fontSize: "42px",
            color: "#ffa049",
            background: "rgba(0,0,0,0.1)"
          }}
        >
          You do not have permission to view
        </div>
      )}
    </>
  );
};

export default Admins;
