import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";

const UserEditModalForm = ({
  selectedListingId,
  setSelectedListingId,
  openAdditionalUserModal,
  setOpenAdditionalUserModal,
  listing,
  getListing,
  selectedUser,
  setSelectedUser
}) => {
  const [users, setUsers] = useState([])
  // const [user1, setUser1] = useState({})
  // const [user2, setUser2] = useState({})
  // const [user3, setUser3] = useState({})

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  // const [edit, setEdit] = useState('');
  // const [editing, setEditing] = useState(false)

  const cancel = () => {
    setSelectedListingId();
    setOpenAdditionalUserModal(false);
    setSelectedUser();
    setName("");
    setEmail("");
    setPhone("");
    // setEdit('')
    // setEditing(false)
  };


useEffect(() => {
if(selectedUser){
  setUsers(listing.notification_users)
  setName(selectedUser.name)
  setEmail(selectedUser.email)
  setPhone(selectedUser.phone)
}
},[selectedUser])





  // const AddUser = (step) => {
  //   if(step === 1){
  //     setUser1({
  //       name,
  //       email,
  //       phone
  //     })
  //     setName('')
  //     setEmail('')
  //     setPhone('')
  //     setEdit('')
  //     setEditing(false)
  //   }else if (step === 2){
  //     setUser2({
  //       name,
  //       email,
  //       phone
  //     })
  //     setName('')
  //     setEmail('')
  //     setEdit('')
  //     setPhone('')
  //     setEditing(false)
  //   }else if (step === 3){
  //     setUser3({
  //       name,
  //       email,
  //       phone
  //     })
  //     setName('')
  //     setEmail('')
  //     setPhone('')
  //     setEdit('')
  //     setEditing(false)
  //   }
  // }

  // const onSave = () => {
  //   if(user1.name && !user2.name && !user3.name){
  //     setUsers([user1])
  //   }else if(user1.name && user2.name){
  //     setUsers([user2])
  //   }else if (user1.name && user2.name && user3.name){
  //     setUsers([user1, user2, user3])
  //   }
  //   if(users && users.length){
  //   axios
  //   .put(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}`, {
  //    users
  //   })
  //   .then((res) => {
  //     toast("User Updated Successfully", {
  //       position: "top-right",
  //       autoClose: 2500,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "dark",
  //     });
  //     setSelectedListingId();
  //     setOpenAdditionalUserModal(false)
  //     getListing();
  //   })
  //   .catch((err) => {
  //     toast("Error Updating", {
  //       position: "top-right",
  //       autoClose: 2500,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "dark",
  //     });
  //   });
  // }
  // }


const onAdd = () => {
  // const temp = [...users, {name, email, phone }]
  axios
  .post(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/user`, {
    name,
    email,
    phone
  })
  .then((res) => {
    toast("User Added Successfully For Sharing", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    setSelectedListingId();
    setOpenAdditionalUserModal(false)
    getListing();
    setName("");
    setEmail("");
    setPhone("");
    setUsers([])
    setSelectedUser();
  })
  .catch((err) => {
    if(err && err.response && err.response?.data && err.response?.data?.message){
      toast(err.response.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }else{
    toast("Error Adding", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  });
}

const onEdit = () => {
// const temp = users.map((user) => user.id == selectedUser.id ? {...user, name, email, phone} : user)
//   setUsers(temp)

let temp = {};
if(email !== selectedUser.email){
  temp = {name, email}
}else if(phone !== selectedUser.phone){
  temp = {name, phone}
}else if (email !== selectedUser.email && phone !== selectedUser.phone){
  temp = {name, email, phone}
}else{
  temp = {name}
}


  axios
    .put(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/user/${selectedUser.id}`, temp)
    .then((res) => {
      toast("User Updated Successfully For Sharing", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setSelectedListingId();
      setOpenAdditionalUserModal(false)
      getListing();
      setName("");
      setEmail("");
      setPhone("");
      setUsers([])
      setSelectedUser();
    })
    .catch((err) => {
      if(err && err.response && err.response?.data && err.response?.data?.message){
        toast(err.response.data.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }else{
      toast("Error Updating", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    });
}

const onDelete = () => {
  axios
  .delete(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/user/${selectedUser.id}`)
  .then((res) => {
    toast("User Deleted Successfully For Sharing", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    setSelectedListingId();
    setOpenAdditionalUserModal(false)
    getListing();
    setName("");
    setEmail("");
    setPhone("");
    setUsers([])
    setSelectedUser();
  })
  .catch((err) => {
    if(err && err.response && err.response?.data && err.response?.data?.message){
      toast(err.response.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }else{
    toast("Error Deleting", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  });
}

  return (
    //     <>
    //       <div className="kt_body">
    //       {/* <div style={{width:'100%', display:'flex', flexWrap:'wrap', marginBottom:'10px'}}>
    //               <div style={{border:'2px solid #ffa459', display:'grid', padding:'10px', width:'auto'}}>
    //                 <div>
    //                   <h6>Name</h6>
    //                   <h5>Test User</h5>
    //                 </div>
    //                 <div>
    //                   <h6>Email</h6>
    //                   <h5>Test+k7@ozandac.com</h5>
    //                 </div>
    //                 <div>
    //                   <h6>Phone Number</h6>
    //                   <h5>+1 9032112 3321</h5>
    //                 </div>
    //               </div>
    //             </div> */}
    //           <div style={{display:'flex', flexDirection:'column', gap:'15px'}}>
    //           <div style={{border:'1px dotted #4a4a4a', padding:'20px 20px', width:'100%'}}>
    //             <h3 style={{color:'#ffa459'}}>User 1</h3>
    //             <div className="input-search mb-5">
    //               <label className="form-label fs-6 fw-bold">Name</label>
    //               <input
    //                 type="text"
    //                 data-kt-user-table-filter="search"
    //                 className="form-control form-control-solid w-100 "
    //                 placeholder="Name"
    //                 defaultValue={user1.name}
    //                 disabled={!user1.name || edit === 1 ? false : true}
    //                 onChange={(e) => setName(e.target.value)}
    //               />
    //             </div>
    //             <div className="input-search mb-5">
    //               <label className="form-label fs-6 fw-bold">
    //                 Email
    //               </label>
    //               <input
    //                 type="email"
    //                 data-kt-user-table-filter="search"
    //                 className="form-control form-control-solid w-100 "
    //                 placeholder="Email"
    //                 defaultValue={user1.email}
    //                 onChange={(e) => setEmail(e.target.value)}
    //                 disabled={!user1.email || edit === 1 ? false : true}
    //               />
    //             </div>
    //             <div className="input-search mb-5">
    //               <label className="form-label fs-6 fw-bold">
    //                 Phone Number
    //               </label>
    //               <PatternFormat
    //             format={"+1 (###) ###-####"}
    //             className={"form-control form-control-solid mb-3 mb-lg-0"}
    //             defaultValue={user1.phone}
    //             onValueChange={(values) => setPhone(values.value)}
    //             placeholder={"Phone Number"}
    //             disabled={!user1.phone || edit === 1 ? false : true}

    //           />
    //             </div>
    //             <div style={{display:'flex', gap:'10px'}}>
    //      {user1.name &&       <>
    //             <button
    //             type="submit"
    //             className="btn btn-light me-3"
    //             style={{ width:'100%' }}
    //             data-kt-users-modal-action="submit"
    //             onClick={() => {
    //               setEdit('')
    //               setEditing(false)
    //             }}
    //           >
    //             <span className="indicator-label">Cancel</span>
    //           </button>
    //           <button
    //             type="submit"
    //             className="btn"
    //             style={{ backgroundColor: "#ffa459", color: "#fff", width:'100%' }}
    //             data-kt-users-modal-action="submit"
    //             disabled={editing && (!name || !email || !phone) ? true : false}
    //             onClick={() => {
    //               if(!editing){
    //               setEdit(1)
    //               setEditing(true)
    //               setName(user1.name)
    //               setEmail(user1.email)
    //               setPhone(user1.phone)
    //               }else{
    //                 AddUser(1)
    //               }
    //             }}
    //           >
    //             <span className="indicator-label">{editing && edit === 1 ? 'Save Changes' : 'Edit User 1'}</span>
    //           </button>
    //           </>
    // }
    //          {!user1.name &&   <button
    //             type="submit"
    //             className="btn"
    //             style={{ backgroundColor: "#ffa459", color: "#fff", width:'100%' }}
    //             data-kt-users-modal-action="submit"
    //             onClick={() => AddUser(1)}
    //             disabled={!name || !email || !phone}
    //           >
    //             <span className="indicator-label">Add User 1</span>
    //           </button>
    // }
    //           </div>
    //             </div>
    //           {user1.name &&  <div style={{border:'1px dotted #4a4a4a', padding:'20px 20px', width:'100%'}}>
    //             <h3 style={{color:'#ffa459'}}>User 2</h3>
    //             <div className="input-search mb-5">
    //               <label className="form-label fs-6 fw-bold">Name</label>
    //               <input
    //                 type="text"
    //                 data-kt-user-table-filter="search"
    //                 className="form-control form-control-solid w-100 "
    //                 placeholder="Name"
    //                 defaultValue={user2.name}
    //                 disabled={!user2.name || edit === 2 ? false : true}
    //                 onChange={(e) => setName(e.target.value)}
    //               />
    //             </div>
    //             <div className="input-search mb-5">
    //               <label className="form-label fs-6 fw-bold">
    //                 Email
    //               </label>
    //               <input
    //                 type="email"
    //                 data-kt-user-table-filter="search"
    //                 className="form-control form-control-solid w-100 "
    //                 placeholder="Email"
    //                 defaultValue={user2.email}
    //                 onChange={(e) => setEmail(e.target.value)}
    //                 disabled={!user2.email || edit === 2 ? false : true}
    //               />
    //             </div>
    //             <div className="input-search mb-5">
    //               <label className="form-label fs-6 fw-bold">
    //                 Phone Number
    //               </label>
    //               <PatternFormat
    //             format={"+1 (###) ###-####"}
    //             className={"form-control form-control-solid mb-3 mb-lg-0"}
    //             defaultValue={user2.phone}
    //             onValueChange={(values) => setPhone(values.value)}
    //             placeholder={"Phone Number"}
    //             disabled={!user2.phone || edit === 2 ? false : true}

    //           />
    //             </div>
    //             <div style={{display:'flex', gap:'10px'}}>
    //      {user2.name &&       <>
    //             <button
    //             type="submit"
    //             className="btn btn-light me-3"
    //             style={{ width:'100%' }}
    //             data-kt-users-modal-action="submit"
    //             onClick={() => {
    //               setEdit('')
    //               setEditing(false)
    //             }}
    //           >
    //             <span className="indicator-label">Cancel</span>
    //           </button>
    //           <button
    //             type="submit"
    //             className="btn"
    //             style={{ backgroundColor: "#ffa459", color: "#fff", width:'100%' }}
    //             data-kt-users-modal-action="submit"
    //             disabled={editing && (!name || !email || !phone) ? true : false}
    //             onClick={() => {
    //               if(!editing){
    //               setEdit(2)
    //               setEditing(true)
    //               setName(user2.name)
    //               setEmail(user2.email)
    //               setPhone(user2.phone)
    //               }else{
    //                 AddUser(2)
    //               }
    //             }}
    //           >
    //             <span className="indicator-label">{editing && edit === 2 ? 'Save Changes' : 'Edit User 2'}</span>
    //           </button>
    //           </>
    // }
    //          {!user2.name &&   <button
    //             type="submit"
    //             className="btn"
    //             style={{ backgroundColor: "#ffa459", color: "#fff", width:'100%' }}
    //             data-kt-users-modal-action="submit"
    //             onClick={() => AddUser(2)}
    //             disabled={!name || !email || !phone}
    //           >
    //             <span className="indicator-label">Add User 2</span>
    //           </button>
    // }
    //           </div>
    //             </div>
    // }
    // {user2.name &&  <div style={{border:'1px dotted #4a4a4a', padding:'20px 20px', width:'100%'}}>
    //             <h3 style={{color:'#ffa459'}}>User 3</h3>
    //             <div className="input-search mb-5">
    //               <label className="form-label fs-6 fw-bold">Name</label>
    //               <input
    //                 type="text"
    //                 data-kt-user-table-filter="search"
    //                 className="form-control form-control-solid w-100 "
    //                 placeholder="Name"
    //                 defaultValue={user3.name}
    //                 disabled={!user3.name || edit === 3 ? false : true}
    //                 onChange={(e) => setName(e.target.value)}
    //               />
    //             </div>
    //             <div className="input-search mb-5">
    //               <label className="form-label fs-6 fw-bold">
    //                 Email
    //               </label>
    //               <input
    //                 type="email"
    //                 data-kt-user-table-filter="search"
    //                 className="form-control form-control-solid w-100 "
    //                 placeholder="Email"
    //                 defaultValue={user3.email}
    //                 onChange={(e) => setEmail(e.target.value)}
    //                 disabled={!user3.email || edit === 3 ? false : true}
    //               />
    //             </div>
    //             <div className="input-search mb-5">
    //               <label className="form-label fs-6 fw-bold">
    //                 Phone Number
    //               </label>
    //               <PatternFormat
    //             format={"+1 (###) ###-####"}
    //             className={"form-control form-control-solid mb-3 mb-lg-0"}
    //             defaultValue={user3.phone}
    //             onValueChange={(values) => setPhone(values.value)}
    //             placeholder={"Phone Number"}
    //             disabled={!user3.phone || edit === 3 ? false : true}

    //           />
    //             </div>
    //             <div style={{display:'flex', gap:'10px'}}>
    //      {user3.name &&       <>
    //             <button
    //             type="submit"
    //             className="btn btn-light me-3"
    //             style={{ width:'100%' }}
    //             data-kt-users-modal-action="submit"
    //             onClick={() => {
    //               setEdit('')
    //               setEditing(false)
    //             }}
    //           >
    //             <span className="indicator-label">Cancel</span>
    //           </button>
    //           <button
    //             type="submit"
    //             className="btn"
    //             style={{ backgroundColor: "#ffa459", color: "#fff", width:'100%' }}
    //             data-kt-users-modal-action="submit"
    //             disabled={editing && (!name || !email || !phone) ? true : false}
    //             onClick={() => {
    //               if(!editing){
    //               setEdit(3)
    //               setEditing(true)
    //               setName(user3.name)
    //               setEmail(user3.email)
    //               setPhone(user3.phone)
    //               }else{
    //                 AddUser(3)
    //               }
    //             }}
    //           >
    //             <span className="indicator-label">{editing && edit === 3 ? 'Save Changes' : 'Edit User'}</span>
    //           </button>
    //           </>
    // }
    //          {!user3.name &&   <button
    //             type="submit"
    //             className="btn"
    //             style={{ backgroundColor: "#ffa459", color: "#fff", width:'100%' }}
    //             data-kt-users-modal-action="submit"
    //             onClick={() => AddUser(3)}
    //             disabled={!name || !email || !phone}
    //           >
    //             <span className="indicator-label">Add User 3</span>
    //           </button>
    // }
    //           </div>
    //             </div>
    // }

    //           </div>
    //       </div>

    //       <div className="text-center pt-5">
    //         <button
    //           type="reset"
    //           onClick={() => cancel()}
    //           className="btn btn-light me-3"
    //           data-kt-users-modal-action="cancel"
    //           // disabled={formik.isSubmitting || isUserLoading}
    //         >
    //           Discard
    //         </button>
    //           <button
    //             type="submit"
    //             className="btn"
    //             style={{ backgroundColor: "#ffa459", color: "#fff" }}
    //             data-kt-users-modal-action="submit"
    //             disabled={!user1.name}
    //             onClick={onSave}
    //           >
    //             <span className="indicator-label">Save Users</span>
    //           </button>

    //       </div>
    //       {/* end::Actions */}
    //       {/* </form> */}
    //     </>
    <>
      <div className="kt_body">
        <div className="input-search mb-5">
          <label className="form-label fs-6 fw-bold">Name</label>
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-100 "
            placeholder="Name"
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="input-search mb-5">
          <label className="form-label fs-6 fw-bold">Email</label>
          <input
            type="email"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-100 "
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => {
              if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)){
                setEmail(email)
              }else{
                setEmail('')
                toast("Please Enter Valid Email", {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }
            }}
          />
        </div>
        <div className="input-search mb-5">
          <label className="form-label fs-6 fw-bold">Phone Number</label>
          <PatternFormat
            format={"+1 (###) ###-####"}
            className={"form-control form-control-solid mb-3 mb-lg-0"}
            value={phone}
            onValueChange={(values) => setPhone(values.value)}
            placeholder={"Phone Number"}
          />
        </div>
      </div>
           <div className="text-center pt-5">
        {selectedUser &&   <button
              type="reset"
              onClick={() => onDelete()}
              className="btn btn-danger me-3"
              data-kt-users-modal-action="cancel"
            >
              Delete
            </button>
}
           <button
              type="reset"
              onClick={() => cancel()}
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
            >
              Discard
            </button>
              <button
                type="submit"
                className="btn"
                style={{ backgroundColor: "#ffa459", color: "#fff" }}
                data-kt-users-modal-action="submit"
                disabled={name && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) ? false : name && phone?.length === 10 ? false : true}
                onClick={selectedUser ? onEdit : onAdd}
              >
                <span className="indicator-label">{selectedUser ? 'Edit User' : 'Add User'}</span>
              </button>

          </div>
    </>
  );
};

export { UserEditModalForm };
