/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { login } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import axios from 'axios'
import VerificationInput from "react-verification-input";
import "./style.css"

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}


export function Login() {
  const [loading, setLoading] = useState(false)
  const [currentState, setCurrentState] = useState('login')
  const [code, setCode] = useState('')
  const [error, setError] = useState(false)
  const [status, setStatus] = useState(false)
  const { saveAuth, setCurrentUser, auth: currentAuth } = useAuth()

  const verifyOTP = async (pin) => {
    setLoading(true)
    try {
      const { data: auth } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login/otp`, {
        session_token: currentAuth?.session_token,
        pin
      })
      saveAuth(auth)
      const { data: userData } = await axios.get(`${process.env.REACT_APP_API_URL}/auth?get_permissions=true`)
      setCurrentUser(userData)
      saveAuth({
        ...auth,
        permissions: userData.permissions,
      })
      setLoading(false)
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message)
        setLoading(false)
      } else {
        setError('Technical Error, Please Try Again')
        setLoading(false)
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password)
        saveAuth({
          session_token: auth.token,
          email: values.email
        })
        setLoading(false)
        setCurrentState('verify')
      } catch (error) {
        if (error && error.response && error.response.data && error.response.data.message) {
          setStatus(error.response.data.message)
          setSubmitting(false)
          setLoading(false)
          saveAuth(undefined)
        } else {
          saveAuth(undefined)
          setStatus('The login details are incorrect')
          setSubmitting(false)
          setLoading(false)
        }
      }
    },
  })

  const resendCode = async () => {
    setLoading(true)
    try {
      const { data: auth } = await login(formik?.getFieldProps('email').value, formik?.getFieldProps('password').value)
      saveAuth({
        session_token: auth.token,
        email: formik?.getFieldProps('email').value
      })
      setStatus('New Code Sent')
      setCode('')
      setError(false)
      setLoading(false)
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message)
        setLoading(false)
      } else {
        setError('Technical Error, Please Try Again')
        setLoading(false)
      }
    }
  }


  return currentState === 'login' ?
    (
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        </div>
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <input
            type='password'
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          {/* begin::Link */}
          <Link to='/auth/forgot-password' className='link-primary'>
            Forgot Password ?
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-2'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
    )
    :
    <form
      className='form w-100'
      id='kt_login_signin_form'
    >
      <>
      {loading && (
          <h4 className='indicator-progress text-center mb-5' style={{ display: 'block' }}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </h4>
        )}
        <h1 className='text-dark fw-bolder mb-3 text-center'>Please Verify Your Account</h1>
        {(error || status) && (
          <div className={`mb-3 alert ${error ? 'alert-danger' : 'alert-success'}`}>
            <div className='alert-text font-weight-bold'>{error || status}</div>
          </div>
        )}
        <p className='mb-3 text-dark fs-6 text-center'>A code has been sent to your email and phone number.</p>
        <h5 className='text-center mb-5 text-dark'>Enter the verification code below:</h5>
        <VerificationInput
          autoFocus
          inputProps={{ autoComplete: "one-time-code" }}
          validChars="0-9"
          length={4}
          value={code}
          
          onChange={(e) => !loading && setCode(e)}
          onComplete={(e) => {
            verifyOTP(e)
          }}
          classNames={{
            container: "container",
            character: `character ${error ? "character--error" : ""}`,
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
            characterFilled: "character--filled",

          }}
        />
        <p className='text-center mt-5 text-dark fs-6'>Didn't get a code? <span className={`link-primary fw-bolder ${!loading && 'cursor-pointer'}`}
          onClick={() => {
            !loading &&
            resendCode()
          }}
        >Resend</span></p>
      </>
    </form>
}
