import { DeleteOrgModalForm } from "./deleteOrgModalForm";

const DeleteOrgModalFormWrapper = ({
  idToDelete,
  setIdToDelete,
  setOpenDeleteModal,
  fetchOrganizations,
}) => {
  return (
    <DeleteOrgModalForm idToDelete={idToDelete} setIdToDelete={setIdToDelete} setOpenDeleteModal={setOpenDeleteModal} fetchOrganizations={fetchOrganizations} />
  );
};

export { DeleteOrgModalFormWrapper };
