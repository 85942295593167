import { KTIcon } from "../../../../../_metronic/helpers";
import { useAuth } from "../../../auth";
import { useNavigate } from "react-router-dom";

const RolesHeader = ({selectedRole}) => {
  const { currentUser, haveAccess } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="card-header border-0 pt-6 justify-content-end align-items-center" style={{ flexWrap: "nowrap" }}>
      <div className="card-toolbar" style={{ gap: "5px" }}>
        {(haveAccess(currentUser, 'admin_roles_edit')) &&
          <>
            <button
              type="button" className="btn" style={{ backgroundColor: "#ffa459", color: "white" }}
              onClick={() => {
                navigate(`/edit-role/${selectedRole?.role_id}`)
              }}
            >
              <KTIcon iconName={'pencil'} className="fs-2 text-white" />
              Edit Role
            </button>
          </>
        }
      </div>
    </div>
  );
};

export { RolesHeader };
