import { AddToBlacklistModalForm } from "./AddToBlacklistModalForm";

const AddToBlacklistModalFormWrapper = ({ setOpenModal, fetchBlacklist, type }) => {
  return (
    <AddToBlacklistModalForm
      setOpenModal={setOpenModal}
      fetchBlacklist={fetchBlacklist}
      type={type}
    />
  );
};

export { AddToBlacklistModalFormWrapper };
