
import {VehicleEditModalForm} from './VehicleEditModalForm'

const VehicleEditModalFormWrapper = ({vehicles, setSelectedBookingId, setVehicles, fetchBooking, selectedBookingId, fetchBookingId, bookingDetails}) => {

    return <VehicleEditModalForm   vehicles={vehicles} setSelectedBookingId={setSelectedBookingId} setVehicles={setVehicles} fetchBooking={fetchBooking} selectedBookingId={selectedBookingId} fetchBookingId={fetchBookingId}
      bookingDetails={bookingDetails}
    />


}

export {VehicleEditModalFormWrapper}
