import { KTIcon } from "../../../../_metronic/helpers";
import {ListingChangesFilter} from './ListingChangesFilter'

const headers = [
  { label: "Listing", key: "Listing" },
  { label: "Date", key: "date" },
  { label: "Change", key: "change" },
  { label: "Old Value", key: "old_value" },
  { label: "New Value", key: "new_value" },
  { label: "Changed By", key: "changed_by" },
  { label: "Reference", key: "reference" },
];


const ListingChangesHeader = ({
  setSearch,
  search,
  sort,
  setSort,
  filter,
  setFilter,
  handleExport,
  allChanges,
  setIsExporting,
  isExporting,
  isLoading
}) => {

  return (
    <div className="card-header border-0 pt-6">
      <div className='d-flex column-gap-3 align-items-center'>
      <select
        className="form-select form-select-solid fw-bolder w-200px"
        value={sort}
        onChange={(e) => setSort(e.target.value)}
      >
        <option value="1">
          Latest First
        </option>
        <option value="2" >
          Oldest First
        </option>
      </select>
      <div className="d-flex align-items-center position-relative my-1 search-wrapper" style={{ gap: "15px" }}>
        <div className="d-flex align-items-center position-relative my-1">
          <KTIcon
            iconName="magnifier"
            className="fs-1 position-absolute ms-6"
          />
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-300px ps-14"
            placeholder={`Search By Listing Name`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      </div>
      <div className="card-toolbar gap-3 align-items-center">
      <button
          className="btn"
          style={{ backgroundColor: "#2ecc71", color: "white" }}
          onClick={handleExport}
          // data={allChanges}
          // headers={headers}
          // filename={"Listing_Changes.csv"}
          // disabled={isExporting || isLoading}
        >
          Export
        </button>
        <ListingChangesFilter filter={filter} setFilter={setFilter}  />
      </div>
    </div>
  );
};

export { ListingChangesHeader };
