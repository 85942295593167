import { useEffect, useState } from "react";
import { PendingListingModalHeader } from "./PendingListingModalHeader";
import { PendingListingModalFormWrapper } from "./PendingListingFormWrapper";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastStyle } from "../../../../../_metronic/helpers/utils";

const PendingListingModal = ({
  selectedListingId,
  setSelectedListingId,
  listing,
  getListing,
  openAdditionalUserModal,
  setOpenAdditionalUserModal,
  openDisableModal,
  setOpenDisableModal,
  openNoteModal,
  setOpenNoteModal,
  notes,
  admins,
  adminNotes,
  noteId,
  setNoteId
}) => {
  const [note, setNote] = useState("");

  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  useEffect(() => {
    if (openNoteModal && noteId) {
      setNote(noteId?.notes)
    }
  }, [noteId])

  const deleteAdminNote = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/listing/note/${noteId.id}`).then((res) => {
      toast("Note Deleted", ToastStyle);
      getListing();
      adminNotes();
      setNoteId(null);
      setNote("");
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, ToastStyle);
      } else {
        toast("Error Deleting Admin Note", ToastStyle);
      }
    });
  }

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <PendingListingModalHeader
              setSelectedListingId={setSelectedListingId}
              openAdditionalUserModal={openAdditionalUserModal}
              setOpenAdditionalUserModal={setOpenAdditionalUserModal}
              listing={listing}
              openDisableModal={openDisableModal}
              setOpenDisableModal={setOpenDisableModal}
              openNoteModal={openNoteModal}
              setOpenNoteModal={setOpenNoteModal}
              noteId={noteId}
              setNoteId={setNoteId}
              deleteAdminNote={deleteAdminNote}
            />
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-5 my-2 p-4">
              <PendingListingModalFormWrapper
                selectedListingId={selectedListingId}
                setSelectedListingId={setSelectedListingId}
                openAdditionalUserModal={openAdditionalUserModal}
                setOpenAdditionalUserModal={setOpenAdditionalUserModal}
                listing={listing}
                getListing={getListing}
                openDisableModal={openDisableModal}
                setOpenDisableModal={setOpenDisableModal}
                openNoteModal={openNoteModal}
                setOpenNoteModal={setOpenNoteModal}
                note={note}
                setNote={setNote}
                notes={notes}
                admins={admins}
                adminNotes={adminNotes}
                noteId={noteId}
                setNoteId={setNoteId}
                deleteAdminNote={deleteAdminNote}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};

export { PendingListingModal };
