import { useNavigate } from "react-router-dom";

const ListingModalForm = ({
  selectedPromo,
  setSelectedPromo,
  setOpenListingModal
}) => {
const navigate = useNavigate()

  const cancel = () => {
    setOpenListingModal(false);
    setSelectedPromo(null);
  };


  return (
    <>
      <div className="kt_body">
        <div style={{ maxHeight: '600px', overflowY: 'auto', minHeight: 'auto', display: 'grid', gap: '10px' }}>
          {
            selectedPromo?.listings && selectedPromo?.listings?.length > 0 ?
              <>
                {selectedPromo?.listings?.map((item, index) => {
                  return <div
                    className="form-check form-check-custom form-check-solid"
                    key={index}
                    style={{
                      background: item.is_active ? "#e3feee9e" : "#f9f9f9",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      borderRadius: "10px",
                      flexWrap: "wrap",
                      cursor: "pointer"
                    }}
                    onClick={() => navigate(`/listings/${item.listing_id}`)}
                  >
                    <h5 style={{ color: "#ffa459", fontWeight: 700, marginBottom:0 }}>{item.listing_name}</h5>
                  </div>
                })
                }
              </>
              :
              <h4>No Listings Assigned</h4>
          }
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            type="button"
            className="btn mt-4 w-100 btn-primary"
            onClick={() => cancel()}
            style={{
              color: "#fff",
              height: "46px",
              fontWeight: "700",
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export { ListingModalForm };
