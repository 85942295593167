import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";

const UserEditModalForm = ({
  selectedListingId,
  setSelectedListingId,
  openAdditionalUserModal,
  setOpenAdditionalUserModal,
  listing,
  getListing,
}) => {
  const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  const isProd = stripeKey.indexOf('pk_live') === 0
  const [type, setType] = useState("");
  const [note, setNote] = useState('')

  const cancel = () => {
    setSelectedListingId();
    setOpenAdditionalUserModal(false);
    setType('')
    setNote('')
  };

  const PendingApproval = (type) => {
    if(type){
      axios
      .patch(
        `${process.env.REACT_APP_API_URL}/listing/${selectedListingId}?is_approved=true`
      )
      .then((res) => {
        toast("Approval Accepted", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        getListing();
        if (window.gtag) {
        window.gtag('event', 'listing_approved', {
          listing_id: selectedListingId,
          listing_name: listing.title,
          owner_name: `${listing?.owner_first_name} ${listing?.owner_last_name[0]}`
      })
      }
      setOpenAdditionalUserModal(false);
      setSelectedListingId();
      })
      .catch((err) => {
        if(err && err.response && err.response?.data && err.response?.data?.message){
          toast(err.response.data.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }else{
        toast("Error Pending Approval", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      });
    }else{
      axios
      .patch(
        `${process.env.REACT_APP_API_URL}/listing/${selectedListingId}?is_approved=false&note=${note}`
      )
      .then((res) => {
        toast("Approval Rejected", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        getListing();
        setOpenAdditionalUserModal(false);
        setSelectedListingId();
      })
      .catch((err) => {
        if(err && err.response && err.response?.data && err.response?.data?.message){
          toast(err.response.data.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }else{
        toast("Error Pending Approval", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      });
    }
  
  };

  return (
    <>
      <div className="kt_body">
        {!type ? (
          <div style={{ display: "flex", gap: "10px" }}>
           
            <button
              type="button"
              className="btn mt-4 w-100"
              onClick={() => setType("reject")}
              style={{
                background: "#ed2626",
                color: "#fff",
                height: "46px",
                fontWeight: "700",
              }}
            >
              Reject
            </button>
            <button
              type="button"
              className="btn mt-4 w-100"
              onClick={() => setType("accept")}
              style={{
                background: "#2ecc71",
                color: "#fff",
                height: "46px",
                fontWeight: "700",
              }}
            >
              Accept
            </button>
          </div>
        ) : (
          <>
            {type === "accept" ? (
              <div>
                <h4>
                  Are you sure you want to{" "}
                  <span style={{ color: "#2ecc71", fontWeight:700 }}>APPROVE</span> this listing
                </h4>
                <div className="text-center pt-5">
                <button
                    type="reset"
                    onClick={() => cancel()}
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                  >
                    Cancel
                  </button>
                  <button
                    type="reset"
                    onClick={() => setType('')}
                    className="btn btn-dark me-3"
                    data-kt-users-modal-action="cancel"
                  >
                    Go Back
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    id='approve'
                    style={{ backgroundColor: "#2ecc71", color: "#fff" }}
                    data-kt-users-modal-action="submit"
                    onClick={() => {
                      PendingApproval(true)
                    }}
                  >
                    <span className="indicator-label">APPROVE LISTING</span>
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <h4>
                  Are you sure you want to{" "}
                  <span style={{ color: "#ed2626", fontWeight:700 }}>REJECT</span> this listing{" "}
                </h4>
                <textarea
                  className="form-control form-control-solid w-100 mt-4"
                  placeholder="Note..."
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  // defaultValue={user2.name}
                  // disabled={!user2.name || edit === 2 ? false : true}
                  // onChange={(e) => setName(e.target.value)}
                />
                <div className="text-center pt-5">
                <button
                    type="reset"
                    onClick={() => cancel()}
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                  >
                    Cancel
                  </button>
                  <button
                    type="reset"
                    onClick={() => setType('')}
                    className="btn btn-dark me-3"
                    data-kt-users-modal-action="cancel"
                
                  >
                    Go Back
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    style={{ backgroundColor: "#ed2626", color: "#fff" }}
                    data-kt-users-modal-action="submit"
                    disabled={!note}
                    onClick={() => {
                      PendingApproval(false)
                    }}
                  >
                    <span className="indicator-label">REJECT LISTING</span>
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/* <div className="text-center pt-5">
        <button
          type="reset"
          onClick={() => cancel()}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
          // disabled={formik.isSubmitting || isUserLoading}
        >
          Discard
        </button>
        <button
          type="submit"
          className="btn"
          style={{ backgroundColor: "#ffa459", color: "#fff" }}
          data-kt-users-modal-action="submit"
        >
          <span className="indicator-label">Save Users</span>
        </button>
      </div> */}
      {/* end::Actions */}
      {/* </form> */}
    </>
  );
};

export { UserEditModalForm };
