import { useEffect, useState } from "react";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import "./style.css";
import { PatternFormat } from "react-number-format";
import { useAuth } from "../../../../auth";
import {initialQueryState, KTIcon, useDebounce } from "../../../../../../_metronic/helpers";
import { generateURL } from "../../../../../../_metronic/helpers/utils";
import { useSearchParams, useNavigate } from "react-router-dom";

const AdminsListSearchComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setAdminPage, adminPage } = useAuth();
  const { updateState } = useQueryRequest();
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [searchBy, setSearchBy] = useState("1");
  const [lastLocation, setLastLocation] = useState(null);
  const searchParamName = searchParams.get("name");

  useEffect(() => {
    if(searchParamName){
      setSearchTerm(searchParamName ? searchParamName : '');
    }else{
      setSearchTerm("");
    }
  }, [searchParams]);

  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined && searchBy) {
      const newUrl = generateURL('/admins', {
        name: debouncedSearchTerm
      })
      if (lastLocation && lastLocation !== newUrl) {
        navigate(newUrl)
      }
      setLastLocation(newUrl)
      if (searchBy === "1") {
        updateState({ name: debouncedSearchTerm?.trim(), ...initialQueryState });
      } else if (searchBy === "2") {
        updateState({ phone: debouncedSearchTerm?.trim(), ...initialQueryState });
      } else if (searchBy === "3") {
        updateState({ public_id: debouncedSearchTerm?.trim(), ...initialQueryState });
      } else if (searchBy === "4") {
        updateState({ plate_number: debouncedSearchTerm?.trim(), ...initialQueryState });
      } else if (searchBy === "5") {
        updateState({ company_name: debouncedSearchTerm?.trim(), ...initialQueryState });
      }
    } 
  }, [debouncedSearchTerm]);

  // useEffect(() => {
  //   setSearchTerm("");
  //   updateState({
  //     name: "",
  //     phone: "",
  //     public_id: "",
  //     plate_number: "",
  //     company_name: "",
  //     ...initialQueryState
  //   });
  // }, [searchBy]);

  return (
    <div className="card-title">
      <div className="mx-5 dropdown-search">
        <select
          className="form-select form-select-solid fw-bolder"
          data-kt-select2="true"
          data-placeholder="Select option"
          data-allow-clear="true"
          data-kt-user-table-filter="two-step"
          data-hide-search="true"
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value)}
        >
          <option value="1" selected>
            Name
          </option>
        </select>
      </div>

      {searchBy && (
        <div className="d-flex align-items-center position-relative my-1 search-container">
          <KTIcon iconName="magnifier" className="fs-1 position-absolute ms-6" />
          {searchBy !== "2" ? (
            <input
              type={"text"}
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14 user-search-input"
              placeholder="Search by Admin Name"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                if (adminPage != 1) {
                  setAdminPage(1);
                }
              }}
            />
          ) : (
            <PatternFormat
              format={"+1 (###) ###-####"}
              className={"form-control form-control-solid w-250px ps-14 user-search-input"}
              placeholder={"Search by Phone Number"}
              value={searchTerm}
              onValueChange={(values) => {
                setSearchTerm(values.value);
                if (adminPage != 1) {
                  setAdminPage(1);
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export { AdminsListSearchComponent };
