import { useListView } from "../../core/ListViewProvider";
import { AdminsListSearchComponent } from "./AdminsListSearchComponent";
import axios from "axios";
import FileDownload from "js-file-download";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { useAuth } from "../../../../auth";

const AdminsListHeader = () => {
  const { setItemIdForUpdate, setIsModalType } = useListView();
  const {currentUser, haveAccess} = useAuth();

  const importEmails = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/daily-report`, { responseType: "blob" })
      .then(res => {
        if (res.data) {
          FileDownload(res.data, "Daily Report.xlsx");
        }
      });
  };

  return (
    <div className="card-header border-0 pt-6">
      <AdminsListSearchComponent />
      <div className="card-toolbar" style={{ gap: "5px" }}>
        <button
          type="button"
          className="btn"
          style={{ backgroundColor: "#2ecc71", color: "white" }}
          onClick={importEmails}
        >
          Download Daily Report
        </button>

     {(haveAccess(currentUser, 'admin_create')) &&   <button
          type="button"
          className="btn"
          style={{
            backgroundColor: "#2ecc71",
            color: "white"
          }}
          onClick={() => {
            setIsModalType("NEW");
            setItemIdForUpdate();
          }}
        >
          <KTIcon iconName="plus" className="fs-2 text-white" />
          Add New Admin User
        </button>
        }
      </div>
    </div>
  );
};

export { AdminsListHeader };
