import markerPin from './marker.svg'

export const Marker = ({
    lat,
    lng,
    markerId,
    onClick,
    highlighted,
    ...props
}) => {
    return (
        <img
            className={highlighted ? 'marker highlighted' : 'marker'}
            src={markerPin}
            // eslint-disable-next-line react/no-unknown-property
            lat={lat}
            // eslint-disable-next-line react/no-unknown-property
            lng={lng}
            onClick={(e) => (onClick ? onClick(e, { markerId, lat, lng }) : null)}
            style={{ cursor: 'pointer', fontSize: 40 }}
            alt={markerId}
            {...props}
        />
    )
}
