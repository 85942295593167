import { UserEditModalForm } from "./UserEditModalForm";

const UserEditModalFormWrapper = ({
  selectedListingId,
  setSelectedListingId,
  openAdditionalUserModal,
  setOpenAdditionalUserModal,
  listing,
  getListing,
  selectedUser,
  setSelectedUser
}) => {
  const itemIdForUpdate = null;
  const isLoading = false;

  if (selectedListingId) {
    return (
      <UserEditModalForm
        isUserLoading={isLoading}
        user={{ id: undefined }}
        setSelectedListingId={setSelectedListingId}
        selectedListingId={selectedListingId}
        openAdditionalUserModal={openAdditionalUserModal}
        setOpenAdditionalUserModal={setOpenAdditionalUserModal}
        listing={listing}
        getListing={getListing}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    );
  }
  return null;
};

export { UserEditModalFormWrapper };
