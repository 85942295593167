import { useEffect, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";
import {useListView} from '../core/ListViewProvider'
import { useQueryResponse } from "../core/QueryResponseProvider";

const UserNewModalForm = () => {
  const { refetch } = useQueryResponse();
  const {setItemIdForUpdate, setIsModalType} = useListView()
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const cancel = () => {
    setItemIdForUpdate(undefined);
    setIsModalType("");
  };

  useEffect(() => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setPassword('');
  }, []);


  const createUser = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/register/email`, {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        phone: phone ? phone : null,
      })
      .then((res) => {
        toast("New User have been added", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        cancel()
        refetch()
      })
      .catch((err) => {
        if(err && err.response && err.response?.data && err.response?.data?.message){
          toast(err.response.data.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }else{
        toast("Technical Error, Please Try Again", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      });
  };


  return (
    <>
      {/* begin::Scroll */}
      <form className="form" id="kt_modal_add_user_form" autoComplete="off" onSubmit={createUser}  method="post" action="">
      <div
        className="d-flex flex-column scroll-y me-n7 pe-7"
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        <div className="fv-row mb-7">
          <label className="required fw-bold fs-6 mb-2">First Name</label>
          <input
            placeholder="First name"
            type="text"
            name="name"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete="off"
          />
        </div>

        <div className="fv-row mb-7">
          <label className="required fw-bold fs-6 mb-2">Last Name</label>
          <input
            placeholder="Last name"
            type="text"
            name="name"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            autoComplete="off"
          />
        </div>
        {/* end::Input group */}

        {/* begin::Input group */}
        <div className="fv-row mb-7">
          {/* begin::Label */}
          <label className="required fw-bold fs-6 mb-2">Email</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder="Email"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            type="email"
            name="email"
            autocomplete="off"
            // disabled={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            readOnly
            onFocus={(e) => {
              e.target.readOnly = false
            }}
            onTouchStart={(e) => {
              e.target.readOnly = false
            }}
            onBlur={() => {
              if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)){
                setEmail(email)
              }else{
                setEmail('')
                toast("Please Enter Valid Email", {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }
            }}
          />
          {/* end::Input */}
        </div>
        {/* end::Input group */}

        {/* begin::Input group */}

        <div className="fv-row mb-7">
          {/* begin::Label */}
          <label className="fw-bold fs-6 mb-2">Phone Number</label>
          <PatternFormat
            format={"+1 (###) ###-####"}
            className={"form-control form-control-solid mb-3 mb-lg-0"}
            value={phone}
            onValueChange={(values) => setPhone(values.value)}
            placeholder={"Phone Number"}
            // onPaste={false}
            autoComplete="off"
          />
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          placeholder="Password"
          autocomplete="off"
          className={"form-control form-control-solid mb-3 mb-lg-0"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          readOnly
          onFocus={(e) => {
            e.target.readOnly = false
          }}
          onTouchStart={(e) => {
            e.target.readOnly = false
          }}
        />
      </div>
      </div>
      {/* end::Scroll */}

      {/* begin::Actions */}
      <div className="text-center pt-15">
        <button
          type="reset"
          onClick={() => cancel()}
          className="btn btn-light mx-3"
          data-kt-users-modal-action="cancel"
          // disabled={formik.isSubmitting || isUserLoading}
        >
          Discard
        </button>

        <button
          type="submit"
          className="btn"
          data-kt-users-modal-action="submit"
          style={{ background: "#ffa459", color: "#fff" }}
          disabled={!firstName || !lastName || !email || !password}
          // onClick={createUser}
        >
          <span className="indicator-label">Submit</span>
        </button>
      </div>
      </form>
      {/* end::Actions */}
    </>
  );
};

export { UserNewModalForm };
