import { AddListingModalForm } from "./AddListingModalForm";

const AddListingModalFormWrapper = ({
user,
getUser,
setShowListingModal
}) => {

    return (
      <AddListingModalForm
       user={user}
        getUser={getUser}
        setShowListingModal={setShowListingModal}
      />
    );
 
};

export { AddListingModalFormWrapper };
