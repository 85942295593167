import {KTIcon} from '../../../../_metronic/helpers'
import axios from 'axios';
import { toast } from "react-toastify";


const VehicleModalHeader = ({user, setShowEditModal, setVehicle, getUser, hide, setConfirmationModal, confirmationModal}) => {


  const deleteVehicle = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/vehicle/${user.id}`).then((res) => {
      toast("Vehicle Deleted Successfully", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      getUser();
      setShowEditModal(false);
      setVehicle(null);
    })
    .catch((err) => {
      if(err && err.response && err.response?.data && err.response?.data?.message){
        toast(err.response.data.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }else{
      toast("Technical Error, Please Try Again", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    });
    }


  return (
    <div className='modal-header'>
    {user && !hide && !confirmationModal && <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() =>  {
          setConfirmationModal(true)
        //  deleteVehicle()
        }}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='trash' className='fs-1' />
      </div>
}
      <h2 className='fw-bolder'>{user && !confirmationModal ? `Edit ${user.vehicle_type == 1 ? 'Truck' : user.vehicle_type == 2 ? 'Trailer' : 'Truck + Trailer'}` : user && confirmationModal ? 'Delete Vehicle'  : 'Add Vehicle'}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() =>  {
          if(confirmationModal && user){
            setConfirmationModal(false)
          }else{
          setShowEditModal(false)
          setVehicle(null)
          }
        }}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>

    </div>
  )
}

export {VehicleModalHeader}
