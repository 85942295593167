import {useEffect} from 'react'
import {ApiModalHeader} from './ApiModalHeader'
import {ApiModalFormWrapper} from './ApiModalFormWrapper'

export const ApiModal = ({ getUser, user, setOpenAPIModal}) => {

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className={`modal-dialog modal-dialog-centered mw-650px`}>
          <div className='modal-content'>
            <ApiModalHeader setOpenAPIModal={setOpenAPIModal} />
            <div className={`modal-body scroll-y mx-5  mx-xl-15 my-7`}>
              <ApiModalFormWrapper getUser={getUser} user={user} setOpenAPIModal={setOpenAPIModal} />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'/>
    </>
  )
}
