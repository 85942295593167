import {useEffect} from 'react'
import {BookingEditModalHeader} from './BookingEditModalHeader'
import {BookingEditModalFormWrapper} from './BookingEditModalFormWrapper'
import {Clock} from "../../Listings/user-edit-modal/Clock";

const BookingEditModal = ({setSelectedBookingId, fetchBooking, selectedBookingId, setOpenEditBookingModal, fetchBookingId}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])



  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className={`modal-dialog modal-dialog-centered mw-650px`}>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <BookingEditModalHeader setSelectedBookingId={setSelectedBookingId} selectedBookingId={selectedBookingId} setOpenEditBookingModal={setOpenEditBookingModal} />

            {/* begin::Modal body */}
            <div className={`modal-body scroll-y mx-5  mx-xl-5 my-2 py-4`}>
            <Clock timezone={selectedBookingId?.timezone_id} />
              <BookingEditModalFormWrapper  setSelectedBookingId={setSelectedBookingId}  fetchBooking={fetchBooking} selectedBookingId={selectedBookingId} setOpenEditBookingModal={setOpenEditBookingModal} fetchBookingId={fetchBookingId}/>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {BookingEditModal}
