import {KTIcon} from '../../../../_metronic/helpers'


const VehicleEditModalHeader = ({setSelectedBookingId, setVehicles, selectedBookingId, bookingDetails}) => {


  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'> Update Vehicles For Reservation Code #{bookingDetails?.reservation_code}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() =>  {
          setSelectedBookingId(null)
          setVehicles([])
        }}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>

    </div>
  )
}

export {VehicleEditModalHeader}
