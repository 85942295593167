
import {VehicleSwapModalForm} from './VehicleSwapModalForm'

const VehicleSwapModalFormWrapper = ({vehicles, selectedVehicle, setSelectedVehicle, fetchBooking, selectedBookingId, fetchBookingId, bookingDetails, setSelectSwapVehicle, selectSwapVehicle}) => {

    return <VehicleSwapModalForm   vehicles={vehicles} selectedVehicle={selectedVehicle} setSelectedVehicle={setSelectedVehicle} fetchBooking={fetchBooking} selectedBookingId={selectedBookingId} fetchBookingId={fetchBookingId}
      bookingDetails={bookingDetails} setSelectSwapVehicle={setSelectSwapVehicle} selectSwapVehicle={selectSwapVehicle}
    />


}

export {VehicleSwapModalFormWrapper}
