import {useEffect} from 'react'
import {ArrearsModalHeader} from './ArrearsModalHeader'
import {ArrearsModalFormWrapper} from './ArrearsModalFormWrapper'

const ArrearsModal = ({
  setOpenArrearsModal,
  setTrigger
}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])


  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className={`modal-dialog modal-dialog-centered mw-650px`}>
          <div className='modal-content'>
            <ArrearsModalHeader
              setOpenArrearsModal={setOpenArrearsModal}
            />
            <div className={`modal-body scroll-y mx-5 mx-xl-15 my-7 `}>
              <ArrearsModalFormWrapper
                setOpenArrearsModal={setOpenArrearsModal}
                setTrigger={setTrigger}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'/>
    </>
  )
}

export {ArrearsModal}
