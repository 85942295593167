const AdminTwoStepsCell = ({ two_steps, id, finance, viewFinance, status, restrictedStatus, restricted }) => (
  <>
    {id ? (
      <div className={`badge ${two_steps ? "badge-light-success" : "badge-danger"} fw-bolder`}>
        {two_steps ? "Verified" : "Unverified"}
      </div>
    ) :
    viewFinance ? (
      <div className={`badge ${!finance ? "badge-secondary" : "badge-light-success"} fw-bolder`}>
        {finance ? "Yes" : "No"}
      </div>
    )
   :
   restrictedStatus ? (
    <div className={`badge ${restricted !== 3 ? "badge-secondary" : "badge-light-success"} fw-bolder`}>
      {restricted == 3 ? "Yes" : "No"}
    </div>
  )
   :
   status && (
      <div className={`badge ${two_steps ? "badge-secondary" : "badge-light-success"} fw-bolder`}>
        {two_steps ? "Inactive" : "Active"}
      </div>
    )}
  </>
);

export { AdminTwoStepsCell };
