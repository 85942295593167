import axios from 'axios'
import {toast} from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}`

const getUsers = (query, page) => {
  return axios
    .get(`${USER_URL}/admin?page=${page}&${query}&include_access_details=true&sort=4`)
    .then(d => d)
}

const getUserById = (id) => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response) => response.data)
    .then((response) => response)
}

const createUser = (user) => {
  return axios
    .post(USER_URL, user)
    .then((response) => response.data)
    .then((response) => response.data)
}

const updateUser = (user) => {
  return axios
    .put(`${USER_URL}/${user.id}`, user)
    .then((response) => response.data)
    .then((response) => response.data)
}

const deleteUser = (userId) => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {
    toast("User have been deleted", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
        });
  }).catch((err) => {
    if(err && err.response && err.response?.data && err.response?.data?.message){
      toast(err.response.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }else{
    toast("Technical Error, Please Try Again", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
        });
      }
  })
}

const deleteSelectedUsers = (userIds) => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser}
