// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {User} from '../../core/_models'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { convertUTCTimeToListingTime } from '../../../../../../../_metronic/helpers/convertTime'

type Props = {
  row: Row<User>
}

function formatPhone(phone) {
  //normalize string and remove all unnecessary characters
  phone = phone?.replace(/[^\d]/g, "");

  //check if number length equals to 10
  if (phone?.length == 11) {
    //reformat and return phone number
    return phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
  }
  else if (phone?.length == 10){
    return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
  }

  return null;
}

const CustomRow: FC<Props> = ({row}) => {
  const navigate = useNavigate();
 return <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          // className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
          style={{cursor: cell.column.id === 'reservation_code' ? 'pointer' : 'default'}}
          onClick={() =>{
            cell.column.id === 'reservation_code' &&
            navigate(`/bookings?search_type=reservation_code&search=${cell.row.original.reservation_code}`)
          }}  
        >
          {cell.column.id === 'listing_id' ? cell.value ? cell.value : '-' : cell.column.id === 'role' ? cell.value == 1 ? 'Super Admin' : cell.value == 3 ? 'Viewer' : cell.value == 4 ? 'Property Owner' : cell.value == 2 ? 'Editor' : cell.value == 5 ? 'Property Editor' : cell.value == 6 ? 'Property Guard' : '-' : cell.column.id === 'createdAt' || cell.column.id === 'updatedAt' ? convertUTCTimeToListingTime(cell.value, cell?.row?.original?.timezone_id, 0, "YYYY-MM-DD hh:mm A")  : cell.render('Cell')}
        </td>
      )
    })}
  </tr>
}

export {CustomRow}
