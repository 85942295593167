
import {BookingEditModalForm} from './BookingEditModalForm'

const BookingEditModalFormWrapper = ({ setSelectedBookingId, fetchBooking, selectedBookingId, setOpenEditBookingModal, fetchBookingId}) => {

    return <BookingEditModalForm  setSelectedBookingId={setSelectedBookingId} fetchBooking={fetchBooking} selectedBookingId={selectedBookingId} setOpenEditBookingModal={setOpenEditBookingModal} fetchBookingId={fetchBookingId}/>


}

export {BookingEditModalFormWrapper}
