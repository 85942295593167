/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {Link} from 'react-router-dom'


type Props = {
  className: string
  items?: any
}

const ListsWidget4: React.FC<Props> = ({className, items = []}) => {

  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Most Booked Locations</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Latest tech trends</span> */}
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Item */}
       {items.map((item:any, index: number) => {
         return (
          <div className='d-flex align-items-sm-center mb-7'>
          {/* begin::Symbol */}
          <Link to={`/listings/${item?.listing_id}`} className='symbol symbol-circle symbol-50px me-5' style={{cursor:'pointer'}}>
          <img
          src={item?.avatar ? process.env.REACT_APP_AWS_URL + item.avatar : toAbsoluteUrl('/media/svg/avatars/001-boy.svg')}
          className='w-20 h-20 align-self-end'
          alt=''
          />
          </Link>
          {/* end::Symbol */}
          {/* begin::Section */}
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <Link to={`/listings/${item?.listing_id}`} className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                {item.title.substring(0, 20)}
              </Link>
              <Link to={`/users/${item?.owner_uid}`} className='text-muted text-hover-primary fw-semibold d-block fs-7' >{item.owner_first_name} {item.owner_last_name}</Link>
            </div>
            <span className='badge fw-bold my-2 text-white' style={{background: item.status === 5
                        ? "#ed2626"
                        : item.status === 1
                        ? "#2ec771"
                        : item.status === 0
                        ? "#4a4a4a"
                        : item.status === 4
                        ? "#ffa459"
                        : "#ed2626"}}>
                      {item.status === 5
                    ? "Rejected"
                    : item.status === 1
                    ? "Active"
                    : item.status === 4
                    ? "Pending"
                    : item.status === 0
                    ? "Draft"
                    : item.status === 3
                    ? "On Hold"
                    : item.status === 2 ? "Inactive"
                    : item.status === -1 &&  "Deleted"
                  }</span>
          </div>
          {/* end::Section */}
        </div>
         )
       })}   
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget4}
