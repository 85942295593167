import { useEffect } from "react";
import { AddToBlacklistModalHeader } from "./AddToBlacklistModalHeader";
import { AddToBlacklistModalFormWrapper } from "./AddToBlacklistModalFormWrapper";

const AddToBlacklistModal = ({ setOpenModal, fetchBlacklist, type }) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className={`modal-dialog modal-dialog-centered mw-650px`}>
          <div className="modal-content">
            <AddToBlacklistModalHeader
              setOpenModal={setOpenModal}
              type={type}
            />
            <div className={`modal-body scroll-y mx-5 mx-xl-15 my-7 `}>
              <AddToBlacklistModalFormWrapper
                setOpenModal={setOpenModal}
                fetchBlacklist={fetchBlacklist}
                type={type}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

export { AddToBlacklistModal };
