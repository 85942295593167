import { KTIcon } from "../../../../_metronic/helpers";

const OrgHeader = ({search, setSearch}) => {

  return (
    <div className="card-header border-0 pt-6 align-items-center" style={{ flexWrap: "nowrap" }}>
      <div className='d-flex'>
          <div className="d-flex align-items-center position-relative">
            <KTIcon
              iconName="magnifier"
              className="fs-1 position-absolute ms-6"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-300px ps-14"
              placeholder={`Search By Organization Name`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
      </div>
    </div>
  );
};

export { OrgHeader };
