import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const OrgModalForm = ({
  setOpenOrgModal,
  getUser,
  user
}) => {
  const [name, setName] = useState('');

  const cancel = () => {
    setName('');
    setOpenOrgModal(false);
  };


  const createOrganization = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}/user/${user.uid}/b2b`, {
      org_name: name
    })
      .then(() => {
        toast.success("Organization have been created successfully for this user");
        getUser();
        cancel();
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      }); 
  }

  return (
    <>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="fv-row">
          <label className="form-label fs-6 fw-bold">Organization Name</label>
              <input
                type="text"
                className="form-control form-control-solid w-100 "
                placeholder="Enter Organization Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
          </div>
        </div>
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn"
            data-kt-users-modal-action="submit"
            style={{ background: "#ffa459", color: "#fff" }}
            disabled={!name}
            onClick={createOrganization}
          >
            <span className="indicator-label">Create</span>
          </button>
        </div>
    </>
  );
};

export { OrgModalForm };
