import { KTIcon } from "../../../../../_metronic/helpers";
import { useListView } from "../core/ListViewProvider";

const AdminNewModalHeader = () => {
  const { setItemIdForUpdate, setIsModalType } = useListView();

  return (
    <div className="modal-header">
      <h2 className="fw-bolder">Add New Admin User</h2>
      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => {
          setItemIdForUpdate(undefined);
          setIsModalType("");
        }}
        style={{ cursor: "pointer" }}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </div>
    </div>
  );
};

export { AdminNewModalHeader };
