import {useEffect} from 'react'
import {UserNewModalHeader} from './UserNewModalHeader'
import {UserNewModalFormWrapper} from './UserNewModalFormWrapper'

const UserNewModal = ({
  selectedListingId,
  setSelectedListingId,
  listing,
  getListing,
  openAddModal,
  setOpenAddModal
}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])


  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className={`modal-dialog modal-dialog-centered mw-650px`}>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <UserNewModalHeader 
             selectedListingId={selectedListingId}
             setSelectedListingId={setSelectedListingId}
             listing={listing}
             getListing={getListing}
             openAddModal={openAddModal}
             setOpenAddModal={setOpenAddModal}           
            />
            {/* begin::Modal body */}
            <div className={`modal-body scroll-y mx-5 mx-xl-15 my-7 `}>
              <UserNewModalFormWrapper  
               selectedListingId={selectedListingId}
               setSelectedListingId={setSelectedListingId}
               listing={listing}
               getListing={getListing}
               openAddModal={openAddModal}
               setOpenAddModal={setOpenAddModal}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {UserNewModal}
