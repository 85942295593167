import clsx from "clsx";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function formatPhone(phone) {
  //normalize string and remove all unnecessary characters
  phone = phone?.replace(/[^\d]/g, "");

  //check if number length equals to 10
  if (phone?.length == 11) {
    //reformat and return phone number
    return phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
  }
  else if (phone?.length == 10){
    return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
  }

  return null;
}

const CustomRow = ({ row }) => {
  const navigate = useNavigate();
  return <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({ "text-end min-w-100px": cell.column.id === "actions" })}
          style={{cursor:cell.column.id === 'role_name' ? 'pointer' : 'default'}}
          onClick={() => {
            cell.column.id === 'role_name' && navigate(`/roles`)
          }}
        >
          {cell.column.id === "listing_id"
            ? cell.value
              ? cell.value
              : "-"
            :cell.column.id === "phone_number"
              ? formatPhone(cell.value)
            : cell.column.id === "role"
              ? cell.value == 1
                ? "Super Admin"
                : cell.value == 3
                  ? "Viewer"
                  : cell.value == 4
                    ? "Property Member"
                    : cell.value == 2
                      ? "Editor"
                      : cell.value == 5
                        ? "Property Editor"
                        : cell.value == 6
                          ? "Property Guard"
                          : "-"
              : cell.column.id === "createdAt"
                ? moment(cell.value).utc().format("YYYY-MM-DD")
                : cell.render("Cell")
          }
        </td>
      );
    })}
  </tr>
  }

export { CustomRow };
