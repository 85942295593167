import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UserEditModalForm = ({
  selectedListingId,
  setSelectedListingId,
  listing,
  getListing,
  openDeleteModal,
  setOpenDeleteModal
}) => {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    bookings: []
  });

  const cancel = () => {
    setSelectedListingId();
    setOpenDeleteModal(false);
  };

  const deleteListing = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}`).then((res) => {
      toast.success("Listing Deleted");
      getListing();
      setOpenDeleteModal(false);
      navigate("/listings");
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        setErrorMessage({
          bookings: err.response.data.bookings
        });
      } else {
        toast.error("Error Deleting Listing");
      }
    });
  };

  return (
    <>
      <div className="kt_body">
        {!showConfirmation ? (
          <h4>
            Are you sure you want to{" "}
            <span style={{ color: "#ed2626", fontWeight: 700 }}>
              Delete
            </span>{" "}
            this listing?
          </h4>
        ) : (
          <h4>
            Are you sure?
          </h4>
        )}
        {(errorMessage.bookings.length > 0) && (
          <h3 className="fw-bold text-danger my-3" style={{ textAlign: "center" }}>
            Oops! this listing can't be
            deleted
          </h3>
        )}
        <div style={{ minHeight: "auto", maxHeight: "300px", overflowY: "auto" }}>
          {errorMessage.bookings && errorMessage.bookings.length > 0 && (
            <div className="mb-7">
              <h6 className="fw-bold">Listing have Active/Upcoming bookings</h6>
              {errorMessage.bookings.map((booking) => (
                <h5 key={booking.id}>
                  *Reservation Code: <span style={{ color: "#2ecc71" }}>{booking.reservation_code}</span>
                  <br />
                </h5>
              ))}
            </div>
          )}
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            type="button"
            className="btn mt-4 w-100 btn-light"
            onClick={() => cancel()}
            style={{
              color: "#000",
              height: "46px",
              fontWeight: "700"
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn mt-4 w-100"
            onClick={() => {
              if (showConfirmation) {
                deleteListing();
              } else {
                setShowConfirmation(true);
              }
            }}
            style={{
              background: "#ed2626",
              color: "#fff",
              height: "46px",
              fontWeight: "700"
            }}
          >
            Delete Listing
          </button>
        </div>
      </div>
    </>
  );
};

export { UserEditModalForm };
