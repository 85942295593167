import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import opacity from "react-element-popper/animations/opacity";
import dayjs from "dayjs";
import { KTIcon } from '../../../../_metronic/helpers'
const PromoListHeader = ({
  setOpenPromoModal,
  tab,
  setTab,
  currentUser,
  haveAccess,
  searchType,
  setSearchType,
  searchPromoCode,
  setSearchPromoCode,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleExport
}) => {
  return (
    <>
      <div className='card-header border-0 pt-6 justify-space-between align-items-center'>
        <div style={{ display: 'flex', background: '#f9f9f9', borderRadius: '10px' }}>
          <span style={{ padding: '15px', fontWeight: '600', background: tab === 'active' ? '#a5f0c2' : '', borderRadius: '10px', cursor: 'pointer' }}
            onClick={() => setTab('active')}>Active</span>
          <span style={{ padding: '15px', fontWeight: '600', background: tab === 'inactive' ? '#a5f0c2' : '', borderRadius: '10px', cursor: 'pointer' }}
            onClick={() => setTab('inactive')}>Inactive</span>
        </div>
        <div className='card-toolbar' style={{ gap: '5px' }}>
        <button
          type="button"
          className="btn"
          style={{ backgroundColor: "#2ecc71", color: "white" }}
          onClick={handleExport}
        >
          Export
        </button>
          {(haveAccess(currentUser, 'promo_create')) && <button type='button' className='btn' style={{ backgroundColor: "#2ecc71", color: "white" }}
            onClick={() => {
              setOpenPromoModal(true)
            }}
          >
            <KTIcon iconName='plus' className='fs-2 text-white' />
            Add Promo Code
          </button>
          }
        </div>
        {/* end::Card toolbar */}
      </div>
      <div className='card-header border-0 pt-0 justify-start align-items-center'>
        <div className='d-flex column-gap-3'>
          <select
            className="form-select form-select-solid fw-bolder w-200px"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="">
              Select Search By
            </option>
            <option value="1" >
              Promo Code
            </option>
            <option value="2">
              Promo Type
            </option>
            <option value="3">
              Discount Type
            </option>
            <option value="4">
              Requester Name
            </option>
            <option value="5">
              Listing Name
            </option>
            <option value="6">
              By Date
            </option>
          </select>
          {searchType && (searchType == 1 || searchType == 5 || searchType == 4) ? (
            <div className="d-flex align-items-center position-relative">
              <KTIcon
                iconName="magnifier"
                className="fs-1 position-absolute ms-6"
              />
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-250px ps-14"
                placeholder={`Search By ${searchType == 1 ? 'Promo Code' : searchType == 5 ? 'Listing Name' : searchType == 4 && 'Requester Name'}`}
                value={searchPromoCode}
                onChange={(e) => setSearchPromoCode(e.target.value)}
              />
            </div>
          )
            :
            searchType == 6 ? (
              <>
                <div>
                  <DatePicker
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      borderRadius: "8px",
                      padding: "0.775rem 1rem",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      lineHeight: "1.5",
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #f9f9f9",
                      color: "#5e6278",
                      transition: "color 0.2s ease",
                      height: "auto"
                    }}
                    containerStyle={{
                      width: "100%"
                    }}
                    className="rmdp-mobile custom-calendar"
                    arrow={false}
                    value={startDate}
                    onChange={(val) => {
                      setStartDate(val);
                    }}
                    maxDate={dayjs(endDate).toDate()}
                    format="dddd MMM DD,YYYY"
                    fixMainPosition={true}
                    animations={[opacity()]}
                    placeholder="Select Start Date"
                    weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                    scrollSensitive={false}
                    hideOnScroll={false}
                  />
                </div>
                <div>
                  <DatePicker
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      borderRadius: "8px",
                      padding: "0.775rem 1rem",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      lineHeight: "1.5",
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #f9f9f9",
                      color: "#5e6278",
                      transition: "color 0.2s ease",
                      height: "auto"
                    }}
                    containerStyle={{
                      width: "100%"
                    }}
                    className="rmdp-mobile custom-calendar"
                    arrow={false}
                    value={endDate}
                    onChange={(val) => {
                      setEndDate(val);
                    }}
                    minDate={dayjs(startDate).toDate()}
                    format="dddd MMM DD,YYYY"
                    fixMainPosition={true}
                    animations={[opacity()]}
                    placeholder="Select End Date"
                    weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                    scrollSensitive={false}
                    hideOnScroll={false}
                  />
                </div>
              </>
            )
            : 
            searchType == 2 ? (
              <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-kt-user-table-filter="role"
              data-hide-search="true"
              onChange={(e) => setSearchPromoCode(e.target.value)}
              value={searchPromoCode}
            >
              <option value="">Select Promo Type</option>
              <option value='1,2,3,4'>For All</option>
              <option value="1">Hourly</option>
              <option value="2">Daily</option>
              <option value="3">Weekly</option>
              <option value="4">Monthly</option>
            </select>
            )
            :
           searchType == 3 && <select
            className="form-select form-select-solid fw-bolder"
            data-kt-select2="true"
            data-placeholder="Select option"
            data-allow-clear="true"
            data-kt-user-table-filter="role"
            data-hide-search="true"
            onChange={(e) => setSearchPromoCode(e.target.value)}
            value={searchPromoCode}
          >
            <option value="">Select Discount Type</option>
            <option value="2">Discount By Percentage</option>
            <option value="1">Discount By Amount</option>
            <option value="3">Days/Hours OFF</option>
          </select>
          }
        </div>
      </div>
    </>
  )
}

export { PromoListHeader }
