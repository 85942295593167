import { useState, useEffect } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { MenuComponent } from '../../../../_metronic/assets/ts/components'


const Header = ({
  setSearch,
  search,
  tab,
  setTab,
  filter,
  setFilter,
  count
}) => {
  const [change, setChange] = useState('')

  const pendingFieldsCount = count && count?.pending_fields?.reduce((acc, current) => acc + Number(current?.count), 0);

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [tab])


  useEffect(() => {
    if (filter) {
      setChange(filter)
    }
  }, [filter])

  const resetData = () => {
    setChange('')
    setFilter('')
  }

  const filterData = () => {
    setFilter(change)
  }

  return (
    <div className="card-header border-0 pt-6">
      <div style={{ display: 'flex', background: '#f9f9f9', borderRadius: '10px', alignItems: 'center' }}>
        <span style={{ padding: '15px', fontWeight: '700', background: tab === 'listings' ? '#a5f0c2' : '', borderRadius: '10px', cursor: 'pointer' }}
          onClick={() => setTab('listings')}>Pending Listings Approvals {count && <span className="badge badge-dark">{count?.pending_listings}</span>}</span>
        <span style={{ padding: '15px', fontWeight: '700', background: tab === 'video' ? '#a5f0c2' : '', borderRadius: '10px', cursor: 'pointer' }}
          onClick={() => setTab('video')}>Other Approvals {count && <span className="badge badge-dark">{pendingFieldsCount}</span>}</span>
      </div>
      {tab === 'listings' ? <div className='d-flex column-gap-3'>
        <div className="d-flex align-items-center position-relative my-1 search-wrapper" style={{ gap: "15px" }}>
          <div className="d-flex align-items-center position-relative my-1">
            <KTIcon
              iconName="magnifier"
              className="fs-1 position-absolute ms-6"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-300px ps-14"
              placeholder={`Search By Listing Name`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
        :
        <div className='d-flex column-gap-3'>
          <button
            type='button'
            className='btn me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            style={{ backgroundColor: '#ffa459', color: 'white' }}
          >
            <KTIcon iconName='filter' className='fs-2 text-white' iconType={filter ? 'solid' : 'duotone'} />
            Filter
          </button>
          <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>Filter</div>
            </div>
            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5' data-kt-user-table-filter='form'>

              <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Filter by Type:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select Approval For'
                  data-allow-clear='true'
                  data-kt-user-table-filter='two-step'
                  data-hide-search='true'
                  onChange={(e) => setChange(e.target.value)}
                  value={change}
                >
                  <option value="" selected >Select Approval For</option>
                  <option value="1" selected >Name</option>
                  <option value="2" selected >Description</option>
                  <option value="5" selected >Important Information</option>
                  <option value="24" selected >Video</option>
                </select>
              </div>
              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  onClick={() => resetData()}
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                >
                  Reset
                </button>
                <button
                  disabled={!change}
                  type='button'
                  onClick={filterData}
                  className='btn fw-bold px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                  style={{ backgroundColor: '#ffa459', color: '#fff' }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export { Header };
