/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser} from '../../core/_requests'
import axios from "axios";
import {toast } from 'react-toastify';
import {useAuth} from '../../../../../auth'
import { useNavigate } from 'react-router-dom'


const UserActionsCell = ({id}) => {

  const {currentUser, haveAccess} = useAuth()
  const navigate = useNavigate()

  const {setItemIdForUpdate,
    setIsModalType} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setIsModalType("EDIT")
    setItemIdForUpdate(id)
  }


  const changeStatus = () => {
    setIsModalType("CHANGE_STATUS")
    setItemIdForUpdate(id)
  }

  const changeRole = () => {
    setIsModalType("CHANGE_ROLE")
    setItemIdForUpdate(id)
  }

  const onDelete = () => {
    setIsModalType("DELETE")
    setItemIdForUpdate(id)
  }

  const addAccess = () => {
    setIsModalType("ADD_ACCESS")
    setItemIdForUpdate(id)
  }


  const viewListing = () => {
      setIsModalType("VIEW_LISTINGS") 
      setItemIdForUpdate(id)
  }

  // const goToListing = () => {
  //   navigate(`/listings/${id?.listing_id}`);
  // }



  const deleteItem = useMutation(() => deleteUser(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
    onError: (error) => {
      console.error(error)
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        
      >
       
        <KTIcon iconName={(haveAccess(currentUser,  'review_delete') || haveAccess(currentUser,  'review_edit')) ? 'pencil' : 'lock-2'} className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
    {(haveAccess(currentUser,  'review_delete') || haveAccess(currentUser,  'review_edit')) && 
     <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
      {(haveAccess(currentUser,  'review_edit')) &&
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit Review
          </a>
        </div>
        }
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {(haveAccess(currentUser,  'review_delete')) &&  <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={onDelete}
          >
            Delete Review
          </a>
        </div>
        }
        {/* end::Menu item */}

      </div>
      }
      {/* end::Menu */}
    </>
  )
}

export {UserActionsCell}
