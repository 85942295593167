import "./style.scss"
import { useState, useEffect } from "react";
import { hours } from "../../../Bookings/editBookingModal/BookingEditModalForm";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastStyle } from "../../../../../_metronic/helpers/utils";
import moment from "moment";
import { KTIcon } from "../../../../../_metronic/helpers";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import opacity from "react-element-popper/animations/opacity";
import "../../../PromoCode/style.css"
import dayjs from "dayjs";
import { convertUTCTimeToListingTime } from "../../../../../_metronic/helpers/convertTime";
const RestrictedHoursModalForm = ({
  selectedListingId,
  setSelectedListingId,
  listing,
  getListing,
  setOpenRestrictedHoursModal,
}) => {
  const date = moment().format('YYYY-MM-DD')
  const [days, setDays] = useState([
    {
      id: 0,
      name: 'Sunday',
      closed: false,
      times: [
        {
          opens_at: null,
          closes_at: null
        }
      ],
    },
    {
      id: 1,
      name: 'Monday',
      closed: false,
      times: [
        {
          opens_at: null,
          closes_at: null
        }
      ],
    },
    {
      id: 2,
      name: 'Tuesday',
      closed: false,
      times: [
        {
          opens_at: null,
          closes_at: null
        }
      ],
    },
    {
      id: 3,
      name: 'Wednesday',
      closed: false,
      times: [
        {
          opens_at: null,
          closes_at: null
        }
      ],
    },
    {
      id: 4,
      name: 'Thursday',
      closed: false,
      times: [
        {
          opens_at: null,
          closes_at: null
        }
      ],
    },
    {
      id: 5,
      name: 'Friday',
      closed: false,
      times: [
        {
          opens_at: null,
          closes_at: null
        }
      ],
    },
    {
      id: 6,
      name: 'Saturday',
      closed: false,
      times: [
        {
          opens_at: null,
          closes_at: null
        }
      ],
    },
  ])
  const [originalDays, setOriginalDays] = useState([]);
  const [specialDays, setSpecialDays] = useState([
    {
      id: 1,
      name: "",
      closed: false,
      times: [
        {
          opens_at: null,
          closes_at: null
        }
      ],
      opens_at: null,
      closes_at: null,
      date: null
    },
  ])
  const [originalSpecialDays, setOriginalSpecialDays] = useState([]);
  const [openTime, setOpenTime] = useState('');
  const [closeTime, setCloseTime] = useState('');


  const convertUTCTimeToListing = (date, timestamp, timeZone, format = "HH:mm:00") => {
    return moment.utc(`${date} ${timestamp}`).tz(timeZone).format(format);
  }

  const convertListingTimeToUTC = (date, timestamp, timeZone, format = "HH:mm") => {
    return moment.tz(`${date} ${timestamp}`, timeZone).utc().format(format);
  }

  useEffect(() => {
    if (listing?.restricted_hours) {
      const updatedDays = days.reduce((acc, day) => {
        const restricted = listing?.restricted_hours.find(hours => hours.day_of_week === day.id);
        const times = restricted?.times?.map(time => {
          return {
            opens_at: time?.opens_at ? moment(`${date} ${time?.opens_at}`).format("HH:mm:00") : '',
            closes_at: time?.closes_at ? moment(`${date} ${time?.closes_at}`).format("HH:mm:00") : ''
          }
        })
        if (restricted) {
          acc.push({
            ...day,
            times: restricted?.times?.length > 0 ? times : [{
              opens_at: null,
              closes_at: null
            }],
            closed: restricted.is_closed,
            restriction_id: restricted.id
          });
        } else {
          acc.push(day);
        }

        return acc;
      }, []);
      setDays(updatedDays)
      setOriginalDays(updatedDays);

      const specialRestricted = listing?.restricted_hours?.filter(day => day.day_of_year).map(day => {
        return {
          id: day.id,
          date: dayjs(convertUTCTimeToListingTime(day.day_of_year, listing.timezone_id, 0, "YYYY-MM-DD")).toDate(),
          restriction_id: day.id,
          name: day.description,
          closed: day.is_closed,
          times: day?.times?.length > 0 ? day.times?.map(time => {
            return {
              opens_at: time?.opens_at ? moment.utc(`${date} ${time?.opens_at}`).format("HH:mm:00") : null,
              closes_at: time?.closes_at ? moment.utc(`${date} ${time?.closes_at}`).format("HH:mm:00") : null
            }
          })
          : [{
            opens_at: null,
            closes_at: null
          }]
        }
      })
      setSpecialDays(specialRestricted)
      setOriginalSpecialDays(specialRestricted);
    }
    setOpenTime(listing.open_time ? listing?.open_time : "");
    setCloseTime(listing.close_time ? listing?.close_time : "");
  }, [listing])

  const hasDataChanged = (id, special = false) => {
    const day = !special ? days.find(d => d.id === id) : specialDays.find(d => d.id === id);
    const originalDay = !special ? originalDays.find(d => d.id === id) : originalSpecialDays.find(d => d.id === id);
    
    if (!day || !originalDay) {
      return false;
    }

    if(!day.restriction_id && !originalDay.restriction_id){
      return false
    }

    const areTimesEqual = (times1, times2) => {
      if (times1.length !== times2.length) return false;
      return times1.every((time, index) =>
        time.opens_at === times2[index].opens_at &&
        time.closes_at === times2[index].closes_at
      );
    };
    
    if(!special){
      return  day.closed === originalDay.closed &&
      areTimesEqual(day.times, originalDay.times) 
    }else{
      return  day.closed === originalDay.closed &&
      areTimesEqual(day.times, originalDay.times) &&
      day.name === originalDay.name
    }
  };

  const hasUniversalDataChanged = () => {
    if (!openTime && !closeTime) {
      return false
    }

    return JSON.stringify({ opens_at: openTime, closes_at: closeTime }) === JSON.stringify({ opens_at: listing?.open_time, closes_at: listing?.close_time })
  };

  const handleSave = (id, special = false) => {
    const restrictedDay = !special ? days.find(day => day.id === id) : specialDays.find(day => day.id === id);
    const times = restrictedDay?.times?.map(time => {
      return {
        opens_at: time?.opens_at ?  moment(`${date} ${time?.opens_at}`).format("HH:mm") : '',
        closes_at: time?.closes_at ? moment(`${date} ${time?.closes_at}`).format("HH:mm") : ''
      }
    })
    const start = special && dayjs(restrictedDay?.date).format("YYYY-MM-DD");
    const UTCDate = special && moment.tz(start, listing?.timezone_id).utc().format("YYYY-MM-DD")
    axios.put(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/restrict`, {
      ...(!special && { day_of_week: id }),
      ...(special && { day_of_year: UTCDate }),
      ...(restrictedDay?.closed && { times: null }),
      ...(!restrictedDay?.closed && { times }),
      description: restrictedDay?.name?.trim(),
      is_closed: restrictedDay?.closed
    }).then(() => {
      getListing();
      toast(`${special ? 'Special' : 'Restricted'} Hours Updated Successfully`, ToastStyle);
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, ToastStyle);
      } else {
        toast(`Error Updating ${special ? 'Special' : 'Restricted'} Hours`, ToastStyle);
      }
    });
  }

  const removeRestrictedHours = (id, special = false) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/restrict/${id}`).then(() => {
      setDays(days.map((d) => d.restriction_id === id ? { id: d.id, name: d.name, closed: false, times: [{ opens_at: null, closes_at: null }], opens_at: null, closes_at: null } : d));
      getListing();
      toast(`${special ? 'Special' : 'Restricted'} Hours Removed Successfully`, ToastStyle);
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, ToastStyle);
      } else {
        toast(`Error Removing ${special ? 'Special' : 'Restricted'} Hours`, ToastStyle);
      }
    });
  }

  const handleSaveUniversalHours = () => {
    const openUTCTime = convertListingTimeToUTC(date, openTime, listing?.timezone_id)
    const closeUTCTime = convertListingTimeToUTC(date, closeTime, listing?.timezone_id)
    axios.put(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}`, {
      open_time: openTime,
      close_time: closeTime,
      amenities:listing?.amenities
    }).then(() => {
      getListing();
      toast(`Universal Hours Updated Successfully`, ToastStyle);
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, ToastStyle);
      } else {
        toast(`Error Updating Universal Hours`, ToastStyle);
      }
    });
  }

  const removeTimeLimits = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/timing`)
      .then(res => {
        setOpenTime('')
        setCloseTime('')
        getListing();
        toast(`Universal Hours Removed Successfully`, ToastStyle);
      }).catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast(err.response.data.message, ToastStyle);
        } else {
          toast(`Error Removing Universal Hours`, ToastStyle);
        }
      });
  }

  const handleNameChange = (id, value) => {
    setSpecialDays(prevSpecialDays =>
      prevSpecialDays.map(day =>
        day.id === id ? { ...day, name: value } : day
      )
    );
  };

  const handleTimeChange = (dayId, indexId, value, key, special=false) => {
    if (special) {
      setSpecialDays(specialDays.map((d) => {
        if (d.id === dayId) {
          return {
            ...d,
            times: d.times.map((t, index) => {
              if (index === indexId) {
                return { ...t, [key]: value };
              }
              return t;
            })
          };
        }
        return d;
      })) 
    }else{
    setDays(days.map((d) => {
      if (d.id === dayId) {
        return {
          ...d,
          times: d.times.map((t, index) => {
            if (index === indexId) {
              return { ...t, [key]: value };
            }
            return t;
          })
        };
      }
      return d;
    }));
  }
  }

  const addMoreHourBlock = (dayId, special=false) => {
    if (special) {
      setSpecialDays(specialDays.map((d) => {
        if (d.id === dayId) {
          return {
            ...d,
            times: [...d.times, { opens_at: null, closes_at: null }]
          };
        }
        return d;
      }))
    }else{
    setDays(days.map((d) => {
      if (d.id === dayId) {
        return {
          ...d,
          times: [...d.times, { opens_at: null, closes_at: null }]
        };
      }
      return d;
    }));
  }
  }

  const removeHourBlock = (dayId, indexId, special=false) => {
    if (special) {
      setSpecialDays(specialDays.map((d) => {
        if (d.id === dayId) {
          return {
            ...d,
            times: d.times.filter((t, index) => index !== indexId)
          };
        }
        return d;
      }))
    }else{
    setDays(days.map((d) => {
      if (d.id === dayId) {
        return {
          ...d,
          times: d.times.filter((t, index) => index !== indexId)
        };
      }
      return d;
    }));
  }
  }

  const isDisable = (day, special=false) => {
    if (special) {
      if(day.closed){
        return hasDataChanged(day.id, true) || !day.name?.trim()  || !day.date || !day.closed
      }else{
        return hasDataChanged(day.id, true) || !day.name?.trim() || !day.date || (day.times.length === 0 || !day.times.every(t => t.opens_at && t.closes_at));
      }
    }else{
     return hasDataChanged(day.id) || (day.closed ? false : day.times.length === 0 || !day.times.every(t => t.opens_at && t.closes_at));
    }
  }


  return (
    <>
      <div className="d-flex flex-column scroll-y scroll-behavior-smooth gap-4">
        <>
          <h3>Universal Hours</h3>
          <div className="d-flex gap-4 mb-2 universal-day-wrapper">
            <div className="fv-row" style={{ flex: '1' }}>
              <label className="fw-bold fs-6 mb-2">
                Open Time
              </label>
              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                value={openTime}
                onChange={(e) => setOpenTime(e.target.value)}
              >
                <option value="">Select open time</option>
                {hours.map((el) => (
                  <option
                    value={el.value}
                    key={el.value}
                  >
                    {el.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="fv-row" style={{ flex: '1' }}>
              <label className="fw-bold fs-6 mb-2">
                Close Time
              </label>
              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                value={closeTime}
                onChange={(e) => setCloseTime(e.target.value)}
              >
                <option value="">Select close time</option>
                {hours.map((el) => (
                  <option
                    value={el.value}
                    key={el.value}
                  >
                    {el.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex gap-2 align-self-end" style={{ width: '18%' }}>
              <button className="btn btn-success btn-sm w-100"
                disabled={!openTime || !closeTime || hasUniversalDataChanged()}
                onClick={() => handleSaveUniversalHours()}
              >Save</button>
              {(listing?.open_time || listing?.close_time) && (
                <button
                  className="btn btn-danger btn-sm text-center align-self-end"
                  onClick={removeTimeLimits}
                >
                  Reset
                </button>
              )}
            </div>
          </div>
        </>
        <>
          <h3>Restricted Days</h3>
          {days.map((day) => (
            <div className="day-wrapper" key={day.id}>
              <div className="day align-self-start">
                <h3>{day.name}</h3>
                <div className="input-search form-check form-check-custom form-check-solid">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id='optionalTerms'
                    checked={day.closed}
                    onChange={() => setDays(days.map((d) => d.id === day.id ? { ...d, closed: !d.closed } : d))}
                  />
                  <label
                    className="form-check-label fs-6"
                    htmlFor="optionalTerms"
                    style={{ fontWeight: "700", color: "#3f4254" }}
                  >
                    Closed
                  </label>
                </div>
              </div>
              {!day.closed &&
                <div className="time-block-wrapper">
                  {day?.times?.map((time, index) => (
                    <div className="time-block">
                      <div className="fv-row">
                        <label className="fw-bold fs-5 mb-2">
                          Close Start Time
                        </label>
                        <select
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          data-kt-user-table-filter="two-step"
                          data-hide-search="true"
                          value={time.closes_at || ''}
                          onChange={(e) => handleTimeChange(day.id, index, e.target.value, 'closes_at')}
                        >
                          <option value="">Select close start time</option>
                          {hours.map((el) => (
                            <option
                              value={el.value}
                              key={el.value}
                            >
                              {el.text}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="fv-row">
                        <label className="fw-bold fs-5 mb-2">
                          Close End Time
                        </label>
                        <select
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          data-kt-user-table-filter="two-step"
                          data-hide-search="true"
                          value={time?.opens_at || ''}
                          onChange={(e) => handleTimeChange(day.id, index, e.target.value, 'opens_at')}
                        >
                          <option value="">Select close end time</option>
                          {hours.map((el) => (
                            <option
                              value={el.value}
                              key={el.value}
                            >
                              {el.text}
                            </option>
                          ))}
                        </select>
                      </div>
                      {day.times?.length < 2 &&
                        <button className="btn btn-sm align-self-end" style={{ backgroundColor: '#ffa459' }}
                          onClick={() => addMoreHourBlock(day.id)}
                        ><KTIcon iconName='plus' className='fs-2 p-0 text-white' /></button>
                      }
                      {
                        index !== 0 && <button className="btn btn-sm btn-danger align-self-end"
                          onClick={() => removeHourBlock(day.id, index)}
                        ><KTIcon iconName='trash' className='fs-2 p-0 text-white' /></button>
                      }
                    </div>
                  ))
                  }
                </div>
              }
              <div className="d-flex gap-2 align-self-end" style={{ width: '18%' }}>
                <button className="btn btn-success btn-sm w-100"
                  disabled={isDisable(day)}
                  onClick={() => handleSave(day.id)}
                >Save</button>
                {day.restriction_id && <button className="btn btn-danger btn-sm text-center" style={{ padding: '5px 10px' }}
                  onClick={() => removeRestrictedHours(day.restriction_id)}
                >
                  Reset
                </button>
                }
              </div>
            </div>
          ))
          }
        </>
        <>
          <h3>Special Hours</h3>
          {specialDays.map((day) => (
            <div className="d-flex flex-column gap-4" key={day.id}>
              <div className="d-flex align-items-center gap-5">
                <div className="input-search" style={{ width: '70%' }}>
                  <label className="required form-label fs-5 fw-bold">Name</label>
                  <input
                    type="text"
                    data-kt-user-table-filter="search"
                    className="form-control form-control-solid w-100"
                    placeholder="Name"
                    value={day.name}
                    onChange={(e) => handleNameChange(day.id, e.target.value)}
                  />
                </div>
                <div className="input-search form-check form-check-custom form-check-solid pt-10">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id='optionalTerms'
                    checked={day.closed}
                    onChange={() => setSpecialDays(specialDays.map((d) => d.id === day.id ? { ...d, closed: !d.closed } : d))}
                  />
                  <label
                    className="form-check-label fs-6"
                    htmlFor="optionalTerms"
                    style={{ fontWeight: "700", color: "#3f4254" }}
                  >
                    Closed
                  </label>
                </div>
              </div>
              <div className="day-wrapper">
                <div className="fv-row align-self-start" style={{ width: '26%' }}>
                  <label className="fw-bold fs-6 mb-2 required">Select Date</label>
                  <DatePicker
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      borderRadius: "8px",
                      padding: "0.775rem 1rem",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      lineHeight: "1.5",
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #f9f9f9",
                      color: "#5e6278",
                      transition: "color 0.2s ease",
                      height: "auto"
                    }}
                    containerStyle={{
                      width: "100%"
                    }}
                    className="rmdp-mobile custom-calendar"
                    arrow={false}
                    value={day.date}
                    onChange={(val) => {
                      setSpecialDays(specialDays.map((d) => d.id === day.id ? { ...d, date: val } : d))
                    }}
                    disabled={day.restriction_id ? true : false}
                    format="dddd MMM DD,YYYY"
                    fixMainPosition={true}
                    animations={[opacity()]}
                    placeholder="Select Date"
                    weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                    scrollSensitive={false}
                    hideOnScroll={false}
                  />
                </div>
                {!day.closed &&
                  <div className="time-block-wrapper">
                    {day?.times?.map((time, index) => (
                      <div className="time-block">
                        <div className="fv-row">
                          <label className="fw-bold fs-5 mb-2">
                            Close Start Time
                          </label>
                          <select
                            className="form-select form-select-solid fw-bolder"
                            data-kt-select2="true"
                            data-placeholder="Select option"
                            data-allow-clear="true"
                            data-kt-user-table-filter="two-step"
                            data-hide-search="true"
                            value={time.closes_at || ''}
                            onChange={(e) => handleTimeChange(day.id, index, e.target.value, 'closes_at', true)}
                          >
                            <option value="">Select close start time</option>
                            {hours.map((el) => (
                              <option
                                value={el.value}
                                key={el.value}
                              >
                                {el.text}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="fv-row">
                          <label className="fw-bold fs-5 mb-2">
                            Close End Time
                          </label>
                          <select
                            className="form-select form-select-solid fw-bolder"
                            data-kt-select2="true"
                            data-placeholder="Select option"
                            data-allow-clear="true"
                            data-kt-user-table-filter="two-step"
                            data-hide-search="true"
                            value={time?.opens_at || ''}
                            onChange={(e) => handleTimeChange(day.id, index, e.target.value, 'opens_at', true)}
                          >
                            <option value="">Select close end time</option>
                            {hours.map((el) => (
                              <option
                                value={el.value}
                                key={el.value}
                              >
                                {el.text}
                              </option>
                            ))}
                          </select>
                        </div>
                        {day.times?.length < 2 &&
                          <button className="btn btn-sm align-self-end" style={{ backgroundColor: '#ffa459' }}
                            onClick={() => addMoreHourBlock(day.id, true)}
                          ><KTIcon iconName='plus' className='fs-2 p-0 text-white' /></button>
                        }
                        {
                          index !== 0 && <button className="btn btn-sm btn-danger align-self-end"
                            onClick={() => removeHourBlock(day.id, index, true)}
                          ><KTIcon iconName='trash' className='fs-2 p-0 text-white' /></button>
                        }
                      </div>
                    ))
                    }
                  </div>
                }
                <div className="d-flex gap-2 align-self-end" style={{ width: '18%' }}>
                  <button className="btn btn-success btn-sm w-100"
                    disabled={isDisable(day, true)}
                    onClick={() => handleSave(day.id, true)}
                  >Save</button>
                  {day.restriction_id && <button className="btn btn-danger btn-sm text-center" style={{ padding: '5px 10px' }}
                    onClick={() => removeRestrictedHours(day.restriction_id, true)}
                  >
                    Reset
                  </button>
                  }
                </div>
              </div>
            </div>
          ))
          }
          <button
            type="reset"
            onClick={() => {
              setSpecialDays([...specialDays, { id: specialDays.length + 7, name: "", date: "", times: [{ opens_at: "", closes_at: "" }], closed: false }])
            }}
            className="btn btn-dark mx-3"
            data-kt-users-modal-action="cancel"
          >
            + Add a date
          </button>
        </>
      </div>
      <div className="text-center pt-15">
        <button
          type="reset"
          onClick={() => setOpenRestrictedHoursModal(false)}
          className="btn btn-light mx-3"
          data-kt-users-modal-action="cancel"
        >
          Close
        </button>
      </div>
    </>
  );
};

export { RestrictedHoursModalForm };
