import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const VideoApprovalModalForm = ({
  video,
  setVideo,
  openVideoApprovalModal,
  setOpenVideoApprovalModal,
  fetchVideoApprovals,
}) => {
  const [type, setType] = useState("");

  const cancel = () => {
    setVideo(null);
    setOpenVideoApprovalModal(false);
  };

  const PendingApproval = (type) => {
    if (type) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/listing/approval/${video.id}?listing_id=${video.listing_id}&is_approved=true`
        )
        .then((res) => {
          toast("Video Approval Accepted", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
          setVideo(null);
          setOpenVideoApprovalModal(false);
          fetchVideoApprovals();
        })
        .catch((err) => {
          toast("Error Pending Approval", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
        });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/listing/approval/${video.id}?listing_id=${video.listing_id}&is_approved=false`
        )
        .then((res) => {
          toast("Video Approval Rejected", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
          setVideo(null);
          setOpenVideoApprovalModal(false);
        })
        .catch((err) => {
          toast("Error Pending Approval", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
        });
    }
  };



  return (
    <>
      <div className="kt_body">
        {!type ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className="btn mt-4 w-100"
              onClick={() => setType("reject")}
              style={{
                background: "#ed2626",
                color: "#fff",
                height: "46px",
                fontWeight: "700"
              }}
            >
              Reject
            </button>
            <button
              type="button"
              className="btn mt-4 w-100"
              onClick={() => setType("accept")}
              style={{
                background: "#2ecc71",
                color: "#fff",
                height: "46px",
                fontWeight: "700"
              }}
            >
              Accept
            </button>
          </div>
        ) : (
          <>
            {type === "accept" ? (
              <div>
                <h4>
                  Are you sure you want to{" "}
                  <span style={{ color: "#2ecc71", fontWeight: 700 }}>
                    APPROVE
                  </span>{" "}
                  the Video
                </h4>
                <div className="text-center pt-5">
                  <button
                    type="reset"
                    onClick={() => cancel()}
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                  >
                    Cancel
                  </button>
                  <button
                    type="reset"
                    onClick={() => setType("")}
                    className="btn btn-dark me-3"
                    data-kt-users-modal-action="cancel"
                  >
                    Go Back
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    id="approve"
                    style={{ backgroundColor: "#2ecc71", color: "#fff" }}
                    data-kt-users-modal-action="submit"
                    onClick={() => {
                      PendingApproval(true);
                    }}
                  >
                    <span className="indicator-label">APPROVE LISTING</span>
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <h4>
                  Are you sure you want to{" "}
                  <span style={{ color: "#ed2626", fontWeight: 700 }}>
                    REJECT
                  </span>{" "}
                  the Video{" "}
                </h4>
                <div className="text-center pt-5">
                  <button
                    type="reset"
                    onClick={() => cancel()}
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                  >
                    Cancel
                  </button>
                  <button
                    type="reset"
                    onClick={() => setType("")}
                    className="btn btn-dark me-3"
                    data-kt-users-modal-action="cancel"
                  >
                    Go Back
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    style={{ backgroundColor: "#ed2626", color: "#fff" }}
                    data-kt-users-modal-action="submit"
                    onClick={() => {
                      PendingApproval(false);
                    }}
                  >
                    <span className="indicator-label">REJECT LISTING</span>
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export { VideoApprovalModalForm };
