import { useEffect, useState } from "react";
import { KTCard, KTCardBody } from "../../../../_metronic/helpers";
import "../style.css";
import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RolesHeader } from "./header/RolesHeader";

const Index = () => {
    const navigate = useNavigate();
    const [view, setView] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [selectedPermission, setSelectedPermission] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState({});
    const [roleName, setRoleName] = useState('');
    const [viewFinance, setViewFinance] = useState(false);


    const fetchPermissions = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_V2_URL}/roles/permissions`);
            setPermissions(data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Could not fetch permissions');
        } finally {
            setLoading(false)
        }
    }

    const fetchRole = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_V2_URL}/roles`);
            const role = data.find(role => role.role_id == window.location.pathname.split('/').pop());
            setSelectedRole(role);
            setRoleName(role?.role_name);
            setViewFinance(role?.can_view_finance);
            setSelectedPermission(role.permissions);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'no such role found');
            navigate('/roles');
        } finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        fetchPermissions()
        fetchRole()
    }, [])


    const EditRole = () => {
        setLoading(true)
        if (!roleName) {
            toast.error('Please enter role name');
            return;
        }
        axios.put(`${process.env.REACT_APP_API_V2_URL}/roles/${selectedRole?.role_id}`, {
            ...(roleName !== selectedRole?.role_name && { role_name: roleName }),
            can_view_finance:viewFinance,
            permissions: selectedPermission
        }).then(() => {
            setLoading(false)
            toast.success('Role Edited successfully');
            navigate('/roles');
        }).catch(err => {
            toast.error(err?.response?.data?.message || 'Could not edit role')
            setLoading(false)
        });
    }

    const getNestedChildren = (parentId, permissions) => {
        let children = permissions.filter(p => p.parent === parentId);
        let nestedChildren = [];
        for (let child of children) {
            nestedChildren.push(child.id);
            nestedChildren = nestedChildren.concat(getNestedChildren(child.id, permissions));
        }
        return nestedChildren;
    };
    
    const getAllParentIds = (childId, permissions) => {
        let parent = permissions.find(p => p.id === childId);
        if (parent && parent.parent) {
            return [parent.parent, ...getAllParentIds(parent.parent, permissions)];
        }
        return [];
    };
    


    function NestedChecklist({ permissions, parentId = null }) {
        const filteredPermissions = permissions.filter(permission => permission.parent === parentId);
        return (
            <ul style={{ display: !parentId ? 'flex' : 'block', gap: '20px', paddingLeft: !parentId ? '0px' : '' }}>
                {filteredPermissions.map(permission => (
                    <div style={{ width: 'max-content' }}>
                        <div className="input-search mb-5 form-check form-check-custom form-check-solid">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id={permission.id}
                                disabled={view}
                                checked={selectedPermission?.find((p) => p == permission.id)}
                                // onChange={(e) => setSelectedPermission(e.target.checked ? [...selectedPermission, permission.id] : selectedPermission.filter((p) => p !== permission.id))}
                                onChange={(e) => {
                                    const selectedParent = permissions.find(p => p.id === permission.id);
                                    const selectedChildsIds = getNestedChildren(permission.id, permissions);
                                    const allParentIds = getAllParentIds(permission.id, permissions);
                                
                                    if (selectedParent?.is_label) {
                                        let selections = [...selectedPermission, permission.id, ...selectedChildsIds];
                                        selections = [...selections, ...allParentIds];
                                        setSelectedPermission(e.target.checked
                                            ? [...new Set(selections)]
                                            : selectedPermission.filter(p => 
                                                p !== permission.id &&
                                                !selectedChildsIds.includes(p)));
                                    } else {
                                        let selections = [...selectedPermission, permission.id];
                                        selections = [...selections, ...allParentIds];
                                        setSelectedPermission(e.target.checked
                                            ? [...new Set(selections)]
                                            : selectedPermission.filter(p => p !== permission.id));
                                    }
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={permission.id}
                                style={{ fontWeight: "700", color: "#3f4254" }}
                            >
                                {permission.permission_name}
                            </label>
                        </div>
                        <NestedChecklist permissions={permissions} parentId={permission.id} />
                    </div>
                ))}
            </ul>
        );
    }



    return (
        <KTCard>
            <RolesHeader selectedRole={selectedRole} />
            <KTCardBody className="pt-0">
                <div className="row g-4">
                    <div className="input-search col-6">
                        <label className="form-label fs-6 fw-bold">Role Name</label>
                        <input
                            type="text"
                            data-kt-user-table-filter="search"
                            className="form-control form-control-solid w-100 "
                            placeholder="Role Name"
                            value={roleName}
                            onChange={(e) => setRoleName(e.target.value)}
                            disabled={view}
                        />
                    </div>
                    <div className="col-12">
                        <label className="form-label fs-6 fw-bold">View Finance</label>
                        <div className="input-search form-check form-check-custom form-check-solid">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="2"
                                id="viewFinance"
                                checked={viewFinance}
                                disabled={view}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setViewFinance(true)
                                        if (viewFinance) {
                                            setViewFinance(false)
                                        }
                                    } else {
                                        setViewFinance(false)
                                    }
                                }}
                            />
                            {/* <label
                            className="form-check-label"
                            for="viewFinance"
                            style={{ fontWeight: "700", color: "#3f4254" }}
                        >
                            Yes/No
                        </label> */}
                        </div>
                    </div>
                    <div className="separator separator-dashed my-5" />
                    <div className="col-12" style={{ overflow: 'auto' }}>
                        <label className="form-label fs-6 fw-bold mb-10">Select Permissions</label>
                        <NestedChecklist permissions={permissions} />
                        <div className="separator separator-dashed my-5" />
                    </div>
                   {!view && <div className="text-center pt-5">
                        <button
                            type="reset"
                            className="btn btn-light me-3"
                            data-kt-users-modal-action="cancel"
                            onClick={() => {
                                navigate("/roles");
                            }}
                        >
                            Discard
                        </button>
                        <button
                            type="reset"
                            className="btn btn-dark me-3"
                            data-kt-users-modal-action="cancel"
                            onClick={() => {
                                setRoleName('');
                                setViewFinance(false);
                                setSelectedPermission([]);
                            }}
                        >
                            Reset
                        </button>
                        <button
                            type="submit"
                            className="btn"
                            style={{ backgroundColor: "#ffa459", color: "#fff" }}
                            data-kt-users-modal-action="submit"
                            disabled={!roleName || !selectedPermission.length}
                            onClick={() => {
                                EditRole()
                            }}
                        >
                            <span className="indicator-label">Edit Role</span>
                        </button>
                    </div>
                    }
                </div>
                {isLoading && <UsersListLoading />}
            </KTCardBody>
        </KTCard>
    )
}

export default Index