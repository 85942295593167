import {KTIcon} from '../../../../_metronic/helpers'


const BookingEditModalHeader = ({setSelectedBookingId,selectedBookingId, setOpenEditBookingModal}) => {


  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'> Edit Booking Details For Booking Number #{selectedBookingId.booking_id}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() =>  {
          setSelectedBookingId(null)
          setOpenEditBookingModal(false)
        }}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>

    </div>
  )
}

export {BookingEditModalHeader}
