import { VideoApprovalModalForm } from "./VideoApprovalModalForm";

const VideoApprovalModalFormWrapper = ({
video,
setVideo,
openVideoApprovalModal,
setOpenVideoApprovalModal,
fetchVideoApprovals
}) => {
  const itemIdForUpdate = null;
  const isLoading = false;

  if (video) {
    return (
      <VideoApprovalModalForm
        isUserLoading={isLoading}
        video={video}
        setVideo={setVideo}
        openVideoApprovalModal={openVideoApprovalModal}
        setOpenVideoApprovalModal={setOpenVideoApprovalModal}
        itemIdForUpdate={itemIdForUpdate}
        fetchVideoApprovals={fetchVideoApprovals}
      />
    );
  }
  return null;
};

export { VideoApprovalModalFormWrapper };
