/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser} from '../../core/_requests'
import axios from "axios";
import {toast } from 'react-toastify';
import {useAuth} from '../../../../../auth'

// type Props = {
//   id: ID
// }

const UserActionsCell = ({id, isDeleted}) => {

  const {currentUser, haveAccess} = useAuth()

  const {setItemIdForUpdate,
    setIsModalType} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setIsModalType("EDIT")
    setItemIdForUpdate(id)
  }


  const changePassword = () => {
    setIsModalType("CHANGE_PASSWORD")
    setItemIdForUpdate(id)
  }

  const loginAsUser = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/user/${id}/login`).then((res) => {
        if(res.data.token){
          window.open(`${process.env.REACT_APP_API_CUSTOMER_URL}admin-customer-login?token=${res?.data?.token}`, "_blank")
        }
    }).catch((err) => {
      if(err && err.response && err.response?.data && err.response?.data?.message){
        toast(err.response.data.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }else{
      toast("Technical Error, Please Try Again", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        
      })
    }
    })

  }


const resetPassword = () => {
  axios.patch(`${process.env.REACT_APP_API_URL}/user/${id}/password`).then((res) => {
    toast("Reset Password Email have been sent", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
        });
  }).catch((err) => {
    if(err && err.response && err.response?.data && err.response?.data?.message){
      toast(err.response.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }else{
    toast("Technical Error, Please Try Again", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
        });
      }
  })
}


const deleteItem = () => {
  setIsModalType("DELETE")
  setItemIdForUpdate(id)
}



  // const deleteItem = useMutation(() => deleteUser(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //   },
  //   onError: (error) => {
  //     console.error(error)
  //   },
  // })

  return (
    !isDeleted ?
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
       
        <KTIcon iconName={(haveAccess(currentUser, 'user_login_as') || haveAccess(currentUser, 'user_password_reset') || haveAccess(currentUser, 'user_delete')) ?'pencil' : 'lock-2'} className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
    {(haveAccess(currentUser, 'user_login_as') || haveAccess(currentUser, 'user_password_reset') || haveAccess(currentUser, 'user_delete')) &&  <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit Profile
          </a>
        </div> */}
    {(haveAccess(currentUser, 'user_login_as')) &&  <div className='menu-item px-3'>
          <a className='menu-link px-3'  onClick={loginAsUser}>
          Login As User
          </a>
        </div>
      }
        {(haveAccess(currentUser, 'user_password_reset')) && <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={resetPassword}>
            Reset Password
          </a>
        </div>
        }
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={changePassword}>
            Change Password
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
    {(haveAccess(currentUser, 'user_delete')) && <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            // onClick={async () => await deleteItem.mutateAsync()}
            onClick={() => deleteItem()}
          >
            Delete User
          </a>
        </div>
}
        {/* end::Menu item */}

      </div>
      }
      {/* end::Menu */}
    </>
    :
    <span className="badge badge-light-danger fw-bolder fs-5">Deleted</span>
  )
}

export {UserActionsCell}
