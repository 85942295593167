import moment from "moment";

const headers = [
  { title: 'Date' },
  { title: 'Time' },
  { title: 'Points' },
  { title: 'Reservation Number' },
  { title: 'Status' },
]

export const PointsTable = ({ points}) => {
  return (
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map(column => (
              <th
                key={column.title}
                className={column.className}
              >
                {column.title}
              </th>
            ))}
          </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
          {points.length > 0 ? (
            points.map((row, i) => (
              <tr key={i}>
                <td style={{ minWidth: '180px'}}>{(moment(row.created_at).utc().format('YYYY-MM-DD'))}</td>
                <td style={{ minWidth: '120px'}}>{(moment(row.created_at).utc().format('HH:mm a'))}</td>
                <td style={{ minWidth: '130px', color: row.type === 'CR' && row.status == 1 ? '#2ecc71' : row.type === 'CR' && row.status == 0 ? '#ffa459' :  '#ed2626'}}>{row.type === 'CR' ? `+${row.credited_balance}` : `-${row.balance_consumed}`} {row.transaction_type === 'review' && '(Review)'}</td>
                <td style={{ minWidth: '180px'}}>{row.remarks || '-'}</td>
                <td style={{textAlign:'center'}}>{row.status == 0 ? <i className="fa fa-clock" style={{color: '#ffa459', fontSize:'16px'}}></i> : <i className="fa fa-circle-check" style={{color: '#2ecc71', fontSize:'16px'}}></i>}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  No points added yet for this user
                </div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
  );
};
