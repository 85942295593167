import { useEffect, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";
import {useListView} from '../core/ListViewProvider'
import { useQueryResponse } from "../core/QueryResponseProvider";
import {
  useDebounce,
  KTIcon
} from "../../../../../../_metronic/helpers";
import "./style.css"

const UserNewModalForm = () => {
  const { refetch } = useQueryResponse();
  const {setItemIdForUpdate, setIsModalType} = useListView()
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [search, setSearch] = useState({
    name:"",
    id:""
  });
  const [listings, setListings] = useState([])
  const [password, setPassword] = useState("");
  const [isManualFormVisible, setManualFormVisible] = useState(false);
  const [addListings, setAddListings] = useState([]);

  const cancel = () => {
    setItemIdForUpdate(undefined);
    setIsModalType("");
  };

  useEffect(() => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setRole('');
    setPassword('');
    setSearch({
      name:"",
      id:""
    })
    setListings([])
    setAddListings([])

  }, []);

  const fetchListing = () => {
    debouncedSearchTerm &&
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/listing?page=1&page_size=10&name=${debouncedSearchTerm}`
      )
      .then((res) => {
        setListings(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };



   const debouncedSearchTerm = useDebounce(search.name, 250);
  // Effect for API call
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
    fetchListing()
    }
  }, [debouncedSearchTerm]);


  const createUser = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin`, {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        role,
        listing_ids: addListings && addListings?.length > 0 ? addListings?.map((item) => item.id) : [],
            })
      .then((res) => {
        toast("New Admin User have been added", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        cancel()
        refetch()
      })
      .catch((err) => {
        if(err && err.response && err.response?.data && err.response?.data?.message){
          toast(err.response.data.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }else{
        toast("Technical Error, Please Try Again", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      });
  };


  return (
    <>
      {/* begin::Scroll */}
      <form className="form" id="kt_modal_add_user_form" >
      <div
        className="d-flex flex-column scroll-y me-n7 pe-7"
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        <div className="fv-row mb-7">
          <label className="required fw-bold fs-6 mb-2">First Name</label>
          <input
            placeholder="First name"
            type="text"
            name="name"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete="off"
          />
        </div>

        <div className="fv-row mb-7">
          <label className="required fw-bold fs-6 mb-2">Last Name</label>
          <input
            placeholder="Last name"
            type="text"
            name="name"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            autoComplete="off"
          />
        </div>
        {/* end::Input group */}

        <div className='fv-row mb-7'>
        <label className='required form-label fw-bolder text-dark fs-6 mb-0'>Role</label>
         <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-kt-user-table-filter="two-step"
              data-hide-search="true"
              value={role}
              onChange={(e) => setRole(+e.target.value)}
            >
              <option value="" disabled selected>
                Select Role
              </option>
              <option value="1" >
                Super Admin
              </option>
              <option value="2">
                Editor
              </option>
              <option value="3">
                Viewer
              </option>
              <option value="4">
                Property Owner
              </option> 
              <option value="5">
                Property Editor
              </option>    
              <option value="6">
                Property Guard
              </option>              
            </select>
      </div>
     {(role == 4 || role == 5 || role == 6) && addListings.length > 0 && <div className='fv-row mb-7'>
      <label className="fw-bold fs-6 mb-2">Selected Listings</label>
      {addListings.map((el, i) => (
                  <div key={i} className="selected col-12" style={{position:'relative', marginBottom:'5px'}}>
                  <input
                    placeholder="Last name"
                    type="text"
                    name="name"
                    className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                    disabled
                    value={el.name}
                    autoComplete="off"
                  />
                    <button
                      className="remove"
                      style={{right:'10px', top:'12px'}}
                      onClick={() =>
                        setAddListings((prev) =>
                          prev.filter((elem) => elem.id !== el.id)
                        )
                      }
                    >
                      <KTIcon iconName="cross" />
                    </button>
                  </div>
                ))}
  </div>
}
      {isManualFormVisible && (role === 4 || role === 5 || role === 6) && (
                  <div className="manualForm fv-row mb-7">
                
                    <label className='required form-label fw-bolder text-dark fs-6 mb-0'>Select Listing</label> (<span style={{color:search.id ? '#2ecc71' : '#ed2626'}}>{search.id ? 'Listing selected' : 'Listing Not selected' }</span>)
                    <div style={{position:'relative'}}>
                    <input
                      type="text"
                      data-kt-user-table-filter="search"
                      className="form-control form-control-solid w-100 "
                      placeholder={
                      "Enter Listing Name"
                      }
                      value={search.name}
                      onChange={(e) => setSearch({
                        id:"",
                        name:e.target.value
                      })}
                    />
                        <button
                      className="remove"
                      style={{right:'10px', top:'12px'}}
                      onClick={() => {
                        setManualFormVisible(false);
                        setSearch({
                          id:"",
                          name:""
                        })
                        setListings([])
                      }}
                    >
                      <KTIcon iconName="cross" />
                    </button>
                    </div>
                        {listings && listings.length > 0 && !search.id &&  <div className="form-control form-control-solid w-100" style={{maxHeight:'150px', overflowY:'auto', minHeight:'auto'}}>
                            {
                              listings.map((item, index) => {
                                return <div key={index} style={{padding:'10px 5px', cursor:'pointer'}} onClick={(e) => {
                                  setSearch({
                                    id:"",
                                    name:""
                                  })
                                  setAddListings([...addListings, {id:item.listing_id, name:item.title}])
                                  setManualFormVisible(false)
                                  setListings([])
                                }}>
                                  {item.title}
                                </div>
                              })
                            }
                            </div>
                  }
                  </div>
                )}
                {!isManualFormVisible  && (role === 4 || role === 5 || (role === 6  && addListings.length === 0))  && (
                  <button
                    onClick={() => setManualFormVisible(true)}
                    style={{ backgroundColor: "#ffa459", color: "#fff" }}
                    className="btn col-12 mb-5"
                  >
                    Assign Listing
                  </button>
                )}
    {/* {role === 1 &&  <div className='fv-row mb-7'>
        <label className='required form-label fw-bolder text-dark fs-6 mb-0'>Select Listing</label> (<span style={{color:search.id ? '#2ecc71' : '#ed2626'}}>{search.id ? 'Listing selected' : 'Listing Not selected' }</span>)
        <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-100 "
            placeholder={
             "Enter Listing Name"
            }
            value={search.name}
            onChange={(e) => setSearch({
              id:"",
              name:e.target.value
            })}
          />
       {listings && listings.length > 0 && !search.id &&  <div className="form-control form-control-solid w-100" style={{maxHeight:'150px', overflowY:'auto', minHeight:'auto'}}>
          {
            listings.map((item, index) => {
              return <div key={index} style={{padding:'10px 5px', cursor:'pointer'}} onClick={(e) => {
                setSearch({
                  id:item.listing_id,
                  name:item.title
                })
              }}>
                {item.title}
              </div>
            })
          }
          </div>
}
      </div>
} */}
        {/* begin::Input group */}
        <div className="fv-row mb-7">
          {/* begin::Label */}
          <label className="required fw-bold fs-6 mb-2">Email</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder="Email"
            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
            type="email"
            name="email"
            autoComplete="off"
            // disabled={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => {
              if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)){
                setEmail(email)
              }else{
                setEmail('')
                toast("Please Enter Valid Email", {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }
            }}
          />
          {/* end::Input */}
        </div>
        {/* end::Input group */}

        {/* begin::Input group */}
        <div className='fv-row mb-7'>
        <label className='required form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          placeholder="Password"
          autoComplete='new-password'
          aria-autocomplete="none"
          className={"form-control form-control-solid mb-3 mb-lg-0"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
   
      </div>
      {/* end::Scroll */}

      {/* begin::Actions */}
      <div className="text-center pt-15">
        <button
          type="reset"
          onClick={() => cancel()}
          className="btn btn-light mx-3"
          data-kt-users-modal-action="cancel"
          // disabled={formik.isSubmitting || isUserLoading}
        >
          Discard
        </button>

        <button
          type="submit"
          className="btn"
          data-kt-users-modal-action="submit"
          style={{ background: "#ffa459", color: "#fff" }}
          disabled={!firstName || !lastName || !email || !password || !role || ((role === 4 || role === 5 || role == 6 ) && addListings.length === 0)}
          onClick={createUser}
        >
          <span className="indicator-label">Submit</span>
        </button>
      </div>
      </form>
      {/* end::Actions */}
    </>
  );
};

export { UserNewModalForm };
