import { useState, useEffect } from "react";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import axios from "axios";
import "./style.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";

const Settings = () => {
  const { currentUser, setCurrentUser, saveAuth, auth } = useAuth();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [access, setAccess] = useState([]);
  const [adminId, setAdminId] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const getUserData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth?get_permissions=true`)
      .then((res) => {
        setEmail(res.data.email);
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setRole(res.data.role);
        setAccess(res.data.access);
        setAdminId(res.data.id);
        setCurrentUser(res.data);
        saveAuth({
          ...auth,
          permissions: res.data.permissions,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const sendOtp = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/auth/password/otp`)
      .then(() => {
        setOtpSent(true);
      })
      .catch(err => {
        console.log(err);
        setOtpSent(false);
        toast.error("Could not send the code");
      });
  };

  const handlePassChange = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/auth/password`, {
      current_password: oldPass,
      new_password: newPass,
      otp
    })
      .then(() => {
        toast.success("Password updated");
        setOldPass("");
        setNewPass("");
      })
      .catch(err => {
        if (err.response.status === 401) {
          toast.error("Invalid current password");
        } else {
          toast.error(err.response.data.message || "Could not change password");
        }
      });
  };

  return (
    <KTCard>
      <KTCardBody>
        <div className="row g-4">
          <div className="input-search col-6">
            <label className="form-label fs-6 fw-bold">First Name</label>
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled
            />
          </div>
          <div className="input-search col-6">
            <label className="form-label fs-6 fw-bold">Last Name</label>
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled
            />
          </div>
          <div className="input-search col-6">
            <label className="form-label fs-6 fw-bold">Email</label>
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
          </div>
          {/* <div className="input-search col-6">
            <label className="form-label fs-6 fw-bold">Role</label>
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-100 "
              placeholder="Space Name"
              value={role == 1 ? "Super Admin" : role == 2 ? "Editor" : role == 3 ? "Viewer" : role == 4 ? "Property Member" : role == 5 ? "Property Editor" : role == 6 ? "Property Guard" : ""}
              // onChange={(e) => setEmail(e.target.value)}
              disabled
            />

          </div> */}

          {access && access.length > 0 && (
            <div className="separator separator-dashed my-5" />
          )}
          {access && access.length > 0 && (
            <div>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h2>Assigned Listings</h2>
              </div>
              <div
                className="mb-10 mt-5 listings-container"
              >
                {access.map((item, index) => (
                  <div
                    className="form-check form-check-custom form-check-solid"
                    key={index}
                    style={{
                      background: "#f9f9f9",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      borderRadius: "5px"
                    }}
                  >
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                      style={{
                        fontWeight: "700",
                        color: "#3f4254",
                        fontSize: "16px",
                        cursor: currentUser.role !== 6 ? "pointer" : "default"
                      }}
                      onClick={() => {
                        currentUser.role !== 6 &&
                        navigate(`/listings/${item.listing_id}`);
                      }}
                    >
                      {item.listing_name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="separator separator-dashed my-5" />
          <div>
            <div>
              <h2>Change Password</h2>
            </div>
            {otpSent && (
              <div className="row g-4 mt-4 mb-4">
                <div className="input-search col-4">
                  <label className="form-label fs-6 fw-bold">Old Password</label>
                  <input
                    type="password"
                    data-kt-user-table-filter="search"
                    className="form-control form-control-solid w-100 "
                    placeholder="Enter Old Password"
                    value={oldPass}
                    onChange={(e) => setOldPass(e.target.value)}
                  />
                </div>
                <div className="input-search col-4">
                  <label className="form-label fs-6 fw-bold">New Password</label>
                  <input
                    type="password"
                    data-kt-user-table-filter="search"
                    className="form-control form-control-solid w-100 "
                    placeholder="Enter New Password"
                    value={newPass}
                    onChange={(e) => setNewPass(e.target.value)}
                  />
                </div>
                <div className="input-search col-4">
                  <label className="form-label fs-6 fw-bold">SMS Code</label>
                  <input
                    data-kt-user-table-filter="search"
                    className="form-control form-control-solid w-100 "
                    placeholder="Enter Code from SMS"
                    value={otp}
                    onChange={(e) => {
                      const re = /^[0-9]*$/;
                      if (!re.test(e.target.value)) {
                        return;
                      } else {
                        setOtp(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <button
              type="button"
              className="btn setting-btn mt-3"
              style={{
                background: "#ffa459",
                color: "#fff",
                height: "46px"
              }}
              onClick={otpSent ? handlePassChange : sendOtp}
              disabled={otpSent && (!newPass || !oldPass || !otp)}
            >
              {otpSent ? "Save Changes" : "Send SMS Code"}
            </button>
          </div>
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default Settings;
