import ReactStars from "react-rating-stars-component";
import {KTIcon } from "../../../../../../../_metronic/helpers";

const UserTwoStepsCell = ({ rating }) => (
  <>
    {[1, 2, 3, 4, 5].map((elem, index) => {
      return (
        <KTIcon
          key={index}
          iconName="star"
          className={
            elem <= rating
              ? "active-rating"
              : "inactive-rating"
          }
        />
      );
    })}
  </>
)

export { UserTwoStepsCell }
